import {
  LegacyAssignment,
  LegacyAssignmentActivity,
} from "../../types";
import { 
  mathEntryPoints,
  readingEntryPoints,
  spanishEntryPoints,
} from "../../data/subject-entry-points";

export function isActivityInSubject(
  activity: LegacyAssignmentActivity,
  subject: string | undefined = undefined
): boolean {
  if (!subject) {
    return true;
  }
  if (!activity.startTag) {
    return false;
  }
  const entrypoints = {
    'reading': readingEntryPoints,
    'math': mathEntryPoints,
    'lectura': spanishEntryPoints,
  }[subject.toLowerCase()] || undefined;
  const [s, ...rest] = activity.startTag.split('.');
  return !!entrypoints && entrypoints.includes(s)
}

export function isLegacyAssignmentComplete(
  assignment: LegacyAssignment,
  subject: string | undefined = undefined
): boolean {
  if (!assignment || !assignment.details) {
    return true;
  }

  return Object.values(assignment.details)
    .filter((activity) => isActivityInSubject(activity, subject))
    .every((activity) => isLegacyActivityComplete(activity));
}

export function isLegacyActivityComplete(activity: LegacyAssignmentActivity): boolean {
  // if there's no activity, we can probably consider it complete
  return !activity || activity.status === 'C';
}
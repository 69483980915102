<div class="sys debugmodule">
  <div class="column-nav">
    <ul class="toc-list">
      <li *ngFor="let section of tableOfContents">
        <a routerLink="" (click)="setView(section.view)">{{section.name}}</a>
        <ul class="toc-list">
          <li *ngFor="let subsection of section.content">
            <a routerLink="" (click)="scrollTo(section.view, subsection.id)">{{subsection.name}}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="column-content" id="debug-content" [ngSwitch]="currentView">
    <swe-isip-debug *ngSwitchCase="debugView.ISIP_DATA"></swe-isip-debug>
    <swe-flag-override *ngSwitchCase="debugView.FLAG_OVERRIDE"></swe-flag-override>
    <swe-general-debug *ngSwitchDefault></swe-general-debug>
  </div>
</div>
import { ApplicationRef, ComponentRef, createComponent, inject, Injectable } from "@angular/core";
import { ImageData } from "@swe/types";
import { DOCUMENT } from "@angular/common";
import { WINDOW } from "@ng-web-apis/common";
import { ToastComponent } from "@swe/shared/components";
import { SvgIconComponent } from "angular-svg-icon";

export type ToastMessageOptions = {
  /** The message to display to the user in the toast */
  message?: string;

  /** The image data to display */
  icon?: ImageData;

  /** The label preceeding the countdown */
  retryLabel?: string;

  /** The text content of the action button */
  actionLabel?: string;
  
  /** Callback to complete when toast is disposed (primarily for retry) */
  actionHandler?: () => void;

  /** Show or display countdown */
  showCountdown?: boolean;
  
  /** Duration the toast message is displayed before it is removed */
  duration?: number;
};

@Injectable()
export class ToastService {
  static DEFAULT_MESSAGE = 'An unknown error occurred';
  static DEFAULT_RETRY_LEADER = 'Retry in';
  static DEFAULT_MESSAGE_CLASS = 'message';
  static DEFAULT_ACTION_CLASS = 'btn';
  static DEFAULT_ACTION_LABEL = 'Retry';
  static DEFAULT_EXPIRATION = 5000;

  public actionHandler?: () => void;

  private document = inject(DOCUMENT);
  private window = inject(WINDOW);
  private appRef: ApplicationRef = inject(ApplicationRef);
  private toastContainer?: ComponentRef<ToastComponent>;
  private elementID = 'toast-container';
  private disposeAfter = ToastService.DEFAULT_EXPIRATION;
  private disposalTimeout?: number;
  private updateInterval?: number;

  showMessage(options?: ToastMessageOptions): void{
    // Destory existing containers
    if (this.toastContainer) {
      this.disposeMessage();
    }

    const hostElement = document.getElementById(this.elementID) ?? document.createElement('swe-toast');
    const outlet = document.querySelector('main') as HTMLElement;
    const environmentInjector = this.appRef.injector;
    const projectableNodes: Node[][] = [];
    const showCountdown = options?.showCountdown ?? true;
    const retryLeader = options?.retryLabel ?? ToastService.DEFAULT_RETRY_LEADER;

    hostElement.id = this.elementID;
    hostElement.classList.add('sys');

    // Setup disposal delay
    this.disposeAfter = options?.duration ?? ToastService.DEFAULT_EXPIRATION;  

    // Add toast message content
    const message = this.document.createElement("SPAN");
    message.classList.add(ToastService.DEFAULT_MESSAGE_CLASS);
    message.innerText = options?.message ?? ToastService.DEFAULT_MESSAGE;
    projectableNodes[1] = [message];
    if (showCountdown) {
      const countdown = this.document.createElement("SPAN");
      countdown.classList.add('countdown');
      countdown.innerText = `(${retryLeader} ${this.disposeAfter / 1000}s)`;
      projectableNodes[1][1] = countdown;
    }

    // Add the toast icon
    if (options?.icon?.url && options.icon.url.includes('svg')) {
      const icon = createComponent(SvgIconComponent, {environmentInjector});
      icon.location.nativeElement.id = 'toast-icon';
      icon.setInput('src', options.icon.url);
      this.appRef.attachView(icon.hostView);
      projectableNodes[0] = [icon.location.nativeElement];
    } else if (options?.icon?.url) {
      const icon = this.document.createElement("IMG") as HTMLImageElement;
      icon.id = options.icon.id; // ImageData.id is required
      icon.src = options.icon.url;
      icon.alt = options.icon.altText ?? '';
      projectableNodes[0] = [icon];
    } else {
      projectableNodes[0] = [];
    }

    // Add the toast action button 
    if (options?.actionLabel) {
      const button = this.document.createElement("BUTTON") as HTMLButtonElement;
      button.type = "button";
      button.classList.add(ToastService.DEFAULT_ACTION_CLASS);
      button.innerText = options.actionLabel;
      button.addEventListener('click', () => this.disposeMessage());
      projectableNodes[2] = [button];
    } else {
      projectableNodes[2] = [];
    }

    // Add the action
    if (options?.actionHandler && typeof options.actionHandler === 'function') {
      this.actionHandler = options.actionHandler;
    }

    // Render view and attach container into the DOM
    outlet.appendChild(hostElement);
    this.toastContainer = createComponent(ToastComponent, {
      hostElement,
      environmentInjector,
      projectableNodes
    });
    this.appRef.attachView(this.toastContainer.hostView);

    // Setup automatic disposal
    this.disposalTimeout = this.window.setTimeout(() => this.disposeMessage(), this.disposeAfter);

    // Update view every second
    let remainingTime = this.disposeAfter;
    this.updateInterval = this.window.setInterval(() => {
      remainingTime -= 1000;
      if (remainingTime > 0) {
        const displayedMessage = this.toastContainer?.location.nativeElement.querySelector(`.toast-${ToastService.DEFAULT_MESSAGE_CLASS}`) as HTMLElement;
        const countdown = this.document.createElement("SPAN");
        countdown.classList.add('countdown');
        countdown.innerText = `(${retryLeader} ${remainingTime / 1000}s)`;
        projectableNodes[1][1] = countdown;
        displayedMessage.replaceChildren(...projectableNodes[1]);
        this.toastContainer?.changeDetectorRef.detectChanges();  
      }
    }, 1000);

    // Update view
    this.toastContainer.changeDetectorRef.detectChanges();
  }

  disposeMessage() {
    if(this.actionHandler && typeof this.actionHandler === 'function') {
      this.actionHandler();
      delete this.actionHandler;
    }
    if (this.updateInterval) {
      this.window.clearInterval(this.updateInterval);
      delete this.updateInterval;
    }
    if (this.disposalTimeout) {
      this.window.clearTimeout(this.disposalTimeout);
      delete this.disposalTimeout;
    }
    if (this.toastContainer) {
      this.toastContainer.destroy();
      delete this.toastContainer;
    }
  }
}
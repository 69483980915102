export class LAUNCH_STATUS {
    static INITIAL  = new LAUNCH_STATUS('INITIAL');
    static LOADING  = new LAUNCH_STATUS('LOADING');
    static LOADED   = new LAUNCH_STATUS('LOADED');
    static LAUNCHED = new LAUNCH_STATUS('LAUNCHED');
    name: string;

    constructor (name: string) {
        this.name = name;
    }

    toString() {
       return `${this.name}`;
    }
}
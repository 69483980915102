<div class="safe-title">
    <div class="safe-action">
        <header class="header">
            <span class="header-logo">
                <span class="placeholder"></span>
            </span>
            <span class="header-notification">
                <span class="placeholder"></span>
            </span>
            <span class="header-user">
                <span class="placeholder"></span>
            </span>
        </header>
    </div>
</div>

import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'swe-progress-bar',
  template: `<progress
    id="progress"
    [max]="max"
    aria-valuemin="0"
    aria-valuemax="max"
    aria-valuenow="value"
    aria-valuetext="Loading Main Menu..."
  ></progress>`,
  styles: [
    `
      :host {
        width: 100%;
      }
      progress {
        width: 100%;
        height: 10px;
      }
    `,
  ],
})
export class ProgressBarComponent {
  @Input() max = 100;
  @Input() value = 0;
}

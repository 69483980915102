import { IsipSubtestName, IsipSubtestNameEs, IsipSubtestNameMath } from "@swe/enums/isip-names";
import { DebugSubjectSubtests } from "../types/";

export const SUBTESTS: DebugSubjectSubtests[] = [
  {
    name: "Reading",
    subtests: Object.keys(IsipSubtestName).map(key => [key, IsipSubtestName[key as keyof typeof IsipSubtestName]])
  },
  {
    name: "Lectura",
    subtests: Object.keys(IsipSubtestNameEs).map(key => [key, IsipSubtestNameEs[key as keyof typeof IsipSubtestNameEs]])
  },
  {
    name: "Math",
    subtests: Object.keys(IsipSubtestNameMath).map(key => [key, IsipSubtestNameMath[key as keyof typeof IsipSubtestNameMath]])
  }
];
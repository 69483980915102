import { Component } from '@angular/core';
import { UserService } from '../services/user.service';
import { THEMES } from '../data/themes';
import { GRADE_BANDS } from '../data/grade-bands';
import { openDebugConsoleEventName } from '../app.component'
import { GradeBands } from '../enums';

@Component({
  selector: 'swe-theme-switcher',
  template: `
    <aside [class]="isOpen?'active':''">
      <button type="button" (click)="toggleOverlay()" [class]="'btn-overlay' + (hasOverlay?' active':'')" [hidden]="!isOpen">◨ Overlay</button>
      <div class="selector">
        <label id="GradeBandSelector">Grade Band</label>
        <select name="grade" class="form-control" (change)="handleGradeChange($event)" aria-labelledby="GradeBandSelector" [hidden]="!isOpen">
          <option *ngFor="let band of gradeBands" [value]="band.id" [selected]="gradeBand === band.id" >{{band.label}}</option>
        </select>
      </div>
      <div class="selector">
        <label id="ThemeSelector">Theme</label>
        <select name="theme" class="form-control" (change)="handleThemeChange($event)" aria-labelledby="ThemeSelector" [hidden]="!isOpen">
          <option *ngFor="let theme of themes" [value]="theme.short_name" [selected]="this.userService.theme.value === theme.short_name" >{{theme.name}}</option>
        </select>
      </div>
      <div>
        <br />
        <button type="button" (click)="openDebugConsole()">Debug Console</button>
        <br />
        <a href="/components-test">Components Test Page</a>
      </div>
      <button type="button" (click)="isOpen = !isOpen" class="btn-switcher"></button>
    </aside>
  `,
  styles: [
`
aside {
  position: absolute;
  width: 250px;
  bottom: 8.5rem;
  left: -285px;
  background: white;
  border: 1px solid #efefef;
  padding: 2.25rem .5rem .5rem 2rem;
  margin: 0;
  border-radius: 0 .5rem .5rem 0;
  color: black;
  font-family: var(--sys-font-family);
  transition: left 500ms ease;
  z-index: 2;

  .btn-switcher {
    position: absolute;
    top: calc(100% - 60px);
    right: -120px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 10px;
    width: 60px;
    height: 60px;
    border: 0;
    border-radius: 50%;
    padding: .25em;
    color: white;
    transition: all 250ms 125ms ease;
    overflow: hidden;

    &::before {
      content: '\\273B';
      background: paleturquoise linear-gradient(to bottom right, palegreen, paleturquoise) no-repeat;
      display: inline-block;
      padding: 5px;
      border: 1px solid rgba(255,255,255,.7);
      text-shadow: 0 2px 2px rgba(0,0,0,.2);
      box-shadow: 0 2px 4px rgba(0,0,0,.4);
    }
  }

  &.active {
    left: 0;

    .btn-switcher {
      top: -20px;
      right: -20px;
      width: 2rem;
      height: 2rem;
      background: pink;
      color: darkred;

      &::before {
        content: '\\00d7';
        background: transparent;
        border: 0;
        text-shadow: none;
        box-shadow: none;
      }
    }
  }

  &::after {
    content: "THEMES";
    position: absolute;
    left: 2rem;
    top: 8px;
    color: rgba(127,127,127,0.25);
    font-size: 1rem;
    letter-spacing: .2em;
    pointer-events: none;
    z-index: 0;
  }
}
.btn-overlay {
  background: pink;
  border: 0;
  padding: 0.3em 0.8em;
  border-radius: 0.3em;
  box-shadow: 0 3px 3px rgba(0,0,0,0.2);
  margin-left: auto;
  position: absolute;
  top:6px;
  right:13px;

  &.active {
    box-shadow: inset 0 3px 3px rgba(0,0,0,0.2);
  }
}
.selector {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: .75rem;
  background: rgb(237,237,237);
  padding: 3px .8em;
  margin: 3px;
  border-radius: .8em;
  label {
    margin-right: 5px;
  }
}
ul {
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
}
li {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 5px;
  padding: 5px;
  text-align: center;
  flex: 1;
  border-radius: .5rem;
  background: transparent;
  border: 1px solid transparent;
  transition: background .25s;

  &.active {
    border-color: orange;
  }

  &:hover {
    background: orange;
  }
}
h4, h5 {
  margin: 0;
  padding: 0;
}
h4 {
  font-size: .9rem;
  font-weight: bold;
}
h5 {
  font-size: .8rem;
  font-style: italic;
  font-weight: normal;
}
`
  ],
})
export class ThemeSwitcherComponent {
  hasOverlay = false;
  isOpen = false;
  gradeBand = 0;
  gradeBands = GRADE_BANDS;
  themes = THEMES.filter(({isDefault, isActive}) => isDefault && isActive);

  constructor(
    public userService: UserService
  ) {
    this.hasOverlay = this.userService.overlayEnabled.value;
  }

  handleThemeChange($event: Event) {
    const selectedTheme = ($event.currentTarget as HTMLSelectElement).value;
    this.userService.theme.next(selectedTheme);
  }

  handleGradeChange($event: Event) {
    const selectedBand = parseInt(($event.currentTarget as HTMLSelectElement).value, 10);
    this.themes = THEMES
      .filter(({isDefault, isActive, availableGradeBands}) => isDefault && isActive && availableGradeBands.indexOf(selectedBand) >= 0);
      //skip setting gradeBand to GradeBands.NONE, so selecting it doesn't lock up the user
      if(selectedBand !== GradeBands.NONE){
        this.userService.gradeBand.next(selectedBand);
      }
  }

  toggleOverlay(){
    this.hasOverlay = !this.userService.overlayEnabled.value;
    this.userService.overlayEnabled.next(this.hasOverlay);
  }

  openDebugConsole(): void {
    window.dispatchEvent(new CustomEvent(openDebugConsoleEventName));
  }
}

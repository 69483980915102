import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { SeriesCard } from '../../types';
import { SeriesCardComponent } from '../series-card/series-card.component';

@Component({
  selector: 'swe-series-carousel',
  templateUrl: './series-carousel.component.html',
  styleUrls: ['./series-carousel.component.scss'],
})
export class SeriesCarouselComponent implements AfterViewInit {
  @Input() series!: SeriesCard[];
  @ViewChild(SeriesCardComponent, { read: ElementRef }) 
  seriesCard!: ElementRef;
  @ViewChild('carousel', { read: ElementRef })
  carousel!: ElementRef;

  get index(): number {
    return this._index;
  }
  set index(value: number) {
    if (value < 1) {
      this._index = 0;
    } else if (value >= this.series.length - 1 ) {
      this._index = this.series.length - 1;
    } else {
      this._index = value;
    }
    if (this._index <= 0) { 
      this.showPrev = false;
    } else if (this._index > 0 && this._index < this.series.length){
      this.showPrev = true;
      this.showNext = true;
    }
    if (this._index >= this.series.length - 1) {
      this.showNext = false;
    }
    this.carousel.nativeElement.style.transform = `translateX(-${this._index * this.offset}px)`
  }

  showPrev = false;
  showNext = false;

  private offset = 20.8;
  private _index = 0;

  ngAfterViewInit(): void {
    this.prepareLayout();
  }

  scroll($event: Event): void {
    const { className: buttonType } = $event.currentTarget as HTMLButtonElement;
    buttonType.includes('next') ? this.index++ : this.index--;
  }

  @HostListener("window:resize")
  private prepareLayout() {
    if (this.seriesCard && this.carousel) {
      const { scrollWidth, clientWidth } = this.carousel.nativeElement;
      const styles = getComputedStyle(this.carousel.nativeElement);
      const gapWidth = parseInt(/\d+/.exec(styles.getPropertyValue('gap'))?.pop() ?? '0', 10);
      const { width: cardWidth } = this.seriesCard.nativeElement.getBoundingClientRect();
      this.offset = cardWidth + gapWidth;
      if (scrollWidth > clientWidth) {
        setTimeout(() => { this.showNext = true });
      }
    }
  }
}

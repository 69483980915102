import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Directive({
  selector: '[sweFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  private flags: string | string[] = '';
  private isHidden = false;
  @Input() set sweFeatureFlag(val: string | string[]) {
    if (val) {
      this.flags = val;
      this.updateView();
    }
  }
  
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<unknown>,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() : void {
    this.updateView();
  }

  private updateView() {
    if (this.checkFlag()) {
      if (this.isHidden) {
        this.view.createEmbeddedView(this.template);
        this.isHidden = false;
      } else {
        this.view.clear();
        this.isHidden = true;
      }
    }
  }

  private checkFlag(): boolean {
    return this.featureFlagService.isFlagEnabled(this.flags);
  }
}

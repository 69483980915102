import { UsageByProduct } from "../types";

export class ProductUsage implements UsageByProduct {
    usageSeconds: number;
    usageGoalSeconds: number;

    constructor(seconds = 0, goalSeconds = 2400){
        this.usageSeconds = seconds;
        this.usageGoalSeconds = goalSeconds;
    }

    get usageMinutes(): number {
        return Math.round(this.usageSeconds/60);
    }

    get usageGoalMinutes(): number {
        return Math.round(this.usageGoalSeconds/60);
    }
}
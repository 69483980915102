<svg-icon
  *ngIf="hasVideo"
  class="node-video"
  src="assets/icons/episode-icons/question-video.svg"
></svg-icon>

<ng-container *ngFor="let node of nodeStates; index as i">
  <span [ngClass]="'path-' + (node.includes('unanswered') ? node : 'answered')"></span>
  <svg-icon
    *ngIf="!node.includes('unanswered'); else unanswered"
    class="node-question"
    [ngClass]="node"
    [src]="'assets/icons/episode-icons/question-' + node + '.svg'"
  ></svg-icon>
  <ng-template #unanswered>
    <span class="node-question unanswered">{{ i + 1 }}</span>
  </ng-template>
</ng-container>

import { Component, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'swe-avatar',
  template: `<figure class="avatar">
    <div class="avatar-mask {{flair}}" [style.background-color]="bkgdColor">
      <img
        src="{{ path  }}"
        class="nav-avatar-img"
        [attr.aria-label]="'Student Avatar ' + avatar"
        alt="Student Avatar {{ avatar }}"
        (error)="handleLoadError($event)"
      />
    </div>
  </figure>`,
  styles: [
    `
	:host {
		display: inline-block;
		height: 100%;
		width: 100%;
	}
	.avatar {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    width: 100%;

    position:relative;
    padding-top: 100%;

    img {
      min-width: 90%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
		}

    @mixin shapeStyle($radius, $max, $height) {
      border-radius: $radius;

			img {
				max-width: $max;
				max-height: $max;
				height: $height;
			}
		}

    .avatar-mask {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      width: 100%;
      height: 100%;

      &.circle {
        @include shapeStyle(50%, 100%, 100%);
      }
      &.square {
        @include shapeStyle(0, 90%, auto);
      }
    }
	}
  `,
  ],
})
export class AvatarComponent {
  @Input()
  get avatar(): number {return this.#avatar;}
  set avatar(value: number) {
    this.#avatar = value;
    this.path = value !== -1 ? this.knownPath : this.unknownPath;
  }
  @Input() flair = 'square';
  @Input() bkgdColor = 'transparent';

  readonly pathRoot: string = this.configService.config.avatarAssetsPath;
  readonly unknownPath: string = this.pathRoot + 'unknown.svg';

  get knownPath(): string {return this.pathRoot + 'avatar_' + this.#avatar + '.svg';}
  path: string = this.unknownPath;

  #avatar = -1;

  //it'd be super cool if we could get descriptions of avatars and set the label dynamically.
  constructor(
    public userService: UserService,
    private configService: ConfigurationService
  ){}

  /**
   * Handles the avatar image not being found. Two sources are attempted:
   * 1. SVG version of the avatar (set in the template)
   * 3. An SVG "unknown" avatar on failure
   * @param event
   */
  handleLoadError(event: Event): void {
    const src = new URL((event.target as HTMLImageElement).src).pathname;
    if (src !== this.unknownPath) {
      console.log('Could not load avatar. Falling back to \'unknown\' avatar');
      (event.target as HTMLImageElement).src = this.unknownPath;
    } else {
      console.log('Could not load last resort unknown avatar');
    }
  }
}

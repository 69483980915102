import {
  IsipMonthInfo,
  IsipSubtest,
  StudentSubject,
  StudentSubjectIsip,
  StudentSubscription,
} from '../types';
import subjectsIsipInfo from './subjects-isip-info';
import subjectsInfo from './subjects-info';

export class StudentSubscriptionSubject implements StudentSubject {
  _name = "";
  name = "";
  sortPriority = 0;
  cardValue = 0;
  cardState = 0;
  image = "";
  alt_text = "";
  subheading?: string;
  description?: string;
  link: Array<string> = [];
  footerLabel?: string;
  usage?: number;
  related?: string;
  isVerbal = false;
  language = "";
  modelingForGrades?: number[];
  limitToGrades?: number[];
  isNeeded = false;

  private DEFAULT = {
    _name: "",
    name: "",
    sortPriority: 0,
    cardValue: 0,
    cardState: 0,
    image: "",
    alt_text: "",
    link: [],
    isVerbal: false,
    language: "",
    isNeeded: false,  
  };
  
  constructor(subject: Partial<StudentSubject>){
    Object.assign(this, this.DEFAULT, subject);
  }

  isGradeAppropriate(grade: number) {
    return  this.limitToGrades ? this.limitToGrades.includes(grade) : true;
  }
}

export class StudentSubscriptionIsip extends StudentSubscriptionSubject {
  startingPeriod = 0;
  period?: number;
  lastCompleted?: Date;
  isRunningAdhoc = false;
  frequency = 1;
  subtests: IsipSubtest[] = [];
  totalScores: IsipMonthInfo[] = [];
  tier = 0;
  goals?: IsipMonthInfo[];
  isVerbal = false;
  kbIsipFolder = '';

  constructor(isip: Partial<StudentSubjectIsip>) {
    super(isip);
  }
}

/**
 * Indexes for Subjects:
 * 		0 = Reading
 * 		1 = Lectura/Spanish
 * 		2 = Math
 * 
 * For ISIPs:
 * 		(Same as above, for when ISIPs are NOT due.)
 * 		3 = Reading, ISIP due, 4-digit scores, has not taken ISIP yet
 * 		4 = Spanish, ISIP due, 4-digit scores, taken ISIP before, no adhoc
 * 		5 = Math, ISIP due, 4-digit scores, taken ISIP before, adhoc
 * 
 * 		6 = Reading, ISIP due, 3-digit scores, taken ISIP before, adhoc
 * 		7 = Spanish, ISIP due, 3-digit scores, has not taken ISIP yet
 * 		8 = Math, ISIP due, 3-digit scores, taken ISIP before, no adhoc
 */

const {Reading, Spanish, Math} = subjectsInfo;
/* eslint-disable @typescript-eslint/no-unused-vars */
const [
  ReadingIsip,
  SpanishIsip,
  MathIsip,
  ReadingIsipEmpty, // Reading, ISIP due, 4-digit scores, has not taken ISIP yet
  SpanishIsipPrimed, // Spanish, ISIP due, 4-digit scores, taken ISIP before, no adhoc
  MathIsipPrimedAdhoc, // Math, ISIP due, 4-digit scores, taken ISIP before, adhoc
  ReadingIsipPrimedAdhoc, // Reading, ISIP due, 3-digit scores, taken ISIP before, adhoc
  SpanishIsipEmpty, // Spanish, ISIP due, 3-digit scores, has not taken ISIP yet
  MathIsipPrimed // Math, ISIP due, 3-digit scores, taken ISIP before, no adhoc
] = subjectsIsipInfo;
/* eslint-enable @typescript-eslint/no-unused-vars */

const subscriptions: StudentSubscription[] = [
  {// 0
    subjects: [
      new StudentSubscriptionSubject(Reading),
      new StudentSubscriptionSubject(Spanish),
      new StudentSubscriptionSubject(Math),
    ],
    isips: [
      new StudentSubscriptionIsip(ReadingIsip),
      new StudentSubscriptionIsip(SpanishIsip),
      new StudentSubscriptionIsip(MathIsip)
    ],
    verbals: []
  },
  {// 1
    subjects: [
      new StudentSubscriptionSubject(Reading),
      new StudentSubscriptionSubject(Math),
    ],
    isips: [
      new StudentSubscriptionIsip(ReadingIsip),
      new StudentSubscriptionIsip(MathIsipPrimedAdhoc),
    ],
    verbals: []
  },
  {// 2
    subjects: [
      new StudentSubscriptionSubject(Reading),
      new StudentSubscriptionSubject(Spanish),
    ],
    isips: [
      new StudentSubscriptionIsip(ReadingIsipEmpty),
      new StudentSubscriptionIsip(MathIsip),
    ],
    verbals: []
  },
  {// 3
    subjects: [
      new StudentSubscriptionSubject(Spanish),
      new StudentSubscriptionSubject(Math),
    ],
    isips: [
      new StudentSubscriptionIsip(SpanishIsip),
      new StudentSubscriptionIsip(MathIsip),
    ],
    verbals: []
  },
  {// 4
    subjects: [],
    isips: [
      new StudentSubscriptionIsip(ReadingIsip),
      new StudentSubscriptionIsip(SpanishIsip),
      new StudentSubscriptionIsip(MathIsipPrimedAdhoc),
    ],
    verbals: []
  },
  {// 5
    subjects: [
      new StudentSubscriptionSubject(Reading),
    ],
    isips: [
      new StudentSubscriptionIsip(ReadingIsip),
      new StudentSubscriptionIsip(SpanishIsip),
      new StudentSubscriptionIsip(MathIsipPrimedAdhoc),
    ],
    verbals: []
  },
  {// 6
    subjects: [
      new StudentSubscriptionSubject(Reading),
      new StudentSubscriptionSubject(Spanish),
      new StudentSubscriptionSubject(Math),
    ],
    isips: [
      new StudentSubscriptionIsip(ReadingIsipPrimedAdhoc),
      new StudentSubscriptionIsip(SpanishIsipEmpty),
      new StudentSubscriptionIsip(MathIsipPrimedAdhoc),
    ],
    verbals: []
  },
];

export default subscriptions;

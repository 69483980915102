import { v5 as uuidv5 } from 'uuid';

export class JumpPathTable {
    table: Map<string, string>;
    constructor(routes?: {[index: string]: string}) {
        this.table = new Map();
        if (routes) {
            Object.entries(routes).map(([k, v]) => this.set(k, v));
        }
    }

    /**
     * Rather than creating an arbitrary hash which has no relationship to the 
     * key in the index - we'll need to have more predictable way to translate
     * to/from a jump path string.
     */
    _hash(key: string): string {
        return uuidv5(key, uuidv5.URL);
    }

    locate(uuid: string): string | undefined {
        return this.table.get(uuid);
    }

    set(key: string, value: string): void {
        const index = this._hash(key);
        this.table.set(index, value);
    }

    get(key: string): string | undefined {
        const target = this._hash(key);
        return this.table.get(target);
    }

    remove(key: string): boolean {
        const index = this._hash(key);
        return this.table.delete(index)
    }

    get size(): number {
        return this.table.size;
    }
}
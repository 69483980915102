import { Injectable } from '@angular/core';

@Injectable()
export class LottieLoaderService {
  private _loading = false;
  private _loaded = false;
  async load() {
    if (!this._loaded && !this._loading) {
      this._loading = true;
      await import('@lottiefiles/lottie-player');
      this._loading = false;
      this._loaded = true;
    }
  }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'swe-number-slider',
	template: `
<div
	class="number-slider"
	[style.--duration]="duration + 'ms'"
>
	<span 
		class="number-slider-digit"
		*ngFor="let digitValue of digitArray"
	>
		<span class="number-slider-digit-spacer">8</span>
		<span class="number-slider-digit-ribbon" [ngClass]="{'doAnims': startAnims}">
			<span 
				class="number-slider-digit-ribbon-section"
				*ngFor="let __ of [].constructor(digitValue + (isBlankFirst ? 2 : 1)); let j=index"

			>
				{{isBlankFirst 
					? ((j === 0) ? "" : j-1)
					: j
				}}
			</span>
		</span>
	</span>
</div>`,
	styles: [
`.number-slider {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	height: 100%;

	&-digit {
		overflow: hidden;

		&-spacer {
			position: relative;
			visibility: hidden;
		}

		&-ribbon {
			display: flex;
			flex-direction: column;
			transition: transform var(--duration) ease-out;
			transform: translateY(0%);

			&.doAnims {
				transform: translateY(-100%);
			}
		}
	}
}`
],
})
export class NumberSliderComponent implements OnInit {
	@Input() value = 0;				// the value for the counter to stop at
	@Input() duration = 1500;		// animation duration in milliseconds
	@Input() isBlankFirst = false;	// "true" to start with a blank/empty view
										// "false" to start with a row of "0"s
	// When [_startAnims] is set to "true", the counter animation will start
	private _startAnims = false;
	public digitArray!: number[];
	@Input() 
	set startAnims(doAnims: boolean) {
		if (doAnims && !this._startAnims) {
			this._startAnims = true;
		}
	}
	get startAnims(): boolean {
		return this._startAnims;
	}

	ngOnInit(): void {
		this.digitArray = [];
		const valueStrArray = Array.from(this.value.toString());
		valueStrArray.forEach(digitStr => this.digitArray.push(parseInt(digitStr, 10)));
	}
}

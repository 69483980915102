import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DirectURLGuard  {

  router = inject(Router);

  canActivate(): boolean | UrlTree {
    // Redirects to home if URL is not coming directly from button
    return !!this.router.getCurrentNavigation()?.previousNavigation
      || this.router.createUrlTree(['/home']);
  }

}

<form #flagsForm="ngForm">
  <fieldset [ngClass]="{'unsaved-form': flagsForm.dirty}" id="flag-override-in-config">
    <legend>In Configuration</legend>
    <table>
      <tr class="spaced-row">
        <td class="column-header">Flag</td>
        <td class="column-header">Override Value</td>
        <td class="column-header">Value in Config</td>
        <td class="column-header">Force/Clear</td>
      </tr>
      <tr
        *ngFor="let item of flagsModel | keyvalue;"
        class="spaced-row"
        [ngClass]="{'changed-flag': isOverridden(item.key)}"
      >
        <td class="custom-control custom-switch">
          <input
              id="flag-{{item.key}}"
              class="custom-control-input switch form-check"
              type="checkbox"
              name="flag-{{item.key}}"
              [(ngModel)]="flagsModel[item.key]"
              (change)="toggleFlag(item.key);"
              
            />
          <label for="flag-{{item.key}}" class="custom-control-label">{{item.key}}</label>
        </td>
        <td style="text-align: center;">{{overrides[item.key]}}</td>
        <td style="text-align: center;">{{configValues[item.key]}}</td>
        <td style="text-align: center;">
          <button 
            *ngIf="isOverridden(item.key)"
            (click)="clearOverride(item.key);"
          >&#x274C;</button>
          <button 
            *ngIf="!isOverridden(item.key)"
            (click)="addOverride(item.key);"
          >&#x2795;</button>
        </td>
      </tr>
    </table>
    <button (click)="clearAllOverrides();">Clear Overrides</button>
  </fieldset>
  <fieldset [ngClass]="{'unsaved-form': flagsForm.dirty}" id="flag-override-custom">
    <legend>Custom</legend>
    <table>
      <tr class="spaced-row">
        <td class="column-header">Flag</td>
        <td class="column-header">Add/Clear</td>
      </tr>
      <tr
        *ngFor="let item of customFlags | keyvalue;"
        class="spaced-row"
      >
        <td class="custom-control custom-switch">
          <input
              id="custom-flag-{{item.key}}"
              class="custom-control-input switch form-check"
              type="checkbox"
              name="custom-flag-{{item.key}}"
              [(ngModel)]="customFlags[item.key]"
            />
          <label for="custom-flag-{{item.key}}" class="custom-control-label">{{item.key}}</label>
        </td>
        <td style="text-align: center;">
          <button 
            (click)="clearCustom(item.key);"
          >&#x274C;</button>
        </td>
      </tr>
      <tr>
        <td class="custom-control custom-switch">
          <input
              id="add-custom-flag-value"
              class="custom-control-input switch form-check"
              type="checkbox"
              name="add-custom-flag-value"
              [(ngModel)]="newCustomValue"
            />
          <label for="add-custom-flag-value" class="custom-control-label">
            <input
              id="custom-flag-name"
              class="form-control"
              type="text"
              name="custom-flag-name"
              [(ngModel)]="newCustomName"
            />
          </label>
        </td>
        <td>
          <button
            (click)="addCustomFlag()"
          >&#x2795;
          </button>
        </td>
      </tr>
    </table>
    <button (click)="clearAllCustomFlags();">Clear Custom Flags</button>
  </fieldset>
  <button (click)="clearAll();">Clear All</button>
  <button (click)="apply();">Apply</button>
</form>
<div class="unsaved-warning" *ngIf="flagsForm.dirty">Potentially unsaved changes!</div>
import { Injectable, inject } from '@angular/core';
import { throwError } from 'rxjs';
import { ContentDataRequestInput } from '@swe/types';
import { IstationSubjects, Grades } from '@swe/enums';
import { ContentMetadataService, LessonProgressService } from '.';
import {
  EpisodeKbData,
  SeriesCard,
  SkillGroup,
  SubjectDomain,
} from '@swe/pages/lesson/types';
import { EpisodeCard } from '@swe/pages/lesson/classes';
import { AssignmentCard } from '@swe/pages/assignments/classes';
import { getMicrolessonThumbnail, getSeriesCoverImage } from '@swe/utils';
import * as cryptojs from 'crypto-js';
import { getIstationSubject } from '@swe/shared/utilities';

export enum SubjectNames {
  READING = 'Reading',
  MATH = 'Math',
  LECTURA = 'Lectura',
}

export type ErrorHandler = {
  error?: ErrorEvent;
  status: number;
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class LessonService {
  public sections: SubjectDomain[] = [];

  private grade = Grades.None;
  private cache: Map<
    string,
    { expiration: number; sections: SubjectDomain[] }
  > = new Map();
  private cacheExpiration = 5 * 60 * 1000; // 5 minutes
  private contentMetadataService: ContentMetadataService = inject(
    ContentMetadataService
  );
  private lessonProgressService: LessonProgressService = inject(
    LessonProgressService
  );

  get availableGrades(): number[] {
    return Array.from(
      new Set(this.sections.flatMap(({ availableGrades }) => availableGrades))
    )
      .filter((e) => !!e)
      .sort() as number[];
  }

  async getLessonsBySubject(
    subject: string,
    grade?: number
  ): Promise<SubjectDomain[]> {
    let sections: SubjectDomain[];
    this.grade = grade !== undefined ? grade : this.grade;
    const subjectFilter = [
      getIstationSubject(subject),
    ];
    const metadataInput = <ContentDataRequestInput>{
      subjectFilter,
      grades: this.gradesToRequest(this.grade),
    };
    const hash = cryptojs.MD5(JSON.stringify(metadataInput)).toString();
    const cachedResult = this.cache.get(hash);
    if (cachedResult && Date.now() < cachedResult.expiration) {
      sections = cachedResult.sections;
    } else {
      const [hierarchy] =
        await this.contentMetadataService.getContentByHierarchy(metadataInput);
      sections =
        !hierarchy || !hierarchy.sections
          ? []
          : hierarchy.sections.map((domain) => {
              const domainGrades = <number[]>[];
              return {
                title: domain.name,
                skills:
                  domain.sections?.map((skill) => {
                    const episodes =
                      skill.content?.map(
                        ({
                          oid,
                          uniqueId,
                          title,
                          contentMedia,
                          learningTargets,
                          description,
                          questionCount: numQuestions,
                          grades,
                          teitypes: teiTypes,
                          manifest,
                          episodeNumber,
                          domain: domainName,
                          studentFriendlyStandardName,
                        }) =>
                          new EpisodeCard({
                            id: `${oid}`,
                            domain: domainName,
                            uniqueId,
                            image: getMicrolessonThumbnail(contentMedia).url,
                            title,
                            learningTargets,
                            description,
                            numQuestions,
                            grades,
                            teiTypes,
                            manifest,
                            episodeNumber,
                            standardName: studentFriendlyStandardName,
                          })
                      ) || [];
                    const episodeGrades = Array.from(
                      new Set(episodes.flatMap(({ grades }) => grades))
                    )
                      .filter((e) => typeof e !== 'undefined')
                      .sort() as number[];
                    domainGrades.push(...episodeGrades);
                    return {
                      name: skill.name,
                      episodes,
                      series:
                        skill.sections?.map((series) => {
                          const standardName =
                            series.content?.find(
                              (e) => !!e.studentFriendlyStandardName
                            )?.studentFriendlyStandardName || '';
                          const episodes =
                            series.content?.map(
                              ({
                                oid,
                                uniqueId,
                                title,
                                contentMedia,
                                learningTargets,
                                description,
                                questionCount: numQuestions,
                                grades,
                                teitypes: teiTypes,
                                manifest,
                                episodeNumber,
                                studentFriendlyStandardName,
                                domain: domainName,
                              }) =>
                                new EpisodeCard({
                                  id: `${oid}`,
                                  domain: domainName,
                                  uniqueId,
                                  image:
                                    getMicrolessonThumbnail(contentMedia).url,
                                  title,
                                  learningTargets,
                                  description,
                                  numQuestions,
                                  grades,
                                  teiTypes,
                                  manifest,
                                  episodeNumber,
                                  standardName: studentFriendlyStandardName,
                                })
                            ) || [];
                          const episodeGrades = Array.from(
                            new Set(episodes.flatMap(({ grades }) => grades))
                          )
                            .filter((e) => typeof e !== 'undefined')
                            .sort() as number[];
                          episodes.sort(
                            (a, b) => a.episodeNumber - b.episodeNumber
                          );
                          domainGrades.push(...episodeGrades);
                          return {
                            id: '' + series.oid,
                            title: series.name,
                            image: getSeriesCoverImage(series.contentMedia).url,
                            domain: domain.name,
                            episodes,
                            standardName,
                          } as SeriesCard;
                        }) || [],
                    } as SkillGroup;
                  }) || [],
                availableGrades: Array.from(new Set(domainGrades)),
              } as SubjectDomain;
            });
      if (sections.length) {
        this.cache.set(hash, {
          expiration: Date.now() + this.cacheExpiration,
          sections,
        });
      }
    }
    this.sections = sections;
    return sections;
  }

  async getInProgress(): Promise<SeriesCard[]> {
    return this.sections.reduce((acc, { skills }) => {
      skills.map(({ series, episodes }) => {
        acc.push(...series.filter((s) => s.progress > 0 && s.progress < 100));
        acc.push(...episodes.filter((e) => e.progress > 0 && e.progress < 100).map(e => e.convertToSeriesCard()));
      });
      return acc;
    }, [] as SeriesCard[]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getSubject(subject: SubjectNames | string): Promise<SubjectDomain[]> {
    return await this.getLessonsBySubject(subject);
  }

  async getSeriesFromId(
    subject: SubjectNames | string,
    seriesId: string
  ): Promise<SeriesCard | undefined> {
    const subjectDomains = await this.getSubject(subject);
    return subjectDomains.length === 0
      ? undefined
      : subjectDomains
          .flatMap((domain) => domain.skills)
          .flatMap((skillGroup) => skillGroup.series)
          .find(({ id }) => id === seriesId);
  }

  async getSkillFromSeriesId(
    subject: SubjectNames | string,
    seriesId: string
  ): Promise<string> {
    const subjectDomains = await this.getSubject(subject);
    return subjectDomains.length === 0
      ? ''
      : subjectDomains
          .flatMap((domain) => domain.skills)
          .find(({ series }) => series.some(({ id }) => id == seriesId))
          ?.name || '';
  }

  async populateLessonsForGrade(
    subjectName: string,
    grade: number
  ): Promise<void> {
    this.sections = await this.getLessonsBySubject(subjectName, grade);
    const progressData =
      await this.lessonProgressService.getSubjectLessonData();
    this.sections = this.mergeProgressData(progressData);
  }

  mergeProgressData(
    progressData: EpisodeKbData[],
    sections?: SubjectDomain[]
  ): SubjectDomain[] {
    sections = sections ?? this.sections;
    const sectionsWithProgress = [...sections];
    sectionsWithProgress.forEach(({ skills }) => {
      skills.forEach(({ series: seriess, episodes }) => {
        episodes.forEach((e) => {
          const { score, progressDetails, lastOpened } =
            progressData.find((d) => d.activityId === e.uniqueId) ?? {};
          Object.assign(e, { score: score ?? 0, progressDetails, lastOpened });
        });
        seriess.forEach((series) => {
          series.episodes.forEach((e) => {
            const { score, progressDetails, lastOpened } =
              progressData.find((d) => d.activityId === e.uniqueId) ?? {};
            Object.assign(e, {
              score: score ?? 0,
              progressDetails,
              lastOpened,
            });
          });
          series.progress = this.calculateSeriesProgress(series.episodes);
        });
      });
    });
    return sectionsWithProgress;
  }

  mergeAssignments(
    assignments: AssignmentCard[],
    sections?: SubjectDomain[]
  ): SubjectDomain[] {
    sections = sections ?? this.sections;
    const sectionsWithAssignments = [...sections];
    sectionsWithAssignments.forEach(({ skills }) => {
      skills.forEach((skill) => {
        const { series: seriess } = skill;
        skill.episodes = skill.episodes.map((e) => {
          const assignment = assignments.find((a) => a.uniqueId === e.uniqueId);
          return assignment
            ? Object.assign(
                assignment,
                new AssignmentCard({
                  ...e,
                  assignmentId: assignment.assignmentId,
                })
              )
            : e;
        });
        seriess.forEach((series) => {
          series.episodes = series.episodes.map((e) => {
            const assignment = assignments.find(
              (a) => a.uniqueId === e.uniqueId
            );
            return assignment
              ? Object.assign(
                  assignment,
                  new AssignmentCard({
                    ...e,
                    assignmentId: assignment.assignmentId,
                  })
                )
              : e;
          });
        });
      });
    });
    return sectionsWithAssignments;
  }

  private calculateSeriesProgress(episodes: EpisodeCard[]): number {
    return Math.round(
      episodes.reduce(
        (accumulator, episode) => accumulator + episode.progress,
        0
      ) / episodes.length
    );
  }

  gradesToRequest(grade: number): number[] {
    const lowerBound = -1;
    const upperBound = 12;
    const modifier = 2;
    grade = Math.min(Math.max(grade, lowerBound), upperBound);
    const min = Math.max(lowerBound, grade - modifier);
    const max = Math.min(upperBound, grade + modifier);
    return [...Array(max - min + 1)].map((e, idx) => (min + idx) as Grades);
  }

  handleError(error: ErrorHandler) {
    const errorMessage =
      error.error instanceof ErrorEvent
        ? error.error.message
        : `Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(() => new Error(errorMessage));
  }
}

/**
 * Grades available for users
 * @enum {number}
 */
export enum Grades {
    "Prekindergarten"   = -1,
    "Kindergarten"      = 0,
    "First"             = 1,
    "Second"            = 2,
    "Third"             = 3,
    "Fourth"            = 4,
    "Fifth"             = 5,
    "Sixth"             = 6,
    "Seventh"           = 7,
    "Eighth"            = 8,
    "Freshman"          = 9,
    "Sophomore"         = 10,
    "Junior"            = 11,
    "Senior"            = 12,
    "None"              = 99
}

/**
 * Types of audio resources
 * @enum {number}
 */
export enum AudioType {
    audio_Undefined,
    audio_HintSubject,
    audio_HintMisc,
    audio_HintResults,
    audio_HintISIP,
    audio_SfxClick,
    audio_SfxPopup,
  }


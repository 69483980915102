import { Injectable, inject } from "@angular/core";
import { KnowledgeBaseService} from ".";
import { EpisodeKbData } from "@swe/pages/lesson/types";
import { KnowledgeBaseObject } from "@swe/types";
import { EpisodeCard } from "@swe/pages/lesson/classes";
import { AssignmentCard } from "@swe/pages/assignments/classes";

@Injectable({
  providedIn: 'root'
})
export class LessonProgressService {
  private _data: EpisodeKbData[] = [];
  private kbService = inject(KnowledgeBaseService);
  private readonly KB_FOLDER = 'HTMLActivityStorage';
  private readonly KB_FOLDER_COMPLETE = 'CompleteActivities';
  private readonly KB_FOLDER_INCOMPLETE = 'IncompleteActivities';

  async getSubjectLessonData(): Promise<EpisodeKbData[]> {
    if (this._data.length === 0) {
      await this.updateDataStore();
    }
    return this._data;
  }

  /**
   * Get the progress data for a single episode
   * @param activityId string The uniqueId to get progress for
   * @returns Promise<EpisodeKbData | undefined>
   */
  async getActivityProgress(activityId: string): Promise<EpisodeKbData | undefined> {
    const allData = await this.getSubjectLessonData();
    return allData.find(({activityId: uniqueId}) => uniqueId === activityId);
  }

  async updateDataStore() {
    await Promise.all([
      this.kbService.get(this.KB_FOLDER, this.KB_FOLDER_COMPLETE),
      this.kbService.get(this.KB_FOLDER, this.KB_FOLDER_INCOMPLETE)
    ]).then(([completeData, incompleteData]) => {
      const incompleteKeys = incompleteData ? Object.keys(incompleteData as KnowledgeBaseObject) : [];
      this._data = [
        ...Object.entries((incompleteData ?? {}) as KnowledgeBaseObject),
        ...Object.entries((completeData ?? {}) as KnowledgeBaseObject)
            .filter(([k,]) => !incompleteKeys.includes(k)), // incomplete data trumps completed data
      ] // Combine both datasets
        .filter(([,v]) => v) // Remove empty progress entries
        .reduce((acc, [activityId, activityString]) => {
          let activity = JSON.parse(activityString as string);
          const isInProgress = incompleteKeys.includes(activityId);
          activity = isInProgress ? activity : activity?.completions?.at(-1);
          if (activity) {
            const progress = <EpisodeKbData>{
              activityId: isInProgress ? activity.id : activity.activityId,
              lastOpened: new Date(+(isInProgress ? activity.lastOpened : activity.dateCompleted)),
              progressDetails: activity.answerStates,
              ...(!isInProgress && {score: activity.score}),
            };
            acc = [...acc, progress];
          }
          return acc;
        }, 
        [] as EpisodeKbData[]);
    });
  }

  async mergeProgressData(activities: EpisodeCard[] | AssignmentCard[]): Promise<void> {
    const progressData = await this.getSubjectLessonData();
    activities.forEach(e => {
      const { score, progressDetails, lastOpened } = progressData.find(d => (d.activityId === e.uniqueId)) ?? {};
      Object.assign(e, {score: score ?? 0, progressDetails, lastOpened});
    });
  }
}
<article class="lessons-skill">
  <h3>{{ skill.name }}</h3>
  <div class="lesson-series">
    <swe-series-card
      *ngFor="let seriesCard of skill.series"
      [series]="seriesCard"
      [attr.data-multi]="seriesCard.episodes.length > 1"
      [episode]="seriesCard.episodes.length === 1 ? seriesCard.episodes[0] : undefined"
      [seriesId]="seriesCard.episodes.length > 1 ? seriesCard.id : undefined"
    ></swe-series-card>
  </div>  
</article>
import { Injectable } from '@angular/core';
import { 
  AppConfigParams,
  AudioParams,
  BrowserCompatParams,
  DebugParams,
  JumpPathParams,
  NetStoreParams,
} from '../enums';

export type UrlParams = {[index: string]: string};
@Injectable({
  providedIn: 'root',
})
export class UrlService {
  
  readonly paramLocation = 'lastParams';
  params?: UrlParams;

  constructor() {}

  getParamsFromStorage(): UrlParams {
    const currStorage = sessionStorage.getItem(this.paramLocation);
    const parameters = currStorage ? JSON.parse(currStorage) : {};
    this.params = parameters;
    return parameters;
  }

  loadParams(): void {
    this.params = this.getParamsFromStorage();
  }

  saveQueryParams(params: URLSearchParams): void {
    const parameters = this.getParamsFromStorage();
    Object.assign(
      parameters,
      {
        ...this.parseParamsForEnum(params, AppConfigParams), 
        ...this.parseParamsForEnum(params, AudioParams),
        ...this.parseParamsForEnum(params, NetStoreParams),
        ...this.parseParamsForEnum(params, JumpPathParams),
        ...this.parseParamsForEnum(params, BrowserCompatParams),
        ...this.parseParamsForEnum(params, DebugParams),
      }
    );
    sessionStorage.setItem(this.paramLocation, JSON.stringify(parameters));
  }

  parseParamsForEnum(params: URLSearchParams, urlEnum: Record<string, string>): UrlParams{
    const out: UrlParams = {};
    for(const [key, value] of params) {
      if(Object.values<string>(urlEnum).includes(key)){
        out[key] = value
      }
    }
    return out;
  }

  clearSavedParams(): void {
    sessionStorage.removeItem(this.paramLocation);
  }

  getQueryParams(): UrlParams {
    return !this.params ? this.getParamsFromStorage() : this.params;
  }

  getSavedJumpPaths(): Array<string | undefined> {
    return this.params ? [this.params[JumpPathParams.StartPath],  this.params[JumpPathParams.EndPath]] : [this.params, this.params];
  }

  hasJumpPathsSaved(): boolean {
    return !!this.params && this.params[JumpPathParams.StartPath] !== undefined;
  }
}

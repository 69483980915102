/**
 * @enum {string}
 */
export enum Themes {
    DEFAULT     = 'default',
    BASE        = 'base',
    WAVY        = 'wavy',
    SPACECAT    = 'spacecat',
    GAMERZ      = 'gamerz',
    SYNTHWAVE   = 'synthwave',
    BLOCKY      = 'blocky'
}

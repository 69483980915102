import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { StorageKeys } from '../enums';
import { FeatureFlagService, UserService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class IsipProgressPageGuard  {

	router = inject(Router);
	featureFlagService = inject(FeatureFlagService);
	userService = inject(UserService);

	canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
		const subjectName = route.params.subject;
		const isipSubjectStarted = sessionStorage.getItem(StorageKeys.ISIP_SUBJECT_STARTED);
		const canSeePage = isipSubjectStarted?.toLowerCase() === subjectName.toLowerCase();
		sessionStorage.removeItem(StorageKeys.ISIP_SUBJECT_STARTED);

		// console.log('IsipProgressPageGuard =>', {subjectName}, {isipSubjectStarted}, {canSeePage});

		if (!canSeePage) {
			return this.router.createUrlTree(['/home']);
		}
		return true;
	}
}

import { TypeCode, LoginCode, LoginCodeMessage } from '../enums';

export const getLoginMessage = (code: LoginCode | number) => LoginCodeMessage[code] || 'Unknown';

export class MessageValue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueActual: any;
    buffer: Uint8Array;
    bufferLength = 1; // <-- we need a header byte for the typecode

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(value: any, typeCode: TypeCode) {
        this.bufferLength += WS.getValueReadSize(typeCode);
        if (WS.isValueVariableLength(typeCode)){
            this.bufferLength += value.length;
        }
        // console.log(
        //     "typecode: " + typeCode, 
        //     "Readsize: " + WS.getValueReadSize(typeCode).valueOf(), 
        //     "Buffer Length: " + this.bufferLength);
        this.buffer = new Uint8Array(this.bufferLength);
        this.type = typeCode;
        this.value = value;
    }

    toString(){
        return this.buffer.toString();
    }

    get type(){
        return this.buffer[0];
    }
    set type(type: TypeCode) {
        this.buffer.set([type]);
    }
    get value () {
        return this.valueActual;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set value(value: any){
        const valueArray = [];
        const isVariableLength = WS.isValueVariableLength(this.type);
        const options = {
            size: (isVariableLength ? value.length : value),
            depth: WS.getValueReadSize(this.type),
            littleEndian: this.type !== TypeCode.tc_string
        };
        this.valueActual = value;
        valueArray.push(...WS.calculateSize(options));
        if (isVariableLength){
            valueArray.push(...[...value].map((c: string) => c.charCodeAt(0)));
        }
        // console.log("Value Array: ");
        // console.log(valueArray);
        // console.log("ValueArray length: " + valueArray.length, "Buffer Array length: " + this.buffer.length);
        if (value !== null) { // <-- tc_null doesn't store any values
            this.buffer.set(valueArray, 1); 
        }
    }
}

export class WS {
    byteOrder = true;
    sendResponse = true;
    buffer: Uint8Array = new Uint8Array();
    values: MessageValue[] = [];
    message = '';
    interfaceName = '';
    commandName = '';
    private messageSize = 0; // <-- in bytes
    private interfaceSize = 0; // <-- in bytes
    private commandSize = 0; // <--in bytes

    constructor (encodedMessage?: string | null | undefined) {
        if (encodedMessage) {
            this.parseMessage(encodedMessage);
        }
    }

    static isValueVariableLength(typeCode: number): boolean{
        return [
            TypeCode.tc_vector,
            TypeCode.tc_blob,
            TypeCode.tc_string
        ].includes(typeCode);
    }

    static getValueReadSize(typeCode: TypeCode): number {
        return ([TypeCode.tc_longlong].includes(typeCode)
            ? 8 : 
            [TypeCode.tc_long,TypeCode.tc_map,TypeCode.tc_vector,TypeCode.tc_blob,TypeCode.tc_string].includes(typeCode)
            ? 4 : 
            [TypeCode.tc_short].includes(typeCode) 
            ? 2 : 
            [TypeCode.tc_null].includes(typeCode)
            ? 0 : 1);
    }

    static calculateSize({
        size,
        littleEndian = false,
        depth = 4
    }: {
        size: number,
        littleEndian?: boolean,
        depth?: number
    }): number[] {
        if(size < 0 ) size = size * -1;
        const sizeBuffer = Number(size).toString(16).padStart(depth*2, '0').match(/[\s\S]{1,2}/g) || new Array(depth);
        if (littleEndian) sizeBuffer.reverse();
        return sizeBuffer.map(num => parseInt(num, 16));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addValue(value: any, typeCode: TypeCode) {
        const messageValue = new MessageValue(value, typeCode);
        this.values.push(messageValue);
    }

    parseMessage(message: string) {
        // Basic information must be optained to begin reading in the data stream
        const raw = atob(message);
        this.message = raw;
        this.buffer = new Uint8Array(raw.length);
        // Fill the array buffer
        [...raw].map((bs, i) => this.buffer[i] = bs.charCodeAt(0));
        const end = this.buffer.lastIndexOf(Number(TypeCode.tc_end));
        this.byteOrder = !!this.buffer[2];
        this.sendResponse = !!this.buffer[3];
        this.messageSize = this.readSize(this.buffer.slice(4,7), true);
        this.interfaceSize = this.readSize(this.buffer.slice(8,11), true);
        this.commandSize = this.readSize(this.buffer.slice(12,15), true);

        const instruction = this.buffer.slice(16, 16 + this.interfaceSize + this.commandSize);
        this.interface = String.fromCharCode(...instruction.slice(0, this.interfaceSize));
        this.command = String.fromCharCode(...instruction.slice(this.interfaceSize, this.interfaceSize + this.commandSize));

        // get the values
        const valuesBlock = this.buffer.slice(16 + this.interfaceSize + this.commandSize, end);

        // console.log("End: " + end);
        // console.table(valuesBlock);
        // we're going to buffer read from here on out
        for ( let offset = 0; offset < valuesBlock.length;) {
            const typeCode = valuesBlock[offset++]; // read header       
            let contentValue: string | null;
            let contentSize = 0;

            if (typeCode === TypeCode.tc_null) {
                contentValue = null;
            } else {
                const readSize = WS.getValueReadSize(typeCode); // read size
                contentSize = this.readSize(valuesBlock.slice(offset, offset + readSize), typeCode !== TypeCode.tc_string); // only tc_string is BE
                contentValue = contentSize.toString(10);
                offset += readSize;
            }

            // get the value from the block
            if (typeCode === TypeCode.tc_string) {
                contentValue = String.fromCharCode(...valuesBlock.slice(offset, offset + contentSize));
                offset += contentSize;
            }

            // Fix offset for variable data
            // console.log("TypeCode: " + typeCode, "Offset: " + offset, contentValue);
            this.addValue(contentValue, typeCode);
        }
    }

    /**
     * Convert class based object to a binary representation
     * 
     * @returns Uint8Array the memory buffer
     */
    composeMessage() {
        // Envelope header
        let offset = 16;
        this.setBufferSize();

        this.buffer.set([73, 83]); // <-- 'IS'
        this.buffer.set([Number(this.byteOrder)], 2); // <-- Endianess
        this.buffer.set([Number(this.sendResponse)], 3); // <-- Send response flag
        this.buffer.set([0,0,0,0], 4); // <-- Message size (4 bytes little endian)
        this.buffer.set(WS.calculateSize({size: this.interfaceSize, littleEndian: true}), 8); // <-- Interface size (4 bytes little endian)
        this.buffer.set(WS.calculateSize({size: this.commandSize, littleEndian: true}), 12); // <-- command size (4 bytes little endian)
        [...this.interface].map(c => this.buffer.set([c.charCodeAt(0)], offset++));
        [...this.command].map(c => this.buffer.set([c.charCodeAt(0)], offset++));
        
        // All values/parameters are added here
        this.values.map(({buffer, bufferLength}) => {
            this.buffer.set(buffer, offset);
            offset += bufferLength;
        });

        // Close out the envelope
        this.buffer.set([Number(TypeCode.tc_end)], offset++);
        this.buffer.set([69, 78, 68], offset); // <-- 'END'

        // set the message size
        this.buffer.set(WS.calculateSize({size: this.buffer.length, littleEndian: true}), 4);
    }

    readSize(content: Uint32Array | Uint16Array | Uint8Array | number[], littleEndian = false){
        if (littleEndian) content.reverse();
        return parseInt((content as []).reduce((acc: string, e: number) => acc += e.toString(16).padStart(2, '0'), ''), 16);
    }

    setBufferSize() {
        let bufferSize = 16; // header
        bufferSize += this.interfaceSize + this.commandSize; // interface+command string
        bufferSize = this.values.reduce((acc: number, v: MessageValue) => acc += v.bufferLength, bufferSize); // add the sizes of the buffer
        bufferSize += 4; // footer
        this.buffer = new Uint8Array(bufferSize);
    }

    get interface() {
        return this.interfaceName;
    }

    set interface(interfaceName: string){
        this.interfaceName = interfaceName; 
        this.interfaceSize = interfaceName.length;
    }

    get command() {
        return this.commandName;
    }
    set command(commandName: string) {
        this.commandName = commandName;
        this.commandSize = commandName.length;
    }

    get messageEncoded (){
        this.composeMessage();
        if (!this.message) {
            this.message = String.fromCharCode(...this.buffer);
        }
        return btoa(this.message);
    }
}
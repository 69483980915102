import { FeatureFlagService } from './../../services/feature-flag.service';
import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureFlagPreloadingStrategy implements PreloadingStrategy {
  constructor(private featureFlagService: FeatureFlagService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (
      route.data?.preloadFlags &&
      this.featureFlagService.isFlagEnabled(route.data?.preloadFlags)
    ) {
      return load();
    } else {
      return of(false);
    }
  }
}

import { NgModule } from '@angular/core';
import { KnowledgeBaseAPI } from '../../classes/knowledge-base/knowledge-base-api';
import { KnowledgeBaseWASM } from '../../classes/knowledge-base/knowledge-base-wasm';
import { KnowledgeBaseService } from '../../services/kb.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DataSourceFacade } from '../../classes/data-source-facade'
import { DATA_SOURCE } from '../../classes/knowledge-base/it-data-source';
import { DataSourceType } from '../../enums/dataSource'
import { BehaviorSubject } from 'rxjs'

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: DATA_SOURCE, 
      useFactory: (c: ConfigurationService) => {
        const source = c.config.dataSource;
         return new BehaviorSubject((source == 'Cloud') ? DataSourceType.CLOUD : DataSourceType.LCM);
      },
      deps: [
        ConfigurationService
      ]
    }, 
    KnowledgeBaseAPI,
    KnowledgeBaseWASM,
    KnowledgeBaseService,
    DataSourceFacade
  ]
})
export class KnowledgeBaseModule { }

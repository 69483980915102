import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RadioListOptions } from '@swe/types';
import * as crypto from 'crypto-js';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'swe-radio-list',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <span class="form-radio-list-indicator" aria-hidden="true"></span>
    <ul class="form-radio-list" [ngClass]="{ inline: inline }">
      <li class="form-radio-list-item" *ngFor="let option of options">
        <input
          type="radio"
          [attr.name]="uniqueID"
          [attr.id]="uniqueID + '_' + option[0]"
          [value]="option[0]"
          [checked]="toggleState === option[0]"
          [ngModel]="toggleState"
          (ngModelChange)="toggleStateChange.emit(option[0])"
        />
        <label [attr.for]="uniqueID + '_' + option[0]">{{ option[1] }}</label>
      </li>
    </ul>
  `,
  styleUrls: ['./radio-list.component.scss'],
})
export class RadioListComponent implements OnInit, AfterViewInit {
  @Input() options: RadioListOptions = [
    ['left', 'left'],
    ['right', 'right'],
  ];
  @Input() toggleState!: string;
  @Input() inline = false;
  @Output() toggleStateChange = new EventEmitter<string>();

  private elementRef: ElementRef = inject(ElementRef);
  public readonly uniqueID =
    'radio-list-' +
    crypto
      .MD5(Math.round(Math.random() * 100000).toString())
      .toString()
      .slice(0, 10);

  ngOnInit() {
    if (!this.toggleState) {
      this.toggleState = this.options[0][0];
    }
  }

  ngAfterViewInit(): void {
    this.toggleStateChange.subscribe((state) => this.updateIndicator(state));
    // Delay the loading of the indicator
    of([])
      .pipe(delay(250))
      .subscribe(() => this.toggleStateChange.emit(this.toggleState));
  }

  private updateIndicator(state: string): void {
    const switchElement: HTMLElement = this.elementRef.nativeElement;
    const indicator = switchElement.querySelector(
      '.form-radio-list-indicator'
    ) as HTMLElement;
    const selectedOption = switchElement.querySelector(
      `#${this.uniqueID}_${state}`
    ) as HTMLInputElement;
    const { left: originLeft } = switchElement.getBoundingClientRect();
    const { width: clientWidth, left: clientLeft } =
      selectedOption.getBoundingClientRect();
    indicator.style.width = `${clientWidth}px`;
    indicator.style.left = `${clientLeft - originLeft}px`;
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePopupComponent } from '../components/profile-popup/profile-popup.component';
import { NavComponent } from '../components/nav/nav.component';
import { NavStarsComponent } from '../components/nav/nav-stars.component';
import { DialogModule } from './dialog.module';
import { SharedModule } from '../../components/shared.module';
import { AudioHelperSharedModule } from './audio-helper-shared.module';
import { LogPopupComponent } from '../../components/log-popup/log-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { FullNamePipe } from '../pipes/full-name.pipe';
import { InitialPipe } from '../pipes/initial.pipe';
import { TrackingModule } from './tracking.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AudioHelperSharedModule,
    DialogModule,
    HttpClientModule,
    AngularSvgIconModule,
    TrackingModule
  ],
  declarations: [
    ProfilePopupComponent,
    NavComponent,
    NavStarsComponent,
    LogPopupComponent,
    FullNamePipe,
    InitialPipe
  ],
  exports: [
    ProfilePopupComponent,
    NavComponent,
    NavStarsComponent,
    LogPopupComponent,
    FullNamePipe,
    InitialPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class NavModule { }

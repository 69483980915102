/**
 * @enum {string}
 */
export enum AudioDescriptor {
    // hints

    // NOTE: Hints for Subjects ("Reading", "Lectura", etc.) must match 
    //       the corresponding subjectsInfo.<subject>.name property
    HINT_READING = "Reading",
    HINT_LECTURA = 'Lectura',
    HINT_MATH = 'Math',
    HINT_MATH_ESP = 'Matemáticas',
    HINT_ORF = 'ORF',
    HINT_ORF_ESP = 'ORF en Español',
    HINT_RAN = 'RAN',
    HINT_RAN_ESP = 'RAN en Español',
    HINT_MINUTES_LEARNING = 'Minutes Spent Learning',
    HINT_MINUTES_LEARNING_ESP = 'Minutes Spent Learning (Espanol)',
    HINT_REWARDS = 'Rewards',
    HINT_REWARDS_ESP = 'Recompensas',
    HINT_HIGHEST_SCORE = 'Highest Score',
    HINT_HIGHEST_SCORE_ESP = 'Highest Score (Espanol)',
    HINT_NEEDS_PRACTICE = 'Needs Practice',
    HINT_NEEDS_PRACTICE_ESP = 'Needs Practice (Espanol)',
    HINT_RESULTS = 'How Do You Feel You Did?',
    HINT_RESULTS_ESP = 'How Do You Feel You Did? (Espanol)',

    // Simple ISIP flow
    HINT_SIMPLE_HI_READING = "Hi! It's time for your reading check-in!",
    HINT_SIMPLE_HI_READING_ESP = "Hi! It's time for your reading check-in! (Espanol)",
    HINT_SIMPLE_HI_MATH = "Hi! It's time for your math check-in!",
    HINT_SIMPLE_HI_MATH_ESP = "Hi! It's time for your math check-in! (Espanol)",
    HINT_SIMPLE_OPEN_CHEST = "Open the treasure chest to collect your prize",
    HINT_SIMPLE_OPEN_CHEST_ESP = "Open the treasure chest to collect your prize (Espanol)",
    HINT_SIMPLE_PRESS = "Press the green button to begin your assessment",
    HINT_SIMPLE_PRESS_ESP = "Press the green button to begin your assessment (Espanol)",
    HINT_SIMPLE_SEE_YOU = "See you next time!",
    HINT_SIMPLE_SEE_YOU_ESP = "See you next time! (Espanol)",
    HINT_SIMPLE_GREAT_WORK = "Great Work!",
    HINT_SIMPLE_GREAT_WORK_ESP = "Great Work! (Espanol)",
    HINT_SIMPLE_FINISHED = "Finished!",
    HINT_SIMPLE_FINISHED_ESP = "Finished! (Espanol)",

    // click sfx
    CLICK_SUBJECT = 'clickSubject',
    CLICK_AVATAR_THEME = 'avatarTheme',
    CLICK_MORE_NEXT = 'moreNext',
    CLICK_GO_PLAY = 'goPlay',
    CLICK_BACK = 'back',
    CLICK_TUTORIAL = 'tutorial',
    CLICK_FINISH_SAVE = 'finishSave',
    CLICK_ERROR = 'clickError',
    CLICK_QUESTION = 'clickQuestion',
    CLICK_HOME = 'home',

    // Animation sfx
    ANIM_FINISHED = 'animFinished',
    ANIM_CHEST_OPEN = 'animChestOpen',
    ANIM_CLOSING = 'animClosing',
    CLICK_SELECT_MAJOR = 'buttonSelectMajor',

    // misc effects
    SFX_POPUP_ERROR = "popupError",
    SFX_POPUP_QUESTION = "popupQuestion",

}
import { Injectable } from '@angular/core';
import {Apollo, ApolloBase, gql} from 'apollo-angular';
import { usageForUserByProduct } from '../data/queries';
import { UsageInterval, UsageLeaRole, ProductValue } from '../enums';
import {  ProductUsageList, SimpleProductUsageData, StudentUsage } from '../types';
import { filter, map, pluck } from 'rxjs/operators';
import { ProductUsage } from '../classes';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UsageService {

  public products: ProductValue[] = [];
  public weekly: ProductUsageList = {};
  public monthly: ProductUsageList = {};
  public weekly$: Subject<ProductUsageList> = new Subject();
  public monthly$: Subject<ProductUsageList> = new Subject();
  public usageOverride = false;
  public manualUsage = 1600;
  public manualUsageGoal = 2400;

  private apollo: ApolloBase;
  private variables = {
    input: {
      leaUserRole: UsageLeaRole.Student,
      dateInterval: UsageInterval.Week,
      products: this.products,
    }
  }

  public secondsToMinutes = (seconds: number) => Math.round(seconds / 60);

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use("usage");
    this.weekly$.subscribe((newUsage) => this.weekly = newUsage);
    this.monthly$.subscribe((newUsage) => this.monthly = newUsage);
  }

  private getUsage(){
    const query = gql(usageForUserByProduct);
    const variables = this.variables;
    return this.apollo.query<StudentUsage | null>({ query, variables });
  }

  getMonthlyUsage(){
    this.variables.input.dateInterval = UsageInterval.Month;
    this.variables.input.products = this.products;
    return this.getUsage()
      .pipe(
        this.filterUsage()
      )
      .subscribe(usage => {
        this.monthly$.next(usage);
      });
  }

  getWeeklyUsage() {
    this.variables.input.dateInterval = UsageInterval.Week;
    this.variables.input.products = this.products;
    return this.usageOverride ? this.setManualUsage() : this.getUsage()
      .pipe(
        this.filterUsage()
      )
      .subscribe(usage => {
        this.weekly$.next(usage);
      });
  }

  setManualUsage(){
    const usage: ProductUsageList = this.products.reduce((acc, productName: ProductValue) => {
      acc[productName] = new ProductUsage(this.manualUsage, this.manualUsageGoal);
      return acc;
    }, {} as ProductUsageList);
    this.weekly$.next(usage);
  }

  transformUsage(usageList: SimpleProductUsageData[]): ProductUsageList {
    return usageList.reduce(
      (acc, { product, usageForLeaUsers }) => {
        const { usageSeconds, usageGoalSeconds } = usageForLeaUsers[0];
        const productIndex =
          ProductValue[product.value as keyof typeof ProductValue];
        const productUsage = new ProductUsage(
          usageSeconds,
          usageGoalSeconds
        );
        acc[productIndex] = productUsage;
        return acc;
      },
      {} as ProductUsageList
    );
  }

  private filterUsage(){
    return (source: Observable<{data: StudentUsage | null}>): Observable<ProductUsageList> => {
      return source.pipe(
        filter(
          ({ data }) =>
            !!data &&
            data.usageData &&
            data.usageData.usageForProducts.length > 0
        ),
        pluck('data', 'usageData', 'usageForProducts'),
        map(usage => this.transformUsage(usage as SimpleProductUsageData[]))
      );
    }
  }
}

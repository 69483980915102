import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkillGroup } from '../../types';

@Component({
  selector: 'swe-lessons-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillComponent {
  private _skillGroup!: SkillGroup;
  @Input() 
  set skill(skill: SkillGroup) {
    skill.series = skill.series.concat(skill.episodes.map(e => e.convertToSeriesCard()));
    this._skillGroup = skill;
  }
  get skill(): SkillGroup {
    return this._skillGroup;
  }

}

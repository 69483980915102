import { Grades } from "../../enums";
import { EpisodeCard } from "../../pages/lesson/classes";
import { SeriesCard, SkillGroup, SubjectDomain } from "../../pages/lesson/types";
import { ReadingEpisodes } from "./reading-episodes";

export const ReadingLessons = [
    {
        title: "Grammar",
        availableGrades: [
            Grades.Third,
            Grades.Fourth,
            Grades.Fifth,
        ],
        skills: [
            {
                name: "Prepositions",
                episodes: [],
                series: [
                    {
                        id: '000',
                        title: "Project Plurals: Let's Make a Zoo",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '111',
                        title: "Project Plurals: Model Report",
                        progress: 50,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '222',
                        title: "Project Plurals: Supply the Supplies",
                        progress: 70,
                        episodes: [ReadingEpisodes[0]],
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '333',
                        title: "Planetary Possessives: Possess Mess",
                        progress: 0,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '444',
                        title: "Planetary Possessives: Cosmic Couriers",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                ]
            },
            {
                name: "Nouns",
                episodes: [],
                series: [
                    {
                        id: '1111',
                        title: "Project Plurals: Let's Make a Zoo",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '2222',
                        title: "Project Plurals: Model Report",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '3333',
                        title: "Project Plurals: Supply the Supplies",
                        progress: 20,
                        episodes: [ReadingEpisodes[0]],
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '4444',
                        title: "Planetary Possessives: Possess Mess",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '5555',
                        title: "Planetary Possessives: Cosmic Couriers",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                ]
            },
            {
                name: "Verbs",
                episodes: [],
                series: [
                    {
                        id: '11111',
                        title: "Project Plurals: Let's Make a Zoo",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '22222',
                        title: "Project Plurals: Model Report",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '33333',
                        title: "Project Plurals: Supply the Supplies",
                        progress: 20,
                        episodes: [ReadingEpisodes[0]],
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '44444',
                        title: "Planetary Possessives: Possess Mess",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                    {
                        id: '55555',
                        title: "Planetary Possessives: Cosmic Couriers",
                        progress: 20,
                        episodes: ReadingEpisodes,
                        image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
                        domain: 'Grammar'
                    },
                ]
            }
        ],
    },
    {
        title: 'Educational Domain 2',
        availableGrades: [
            Grades.Third,
            Grades.Fourth,
            Grades.Fifth,
        ],
        skills: [
            {
                name: 'Skill A',
                episodes: [],
                series: [
                    {
                        id: '777',
                        title: 'Series A',
                        progress: 30,
                        episodes: ReadingEpisodes,
                        image: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
                        domain: 'Educational Domain 2'
                    },
                    {
                        id: '888',
                        title: 'Series B',
                        progress: 60,
                        episodes: ReadingEpisodes,
                        image: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
                        domain: 'Educational Domain 2'
                    },
                    {
                        id: '999',
                        title: 'Series C',
                        progress: 80,
                        episodes: [ReadingEpisodes[0]],
                        image: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
                        domain: 'Educational Domain 2'
                    }
                ]
            },
            {
                name: 'Skill B',
                episodes: [],
                series: [
                    {
                        id: '7777',
                        title: 'Series D',
                        progress: 40,
                        episodes: ReadingEpisodes,
                        image: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
                        domain: 'Educational Domain 2'
                    },
                    {
                        id: '8888',
                        title: 'Series E',
                        progress: 70,
                        episodes: ReadingEpisodes,
                        image: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
                        domain: 'Educational Domain 2'
                    },
                    {
                        id: '9999',
                        title: 'Series F',
                        progress: 90,
                        episodes: [ReadingEpisodes[1]],
                        image: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
                        domain: 'Educational Domain 2'
                    }
                ]
            }
        ],
    }
]

export const ReadingLessonsFromApi: SubjectDomain[] = [
    {
        title: "Grammar",
        availableGrades: [3],
        skills: [
            {
                name: "Prepositions",
                episodes: [],
                series: [
                    {
                        id: "13068",
                        image: "/path/to/axis-big-break.png",
                        title: "Axis's Big Break",
                        progress: 0,
                        standardName: "Some Standard",
                        episodes: [{
                                id: "25428",
                                uniqueId: "reading-3-prepositions-1-fromplanetstostars",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V1_Poster.jpg",
                                title: "From Planets to Stars",
                                description: "Nova Scout Axis and his trusty AI sidekick, C.A.T., are about to start their Earthen merit badges. They must master the use of prepositions from the human language of English.",
                                progressDetails: undefined,
                                lastOpened: undefined,
                                numQuestions: 10,
                                score: 0,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "ENTRY_SURVEY",
                                    "EXIT_SURVEY",
                                    "START_SCREEN",
                                    "RESULTS_SCREEN",
                                    "QUESTION",
                                    "VIDEO"
                                ],                
                                grades: [
                                    3
                                ],
                                episodeNumber: 1,
                                domain: undefined,
                                standardName: "Stars",
                            },
                            {
                                id: "25429",
                                uniqueId: "reading-3-prepositions-2-comettale",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V2_Poster.jpg",
                                title: "Comet Tale",
                                description: "With their shields down, Axis and C.A.T. must race against the clock to get them back up. Knowing how to use prepositions in context will help them avoid more damage from a nearby comet.",
                                progressDetails: undefined,
                                lastOpened: undefined,
                                numQuestions: 10,
                                score: 0,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "START_SCREEN",
                                    "VIDEO",
                                    "RESULTS_SCREEN",
                                    "ENTRY_SURVEY",
                                    "QUESTION",
                                    "EXIT_SURVEY"
                                ],
                                grades: [
                                    3
                                ],
                                episodeNumber: 2,
                                domain: undefined,
                                standardName: "Comets in Space",
                            },
                            {
                                id: "25430",
                                uniqueId: "reading-3-prepositions-3-surfsupshipsdown",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V3_Poster.jpg",
                                title: "Surf's Up, Ship's Down",
                                description: "It was a bumpy landing, and now Axis and C.A.T. must chat with the locals to find the missing pieces of their ship. Understanding how humans use prepositions will make all the difference.",
                                progressDetails: undefined,
                                lastOpened: undefined,
                                numQuestions: 10,
                                score: 0,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "START_SCREEN",
                                    "VIDEO",
                                    "RESULTS_SCREEN",
                                    "ENTRY_SURVEY",
                                    "QUESTION",
                                    "EXIT_SURVEY"
                                ],                
                                grades: [
                                    3
                                ],
                                episodeNumber: 3,
                                domain: undefined,
                                standardName: "Some Standard",
                            }
                        ].map(e => new EpisodeCard(e)),
                        domain: 'Grammar'
                    }
                ]
            }
        ]
    },
    {
        title: "ABCs",
        availableGrades: [0],
        skills: [
            {
                name: "Letter Sounds",
                episodes: [],
                series: [
                    {
                        id: "13071",
                        image: "/helix/UX_Images/Header_Images/Header_Default_Series_01.png",
                        title: "Sample Testing Series",
                        standardName: "Words",
                        progress: 0,
                        episodes: [
                            {
                                id: "25431",
                                uniqueId: "Reading.0.Sample.1.SampleEp",
                                image: "/helix/Reading/Grade_K/Letterrooms/A/R_Letterrooms_A_Astronaut/R_Letterrooms_A_Astronaut.png",
                                title: "Reading Test Activity",
                                description: "This activity is for use to try out different question types.",
                                progressDetails: undefined,
                                lastOpened: undefined,
                                numQuestions: 12,
                                score: 0,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "VIDEO",
                                    "EXIT_SURVEY",
                                    "QUESTION",
                                    "START_SCREEN",
                                    "ENTRY_SURVEY",
                                    "RESULTS_SCREEN"
                                ],                
                                grades: [
                                    0
                                ],
                                episodeNumber: 1,
                                domain: undefined,
                                standardName: "Words",
                            }
                        ].map( e => new EpisodeCard(e)),
                        domain: 'ABCs'
                    }
                ]
            }
        ]
    }
]

export const ThirdGradeReadingLessonsFromApi: SubjectDomain[] = [
    {
        title: "Grammar",
        availableGrades: [3],
        skills: [
            {
                name: "Prepositions",
                episodes: [],
                series: [
                    {
                        id: "13068",
                        image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V3_Poster.jpg",
                        title: "Axis's Big Break",
                        progress: 0,
                        domain: 'Grammar',
                        episodes: [
                            {
                                id: "25428",
                                uniqueId: "reading-3-prepositions-1-fromplanetstostars",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V1_Poster.jpg",
                                title: "From Planets to Stars",
                                description: "Nova Scout Axis and his trusty AI sidekick, C.A.T., are about to start their Earthen merit badges. They must master the use of prepositions from the human language of English.",
                                lastOpened: undefined,
                                progressDetails: undefined,
                                numQuestions: 10,
                                score: 0,
                                manifest: [
                                    "ENTRY_SURVEY",
                                    "EXIT_SURVEY",
                                    "START_SCREEN",
                                    "RESULTS_SCREEN",
                                    "QUESTION",
                                    "VIDEO",
                                ],
                                grades: [
                                    3
                                ],
                                episodeNumber: 1
                            },
                            {
                                id: "25429",
                                uniqueId: "reading-3-prepositions-2-comettale",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V2_Poster.jpg",
                                title: "Comet Tale",
                                description: "With their shields down, Axis and C.A.T. must race against the clock to get them back up. Knowing how to use prepositions in context will help them avoid more damage from a nearby comet.",
                                progressDetails: undefined,
                                lastOpened: undefined,
                                numQuestions: 10,
                                score: 0,
                                manifest: [
                                    "START_SCREEN",
                                    "VIDEO",
                                    "RESULTS_SCREEN",
                                    "ENTRY_SURVEY",
                                    "QUESTION",
                                    "EXIT_SURVEY"
                                ],
                                grades: [
                                    3
                                ],
                                episodeNumber: 2
                            },
                            {
                                id: "25430",
                                uniqueId: "reading-3-prepositions-3-surfsupshipsdown",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V3_Poster.jpg",
                                title: "Surf's Up, Ship's Down",
                                description: "It was a bumpy landing, and now Axis and C.A.T. must chat with the locals to find the missing pieces of their ship. Understanding how humans use prepositions will make all the difference.",
                                progressDetails: undefined,
                                lastOpened: undefined,
                                numQuestions: 10,
                                score: 0,
                                manifest: [
                                    "START_SCREEN",
                                    "VIDEO",
                                    "RESULTS_SCREEN",
                                    "ENTRY_SURVEY",
                                    "QUESTION",
                                    "EXIT_SURVEY"
                                ],
                                grades: [
                                    3
                                ],
                                episodeNumber: 3
                            }
                        ].map(e => new EpisodeCard(e))
                    }
                ]
            }
        ]
    },
]

export const ReadingLessonsFromApiAndKb = [
    {
        title: "Grammar",
        availableGrades: [3],
        skills: [
            {
                name: "Prepositions",
                episodes: [],
                series: [
                    {
                        id: "13068",
                        image: "/path/to/axis-big-break.png",
                        title: "Axis's Big Break",
                        progress: 53,
                        standardName: "Some Standard",
                        episodes: [
                            {
                                id: "25428",
                                uniqueId: "reading-3-prepositions-1-fromplanetstostars",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V1_Poster.jpg",
                                title: "From Planets to Stars",
                                description: "Nova Scout Axis and his trusty AI sidekick, C.A.T., are about to start their Earthen merit badges. They must master the use of prepositions from the human language of English.",
                                progressDetails: [1,0,1,1,1,1,1,1,2,1],
                                lastOpened: new Date(1708024269480),
                                numQuestions: 10,
                                score: 85,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "ENTRY_SURVEY",
                                    "EXIT_SURVEY",
                                    "START_SCREEN",
                                    "RESULTS_SCREEN",
                                    "QUESTION",
                                    "VIDEO"
                                ],                
                                grades: [
                                    3
                                ],
                                episodeNumber: 1,
                                domain: undefined,
                                standardName: "Stars",
                            },
                            {
                                id: "25429",
                                uniqueId: "reading-3-prepositions-2-comettale",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V2_Poster.jpg",
                                title: "Comet Tale",
                                description: "With their shields down, Axis and C.A.T. must race against the clock to get them back up. Knowing how to use prepositions in context will help them avoid more damage from a nearby comet.",
                                progressDetails: [1,1,2,0,0,1],
                                lastOpened: new Date(1708458104782),
                                numQuestions: 10,
                                score: 0,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "START_SCREEN",
                                    "VIDEO",
                                    "RESULTS_SCREEN",
                                    "ENTRY_SURVEY",
                                    "QUESTION",
                                    "EXIT_SURVEY"
                                ],
                                grades: [
                                    3
                                ],
                                episodeNumber: 2,
                                domain: undefined,
                                standardName: "Comets in Space",
                            },
                            {
                                id: "25430",
                                uniqueId: "reading-3-prepositions-3-surfsupshipsdown",
                                image: "/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V3_Poster.jpg",
                                title: "Surf's Up, Ship's Down",
                                description: "It was a bumpy landing, and now Axis and C.A.T. must chat with the locals to find the missing pieces of their ship. Understanding how humans use prepositions will make all the difference.",
                                progressDetails: [],
                                lastOpened: new Date(1708025697992),
                                numQuestions: 10,
                                score: 0,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "START_SCREEN",
                                    "VIDEO",
                                    "RESULTS_SCREEN",
                                    "ENTRY_SURVEY",
                                    "QUESTION",
                                    "EXIT_SURVEY"
                                ],                
                                grades: [
                                    3
                                ],
                                episodeNumber: 3,
                                domain: undefined,
                                standardName: "Some Standard",
                            }
                        ].map(e => new EpisodeCard(e)),
                        domain: 'Grammar'
                    }
                ]
            }
        ]
    },
    {
        title: "ABCs",
        availableGrades: [0],
        skills: [
            {
                name: "Letter Sounds",
                episodes: [],
                series: [
                    {
                        id: "13071",
                        image: "/helix/UX_Images/Header_Images/Header_Default_Series_01.png",
                        title: "Sample Testing Series",
                        progress: 0,
                        standardName: "Words",
                        episodes: [
                            {
                                id: "25431",
                                uniqueId: "Reading.0.Sample.1.SampleEp",
                                image: "/helix/Reading/Grade_K/Letterrooms/A/R_Letterrooms_A_Astronaut/R_Letterrooms_A_Astronaut.png",
                                title: "Reading Test Activity",
                                description: "This activity is for use to try out different question types.",
                                progressDetails: undefined,
                                lastOpened: undefined,
                                numQuestions: 12,
                                score: 0,
                                learningTargets: [],
                                teiTypes: [],
                                manifest: [
                                    "VIDEO",
                                    "EXIT_SURVEY",
                                    "QUESTION",
                                    "START_SCREEN",
                                    "ENTRY_SURVEY",
                                    "RESULTS_SCREEN"
                                ],                
                                grades: [
                                    0
                                ],
                                episodeNumber: 1,
                                domain: undefined,
                                standardName: "Words",
                            }
                        ].map(e => new EpisodeCard(e)),
                        domain: 'ABCs'
                    }
                ]
            }
        ]
    }
]

export const ReadingLessonsKbData = {
    "HTMLActivityStorage": {
        "CompleteActivities": {
            "reading-3-prepositions-1-fromplanetstostars": '{"activityId":"reading-3-prepositions-1-fromplanetstostars","timesCompleted":1,"maxStars":2,"fastestTimeInSecs":126,"completions":[{"activityId":"reading-3-prepositions-1-fromplanetstostars","dateCompleted":"1708024269480","timeToCompleteSecs":126,"starsEarned":2,"score":85,"answerStates":[1,0,1,1,1,1,1,1,2,1]}]}'
        },
        "IncompleteActivities" : {
            "reading-3-prepositions-2-comettale": '{"id":"reading-3-prepositions-2-comettale","currentStep":8,"questionNumber":5,"numPerfect":3,"numCorrect":5,"numIncorrect":1,"answerStates":[1,1,2,0,0,1],"populationResponses":[{"responses":[[["33744.B.2.1_1"]],[["33744.B.2.2_1"]],[["-1"]],[["-1"]],[["-1"]],[["33744.B.2.4_1"]],[["-1"]],[["33744.B.2.5_1"]],[["33744.B.2.6_1"]]],"elapsedTime":2364}],"progressNodes":[{"index":0,"type":"VideoNodeComponent","data":{},"focus":2,"status":0},{"index":1,"type":"ProgressNodeComponent","data":{"text":"1"},"focus":2,"status":3},{"index":2,"type":"ProgressNodeComponent","data":{"text":"2"},"focus":2,"status":3},{"index":3,"type":"ProgressNodeComponent","data":{"text":"3"},"focus":2,"status":1},{"index":4,"type":"ProgressNodeComponent","data":{"text":"4"},"focus":2,"status":2},{"index":5,"type":"ProgressNodeComponent","data":{"text":"5"},"focus":2,"status":2},{"index":6,"type":"ProgressNodeComponent","data":{"text":"6"},"focus":2,"status":3},{"index":7,"type":"ProgressNodeComponent","data":{"text":"7"},"focus":1,"status":0},{"index":8,"type":"ProgressNodeComponent","data":{"text":"8"},"focus":0,"status":0},{"index":9,"type":"ProgressNodeComponent","data":{"text":"9"},"focus":0,"status":0},{"index":10,"type":"ProgressNodeComponent","data":{"text":"10"},"focus":0,"status":0}],"elapsedTime":112,"currTry":0,"filledAnswers":[[]],"selectedAnswers":[[]],"disabledAnswers":[[]],"curHintsRevealed":[],"lastOpened":"1708458104782"}',
            "reading-3-prepositions-3-surfsupshipsdown": '{"id":"reading-3-prepositions-3-surfsupshipsdown","currentStep":2,"questionNumber":-1,"numPerfect":0,"numCorrect":0,"numIncorrect":0,"answerStates":[],"populationResponses":[],"progressNodes":[{"index":0,"type":"VideoNodeComponent","data":{},"focus":2,"status":0},{"index":1,"type":"ProgressNodeComponent","data":{"text":"1"},"focus":1,"status":0},{"index":2,"type":"ProgressNodeComponent","data":{"text":"2"},"focus":0,"status":0},{"index":3,"type":"ProgressNodeComponent","data":{"text":"3"},"focus":0,"status":0},{"index":4,"type":"ProgressNodeComponent","data":{"text":"4"},"focus":0,"status":0},{"index":5,"type":"ProgressNodeComponent","data":{"text":"5"},"focus":0,"status":0},{"index":6,"type":"ProgressNodeComponent","data":{"text":"6"},"focus":0,"status":0},{"index":7,"type":"ProgressNodeComponent","data":{"text":"7"},"focus":0,"status":0},{"index":8,"type":"ProgressNodeComponent","data":{"text":"8"},"focus":0,"status":0},{"index":9,"type":"ProgressNodeComponent","data":{"text":"9"},"focus":0,"status":0},{"index":10,"type":"ProgressNodeComponent","data":{"text":"10"},"focus":0,"status":0}],"elapsedTime":608,"currTry":0,"filledAnswers":[[]],"selectedAnswers":[[]],"disabledAnswers":[[]],"curHintsRevealed":[],"lastOpened":"1708025697992"}'
        }
    }
}

export const ReadingLessonsKbDataWithReplay = {
    "HTMLActivityStorage": {
        "CompleteActivities": {
            "reading-3-prepositions-1-fromplanetstostars": '{"activityId":"reading-3-prepositions-1-fromplanetstostars","timesCompleted":1,"maxStars":2,"fastestTimeInSecs":126,"completions":[{"activityId":"reading-3-prepositions-1-fromplanetstostars","dateCompleted":"1708024269480","timeToCompleteSecs":126,"starsEarned":2,"score":85,"answerStates":[1,0,1,1,1,1,1,1,2,1]}]}'
        },
        "IncompleteActivities" : {
            "reading-3-prepositions-1-fromplanetstostars": '{"id":"reading-3-prepositions-1-fromplanetstostars","currentStep":8,"questionNumber":5,"numPerfect":3,"numCorrect":5,"numIncorrect":1,"answerStates":[1,1,2,0,0,1],"populationResponses":[{"responses":[[["33744.B.2.1_1"]],[["33744.B.2.2_1"]],[["-1"]],[["-1"]],[["-1"]],[["33744.B.2.4_1"]],[["-1"]],[["33744.B.2.5_1"]],[["33744.B.2.6_1"]]],"elapsedTime":2364}],"progressNodes":[{"index":0,"type":"VideoNodeComponent","data":{},"focus":2,"status":0},{"index":1,"type":"ProgressNodeComponent","data":{"text":"1"},"focus":2,"status":3},{"index":2,"type":"ProgressNodeComponent","data":{"text":"2"},"focus":2,"status":3},{"index":3,"type":"ProgressNodeComponent","data":{"text":"3"},"focus":2,"status":1},{"index":4,"type":"ProgressNodeComponent","data":{"text":"4"},"focus":2,"status":2},{"index":5,"type":"ProgressNodeComponent","data":{"text":"5"},"focus":2,"status":2},{"index":6,"type":"ProgressNodeComponent","data":{"text":"6"},"focus":2,"status":3},{"index":7,"type":"ProgressNodeComponent","data":{"text":"7"},"focus":1,"status":0},{"index":8,"type":"ProgressNodeComponent","data":{"text":"8"},"focus":0,"status":0},{"index":9,"type":"ProgressNodeComponent","data":{"text":"9"},"focus":0,"status":0},{"index":10,"type":"ProgressNodeComponent","data":{"text":"10"},"focus":0,"status":0}],"elapsedTime":112,"currTry":0,"filledAnswers":[[]],"selectedAnswers":[[]],"disabledAnswers":[[]],"curHintsRevealed":[],"lastOpened":"1708458104782"}'
        }
    }
}

export const EpisodeKbDataObjects = [
    {
        activityId: "reading-3-prepositions-2-comettale",
        lastOpened: new Date(1708458104782),
        progressDetails: [1,1,2,0,0,1],
    },
    {
        activityId: "reading-3-prepositions-3-surfsupshipsdown",
        lastOpened: new Date(1708025697992),
        progressDetails: [],
    },
    {
        activityId: "reading-3-prepositions-1-fromplanetstostars",
        lastOpened: new Date(1708024269480),
        score: 85,
        progressDetails: [1,0,1,1,1,1,1,1,2,1],
    }
]

export const EpisodeKbDataObjectsWithReplay = [{activityId: "reading-3-prepositions-1-fromplanetstostars",
    lastOpened: new Date(1708458104782),
    progressDetails: [1,1,2,0,0,1],
}]

export const ExampleEpisodes: {[key: string]: Partial<EpisodeCard>} = {
    'Grade3_A': new EpisodeCard({
        id: '31',
        uniqueId: 'Reading.31',
        title: 'Third Grade Episode A',
        numQuestions: 5,
        episodeNumber: 1,
        grades: [3]
    }),
    'Grade3_B': new EpisodeCard({
        id: '32',
        uniqueId: 'Reading.32',
        title: 'Third Grade Episode B',
        numQuestions: 5,
        episodeNumber: 2,
        grades: [3]
    }),
    'Grade3_C': new EpisodeCard({
        id: '33',
        uniqueId: 'Reading.33',
        title: 'Third Grade Episode C',
        numQuestions: 5,
        episodeNumber: 3,
        grades: [3]
    }),
    'Grade2_A': new EpisodeCard({
        id: '21',
        uniqueId: 'Reading.21',
        title: 'Second Grade Episode A',
        numQuestions: 5,
        episodeNumber: 1,
        grades: [2]
    }),
    'Grade2_B': new EpisodeCard({
        id: '22',
        uniqueId: 'Reading.22',
        title: 'Second Grade Episode B',
        numQuestions: 5,
        episodeNumber: 1,
        grades: [2]
    }),
    'GradeNone_A': new EpisodeCard({
        id: '1',
        uniqueId: 'Reading.1',
        title: 'No Grade Episode A',
        numQuestions: 5,
        episodeNumber: 1,
    }),
    'Grade3_5_A': new EpisodeCard({
        id: '35',
        uniqueId: 'Reading.35',
        title: 'Third and Fifth Grade Episode A',
        numQuestions: 5,
        episodeNumber: 1,
        grades: [3,5]
    }),
}

export const ExampleSeries: {[key: string]: SeriesCard} = {
    ThreeGrade3: {
        id: '123456',
        title: 'Series with 3 grade 3 Episodes',
        progress: 0,
        domain: 'Domain 1',
        episodes: [
            ExampleEpisodes.Grade3_A,
            ExampleEpisodes.Grade3_B,
            ExampleEpisodes.Grade3_C,
        ].map(e => new EpisodeCard(e))
    },
    TwoGrade3: {
        id: '234567',
        title: 'Series with 2 grade 3 episodes',
        progress: 0,
        domain: 'Domain 2',
        episodes: [
            ExampleEpisodes.Grade3_A,
            ExampleEpisodes.Grade3_B,
        ].map(e => new EpisodeCard(e))
    },
    TwoGrade2: {
        id: '345678',
        title: 'Series with 2 grade 2 episodes',
        progress: 0,
        domain: 'Domain 3',
        episodes: [
            ExampleEpisodes.Grade2_A,
            ExampleEpisodes.Grade2_B,
        ].map(e => new EpisodeCard(e))
    },
    NoEpisodes: {
        id: '456789',
        title: 'Series with no episodes',
        progress: 0,
        domain: 'Domain 4',
        episodes: []
    },
    OneGrade2OneGrade3: {
        id: '567890',
        title: 'Series with 1 grade 2 and 1 grade 3 episode',
        progress: 0,
        domain: 'Domain 5',
        episodes: [
            ExampleEpisodes.Grade2_A,
            ExampleEpisodes.Grade3_A,
        ].map(e => new EpisodeCard(e))
    },
    MixedGradeEpisode: {
        id: '112233',
        title: 'Series with a mixed grade episode',
        progress: 0,
        domain: 'Domain 6',
        episodes: [
            ExampleEpisodes.Grade3_5_A
        ].map(e => new EpisodeCard(e))
    },
}

export const ExampleSkillGroups: {[key: string]: SkillGroup} = {
    TwoSeries: {
        name: 'Skill Group with two 3rd grade series',
        episodes: [],
        series: [
            ExampleSeries.ThreeGrade3,
            ExampleSeries.TwoGrade3,
        ]
    },
    OneGrade2OneGrade3: {
        name: 'Skill group with one 2nd grade and one 3rd grade series',
        episodes: [],
        series: [
            ExampleSeries.TwoGrade2,
            ExampleSeries.TwoGrade3,
        ]
    },
    MixedGradeSeries: {
        name: 'Skill group with a mixed grade series',
        episodes: [],
        series: [
            ExampleSeries.MixedGradeEpisode,
        ]
    },
    OneGrade2: {
        name: 'Skill group with one 2nd grade series',
        episodes: [],
        series: [
            ExampleSeries.TwoGrade2,
        ]
    },
}

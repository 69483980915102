<section class="episode-preview">
  <section 
  *ngIf="isAssigned"
  class="assigned-banner"
  [ngClass]="{
    'due-soon': deadlineStatus === TOMORROW,
    'due-now': deadlineStatus === NOW,
    'due-past': deadlineStatus === OVERDUE,
    'due-none': deadlineStatus === NONE,
  }"
>
    <svg-icon class="mini-folder" src="assets/icons/mini-folder.svg"></svg-icon>
    <span class="assigned-banner-txt" >
      {{ 'ASSIGNMENTS.ASSIGNMENT' | translate }}
    </span>
    <svg-icon class="icon-calendar" [src]="'assets/icons/calendar' + (deadlineStatus === OVERDUE ? '-alert' : '') + '.svg'"></svg-icon>
    <span class="assignment-duedate">
        <strong *ngIf="deadlineStatus !== NONE">{{ (deadlineStatus === OVERDUE ? 'ASSIGNMENTS.DUE_PAST' : 'ASSIGNMENTS.DUE' ) | translate }}</strong> 
        {{ $any(episode).localizedDeadline | translate }}
    </span>  
</section>

  <article>
    <figure class="episode-preview-card-image">
      <img 
        crossorigin
        [src]="(configService.config.assetsURL + episode.image)" 
      />
    </figure>
    <section>
      <p *ngIf="skillName" class="skill-name">{{skillName}}</p>
      <header><h3>{{'LESSONS.LEARNING_LEADER' | translate}} {{episode.learningTarget}}.</h3></header>
      <p>{{episode.description}}</p>
    </section>
  </article>

  <footer footer>
    <ng-container *ngIf="!episode.progressDetails; else footerHasStarted">
      <swe-episode-overview
        [contents]="episode.contains"
      ></swe-episode-overview>
      <button
        type="button"
        class="btn btn-primary"
        sweAudioClick
        [audioType]="types.audio_SfxClick"
        [descriptor]="descriptors.CLICK_MORE_NEXT"
        sweMicrolessonNavigation
        [activityOid]="episode.id"
        [activityId]="episode.uniqueId"
        [assignmentId]="assignmentId"
        [seriesId]="parentSeries"
      >
        {{ 'LESSONS.START' | translate }}
        <svg-icon
          src="assets/icons/play.svg"
        ></svg-icon>
      </button>
    </ng-container>

    <ng-template #footerHasStarted>
      <section class="episode-status" *ngIf="episode.progress>=0">
        <span>
          <h6>{{(episode.completed ? 'LESSONS.COMPLETED' : 'LESSONS.LAST_OPENED') | translate }}</h6>
          <h6>{{ episode.formattedDate }}</h6>
        </span>

        <swe-preview-progress-bar
          [hasVideo]="episode.hasVideo"
          [numQuestions]="episode.numQuestions"
          [progressDetails]="episode.progressDetails||[]"
        ></swe-preview-progress-bar>

        <span class="episode-score" *ngIf="episode.completed">
          <h6>{{'LESSONS.SCORE' | translate }}</h6>
          <h6>{{ episode.completedScore }}</h6>
        </span>
      </section>

      <section class="btn-group">
        <button
          *ngIf="episode.progress<100"
          type="button"
          class="btn btn-secondary btn-restart"
          sweAudioClick
          [audioType]="types.audio_SfxClick"
          [descriptor]="descriptors.CLICK_MORE_NEXT"
          sweMicrolessonNavigation
          [activityOid]="episode.id"
          [activityId]="episode.uniqueId"
          [assignmentId]="assignmentId"
          [seriesId]="parentSeries"
          [isRestarting]="true"
        >
          <svg-icon
            src="assets/icons/refresh.svg"
          ></svg-icon>
            {{ 'LESSONS.START_OVER' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="{'btn-restart': episode.progress===100}"
          sweAudioClick
          [audioType]="types.audio_SfxClick" 
          [descriptor]="descriptors.CLICK_MORE_NEXT"
          sweMicrolessonNavigation
          [activityOid]="episode.id"
          [activityId]="episode.uniqueId"
          [assignmentId]="assignmentId"
          [seriesId]="parentSeries"
        >
          {{ (episode.progress<100 ? 'LESSONS.CONTINUE' : 'LESSONS.REPLAY') | translate }}
          <svg-icon
            [src]="'assets/icons/' + (episode.progress<100 ? 'play' : 'refresh') + '.svg'"
          ></svg-icon>
        </button>
      </section>
    </ng-template> 
  </footer>
</section>
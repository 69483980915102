<div class="popup" sweTrapFocus>
  <div class="popup-menu">
    <swe-avatar
      class="avatar"
      [avatar]="avatar"
      [flair]="'square'"
    ></swe-avatar>
    <h3 class="student-name">{{ studentName }}</h3>
    <button
      #btnAvatar
      id="btn-avatar-chooser"
      class="btn btn-system-primary btn-avatar"
      type="button"
      role="menuitem"
      sweAudioClick
      [audioType]="clickType"
      [descriptor]="clickDesc"
      (click)="handleAvatarPopup()"
    >
      <svg-icon src="assets/icons/avatar.svg"></svg-icon> Avatar
    </button>
    <button
      class="btn btn-system-primary btn-settings"
      type="button"
      *sweFeatureFlag="'settingsPopup'"
      role="menuitem"
    >
      <svg-icon src="assets/icons/settings.svg"></svg-icon>
      Settings
    </button>

    <div class="version">
      <p>
        <ng-container
          *ngIf="this.wasmService.runtimeVersion | async; let runtimeVersion"
          >v{{ runtimeVersion }}</ng-container
        >
        (v{{ appVersion }})
      </p>
      <p>
        <label>Data Source: </label>
        <ng-container>{{ dataSource }}</ng-container>
      </p>
      <button
        #btnSupport
        class="btn-link"
        type="button"
        role="menuitem"
        (click)="handleSupportPopup()"
      >
        Support
      </button>
    </div>
  </div>
  <footer class="popup-footer">
    <button
      class="btn btn-system-tertiary btn-logout"
      type="button"
      role="menuitem"
      (click)="logoutPopup()"
    >
      <svg-icon src="assets/icons/logout.svg"></svg-icon>
      Logout
    </button>
  </footer>
</div>
<button
  type="button"
  class="btn btn-system-tertiary btn-close"
  sweAudioClick
  [audioType]="clickType"
  [descriptor]="clickClose"
  (click)="closeProfile()"
  aria-label="Close profile settings menu"
>
  <svg-icon src="assets/icons/close.svg"></svg-icon>
</button>

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { FeatureFlagService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class SubjectNameGuard  {

  router = inject(Router);
  featureFlag = inject(FeatureFlagService);

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const allowedSubjects = ['reading', 'math', 'lectura','matemáticas'];
    const subjectName = route.params.subject;

    if (subjectName === 'matemáticas' && !this.featureFlag.isFlagEnabled('simpleIsipFlow')) {
			// Currently no additional pages for Matemáticas
			return this.router.createUrlTree(['/home']);
		}
    if (allowedSubjects.includes(subjectName)) {
			return true;
		}
		// Show 404 if the subject name entered is invalid
    return this.router.createUrlTree(['/unavailable']);
  }

}

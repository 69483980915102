import {
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { AssessmentApiService } from '@swe/services';
import { PeriodScore } from '@swe/types';
import { SUBTESTS } from "../data/debug-isip";

@Component({
  standalone: true,
  selector: 'swe-isip-debug',
  templateUrl: './isip-debug-control.component.html',
  styleUrls: [
    '../debug.component.scss',
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ]
})
export class IsipDebugControlComponent implements OnInit {
  assessmentApiService = inject(AssessmentApiService);
  private formBuilder = inject(UntypedFormBuilder);

  isipDataForm!: UntypedFormGroup;
  isipDataToggleForm!: FormGroup;
  isipSkipToggleForm!: FormGroup;

  availableSubtests = SUBTESTS;

  readonly NUM_PERIODS = 12;
  readonly SCORE_MIN = 0;
  readonly SCORE_MAX = 3000;

  get isipPeriodFormArray() {
    return this.isipDataForm.get("isipPeriodFormArray") as FormArray;
  }

  ngOnInit(): void {
    this.isipSkipToggleForm = new FormGroup({
      isipSkipSwitch: new FormControl(this.assessmentApiService.canSkipAssessment)
    });
    this.isipDataToggleForm = new FormGroup({
      isipDataOverrideSwitch: new FormControl(this.assessmentApiService.useDataOverride)
    });

    if (this.assessmentApiService.manualData && this.assessmentApiService.manualGoals.length) {
      this.setIsipFormFromAssessmentApiData();
    } else {
      this.resetIsipForm();
    }
  }

  resetIsipForm(): void {
    this.setupIsipForm();
    this.setAssessmentApiDataFromIsipForm();
  }

  setAssessmentApiDataFromIsipForm(): void {
    const periodData = this.isipPeriodFormArray.value;
    // console.log("%c Saving ISIP data from form -->", 'background:pink;color:black;', periodData);

    // correct/adjust scores and goals
    const adjScoreArray: number[] = [];
    const adjGoalArray: number[] = [];
    const formArrayData = [];
    const scores: PeriodScore[] = [];
    const goals: PeriodScore[] = [];
    const autoGoalStep = 10;

    let initialScore = 0;
    for (let i = 0; i < this.NUM_PERIODS; ++i) {
      // grab score value, adjust if needed, and save to array
      let score = periodData[i].isipPeriodScore as number;
      score = (!score || score < this.SCORE_MIN)
              ? this.SCORE_MIN
              : ((score > this.SCORE_MAX)
                  ? this.SCORE_MAX
                  : score);  // check if exists or valid value, and update if not
      adjScoreArray.push(score);

      // grab goal value, adjust if needed, and save to array
      let goal = periodData[i].isipPeriodGoal as number;
      if (!initialScore) {
        if (score) {// our 1st score; autogenerated goals will not go below this
          initialScore = score;
        }
        goal = score; // 1st goal value = 1st score value
      } else { // this is for after we've gotten a score
        goal = (!goal || score < this.SCORE_MIN)
                ? initialScore + (i * autoGoalStep) // auto generate goal
                : ((goal > this.SCORE_MAX)
                    ? this.SCORE_MAX  // too high
                    : goal);
      }
      adjGoalArray.push(goal);

      // save [adjusted] data to display on form
      formArrayData.push({
        isipPeriodScore: score,
        isipPeriodGoal: goal
      });

      // save [adjusted] data to store in manual override data within service
      if (score) {
        scores.push(this.createPeriodScoreObj(i, score));
      }
      if (goal) {
        goals.push(this.createPeriodScoreObj(i, goal));
      }
    }

    // adjust values displayed on form
    this.isipPeriodFormArray.patchValue(formArrayData);

    // save manual override data within service
    this.assessmentApiService.manualData = {
      lastCompleted: undefined,
      subtests: [
          this.isipDataForm.get('isipSubtestWorst')?.value,
          this.isipDataForm.get('isipSubtestBest')?.value
        ],
      totalScores: scores
    };
    this.assessmentApiService.manualGoals = goals;
  }

  createPeriodScoreObj(periodValue: number, scoreValue: number): PeriodScore {
    return {
      period: periodValue,
      value: scoreValue
    };
  }

  setupIsipForm(subtests: string[] = ['CMP', 'CMP']): void {
    this.isipDataForm = this.formBuilder.group({
      isipSubtestBest: new FormControl(subtests[subtests.length-1]),
      isipSubtestWorst: new FormControl(subtests[0]),
      isipPeriodFormArray: this.formBuilder.array([]),
    });

    for (let i = 0; i < this.NUM_PERIODS; i++) {
      this.isipPeriodFormArray.push(
        this.formBuilder.group({
          isipPeriodScore: new FormControl(0),
          isipPeriodGoal: new FormControl(0)
        })
      );
    }
  }

  setIsipFormFromAssessmentApiData(): void {
    this.setupIsipForm(this.assessmentApiService.manualData.subtests);

    const periodData = this.isipPeriodFormArray.value;
    const scores = this.assessmentApiService.manualData.totalScores;
    const goals = this.assessmentApiService.manualGoals

    if (scores.length) {// in case we didn't save any score data
      scores.forEach(
          score => periodData[score.period].isipPeriodScore = score.value
        );
    }
    if (goals.length) {// in case we didn't save any goal data
      goals.forEach(
          goal => periodData[goal.period].isipPeriodGoal = goal.value
        );
    }

    this.isipPeriodFormArray.patchValue(periodData);
  }
}
export type HttpParams = { [index: string]: string | number };

export type HttpHeaders = { [key: string]: string };

export type HttpMethods = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';

export interface RequestOptions {
  params?: HttpParams;
  headers?: HttpHeaders;
}

export interface RequestOptionsWithData<T extends Record<string, unknown>> extends RequestOptions {
  data?: T;
}

export interface FetchConfig {
    baseUrl?: string;
    defaultHeaders?: HttpHeaders;
    csrfCookieName?: string;
    csrfHeaderName?: string;
    trailingSlash?: TrailingSlashOptions;
}

export type TrailingSlashOptions = 'always' | 'none' | 'write_only'

/**
 * Library loosely adapted from the premise in ngx-fetch-api
 */
export class APIHandler {
    baseUrl = 'https://api-priv.hydra.dev.istation.net/olpdata-priv-6nyf9en0z7';
    endpoint?: string;
    private defaultHeaders: HttpHeaders = {};
    private trailingSlash: TrailingSlashOptions = 'write_only';

    constructor(config?: FetchConfig){
        if (config) {
            this.configure(config);
        }
    }

    configure(config: FetchConfig): void {
        if (config.baseUrl) {
          this.baseUrl = config.baseUrl.endsWith('/')
            ? config.baseUrl.slice(0, -1)
            : config.baseUrl;
        }
    
        if (config.defaultHeaders) {
          this.defaultHeaders = config.defaultHeaders;
        }
    
        if (config.trailingSlash) {
          this.trailingSlash = config.trailingSlash;
        }
    }

    getHeaders(headers?: HttpHeaders): HttpHeaders {
        const newHeaders = {
          ...this.defaultHeaders,
          ...headers,
        };
        
        return newHeaders;
    }
    
    request<TResponse extends {} | void>(
        method: HttpMethods,
        path: string,
        options?: RequestOptionsWithData<Partial<TResponse>> | RequestOptions,
    ): Promise<TResponse> {
        const fetchOptions: {[key: string]: unknown} = { method };
        const headers: {[key: string]: unknown} = this.getHeaders(options?.headers) || {};
        const url: string = (() => {
            let url = path.startsWith('/') ? path : `${this.baseUrl}/${path}`;
            if (!path.endsWith('/')) {
                const ts = this.trailingSlash;
                if (ts === 'always' || (ts === 'write_only' && method !== 'GET')) {
                    path = path + '/';
                }
            }
            if (options && options.params && Object.keys(options.params).length > 0) {
                url += "?" + Object.entries(options.params).reduce((acc, [k,v]) => {
                    (k && v !== null && typeof v !== 'undefined' && acc.push(`${encodeURIComponent(k)}=${encodeURIComponent('' + v)}`));
                    return acc;
                }, [] as string[]).join('&');
            }
            return url;
        })();

        if ((<RequestOptionsWithData<Partial<TResponse>>>options)?.data) {
          fetchOptions['body'] = JSON.stringify(
            (<RequestOptionsWithData<Partial<TResponse>>>options).data,
          );
          headers['Content-Type'] = 'application/json';
        }
    
        fetchOptions['headers'] = headers;
    
        return fetch(url, fetchOptions)
            .then(async (response) => {
                const contentType = response.headers.get('Content-Type');
                const isJson = contentType === 'application/json';
                if (this.hasValidResponse(response)) {
                    return isJson ? response.json() : null;
                } else if (response.status === 400 && isJson) {
                    throw await response.json();
                }
                throw response;
            })
            .then(json => {
                if (this.hasApiMessage(json)) {
                    throw json.message; 
                } else return json;
            });
    }

    get<TResponse extends {}>(path: string, options?: RequestOptions): Promise<TResponse> {
        return this.request<TResponse>('GET', path, options);
    }
    
    post<TResponse extends {}>(path: string, options?: RequestOptionsWithData<TResponse>): Promise<TResponse> {
        return this.request<TResponse>('POST', path, options);
    }

    put<TResponse extends {}>(path: string, options?: RequestOptionsWithData<TResponse>): Promise<TResponse> {
        return this.request<TResponse>('PUT', path, options);
    }

    patch<TResponse extends {}>(
        path: string,
        options?: RequestOptionsWithData<Partial<TResponse>>,
    ): Promise<TResponse> {
        return this.request<TResponse>('PATCH', path, options);
    }

    delete(path: string, options?: RequestOptions): Promise<void> {
        return this.request<void>('DELETE', path, options);
    }

    private hasValidResponse(apiResponse: Response): boolean {
        return apiResponse && apiResponse.ok && apiResponse.status >= 200 && apiResponse.status < 300 && !!apiResponse.body;
    }

    private hasApiMessage<T>(apiResponse: T | null): boolean {
        return !!apiResponse && Object.prototype.hasOwnProperty.call(apiResponse, 'message')
    }
}
import {
  Component,
  inject,
  OnInit,
  ViewChild
} from "@angular/core";
import { NgForm } from '@angular/forms';
import { FeatureFlagService } from '@swe/services';

@Component({
  selector: 'swe-flag-override',
  templateUrl: './flag-override-control.component.html',
  styleUrls: [
    './flag-override-control.component.scss',
    '../debug.component.scss',
  ]
})
export class FlagOverrideControlComponent implements OnInit {
  flagsModel: {[key: string]: boolean} = {};
  configValues: {[key: string]: boolean} = {};
  overrides: {[key: string]: boolean} = {};
  customFlags: {[key: string]: boolean} = {};
  newCustomName = '';
  newCustomValue = false;

  flagService = inject(FeatureFlagService);

  @ViewChild('flagsForm') flagsForm!: NgForm;

  constructor() {}

  ngOnInit(): void {
    this.flagsModel = {...this.flagService.flags()};
    this.configValues = {...this.flagService.flags()};
    this.overrides = {...this.flagService.overrides()};
    Object.entries(this.overrides).forEach(([key, value]) => {
      if(this.flagsModel.hasOwnProperty(key)) {
        this.flagsModel[key] = value;
      } else {
        this.customFlags[key] = value;
      }
    })
  }

  addCustomFlag(): void {
    if (!this.newCustomName.trim() || this.doesFlagExist(this.newCustomName)) {
      console.warn('Flag already exists!')
      return;
    }
    this.customFlags[this.newCustomName] = this.newCustomValue;
    this.newCustomName = '';
    this.newCustomValue = false;
  }

  addOverride(flag: string): void {
    this.overrides[flag] = this.flagsModel[flag];
  }

  toggleFlag(flag: string): void {
    if (this.flagsModel[flag] === this.configValues[flag]) {
      console.warn('same, so delete')
      delete this.overrides[flag]
    } else {
      console.warn('not same, set')
      this.overrides[flag] = this.flagsModel[flag];
    }
  }

  isOverridden(flag: string): boolean {
    return this.flagsModel[flag] !== this.configValues[flag] ||
      this.overrides.hasOwnProperty(flag);
  }

  clearAllOverrides(): void {
    this.flagsModel = {...this.flagService.flags()};
    this.overrides = {};
  }

  clearAllCustomFlags(): void {
    this.customFlags = {};
  }

  clearAll(): void {
    this.clearAllOverrides();
    this.clearAllCustomFlags();
  }

  clearOverride(flag: string): void {
    this.flagsModel[flag] = this.configValues[flag]
    delete this.overrides[flag];
  }

  clearCustom(flag: string): void {
    delete this.customFlags[flag];
  }

  doesFlagExist(flag: string): boolean {
    return this.configValues.hasOwnProperty(flag) ||
      this.customFlags.hasOwnProperty(flag);
  }

  apply(): void {
    const o = {...this.overrides, ...this.customFlags}
    this.flagService.setOverrides(o);
    this.flagService.storeOverrides();
    this.flagsForm.form.markAsPristine();
  }
}
import SubjectDescriptions from "../data/subject-descriptions";
import { SubjectFactory } from "./subject-factory";
import SubjectSubheadings from "../data/subject-subheadings";
import SubjectThumbnails from "../data/subject-thumbnails";
import subjectsInfo from "../data/subjects-info";
import {
    GradeBands,
    LanguageCodes,
} from "../enums";
import { 
    DictionaryISIPValue,
    DictionaryScoresValue,
    InvokeResponseResult,
    IsipMonthInfo,
    IsipSubtest,
    StudentSubject,
    StudentSubjectIsip,
    SubjectEntity
} from "../types";

type StudentSubjects = {
    [key: string]: StudentSubject
};

type IsipMeta = {
    name: string,
    subheading: string,
    description: string,
    image: string,
    period: number;
    startingPeriod: number;
    lastCompleted?: Date;
    frequency: number;
    subtests:IsipSubtest[],
    totalScores: IsipMonthInfo[],
    goals: IsipMonthInfo[],  
    link: string[],
    resultsLabel: string,
}

export class SubjectCard implements SubjectEntity {
    private _subheading = "";
    private _description = '';
    private _image = '';
    private _link: string[] = [];
    name = '';
    _name = "";
    isip: IsipMeta = {
        name: "",
        subheading: "Show what you know!",
        description: "",
        image: "assets/thumbnails/Isip_Small.png",
        period: 0,
        frequency: 1,
        startingPeriod: 0,
        subtests: <Array<IsipSubtest>>[],
        totalScores: <Array<IsipMonthInfo>>[],
        goals: <Array<IsipMonthInfo>>[],
        link: <Array<string>>[],
        resultsLabel: "Results",
    }
    sortPriority = 0;
    cardValue = 0;
    cardState = 0;
    alt_text = "";
    isVerbal = false;
    language = "";
    limitToGrades: number[] = [];
    isNeeded = false;
    isRunningAdhoc = false;
    isipOnly = false;
    footerLabel = '';
    tier = 0;
  
    constructor({
        name,
        band = GradeBands.BAND_1,
        isipDictionary
    }:{
        name: string,
        band?: GradeBands,
        isipDictionary?: DictionaryISIPValue
    })
    {
        const subjects = subjectsInfo as StudentSubjects;
        const validSubject = name in subjects;
        if (validSubject) {
            const subject = structuredClone(subjects[name]);
            subject.image = this.getValue('thumbnail', name, band);
            subject.subheading = this.getValue('subheading', name, band);
            subject.description = this.getValue('description', name, band);
            Object.assign(this, subject);

            // Check for and fill in ISIP
            const isipName = name + 'Isip';
            const validIsip = isipName in subjects;
            const { InvalidIsip } = subjects;
            const isipSubject = (validIsip ? subjects[isipName]: InvalidIsip) as StudentSubjectIsip;
            const isip = <Partial<IsipMeta>>{
                name: isipName,
                image: this.getValue('thumbnail', isipName, band),
                subheading: this.getValue('subheading', isipName, band),
                description: this.getValue('description', isipName, band),
                link: isipSubject.link ?? [],
                resultsLabel: this.getResultsLabel(subject.language),
            };

            if (isipDictionary) {
                const [subtests, totalScores, goals] = !!isipDictionary.Scores.value ? this.parseSubtests(isipDictionary?.Scores?.value as DictionaryScoresValue) : [];
                Object.assign(isip, {
                    startingPeriod: parseInt(isipDictionary.StartingPeriod.value, 10),
                    period: parseInt(isipDictionary.Period.value, 10),
                    lastCompleted: SubjectFactory.parseDate(isipDictionary.lastCompleted),
                    subtests,
                    totalScores,
                    goals,
                });
                // Update object properties from dictionary values
                this.isRunningAdhoc = isipDictionary.isRunningAdhoc.value === 'true',
                this.isNeeded = isipDictionary?.IsNeeded?.value === 'true';
            }
            Object.assign(this.isip, isip);
            // if( name === 'Math' ) {
            //     console.warn(`is needed ${subject.isNeeded}`);
            //     // console.warn(`We have a true isNeeded`, isipDictionary, isip);
            //     // console.warn(`The evaluation is ${(isipDictionary?.IsNeeded?.value === 'true').toString()}`, isipDictionary, isip);
            //     console.trace('Trace');    
            // }
            // console.warn(JSON.parse(JSON.stringify(isip)));
        } else {
            const { Invalid } = subjects;
            Object.assign(this, Invalid);
            throw 'Could not find data for (invalid) subject: ' + name;
        }
    }

    parseSubtests(scoresList: DictionaryScoresValue): [IsipSubtest[], IsipMonthInfo[], IsipMonthInfo[]] {
       return Object.entries(scoresList)
            .reduce<[IsipSubtest[], IsipMonthInfo[], IsipMonthInfo[]]>(([s, o, g], [key, {value: valuesList}]) => {
                if ( key === 'Skills' ) {
                    s = (Object.values(valuesList) as InvokeResponseResult[]).map<IsipSubtest>(({value: name}) => ({name}));
                } else if ( Object.keys(valuesList).length ) {
                    const valuesArray = Object.entries(valuesList);
                    const scoreArray = valuesArray.map(([scorePeriod, {value: scoreValue}]) => {
                        const period = parseInt(scorePeriod, 10);
                        const value = parseFloat(scoreValue);
                        return Object.assign({}, { period, value });
                    });
                    if (key === 'Overall') {
                        o = scoreArray;
                    } else {
                        g = scoreArray;
                    }
                }
                return [ s, o, g ];
            }, [[], [], []]);
    }

    isGradeAppropriate(grade: number): boolean {
        return this.limitToGrades && this.limitToGrades.length ? this.limitToGrades.includes(grade) : true;
    }

    private getValue (valueType: 'description' | 'subheading' | 'thumbnail', subjectName: string, gradeBand: GradeBands): string {
        const database: {[index: string]: {[index: number]: string}} = valueType === "description" 
            ? SubjectDescriptions 
                : valueType === "subheading" 
                    ? SubjectSubheadings 
                        : valueType === "thumbnail" 
                            ? SubjectThumbnails 
                                : {};
        // console.warn(subjectName,database[subjectName]);
        return database[subjectName] && database[subjectName][gradeBand] ? database[subjectName][gradeBand] : '';
    }

    private getResultsLabel(language: string) : string {
        switch (language) {
            case LanguageCodes.Spanish: return 'Resultados';
            default: // do nothing
        }
        return 'Results';
    }
    
    /* Native Getters and Setters */
    set description(value: string) {
        this._description = value;
    }
    get description(): string {
        return this.isNeeded ? this.isip.description : this._description;
    }
    set subheading(value: string) {
        this._subheading = value;
    }
    get subheading(): string {
        return this.isNeeded ? this.isip.subheading : this._subheading;
    }
    set image(value: string) {
        this._image = value;
    }
    get image(): string {
        return this.isNeeded ? this.isip.image : this._image;
    }

    set link(value: string[]) {
        this._link = value;
    }
    get link(): string[] {
        return this.isNeeded ? this.isip.link : this._link;
    }

}
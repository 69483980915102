import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AudioHelperSharedModule } from '../shared/modules/audio-helper-shared.module';
import { FeatureFlagDirective } from '../shared/directives/feature-flag.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarComponent } from './avatar/avatar.component';
import { AvatarPathMarkerComponent } from './avatar-path-marker/avatar-path-marker.component';
import { LoadingComponent } from './loading/loading.component';
import { NumberCounterComponent } from './number-counter/number-counter.component';
import { NumberSliderComponent } from './number-slider/number-slider.component';
import { OverlayComponent } from './overlay/overlay.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { ProgressBarComponent } from '../shared/components/progress-bar/progress-bar.component';
import { SpriteComponent } from './sprite/sprite.component';
import { ThemeSwitcherComponent } from './theme-switcher.component';
import { TrapFocusDirective } from '../shared/directives/trap-focus.directive';
import { UsageMeterComponent } from './usage-meter/usage-meter.component';
import { UserRatingWindowComponent } from './user-rating-window/user-rating-window.component';
import { WaveBackgroundOverlayComponent } from './wave-background-overlay/wave-background-overlay.component';
import { SourceSwitchComponent } from './source-switch.component';
import { LottiePlayerModule } from '../features/lottie-player/lottie-player.module';
import { ShinyButtonComponent } from '../shared/components/shiny-button/shiny-button.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AudioHelperSharedModule,
    FormsModule,
    HttpClientModule,
    AngularSvgIconModule,
    LottiePlayerModule
  ],
  declarations: [
    AvatarComponent,
    AvatarPathMarkerComponent,
    FeatureFlagDirective,
    LoadingComponent,
    NumberCounterComponent,
    NumberSliderComponent,
    OverlayComponent,
    PageNotFoundComponent,
    ProgressBarComponent,
    ShinyButtonComponent,
    SourceSwitchComponent,
    SpriteComponent,
    ThemeSwitcherComponent,
    TrapFocusDirective,
    UsageMeterComponent,
    UserRatingWindowComponent,
    WaveBackgroundOverlayComponent,
  ],
  exports: [
    AvatarComponent,
    AvatarPathMarkerComponent,
    FeatureFlagDirective,
    LoadingComponent,
    NumberCounterComponent,
    NumberSliderComponent,
    OverlayComponent,
    PageNotFoundComponent,
    ProgressBarComponent,
    ShinyButtonComponent,
    SourceSwitchComponent,
    SpriteComponent,
    ThemeSwitcherComponent,
    TrapFocusDirective,
    UsageMeterComponent,
    UserRatingWindowComponent,
    WaveBackgroundOverlayComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

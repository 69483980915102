import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CountDownComponent } from './count-down/count-down.component';
import { MessagePopupComponent } from './message-popup.component';
import { MessageService } from './message.service';

@NgModule({
  declarations: [
    MessagePopupComponent, 
    CountDownComponent
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule
  ],
  exports: [
    MessagePopupComponent, 
    CountDownComponent
  ],
  providers: [MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MessagePopupModule {
  static forRoot(): ModuleWithProviders<MessagePopupModule> {
    return {
      ngModule: MessagePopupModule,
      providers: [MessageService]
    }
  }
}

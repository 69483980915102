import { inject, Injectable}from '@angular/core';
import { DataSourceType } from '../enums/dataSource'
import { ConfigurationService } from '../services/configuration.service';
import { KnowledgeBaseWASM } from '../classes/knowledge-base/knowledge-base-wasm';
import { KnowledgeBaseAPI } from '../classes/knowledge-base/knowledge-base-api';

export type KbFacadeType = KnowledgeBaseWASM | KnowledgeBaseAPI;

@Injectable()
export class DataSourceFacade {


    private configService: ConfigurationService = inject(ConfigurationService);
    private kbSource!: DataSourceType;
    private kbFacade!: KbFacadeType;

    constructor () {

        this.kbSource = this.getSourceFromConfig();
    }

   public isSource(s: string): s is DataSourceType {

        return s == 'Cloud'   || s == 'Legacy';
    } 

    public getSourceFromConfig() : DataSourceType {
        
        let source: DataSourceType = DataSourceType.LCM;

        const sourceFromConfig = this.configService?.config.dataSource;

        if ( this.isSource(sourceFromConfig) ) {

            source = sourceFromConfig;
        }
        return source;
    }

    public setKbFacade(source: DataSourceType) : KbFacadeType {

        if (source != this.kbSource) {

            this.kbSource = source;           
        }

        let facade: KbFacadeType;

        switch (source) {
            case DataSourceType.CLOUD:
                facade = inject(KnowledgeBaseAPI);
                break;
            case DataSourceType.LCM:
                facade = inject(KnowledgeBaseWASM);
                break;
            default: 
            {
                facade = inject(KnowledgeBaseWASM);
                console.log('setKbFacade: Knowledge Base data source: (default)');
            }
        }

        if (facade !== this.kbFacade) {
            
            console.log('Set Knowledge Base data source: ' + source);
            this.kbFacade = facade;
        }

        return this.kbFacade;
    }

    public getKbFacade(): KbFacadeType {

//        console.log('getKbFacade: source: ' + this.kbSource);
//        console.log('getKbFacade: facade: ' + this.kbFacade);
        if (!this.kbFacade) {

            if (!this.kbSource) {

                this.kbSource = this.getSourceFromConfig();
                console.log('getKbFacade: source from config : ' + this.kbSource);
            }
            
            this.setKbFacade(this.kbSource);
        }
        return this.kbFacade;
    }
    
    
}

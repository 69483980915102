import { NgModule } from '@angular/core';
import { ConfigDataWASM } from '../../classes/config-data/config-data-wasm';
import { ConfigDataAPI } from '../../classes/config-data/config-data-api';
import { ConfigDataService } from '../../services/config-data.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    ConfigDataAPI,
    ConfigDataWASM,
    ConfigDataService
  ]
})
export class ConfigDataModule { }
import { inject, Injectable } from '@angular/core';
import { AssignmentCard } from '@swe/pages/assignments/classes/assignment-card';
import { IstationSubjects } from '@swe/enums';
import { AssignmentStatus, AssignmentRoute } from '@swe/pages/assignments/enums';
import { ContentMetadataService } from '@swe/services';
import { getMicrolessonThumbnail } from '@swe/utils';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {
  private contentMetadataService = inject(ContentMetadataService);

  async getAllAssignments(subject: IstationSubjects): Promise<AssignmentCard[]> {
    return await this.contentMetadataService.getAssignments({subjectFilter: subject, route: AssignmentRoute.STUDENT}).then(apiResponse => 
      apiResponse.map(el => new AssignmentCard({
          uniqueId: el.content?.uniqueId ?? '',
          title: el.content?.title ?? '',
          learningTargets: el.content?.learningTargets ?? [],
          numQuestions: el.content?.questionCount ?? 0,
          teiTypes: el.content?.teiTypes ?? [],
          manifest: el.content?.manifest ?? [],
          episodeNumber: 1,
          ...((el.content?.oid || el.content?.oid === 0) && {id: el.content?.oid.toString()}),
          ...((el.id || el.id === 0) && {assignmentId: el.id}),
          ...(el.status && {status: el.status}),
          ...(el.availableDate && {dateAvailable: new Date(el.availableDate)}),
          ...(el.startDate && {dateStarted: new Date(el.startDate)}),
          ...(el.completedDate && {dateCompleted: new Date(el.completedDate)}),
          ...(el.dueDate && {dateDue: new Date(el.dueDate)}),
          ...(el.content && {image: getMicrolessonThumbnail(el.content?.contentMedia).url}),
          ...(el.content?.grades && {grades: el.content?.grades}),
          ...(el.content?.description && {description: el.content?.description}),
          ...(el.content?.languageCode && {languageCode: el.content?.languageCode}),
          ...(el.content?.learningTargets && {learningTargets: el.content?.learningTargets}),
          ...(el.content?.skill && {skill: el.content?.skill}),
        })
      )
    );
  }

  async getCompletedAssignments(subject: IstationSubjects): Promise<AssignmentCard[]> {
    return await this.getAllAssignments(subject)
      .then(assignments => assignments.filter(({status}) => status === AssignmentStatus.COMPLETED));
  }

  async getIncompletedAssignments(subject: IstationSubjects): Promise<AssignmentCard[]> {
    return await this.getAllAssignments(subject)
      .then(assignments => assignments.filter(({status}) => [AssignmentStatus.ASSIGNED,AssignmentStatus.IN_PROGRESS].includes(status)));
  }

  async hasIncompleteAssignments(subject: IstationSubjects): Promise<boolean> {
    return this.contentMetadataService.hasIncompleteAssignments(subject);
  }
}

import { ConfigurationService } from '../../services/configuration.service';
import { NgModule } from '@angular/core';
import {
  AuthModule,
  StsConfigHttpLoader,
  StsConfigLoader,
} from 'angular-auth-oidc-client';
import { from } from 'rxjs';

const SUPPORTED_SCOPES = [
  'openid',
  'profile',
  'offline_access',
  'runtime_token',
  'api.auth',
  'api.content.read',
  'api.assignment.read',
];
SUPPORTED_SCOPES.toString = function () {
  return this.join(' ');
};

const authConfigFactory = (c: ConfigurationService): StsConfigHttpLoader => {
  return new StsConfigHttpLoader(
    from(c.getAuthConfig().then((c) => ({
      ...c,
      scope: SUPPORTED_SCOPES.toString(),
    } )))
  )
}

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authConfigFactory,
        deps: [ConfigurationService],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}

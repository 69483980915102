/**
 * A Synthetic API Service to intercept requests until a suitable API exists
 */
import { Injectable } from '@angular/core';
import {
  getStatusText,
  RequestInfo,
  InMemoryDbService,
  ResponseOptions,
  STATUS,
} from 'angular-in-memory-web-api';
import { User } from '../types';

import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

// Data imports
import users from '../data/users';
import domains from '../data/domains';
import populations from '../data/populations';
import subscriptions from '../data/subscriptions';
import activities from '../data/activities';

@Injectable({
  providedIn: 'root',
})
export class SimulacrumService extends InMemoryDbService {
  private SECRET_KEY = '1234567890';
  private registeredUser!: User;
  private EXPIRY = '1d';

  createDb(): {} | Observable<{}> | Promise<{}> {
    return {
      users, // <-- import from data definition
      activities, // <-- import from data definition
      populations, // <-- import from data definition
      subscriptions, // <-- import from data definition
      domains, // <-- import from data definition
    };
  }

  get(requestInfo: RequestInfo): Observable<User> | undefined {
    // console.log(requestInfo);
    const { collectionName } = requestInfo;
    if (collectionName === 'getUser') {
      return this.getUser(requestInfo);
    }
    return undefined;
  }

  private getUser(reqInfo: RequestInfo) {
    return reqInfo.utils.createResponse$(() => {
      const data = this.registeredUser;
      const options: ResponseOptions = data
        ? {
            body: data,
            status: STATUS.OK,
            statusText: getStatusText(STATUS.OK),
          }
        : {
            body: { error: `No user active` },
            status: STATUS.UNAUTHORIZED,
            statusText: getStatusText(STATUS.NOT_FOUND),
          };
      options.headers = reqInfo.headers;
      options.url = reqInfo.url;
      return options;
    });
  }

  /**
   * Post Interceptor
   * @param requestInfo
   * @returns
   */
  post(requestInfo: RequestInfo): Observable<string> | undefined {
    const { collectionName, id } = requestInfo;
    if (collectionName === 'users' && id === 'login') {
      return this.authenticate(requestInfo);
    }
    return undefined; // let the default GET handle all others
  }

  genId<T extends User>(collection: T[]): number {
    return collection.length > 0
      ? Math.max(...collection.map((t) => t.id)) + 1
      : 100;
  }

  // Create a token from a payload
  private createToken<T>(payload: T & {expires?: number}): string {
    const header = JSON.stringify({
      alg: 'HS256',
      typ: 'JWT',
    });
    let token = `${this.base64UrlEncode(header)}`;
    const expiry = new Date();
    payload.expires = expiry.setDate(expiry.getDate() + 1); // <-- make it a day for now
    token += `.${this.base64UrlEncode(JSON.stringify(payload))}`;
    return `${token}.${CryptoJS.HmacSHA256(token, this.SECRET_KEY).toString(
      CryptoJS.enc.Base64
    )}`;
    // return jwt.sign(payload, this.SECRET_KEY, {expiresIn: this.EXPIRY});
  }

  // Verify the token
  private verifyToken(token: string): boolean {
    return token ? true : false;
    // return jwt.verify(token, this.SECRET_KEY, {} /*(err: string, decode: string | undefined) => decode !== undefined ?  decode : err*/);
  }

  // Check if the user exists in database
  private authenticate(reqInfo: RequestInfo) {
    const { collection: users } = reqInfo;
    return reqInfo.utils.createResponse$(() => {
      let data;
      const { username, password, domain } = reqInfo.utils.getJsonBody(
        reqInfo.req
      );
      if (username && password && domain) {
        // lookup user by username and domain
        const user = users.find(
          ({ username: un, domain: dm }: User) =>
            un.toUpperCase() === username.toUpperCase() && dm === domain
        );
        // Check password
        if (user && user.password && user.password === password) {
          this.registeredUser = user;
          data = this.createToken({
            oid: user.id,
            name: `${user.firstName} ${user.lastName}`,
          });
        }
      }

      const options: ResponseOptions = data
        ? {
            body: data,
            status: STATUS.OK,
            statusText: getStatusText(STATUS.OK),
          }
        : {
            body: { error: `Login failed` },
            status: STATUS.UNAUTHORIZED,
            statusText: getStatusText(STATUS.NOT_FOUND),
          };
      options.headers = reqInfo.headers;
      options.url = reqInfo.url;
      return options;
    });
  }

  private base64UrlEncode(source: string): string {
    const wordArray = CryptoJS.enc.Utf8.parse(source);
    let encodedSource = CryptoJS.enc.Base64.stringify(wordArray);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
    return encodedSource;
  }
}

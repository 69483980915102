import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'swe-usage-meter',
	templateUrl: './usage-meter.component.html',
	styleUrls: ['./usage-meter.component.scss'],
})
export class UsageMeterComponent implements OnChanges {
	@Input() subjectName = '';
	@Input() title = 'Weekly Minutes';
	@Input() label = 'Goal';
	@Input() hint = '';
	@Input() goalValue = 0;
	@Input() userValue = 0;

	readonly NO_DATA_LABEL = '--';
	readonly CUTOFF_MULTIPLIER = 4;	// 140 for 40, 105 for 30;
	progress = 0;
	hasMetGoal = false;

	startAnimation = false;
	animationDuration = 0;
	frameDuration = 1000 / 60;
	totalFrames = 0;
	animationDelay = 0;

	easeOutQuad = (t: number) => t * ( 2 - t );
	
	ngOnChanges(changes: SimpleChanges) {
		if (changes.userValue || changes.goalValue) {
			this.calculateValues();

			if (this.userValue > 0 && this.goalValue > 0) {
				this.runAnimations();
			}
		}
	}

	calculateValues(): void {
		if (this.goalValue > 0) {
			this.hasMetGoal = this.userValue >= this.goalValue;
			const cutoffValue = this.goalValue * this.CUTOFF_MULTIPLIER;	
			if (!this.hasMetGoal) {
				this.progress = this.userValue / this.goalValue;
			} else if (this.userValue <= this.goalValue * 2) {
				this.progress = this.goalValue / this.userValue;
			} else if (this.userValue < cutoffValue) {
				this.progress = 1 - this.userValue / cutoffValue;	// need to start slowing down
			} else {
				this.progress = 0;	// end of the line if we at CUTOFF_VALUE or higher
			}
		}
		// console.log(`%c UsageMeterComponent => subjectName[${this.subjectName}] goalValue[${this.goalValue}] userValue[${this.userValue}] hasGoalData[${this.hasGoalData}] progress[${this.progress}] hasMetGoal[${this.hasMetGoal}] cutoffValue[${cutoffValue}]`, 'background:cyan;color:black;');
	}



	// The animation function for counting up to userValue
	animateCountUp = (el: { innerHTML: string }) => {
		let frame = 0;
		const countTo = parseInt( this.userValue.toString(), 10 ) * (1 / this.animationDelay);
		// Start the animation
		const counter = setInterval( () => {
			frame++;
			const progress = Math.max(this.easeOutQuad( frame / this.totalFrames ) - (1 - this.animationDelay), 0);
			// Use the progress value to calculate the current count
			const currentCount = Math.round( countTo * progress );

			// If the current count has changed, update the element
			if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
				el.innerHTML = currentCount.toString();
			}

			if ( frame === this.totalFrames ) {
				clearInterval( counter );
			}
		}, this.frameDuration );
	};

	// Run the animation on tooltip
	runAnimations() {
		if(this.userValue < this.goalValue){
			this.animationDuration = 2000;
			this.animationDelay = 0.5;
		} 
		else if(this.userValue == this.goalValue){
			this.animationDuration = 2000;
			this.animationDelay = 1;
		} 
		else{
			this.animationDuration = 5000;
			this.animationDelay = 1;
		}
		this.totalFrames = Math.round( this.animationDuration / this.frameDuration );
		const countupEls = document.querySelectorAll( '.tooltip' );
		countupEls.forEach( this.animateCountUp );
		this.startAnimation = true;
	}
}

export enum System {
	es_unknown = 0,
	es_winNT351,
	es_win95,
	es_winNT40,
	es_win98,
	es_win98se, // dummy, not used.
	es_win2000,
	es_winXP,
	es_winME,
	es_macOS8,
	es_macOS9,
	es_macOSX_0,
	es_macOSX_1,
	es_macOSX_2,
	es_macOSX_3,
	es_macOSX_4,
	es_macOSX_5,
	es_win2003,
	es_winVista,
	es_macOSX_6,
	es_win7,
	es_win2008,
	es_win2008R2,
	es_macOSX_7,
	es_macOSX_8,
	es_ios_5,
	es_ios_6,
	es_macOSX_9,
	es_win8,
	es_win2012,
	es_ios_7,
	es_ios_8,
	es_ios_9,
	es_win10,
	es_macOSX_10,
	es_macOSX_11,
	es_macOSX_12,
	es_macOSX_13,
	es_unity_5,
	es_ios_10,
	es_ios_11,
	es_ios_12,
	es_ios_13,
	es_macOSX_14,
	es_macOSX_15,
	es_ios_14,
	es_wasm,
	es_macOS_11_0, //big sur
	es_macOS_11_1,
	es_macOS_11_2,
	es_macOS_11_3,
	es_macOS_11_4,
	es_macOS_11_5,
	//... leave a gap here
	es_wasmNewest = 0xF9,
	es_unityNewest = 0xFA,
	es_androidNewest = 0xFB,
	es_chromeNewest = 0xFC,
	es_iosNewest = 0xFD,
	es_macNewest = 0xFE,
	es_winNewest = 0xFF
}
import { Domain } from "../types"
const domains: Domain[] = [
    { id: "4083b800-7086-4be1-9eb1-d59b92de472c", name: "Istation Development", value: "istation" },
    { id: "e292ac96-cd6a-4d5b-a62a-408d6b13dae6", name: "Istation Admin", value: "admin.istation" },
    { id: "139bdc8c-53ed-489c-9746-7207f6c7e8bb", name: "Istation Admin Demo", value: "admin.istation.demo" },
    { id: "43b8d7dd-4f17-4344-b46f-924761f5e4d3", name: "Team Hydra Test Domain", value: "hydra.istation.domain" },
    { id: "f1b63818-9031-4618-859c-de655aae240f", name: "Istation.com", value: "istation.com" },
    { id: "5ec9e92d-8895-40d1-9455-91c843a07c9d", name: "Istation Development", value: "istation" },
    { id: "1d99633e-7d2a-4abf-ae42-afb3815fe72b", name: "Istation Development", value: "istation" },
    { id: "d478fe1e-ada9-4075-870c-06284f295929", name: "Texas Education Agency", value: "admin.tea.tx"},
    { id: "8581f25c-8dd3-413a-8bc3-c695948d94f5", name: "Texas Success Home Use", value: "success.home.tx"},
    { id: "38a8d30d-6ce2-4adb-af98-aa616b8fb193", name: "Little Elm Independent School District", value: "admin.littleelm.tx"},
    { id: "ac27b486-cb57-48ab-beb2-937d319ad2dd", name: "Brent Elementary School", value: "brent.littleelm.tx"},
    { id: "20a1c3a4-61d7-4592-807a-d043781e255f", name: "Cesar Chavez Elementary School", value: "chavez.littleelm.tx"},
    { id: "5d84a2e6-6356-42c4-9345-666c8d4e8b60", name: "Hackberry Elementary School", value: "hackberry.littleelm.tx"},
    { id: "3ec95227-24b5-4a2f-88e7-745f9d0efe06", name: "Lakeside Elementary School", value: "lakeside.littleelm.tx"},
    { id: "cfb87ae8-bd5f-431e-8e25-43ea5fa11599", name: "Lakeview Elementary School", value: "lakeview.littleelm.tx"},
    { id: "ca171796-5a5e-4b21-80f5-42684739b6a9", name: "Oak Point Elementary School", value: "point.littleelm.tx"},
    { id: "f61ab661-fc11-4e40-8ee9-cb82408eee3d", name: "St. Elmo Elementary School", value: "stelmo.littleelm.tx"},
    { id: "a53ba05d-df9f-4a42-8111-066cf85939f5", name: "Herschel Zellars LRNG &amp; LDRSP", value: "zellars.littleelm.tx"},
  ];
export default domains;
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'swe-lessons-banner',
  template: `
<header class="lessons-banner"><h1>{{ title }}</h1></header>
<header class="lessons-banner-sticky"><h1>{{ title }}</h1></header>
`,
  styleUrls: ['./lessons-banner.component.scss']
})
export class LessonsBannerComponent {
  @Input() title = 'Lessons';
}

export enum ProductValue {
    "ISIPAdvancedReading" = "ISIP_ADVANCED_READING",
    "ISIPAllMath" = "ISIP_ALL_MATH",
    "ISIPEarlyReading" = "ISIP_EARLY_READING",
    "ISIPLectura" = "ISIP_LECTURA",
    "ISIPLecturaAvanzada" = "ISIP_LECTURA_AVANZADA",
    "ISIPLecturaTemprana" = "ISIP_LECTURA_TEMPRANA",
    "ISIPMath" = "ISIP_MATH",
    "ISIPReading" = "ISIP_READING",
    "IstationLectura" = "ISTATION_LECTURA",
    "IstationMath" = "ISTATION_MATH",
    "IstationReading" = "ISTATION_READING",
    "Lectura" = "LECTURA",
    "Math" = "MATH",
    "Reading" = "READING",
    "Undefined" = "UNDEFINED",
}
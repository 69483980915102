import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'fullname',
})
export class FullNamePipe implements PipeTransform {
  transform(value: string | null, args?:string): string {
    if (value) {
      const [firstName, lastName] = value.split(' ');
      switch (args || null) {
        case 'first':
          return firstName;
        case 'last':
          // if we don't have a last name, we'll just return the first name
          return lastName || firstName;
        case 'firstl':
          return `${firstName} ${lastName ? lastName.substring(0,1).concat('.'):''}`;
        case 'flast':
          return `${firstName.substring(0,1)}. ${lastName || ''}`;
      }
      return value;
    }
    return '';
  }
}
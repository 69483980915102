import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { LottiePlayerDirective } from './lottie-player.directive';
import { LottieLoaderService } from './lottie-loader.service';

@NgModule({
  declarations: [LottiePlayerDirective],
  exports: [LottiePlayerDirective],
})
export class LottiePlayerModule {

  static forRoot(): ModuleWithProviders<LottiePlayerModule> {
    return {
      ngModule: LottiePlayerModule,
      providers: [LottieLoaderService],
    };
  }
}

import { Inject, Injectable } from '@angular/core';
import { KnowledgeBaseModules, KnowledgeBaseValue } from '../types';
import { BehaviorSubject } from 'rxjs';
import { DataSourceType } from '../enums/dataSource';
import { KbFacadeType } from '../classes/data-source-facade';
import { getKbFacadeFn, setKbFacadeFn } from '../classes/data-source-facade.fns'
import { DATA_SOURCE } from '../classes/knowledge-base/it-data-source';


/*
const getKbFacade = (method?: 'api' | 'runtime' | 'emscripten') => {
    method = 'runtime';
    const injectionPoint: ProviderToken<KnowledgeBaseWASM | KnowledgeBaseAPI> = method === 'runtime' 
        ? KnowledgeBaseWASM 
            : method === 'emscripten' 
                ? KnowledgeBaseAPI 
                    : KnowledgeBaseAPI
    const facade = inject(injectionPoint);
    return facade;
}
*/

@Injectable()
export class KnowledgeBaseService {

    private _getKbFacadeFn = getKbFacadeFn();
    private _setKbFacadeFn = setKbFacadeFn();

    private _kb: KbFacadeType = this._getKbFacadeFn();

    constructor(@Inject(DATA_SOURCE) dataSource: BehaviorSubject<DataSourceType>){

        dataSource.subscribe((ds) => { this._kb = this._setKbFacadeFn(ds); });
    }

    async getAll(): Promise<KnowledgeBaseModules | null | undefined> {
        return await this._kb.getAll();
    }

    async get(section: string, key: string): Promise<KnowledgeBaseValue> {
        return await this._kb.get(section, key)
    }
    set(section: string, key: string, value: KnowledgeBaseValue): void {
        this._kb.set(section, key, value);
    }

    set userID(value: string | number | undefined) {
        this._kb.user_id= value;
    }

    get userID(): string | number | undefined{
        return this._kb.user_id;
    }

    async setAndSync(section: string, key: string, value: KnowledgeBaseValue): Promise<void> {
        await this._kb.setAndSync(section,key, value);
    }
    
    async sync(): Promise<void> {
        await this._kb.sync();
    }
}
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'swe-progress',
  template: `
<div 
  class="progress"
  [class.progress-complete]="progress===100"
  [style.--progress-width]="progress + '%'"
></div>`,
  styles: [`
.progress {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--lessons-progress-bg);
}
.progress::after {
  content: '';
  width: var(--progress-width, 0);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1rem;
  background: var(--lessons-progress-meter);
  transition: width 500ms ease;
}

.progress-complete::after {
  background: var(--lessons-progress-meter-complete);
}
`]
})
export class ProgressComponent {
  @Input() progress = 0;
}

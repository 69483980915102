import { WasmService } from './../services/wasm.service';
import { Injectable } from '@angular/core';
import { MessageCodes } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class WasmNavigationGuard  {
  constructor(private wasmService: WasmService){}
  canDeactivate(){
    let canDeactivate = true;

    // If in a runtime activity, then prevent navigation away,
    // pause the runtime, and show a popup message
    if (this.wasmService.hasUnsavedData) {
      canDeactivate = false;

      this.wasmService.pauseRuntimeRef(true);

      this.wasmService.message.showMessage(MessageCodes.ACTIVITY_BROWSER_NAV);
    }

    return canDeactivate;
  }
}

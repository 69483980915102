import { Component, Input, inject } from '@angular/core';
import { ConfigurationService } from '../../../../services';
import { SeriesCard } from './../../types/series-card';
import { SeriesCardLinkDirective } from '../../directives/series-card-link.directive';

@Component({
  selector: 'swe-series-card',
  templateUrl: './series-card.component.html',
  styleUrls: ['./series-card.component.scss'],
  hostDirectives: [
    {
      directive: SeriesCardLinkDirective,
      inputs: ['seriesId', 'episode', 'assignmentId'],
    },
  ],
})
export class SeriesCardComponent {
  private readonly fallbackImage = `/helix/UX_Images/Header_Images/Header_Default_Series_01.png`;

  @Input() series!: SeriesCard;

  private configService: ConfigurationService = inject(ConfigurationService);

  get isMulti(): boolean {
    return !!this.series?.episodes?.length && this.series?.episodes?.length > 1;
  }

  get hasAssignment(): boolean {
    return this.series.episodes.some((e) => Object.hasOwn(e, 'assignmentId'));
  }

  get thumbnail(): string {
    return `${this.configService.config.assetsURL}${this.series.image}`;
  }

  get fallbackThumbnail(): string {
    return `${this.configService.config.assetsURL}${this.fallbackImage}`;
  }
}

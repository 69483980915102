import { GradeBands, Themes } from "../enums";

export const THEMES: Array<{
    name: string,
    short_name: string,
    isActive: boolean,
    isDefault: boolean,
    availableGradeBands: Array<number>
}> = [
    {
        name: 'Base',
        short_name: Themes.BASE,
        isActive: true,
        isDefault: true,
        availableGradeBands: [
            GradeBands.NONE,
        ],
    },
    {
        name: 'Wavy',
        short_name: Themes.WAVY,
        isActive: true,
        isDefault: true,
        availableGradeBands: [
            GradeBands.BAND_1
        ],
    },
    {
        name: 'SpaceCat',
        short_name: Themes.SPACECAT,
        isActive: true,
        isDefault: true,
        availableGradeBands: [
            GradeBands.BAND_2
        ],
    },
    {
        name: 'Blocky',
        short_name: Themes.BLOCKY,
        isActive: false,
        isDefault: false,
        availableGradeBands: [
            GradeBands.BAND_1
        ],
    },
    {
        name: 'Gamerz',
        short_name: Themes.GAMERZ,
        isActive: true,
        isDefault: true,
        availableGradeBands: [
            GradeBands.BAND_3
        ],
    },
    {
        name: 'SynthWave',
        short_name: Themes.SYNTHWAVE,
        isActive: true,
        isDefault: true,
        availableGradeBands: [
            GradeBands.BAND_4
        ],
    }    
];
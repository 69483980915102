import { FeatureFlagsEnum } from './../enums/feature-flags';
import { FeatureFlagService } from './../services/feature-flag.service';
import { UrlService } from './../services/url.service';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService, WasmService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ActivityGuard  {
  
  constructor(
    private wasmService: WasmService,
    private userService: UserService,
    private urlService: UrlService,
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //are we logged in and a path is in the queue? (Normal path to activities)
    const isNormalLaunch = (this.wasmService.loggedIn.value && (this.userService.needsIntro || this.wasmService.activityReturnPath));

    //are we trying to launch something from url params and have the debug tools feature flag enabled? 
    const isDebugLaunch = (this.urlService.hasJumpPathsSaved() && this.featureFlagService.isFlagEnabled(FeatureFlagsEnum.DEBUG_TOOLS));
    
    //if we've got a normal, or debug launch then activate, else redirect home. 
    return (isNormalLaunch || isDebugLaunch) ? true : this.router.createUrlTree(['/home']);
  }
}

import { Component, Input } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: '[avatar-path-marker]',
	template: `
		<div class="avatar-marker-wrapper" [ngClass]="{'animBobbing': useBobbingAnim }">
			<swe-avatar 
				class="avatar-marker" 
				[avatar]="avatar"
				[flair]="'circle'"
				[bkgdColor]="'rgba(255, 255, 255, 0.75)'">
			</swe-avatar>
		</div>
		`,
	styles: [`
		.avatar-marker-wrapper {
			position: absolute;
			display: flex;
			justify-content: center;
			user-select: none;

			&:not(.animBobbing) {
				transform: translate(-50%, -160px);
			}
			&.animBobbing {
				animation: avatarAnimBob 3s ease-out infinite;
			}

			// clip-path needs to be on an element separate from one with the filter property
			&::before {
				content: "";
				position: absolute;
				height: 130px;
				width: 90px;
				background: var(--avatar-marker-color, cyan);
				clip-path: path("M 89.88 44.94 A 7.49 7.49 90 0 0 0 44.94 Q 0 69.2825 22.47 84.2625 L 44.94 123.585 L 67.41 84.2625 Q 89.88 69.2825 89.88 44.94 Z");
			}

			@keyframes avatarAnimBob {
				0% {
					transform: translate(-50%, -160px);
				}
				50% {
					transform: translate(-50%, -150px);
				}
				100% {
					transform: translate(-50%, -160px);
				}
			}
		}

		.avatar-marker {
			margin-top: 2px;
			height: 86px;
			width: 86px;
			border-radius: 50px;
			border: 7px solid rgba(0, 0, 0, 0.15);
			z-index: 1;	// in order to get the border to show
		}
	`],
})
export class AvatarPathMarkerComponent {
	@Input() avatar = -1;
	@Input() useBobbingAnim = false;
}

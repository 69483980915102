import { 
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { APISourceLocations } from '../types';

@Component({
  selector: 'swe-source-switch',
  template: `
<div class="custom-control custom-switch toggle-source">
  <input 
    type="checkbox" 
    name="toggle-source-switch"
    id="toggle-source-switch"
    class="custom-control-input switch"
    [checked]="this.source === 'api'"
    (change)="this.handleSourceChange()"
    />
  <label for="toggle-source-switch" class="custom-control-label">{{ this.source === "api" ? "Cloud" : "Legacy" }}</label>
</div>`,
})
export class SourceSwitchComponent  {
  @Input() source: APISourceLocations = "runtime";
  @Output() sourceChange = new EventEmitter<APISourceLocations>();

  constructor() { }

  handleSourceChange(): void {
    this.source = this.source === "api" ? "runtime" : "api";
    this.sourceChange.emit(this.source);
  }
}

<div class="left">
    <menu class="avatar-selection" *ngIf="this.avatarSet.avatars; else noAvatars">
        <li *ngFor="let item of this.avatarSet.avatars" class="avatar-selection-form-group">
            <input
                type="radio"
                name="avatar-selection"
                [id]="'avatar' + item.id"
                [value]="item.id"
                [formControl]="avatarForm"
                [checked]="item.id === this.avatar"
                [attr.aria-label]="'avatar ' + item.id" />
            <label [for]="'avatar' + item.id">{{ item.name }}</label>
            <swe-avatar class ="avatar" [avatar]="item.id"></swe-avatar>
        </li>
    </menu>
    <ng-template #noAvatars>
        <p>No available avatars</p>
    </ng-template>
</div>
<div class="right">
    <div class="avatar-display">
        <swe-avatar class="current-avatar" [avatar]="avatar"></swe-avatar>
    </div>
    <footer>
        <button
            id="avatar-change-save"
            type="button"
            tabindex="2"
            class="btn btn-system-primary"
            sweAudioClick
            [audioType]="clickType"
            [descriptor]="clickSave"
            (click)="save()"
            aria-label="Save avatar">
            Save
        </button>
    </footer>
</div>

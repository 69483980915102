import {
  Component,
} from '@angular/core';
import { DebugView } from './enums/debug-view';
import { ensureElementVisible } from '@swe/utils';

export const debugMenuTableOfContents = [
  {
    name: 'General',
    view: DebugView.DEFAULT,
    content: [
      {
        name: 'Edit INI',
        id: 'debug-edit-ini'
      },
      {
        name: 'Download Log File',
        id: 'debug-log-file'
      },
      {
        name: 'View error code message',
        id: 'debug-view-error-code'
      },
      {
        name: 'Debug Launch',
        id: 'debug-debug-launch'
      },
      {
        name: 'Set Effective Date',
        id: 'debug-set-date'
      },
      {
        name: 'Usage',
        id: 'debug-usage'
      },
      {
        name: 'Data Source',
        id: 'debug-data-source'
      },
      {
        name: 'User Data',
        id: 'debug-user-data'
      },
      {
        name: 'Assignments API',
        id: 'debug-assignments'
      },
      {
        name: 'Legacy Assignments',
        id: 'debug-legacy-assignments'
      },
      {
        name: 'Assessment API',
        id: 'debug-assessment-api'
      },
    ]
  },
  {
    name: 'ISIP Data',
    view: DebugView.ISIP_DATA,
    content: [
      {
        name: 'Skip Flag',
        id: 'isip-data-skip-flag'
      },
      {
        name: 'Data Override',
        id: 'isip-data-override'
      },
    ]
  },
  {
    name: 'Feature Flags',
    view: DebugView.FLAG_OVERRIDE,
    content: [
      {
        name: 'Flags in configuration',
        id: 'flag-override-in-config'
      },
      {
        name: 'Custom flags',
        id: 'flag-override-custom'
      },
    ]
  },
]


@Component({
  selector: 'swe-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent {
  readonly tableOfContents = debugMenuTableOfContents;
  readonly debugView = DebugView;  // to be able to use it in the html file

  currentView = DebugView.DEFAULT;

  setView(value: number): void {
    this.currentView =
      Object.values(DebugView).includes(value)
      ? value
      : DebugView.DEFAULT;
  }

  scrollTo(view: number, id: string): void {
    this.setView(view);
    const list = document.getElementsByClassName('draw-attention');
    while (list.length) {
      list[0].classList.remove('draw-attention');
    }
    setTimeout(() => {
      const el = document.getElementById(id);
      if (!el) {
        return;
      }
      ensureElementVisible(el, document.getElementById("debug-content"))
      // force reflow so we can re-animate if necessary
      el.offsetHeight;
      el.classList.add('draw-attention');
    });
  }
}

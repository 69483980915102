import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeToken } from '../../types';
import { inject } from '@angular/core';
import { ConfigurationService } from '../../services';

export const getRuntimeToken = async (
  userOid: number
): Promise<Observable<RuntimeToken>> => {
  const configService = inject(ConfigurationService);
  const params = new HttpParams().set('userId', userOid);
  await configService.load();
  const { idServerURL } = configService.config;
  return inject(HttpClient).get<RuntimeToken>(`${idServerURL}api/runtimetoken`, {
    params,
  });
};

export const buildRuntimeSSOAuthUri = (
    tokenPacket: RuntimeToken
) => `istation://doSSO?iuserOid=${tokenPacket.userOid}&token=${tokenPacket.runtimeToken}`;

/**
 * Returns the number of stars earned with the given [ISIP] score/goal.
 * @param scoreValue score value for the subject
 * @param goalValue goal value for the subject
 * @param isFirst whether or not the data in question is from the first testing period
 * @param maxStars maximum number of earnable stars
 * @param threshold how much you're allowed to be off from the goal before getting pentalized [more]
 * @returns
 */
export const getNumStars = (
  scoreValue: number,
  goalValue: number,
  isFirst: boolean,
  maxStars = 3,
  threshold = 10
): number => {
	let numStars = 1; // minimum stars earned - fallthrough
	if (isFirst) {
      // first testing period gets the maximum earnable stars
	  numStars = maxStars;
	} else if (scoreValue <= 0 || goalValue <= 0) {
      // invalid score/goal value(s)
	  numStars = 0;
	} else {
	  const diff = goalValue - scoreValue;
	  if (diff <= 0) {
	    numStars = maxStars;
	  } else if (diff < threshold) {
		numStars = Math.round(maxStars / 2);
      }
	}
	return numStars;
}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'swe-sprite',
  templateUrl: './sprite.component.html',
  styleUrls: ['./sprite.component.scss']
})
export class SpriteComponent {
  @Input() width!: number;
  @Input() height!: number;
  @Input() image!: string;
  @Input() frameWidth!: number;
  @Input() frameHeight!: number;
}

export enum RuntimeStatus {
  // script is not injected into the SPA page
  NOT_READY = 0,

  // _isapp_module is available in the global scope
  LOADED,

  // Supervisor is ready for login
  SUPERVISOR_READY,

  // Supervisor is logged in and has a instance
  SUPERVISOR_ACTIVE,

  // WASMStartup is the current active scene
  JOE_READY,
}
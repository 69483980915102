/**
 * Activity names for navigating through wasm main menu
 * @enum {string}
 */
export enum WasmMenuActivities {
  ProductSelect = "Subject", 
  ISIP = "ISIP", 
  Modeling = "Modeling", 
  LinearPath = "LinearPath",
  IntroToComputers = "IntroToComputers",
}

import { Injectable } from '@angular/core';
import {Apollo, ApolloBase } from 'apollo-angular';
import {
  AssessmentResultsResponse,
  LegacyAssessmentResult,
  OverallAssessmentResult,
  PeriodScore,
  SchoolYearResponse
} from '../types';
import { ConfigDataService } from './config-data.service';
import { ASSESSMENT_RESULTS_QUERY, SCHOOL_YEAR_QUERY } from '../data/queries';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AssessmentApiService {    
    public apollo: ApolloBase;

    // debug flags/variables
    public canSkipAssessment = false;
    public useDataOverride = false;
    public manualData!: LegacyAssessmentResult;
    public manualGoals!: PeriodScore[];

    private cachedYears: number[] = [];

    constructor(
      private apolloProvider: Apollo,
      private configDataService: ConfigDataService
    ) {
        this.apollo = this.apolloProvider.use('assessmentResults');
    }

    fetchDataRaw(schoolYear: number | undefined = undefined): Observable<{[key: string]: OverallAssessmentResult[]}> {
      // This will also catch a school year of 0, but we wouldn't have that, anyway
      if (!schoolYear) {
        return this.fetchSchoolYear().pipe(
          switchMap(year => {
            return this.fetchDataRaw(year);
          })
        )
      }

      const v = {
        input: {
          userOid: this.configDataService.userOid + '',
          schoolYear: schoolYear
        }
      }

      // years are only added to cachedYears if a previous query for that year was successful. 
      // A previous UNsuccessful query could potentially cache bad data, so we want to force it
      // to make the new query to the remote API
      const fetchMethod = this.cachedYears.includes(schoolYear) ? 'cache-first' : 'network-only';

      return this.apollo.query<AssessmentResultsResponse>({
        query: ASSESSMENT_RESULTS_QUERY,
        variables: v,
        errorPolicy: 'all',
        fetchPolicy: fetchMethod,

      }).pipe(
        map(({data, errors}) => {
          if (errors) {
            console.error('Error getting assessment data:', errors.map(a => a.message));
            // remove the year from cachedYears to force the network request on the next attempt
            this.cachedYears = this.cachedYears.filter(a => a != schoolYear);
            throw new Error('Assessment data query failed');
          } else {
            // adds the year to cached years to enable use of the cache for subsequent queries
            this.cachedYears.push(schoolYear);
          }
          return this.splitAssessments(data.assessmentResults.assessmentData.assessments);
        }),
        catchError((error) => {
          return throwError('Failed assessment query > ' + error);
        }),
      );
    }

    fetchDataTransform(schoolYear: number | undefined = undefined): Observable<{[key: string]: LegacyAssessmentResult}> {
        return this
          .fetchDataRaw(schoolYear)
          .pipe(
            map((value) => this.mapLegacyAssessmentData(value))
          );
    }

    mapLegacyAssessmentData = (value: {[key: string]: OverallAssessmentResult[]}) => {
      const obj: {[key: string]: LegacyAssessmentResult} = {};
      for (const key in value) {
        const subtest = this.normalizeIsipData(value[key]);
        subtest && (obj[key] = subtest);
      }
      return (obj);
    };

    fetchSchoolYear(): Observable<number> {
      const v = {
        input: {
          userAccountId: this.configDataService.userOid + '',
        }
      }
      // this appears to fail completely or work completely
      return this.apollo.query<SchoolYearResponse>({
        query: SCHOOL_YEAR_QUERY,
        variables: v,
      }).pipe(
        map(({errors, data}) => {
          if (errors) {
            throw new Error('School Year query failed');
          }
          return data.userData.userData.currentSchoolYear
        }),
        catchError((error) => {
          return throwError('Failed school year query > ' + error);
        })
      );
    }

    splitAssessments(obj: OverallAssessmentResult[]) {
      return Object.values(obj).reduce((result: any, item: OverallAssessmentResult) => {
        const product = item['productValue'];
        if (!result[product]) {
          result[product] = [];
        }
        result[product].push(item);
        return result;
      }, {});
    }

    normalizeIsipData(data: OverallAssessmentResult[]) : LegacyAssessmentResult | undefined {
      if (!data || !data[0]) return undefined;
      
      const totalScores = [];

      for (const d of data) {
        const s = this.getOverallScore(d);
        s && totalScores.unshift(s);
      }
      
      const subtests = this.getSubtestScores(data[0]);

      return {
        lastCompleted: this.getAssessmentDate(data[0]),
        totalScores: totalScores,
        subtests: Object.keys(subtests).sort((a, b) => subtests[a] - subtests[b])
      }
    }

    getSubtestScores(data: OverallAssessmentResult): {[key: string]: number} {
      const subtests: {[key: string]: number} = {};
      for (const obj of data.subtests) {
        if (obj.isOverall === 'Y' || obj.value.includes('Overall')) continue;
        subtests[obj.value] = obj.percentile;
      }
      return subtests;
    }

    getAssessmentDate(data: OverallAssessmentResult): Date | undefined {
      for (const obj of data.subtests) {
        if (obj.isOverall === 'Y') {
          return new Date(Date.parse(obj.assessDate));
        }
      }
      return undefined;
    }

    getOverallScore(data: OverallAssessmentResult): PeriodScore | undefined {
      for (const obj of data.subtests) {
        if (obj.isOverall === 'Y') {
          return {period: obj.period, value: obj.score}
        }
      }
      return undefined
    }

    clearAssessmentCache(): void {
      this.cachedYears = [];
      this.apollo.client.cache.evict({id: 'ROOT_QUERY', fieldName: 'assessmentResults'});
    }

    clearSchoolYearCache(): void {
      this.apollo.client.cache.evict({id: 'ROOT_QUERY', fieldName: 'userData'});
    }

    isDataCachedForYear(year: number): boolean {
      return this.cachedYears.includes(year);
    }

    canOverrideScores(): boolean {
      return this.useDataOverride
              && this.manualData
              && this.manualData.totalScores.length > 0;
    }

    canOverrideGoals(): boolean {
      return this.useDataOverride
              && this.manualGoals
              && this.manualGoals.length > 0;
    }
}
import { AvatarSet } from "../types/avatar-set";

const allAvatars: AvatarSet = {
    avatars: [
        {
            id: 0,
            name: 'invalid'
        },
        {
            id: 1,
            name: 'PH Cat'
        },
        {
            id: 2,
            name: 'PH Dog'
        },
        {
            id: 3,
            name: 'PH Goat'
        },    
        {
            id: 4,
            name: 'PH Bunny'
        },
        {
            id: 5,
            name: 'PH Hippo'
        },
        {
            id: 6,
            name: 'PH Truck'
        },
        {
            id: 7,
            name: 'PH Dinosaur Head'
        },
        {
            id: 8,
            name: 'PH Butterfly'
        },
        {
            id: 9,
            name: 'PH Popsicle'
        },
        {
            id: 10,
            name: 'PH Sports'
        },
        {
            id: 11,
            name: 'Unused'
        },
        {
            id: 12,
            name: 'PH Star Badge'
        },
        {
            id: 13,
            name: 'PH Guitar Badge'
        },
        {
            id: 14,
            name: 'PH Flower Badge'
        },
        {
            id: 15,
            name: 'PH Fighter Badge'
        },
        {
            id: 16,
            name: 'PH Heart Eyes Face'
        },
        {
            id: 17,
            name: 'PH ROFL Face'
        },
        {
            id: 18,
            name: 'PH Side Eye Face'
        },
        {
            id: 19,
            name: 'PH Ack Face'
        },
        {
            id: 20,
            name: 'PH Crying Face'
        },
        {
            id: 21,
            name: 'PH Eye Roll Face'
        },
        {
            id: 22,
            name: 'PH AnimExpBlobs'
        },
        {
            id: 23,
            name: 'PH Mexican Textile 1'
        },
        {
            id: 24,
            name: 'PH Mexican Textile 2'
        },
        {
            id: 25,
            name: 'PH African Textile 1'
        },
        {
            id: 26,
            name: 'PH Paisley Pattern'
        },
        {
            id: 27,
            name: 'PH Boba Tea'
        },
        {
            id: 28,
            name: 'PH Gaming'
        },
        {
            id: 29,
            name: 'PH Churros'
        },
        {
            id: 30,
            name: 'PH Block Head Smug'
        },
        {
            id: 31,
            name: 'PH Block Head Surprised'
        },
        {
            id: 32,
            name: 'PH Block Head Coy'
        },
        {
            id: 33,
            name: 'PH Block Head Disgusted'
        },
        {
            id: 34,
            name: 'PH Block Head Relexed'
        },
        {
            id: 35,
            name: 'PH Block Head Scared'
        },
        {
            id: 36,
            name: 'PH Block Head Angry'
        },
    ]
};


const preKAvatars: AvatarSet = {
    avatars: [
        allAvatars.avatars[2],
        allAvatars.avatars[3],
        allAvatars.avatars[1],
        allAvatars.avatars[4],
        allAvatars.avatars[5],
        allAvatars.avatars[7],
        allAvatars.avatars[8],
    ]
};

const grade1Avatars: AvatarSet = {
    avatars: [
        allAvatars.avatars[6],
        allAvatars.avatars[9],
        allAvatars.avatars[10],
        allAvatars.avatars[12],
        allAvatars.avatars[13],
        allAvatars.avatars[14],
        allAvatars.avatars[15],
        allAvatars.avatars[2],
        allAvatars.avatars[3],
        allAvatars.avatars[1],
        allAvatars.avatars[4],
        allAvatars.avatars[5],
        allAvatars.avatars[7],
        allAvatars.avatars[8],
    ]
};

const grade4Avatars: AvatarSet = {
    avatars: [
        allAvatars.avatars[16],
        allAvatars.avatars[17],
        allAvatars.avatars[18],
        allAvatars.avatars[19],
        allAvatars.avatars[20],
        allAvatars.avatars[21],
        allAvatars.avatars[22],
        allAvatars.avatars[23],
        allAvatars.avatars[24],
        allAvatars.avatars[25],
        allAvatars.avatars[26],
        allAvatars.avatars[27],
        allAvatars.avatars[28],
        allAvatars.avatars[29],
        allAvatars.avatars[30],
        allAvatars.avatars[31],
        allAvatars.avatars[32],
        allAvatars.avatars[33],
        allAvatars.avatars[34],
        allAvatars.avatars[35],
        allAvatars.avatars[36],
    ]
}

const avatarSets : AvatarSet[] = [
    preKAvatars, grade1Avatars, grade4Avatars
]

export {
    avatarSets as default,
    avatarSets,
    allAvatars,
    preKAvatars,
    grade1Avatars,
    grade4Avatars
}
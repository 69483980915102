<select 
  class="form-select" 
  [attr.aria-label]="label"
  [ngModel]="selectedValue"
  (ngModelChange)="changedValue($event)"
>
  <ng-content select="[defaultOption]"></ng-content>
  <option 
    *ngFor="let option of options | keyvalue"
    [value]="option.key"
    [selected]="option.key === selectedValue"
  >{{ option.value }}</option>
</select>

import { inject, Injectable } from '@angular/core';
import { FeatureFlagService } from '@swe/services';

@Injectable({
  providedIn: 'root'
})
export class DebugGuard  {
  private flagService = inject(FeatureFlagService);

  canActivate(): boolean {
    const roles = JSON.parse(sessionStorage.getItem('idsrv-dev') || '{}').userData?.role || [];
    return this.flagService.isFlagEnabled('debugTools') || roles.includes('jumpScene');
  }

}

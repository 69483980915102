import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UserService } from '../../../services/user.service';
import {
  preKAvatars,
  grade1Avatars,
  grade4Avatars
} from '../../../data/avatar-sets'
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AudioType, AudioDescriptor } from '../../../enums'
import { User, AvatarSet } from '../../../types';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'swe-avatar-chooser',
  templateUrl: './avatar-chooser.component.html',
  styleUrls: ['./avatar-chooser.component.scss']
})
export class AvatarChooserComponent implements OnInit, OnDestroy {

  @Input() dialog = true;
  @Output() dialogChange = new EventEmitter<boolean>();

  private destroy$ = new Subject();

  avatar = -1;
  gradeBand = -1;
  grade = -10;

  avatarSet: AvatarSet = {avatars: []};
  avatarForm = new UntypedFormControl(-1);
  changeState = new ReplaySubject<SimpleChanges>(1);

  clickType = AudioType.audio_SfxClick;
  clickSave = AudioDescriptor.CLICK_FINISH_SAVE;
  clickClose = AudioDescriptor.CLICK_BACK;

  constructor(
    public userService: UserService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.setupAvatarPopup();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  setupAvatarPopup(): void {
    // get current avatar from UserService
    this.userService.avatar
      .pipe(takeUntil(this.destroy$))
      .subscribe((serviceAvatar) => {
        this.avatar = serviceAvatar;
        this.avatarForm.setValue(serviceAvatar);
      });

    this.userService.user
      .pipe(takeUntil(this.destroy$))
      .subscribe((u) => {
        if (u?.grade || u?.grade === 0){
          this.grade = u.grade;
          this.avatarSet = this.getAvatarSetsByGrade(this.grade);
          const [firstAvatar] = this.avatarSet.avatars;
          if (this.avatar === -1 && firstAvatar) {
            this.avatar = firstAvatar.id;
            this.avatarForm.setValue(firstAvatar.id);
          }
        }
      });

    this.avatarForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=> {
        this.avatar = this.avatarForm.value;
        this.scrollToOption(this.avatar);
      });
  }

  getAvatarSetsByGrade(grade: number): AvatarSet {
    return (grade >= -1 && grade < 1)
      ? preKAvatars
        : (grade >= 1 && grade < 4)
          ? grade1Avatars
            : grade4Avatars;
  }

  scrollToOption(id: number): void {
    const item = document.getElementById('avatar' + id )?.parentElement;
    const view = item?.parentElement;
    if (item && view) {
      view.scrollTop = item.offsetTop;
    }
  }

  save(): void {
    const user = this.userService.user.getValue() as User;
    const { avatar: currentAvatar } = user || {};
    const newAvatar = this.avatarForm.value;
    if (newAvatar && currentAvatar !== undefined && currentAvatar !== newAvatar) {
      this.trackingService.push({
        event: 'avatar_change',
        avatar_id: `avatar-${newAvatar}`,
        avatar_name: document.querySelector<HTMLLabelElement>(`[for="avatar${newAvatar}"]`)?.innerText
      });
      this.userService.user.next(Object.assign({}, user, {avatar: newAvatar}));
    }
    this.dialogChange.emit(false);
  }
}

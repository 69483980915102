import { Injectable } from  "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { CompatibilityService } from "../services";
import { AutoLoginPartialRoutesGuard } from "angular-auth-oidc-client";
import { Observable } from "rxjs";

@Injectable()
export class CompatibilityGuard  {

    constructor (
        private router : Router,
        private compatibility:CompatibilityService,
        private loginGuard: AutoLoginPartialRoutesGuard,
    ) { }

    /**
     * Check if the users browser is compatible before log in
     *
     * @param route 
     * @param state
     * @returns UrlTree or Observeable<boolean>
     */
    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<boolean>| UrlTree {
        if(!this.compatibility.checkBrowser()){
            return this.router.createUrlTree(['/error', 'compatibility-message']); 
        }else{
            return this.loginGuard.canActivate(route, state);
        }   
    }
}
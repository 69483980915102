import { ActivatedRoute } from '@angular/router';
import subjectsInfo from '@swe/data/subjects-info';
import { LanguageCodes } from '@swe/enums';

export function capitalizeString(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getSubjectNameFromUrl(route: ActivatedRoute): string {
  const name = route.snapshot.params['subject'];
  return capitalizeString(name || '');
}

export function getLanguageBySubjectName(name: string): string {
  if (name.toLowerCase() === 'matematicas') name = 'Matemáticas';
  const subjectInfo = Object.values(subjectsInfo).find(
    ({ name: subjectName }) => subjectName.toLowerCase() === name.toLowerCase()
  );
  return subjectInfo ? subjectInfo.language : LanguageCodes.English;
}

export function getIsipName(
  subject: string,
  grade: number
): string | undefined {
  switch (subject.toLowerCase()) {
    case 'reading':
      return grade <= 3 ? 'Screeners' : 'ISIPAR';
    case 'lectura':
      return grade <= 3 ? 'ISIPER_ES' : 'ISIP_ESPAR';
    case 'math':
      return 'ISIPMath';
    case 'matemáticas': // fall through to matematicas
    case 'matematicas':
      return 'ISIPMatematicas';
    default:
      return undefined;
  }
}

export function gradeOrdinal(
  value: number,
  language: string = LanguageCodes.English
): string {
  if (language === LanguageCodes.Spanish) {
    return value > 0 && value <= 12 // we don't have grades above 12th
      ? `${value}${
          [
            'er',
            'do',
            'er',
            'to',
            'to',
            'to',
            'mo',
            'vo',
            'no',
            'mo',
            'mo',
            'mo',
          ][value - 1]
        }`
      : value < 0
      ? 'Pre-Kinder'
      : 'Kinder';
  }
  return value > 0
    ? `${value}${
        ['st', 'nd', 'rd'][((((value + 90) % 100) - 10) % 10) - 1] || 'th'
      }`
    : value < 0
    ? 'Prekindergarten'
    : 'Kindergarten';
}

export function getGradeLabel(
  grade: number,
  language: string = LanguageCodes.English
): string {
  let gradeText = gradeOrdinal(grade, language);
  if (grade > 0) {
    gradeText += language === LanguageCodes.Spanish ? ' grado' : ' Grade';
  }
  return gradeText;
}

export enum Arch {
    ea_unknown = 0,
    ea_x86,
    ea_x86_64,
    ea_ppc,
    ea_ppc64,
    ea_arm,
    ea_pnacl,
    ea_mips,
    ea_arm64,
    ea_wasm
}
export enum RuntimeLoginStatus {
  kLoginSucceeded           = 0,  // Login is OK.
  kLoginAuthFailure         = 1,  // Username or password is incorrect.
  kLoginAlreadyLoggedIn     = 2,  // This user is already logged into the server.
  kLoginNoSchedule          = 3,  // Unable to load the subscription (curriculum) for this user. The server said they don't have a subscription.
  kLoginNoNetwork           = 4,  // Not enough locally to login this user - need the network and its not there. Couldn't get curriculum for user.
  kLoginError               = 5,  // We could not read files from disk, or other error. Try again soon.
  kLoginAborted             = 6,  // We aborted the login process before it finished.
  kLoginOutOfDate           = 7,  // We tried to run with entry points that are out of date for this user. Check network and try again.
  kLoginUnableToDownloadAct = 8,  // Could not download the all important default activity. ControlScene.
  kLoginDisabled            = 9,  // Logins are disabled at the server
  kLoginNoClass             = 10, // User not in a current class
  kLoginUnknownError        = 11, // We caught an unexpected error why trying to login.
  kLoginRestricted          = 12, // Login denied due to login restrictions (i.e. IP Address)
  kLoginInvalidToken        = 13, // invalid SSO token
  kLoginSSOConfig           = 14  // SSO is not configured correctly
}
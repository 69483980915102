/**
 * Grade Bands as defined for Phase I for theming
 * @enum {number}
 */
export enum GradeBands {
    /** No grade band set or runtime application not ready */
    NONE = 0,

    /** Pre-kindergarten or Kindergarten */
    BAND_1,	

    /** First through Second grade inclusive */
    BAND_2,

    /** Third through Fifth grade inclusive */
    BAND_3,

    /** Sixth Grade and above */
    BAND_4
}
import { FeatureFlags } from './../types/feature-flags';
import { ConfigurationService } from './';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlags: {[key: string]: boolean} = {};
  private overrideFlags: {[key: string]: boolean} = {};
  private combinedFlags: {[key: string]: boolean} = {};
  private readonly STORAGE_KEY = 'FlagOverride';
  private readonly FORBIDDEN_FLAGS = ['debugTools'];

  isFlagEnabled = this.isFlagEnabledInConfig

  private configService = inject(ConfigurationService);

  constructor() {}

  populateFlags(): void {
    this.featureFlags = {...this.configService.config?.featureFlags};
    // We want to check the config value for debugTools, otherwise you could accidentally lock
    // yourself out
    const roles = JSON.parse(sessionStorage.getItem('idsrv-dev') || '{}').userData?.role || [];
    const hasDebugRole = roles.includes('jumpScene');
    if (!(this.isFlagEnabledInConfig('debugTools') || hasDebugRole)) {
      this.isFlagEnabled = this.isFlagEnabledInConfig;
      return;
    }
    const o = localStorage.getItem(this.STORAGE_KEY);
    if (o) {
      this.overrideFlags = JSON.parse(o);
      this.combinedFlags = {...this.featureFlags, ...this.overrideFlags};
      this.isFlagEnabled = this.isFlagEnabledWithOverrides;
    } else {
      this.combinedFlags = this.featureFlags;
    }
  }

  /**
   * Check only the flags from the environment's configuration.
   * 
   * @param flags flags to check
   * @returns true if all the given flags are enabled, false otherwise
   */
  isFlagEnabledInConfig(flags: string | string[]): boolean {
    if (this.featureFlags) {
      return (Array.isArray(flags) ? flags : [flags]).every(flag => flag in this.featureFlags && this.featureFlags[flag as keyof FeatureFlags]);
    }
    return false;
  }

  /**
   * Check flags combined from the environment's configuration AND and overrides
   * 
   * @param flags flags to check
   * @returns true if all the given flags are enabled, false otherwise
   */
  isFlagEnabledWithOverrides(flags: string | string[]): boolean {
    if (this.combinedFlags) {
      return (Array.isArray(flags) ? flags : [flags]).every(flag => flag in this.combinedFlags && this.combinedFlags[flag as keyof FeatureFlags]);
    }

    return false;
  }

  /**
   * Set all the override flags. This overwrites any previous overrides
   * 
   * @param flags object containing key/value pairs of flags
   */
  setOverrides(flags: {[key: string]: boolean}): void {
    this.overrideFlags = {};
    Object.entries(flags)
      .filter(([key]) => { return !this.FORBIDDEN_FLAGS.includes(key) })
      .forEach(([key, value]) => { this.overrideFlags[key] = value })
    this.combinedFlags = {...this.featureFlags, ...this.overrideFlags};
    if (Object.keys(this.overrideFlags).length === 0) {
      this.isFlagEnabled = this.isFlagEnabledInConfig;
    } else {
      this.isFlagEnabled = this.isFlagEnabledWithOverrides
    }
  }

  /**
   * Save all overrides to local storage
   */
  storeOverrides(): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.overrideFlags))
  }

  /** 
   * Remove all overrides from local storage
   */
  clearOverrides(): void {
    this.overrideFlags = {};
    this.combinedFlags = {...this.featureFlags};
    localStorage.removeItem(this.STORAGE_KEY);
  }

  /**
   * These should be the flags and values as loaded from the environment's configuration
   * 
   * @returns Object containing feature flags and their  values
   */
  flags(): {[key: string]: boolean} {
    return {...this.featureFlags};
  }

  /**
   * These should be the flags and values as loaded from local storage (or set from a debug menu or 
   * another source)
   * 
   * @returns Object containing override flags and their values
   */
  overrides(): {[key: string]: boolean} {
    return {...this.overrideFlags};
  }
}

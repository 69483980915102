import { inject, Injectable } from "@angular/core";
import { WINDOW } from "@ng-web-apis/common";
import { CookieService, CookieOptions } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root',
})
export class RuntimeDataService {
  public clickstreamSessionID = '';

  private cookieKey = "clickstream.session";
  private cookieExpiry = 2 * 3.6e6;
  private window = inject(WINDOW);
  private cookieService = inject(CookieService);

  saveClickstreamSession(): void {
    const expires = new Date(Date.now() + this.cookieExpiry);
    const domain = !this.window.location.origin.includes('localhost') ? ".istation.com" : 'localhost';
    const cookieOptions: CookieOptions = {
      expires,
      domain,
      path: "/",
      secure: true,
      sameSite: 'Strict',
    };
    this.clickstreamSessionID = this.getClickstreamSession();
    if (this.clickstreamSessionID) {
      this.cookieService.set(this.cookieKey, this.clickstreamSessionID, cookieOptions);
    } else if (this.cookieService.check(this.cookieKey)) {
      this.cookieService.delete(this.cookieKey);
    }
  }

  private getClickstreamSession(): string {
    return this.window._isapp_module?.GetSessionId() || '';
  }
}
import { gql } from 'apollo-angular';

export const ASSESSMENT_RESULTS_QUERY = gql`
  query ($input: AssessmentResultsInput!) {
    assessmentResults(input: $input) {
      assessmentData {
        assessments {
          id,
          isActive,
          isComplete,
          productValue,
          schoolYear,
          skillName,
          subtests {
            assessDate,
              assessMonth,
              authorizedIp,
              id,
              inOverall,
              isOverall,
              percentile,
              period,
              schoolYear,
              score,
              skillId,
              value
          },
        },
        schoolYear,
      },
    }
  }
`;

export const ASSESSMENT_TEST_QUERY = gql`
query ($input: AssessmentResultsInput!) {
  assessmentResults(input: $input) {
    schoolYear,
  }
}
`;


export const SCHOOL_YEAR_QUERY = gql`
  query ($input: UserDataInput!) {
    userData(input: $input) {
      userData {
        currentSchoolYear
      }
    }
  }
`;

export const ASSESSMENT_RESULTS_QUERY_ALL_FIELDS = gql`
query ($input: AssessmentResultsInput!) {
  assessmentResults(input: $input) {
    assessmentData {
      assessments {
        id,
        isActive,
        isComplete,
        productValue,
        schoolYear,
        skillName,
        subtests {
          assessDate,
            assessMonth,
            authorizedIp,
            gradeSnapShot,
            id,
            inOverall,
            isActive,
            isOverall,
            onDemand,
            percentile,
            performanceScaleId,
            period,
            schoolYear,
            score,
            serial,
            skill {
              id,
              productValue,
              skillName
            },
            skillId,
            tier,
            value
        },
      },
      domainId,
      performanceScaleId,
      userAccountId,
      schoolYear,
    },
    schoolYear,
  }
}
`;
import { Directive, inject } from '@angular/core';
import { LottieLoaderService } from './lottie-loader.service';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lottie-player',
})
export class LottiePlayerDirective {
  private lottieService = inject(LottieLoaderService);
  constructor() {
    this.lottieService.load();
  }
}
import { AnswerStateEnum } from "@swe/pages/lesson/enums"
import { EpisodeCard } from "@swe/pages/lesson/classes"
import { AssignmentCard } from "@swe/pages/assignments/classes";

const ReadingEpisode_1 = {
  id: 'a',
  uniqueId: 'reading-3-prepositions-1-fromplanetstostars',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "From Planets to Stars but with a longer title in order to span multiple lines",
  learningTargets: [
    { learningTargetStatement: "what a preposition and prepositional phrase is"}
  ],
  description: "Nova Scout Axis, and his trust AI sidekick, C.A.T. are about to start their Earthen merit badges. They must master the use of prepositions from the human language of English.",
  progressDetails: [
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.CORRECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.PERFECT
  ],
  numQuestions: 10,
  lastOpened: new Date(2024, 1, 17),
  episodeNumber: 1
};

const ReadingEpisode_2 = {
  id: 'b',
  uniqueId: 'reading-3-prepositions-2-comettale',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Lorem ipsum dolor sit amet",
  learningTargets: [
    { learningTargetStatement: "something happens"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla feugiat tellus eu viverra consectetur. Fusce luctus tempus lectus, et vulputate ligula porta tempus. Aliquam est turpis, blandit ultricies efficitur ut.",
  progressDetails: [
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.CORRECT,
    AnswerStateEnum.CORRECT,
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.PERFECT
  ],
  numQuestions: 12,
  lastOpened: new Date(1986, 8, 6),
  episodeNumber: 2
};

const ReadingEpisode_3 = {
  id: 'c',
  uniqueId: 'reading-3-prepositions-3-surfsupshipsdown',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Nam pellentesque nibh lectus",
  parentSeries: "Super Cool Series Name Here",
  siblingCount: 6,
  learningTargets: [
    { learningTargetStatement: "adventure awaits"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elementum mauris cursus erat laoreet, eu imperdiet urna bibendum. Sed ac ex commodo, faucibus erat at, fermentum dolor. Proin iaculis mollis.",
  progressDetails: [
    AnswerStateEnum.CORRECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.PERFECT
  ],
  numQuestions: 15,
  lastOpened: new Date(2023, 5, 4),
  episodeNumber: 3
};

const ReadingEpisode_4 = {
  id: 'd',
  uniqueId: 'reading-3-prepositions-4-glitchinthemanual',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Fusce elementum leo sem",
  parentSeries: "Super Cool Series Name Here",
  siblingCount: 6,
  learningTargets: [
    { learningTargetStatement: "... stuff"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In dictum lacus laoreet, luctus enim sed, lobortis ipsum. Etiam vel consectetur ante. Donec facilisis eros mi. Nullam dapibus, felis quis accumsan.",
  progressDetails: [
    AnswerStateEnum.CORRECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.INCORRECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.CORRECT,
    AnswerStateEnum.CORRECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.PERFECT,
    AnswerStateEnum.PERFECT
  ],
  numQuestions: 10,
  score: 75,
  lastOpened: new Date(2023, 3, 23),
  episodeNumber: 4
};

const ReadingEpisode_5 = {
  id: 'e',
  uniqueId: 'reading-3-prepositions-5-discoveryisours',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "MMMMM MMMMMMMMM MMMMMMMMMM MMMMM MMMMMMM MMMMMMMMMMM",
  parentSeries: "Super Cool Series Name Here",
  siblingCount: 6,
  learningTargets: [
    { learningTargetStatement: "<loading>"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac elementum leo, sed aliquet urna. Nullam feugiat ac nunc et posuere. Orci varius natoque penatibus et magnis dis parturient montes.",
  numQuestions: 1,
  episodeNumber: 5,
};

const ReadingEpisode_6 = {
  id: 'f',
  uniqueId: 'blargh-blarghity-blarghity-blargh-blargh',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Blargh blarghity blarghity blargh blargh",
  learningTargets: [
    { learningTargetStatement: "how to English (maybe)"}
  ],
  description: "What even is English?",
  parentSeries: "Super Cool Series Name Here",
  siblingCount: 6,
  product: "Reading",
  skill: "Nouns",
  progressDetails: [0,0,0,0,0,1,1,2,1,0,2],
  numQuestions: 11,
  score: 41,
  lastOpened: new Date(2024, 1, 17),
  episodeNumber: 6
};

const ReadingEpisode_7 = {
  id: 'g',
  uniqueId: 'reading-3-functionofnouns-1-grammargoggles',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Grammar Goggles",
  learningTargets: [
    { learningTargetStatement: "something... is somethinging"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vestibulum dui nec justo gravida volutpat. Quisque tristique tellus nec blandit porta. Donec nec mauris a nulla tempus accumsan.",
  parentSeries: "Noun Navigator",
  siblingCount: 8,
  product: "Reading",
  skill: "Nouns",
  progressDetails: [1,2,0,1,1,0],
  numQuestions: 10,
  lastOpened: new Date(1986, 8, 6),
  episodeNumber: 7
};

const ReadingEpisode_8 = {
  id: 'h',
  uniqueId: 'ut-rutrum-risus-eget-libero',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Ut rutrum risus eget libero",
  learningTargets: [
    { learningTargetStatement: "Zhu Li does the thing"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec varius et leo non pharetra. Suspendisse arcu sem, tincidunt eu nisl nec, viverra aliquam dolor. Quisque mattis imperdiet urna, sed accumsan turpis mattis eget. Quisque imperdiet urna sed gravida accumsan. Proin volutpat id nisi id varius. Ut mi leo, luctus quis vulputate eget, ornare ac ipsum.",
  parentSeries: "Super Cool Series Name Here",
  siblingCount: 8,
  product: "Reading",
  skill: "Nouns",
  progressDetails: [2,0,1,2],
  numQuestions: 11,
  lastOpened: new Date(2023, 5, 4),
  episodeNumber: 8
};

const ReadingEpisode_9 = {
  id: 'i',
  uniqueId: 'sed-tincidunt-metus-at-est',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Sed tincidunt metus at est",
  learningTargets: [
    { learningTargetStatement: "... meow"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id laoreet elit. Maecenas viverra elit hendrerit, consectetur elit quis, convallis nunc. Duis semper diam sit amet diam mollis hendrerit. Maecenas elementum, quam eget luctus porttitor, ex orci facilisis augue, elementum aliquet risus ligula sed dui.",
  parentSeries: "Super Cool Series Name Here",
  siblingCount: 10,
  product: "Reading",
  skill: "Nouns",
  numQuestions: 11,
  episodeNumber: 9
};

const ReadingEpisode_10 = {
  id: 'j',
  uniqueId: 'reading-3-pluralnouns-2-dioramadilemma',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Diorama Dilemma",
  learningTargets: [
    { learningTargetStatement: "... bark"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus luctus, arcu ut vehicula blandit, eros ligula vehicula libero, eu mollis nisi enim vitae turpis. Vestibulum felis nisi, tempor et erat mattis, tempus placerat lorem. Donec et lobortis purus, vel iaculis sem.",
  parentSeries: "Project Plurals",
  siblingCount: 10,
  product: "Reading",
  skill: "Nouns",
  progressDetails: [0,2,1,1,2,2,1,2,1,0,0],
  numQuestions: 12,
  lastOpened: new Date(2024, 1, 17),
  episodeNumber: 10
};

const ReadingEpisode_11 = {
  id: 'k',
  uniqueId: 'the-princess-is-in-another-castle',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "The Princess is in Another Castle",
  learningTargets: [
    { learningTargetStatement: "how many times Mario has had to save Princess Peach"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ante purus, pulvinar ac finibus ut, tristique a risus. Vestibulum vel euismod neque. Suspendisse tincidunt felis in neque fringilla hendrerit... But no, seriously, how many times has it been at this point?",
  parentSeries: "The Princess is in Another Castle",
  siblingCount: 10,
  product: "Reading",
  skill: "Verbs",
  progressDetails: [1,1,0,2,1,0,1,2,0,2],
  numQuestions: 10,
  score: 55,
  lastOpened: new Date(2024, 1, 17),
  episodeNumber: 1
};

const ReadingEpisode_12 = {
  id: 'l',
  uniqueId: 'gotta-go-fast',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Gotta Go Fast!",
  learningTargets: [
    { learningTargetStatement: "Chili Dogs Chili Dogs Chili Dogs Chili Dogs Chili Dogs Chili Dogs Chili Dogs Chili Dogs Chili Dogs"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non nulla volutpat, tincidunt purus vel, pretium ex. Integer in enim ut ex hendrerit vulputate at sed ex. Duis lectus ex, scelerisque id molestie ac, auctor at risus.",
  parentSeries: "MEGAKEYBOARDSMASHKDJFKLDSJFLKDS",
  siblingCount: 12,
  product: "Reading",
  skill: "Verbs",
  numQuestions: 12,
  episodeNumber: 12
};

const ReadingEpisode_13 = {
  id: 'm',
  uniqueId: 'decent-length-witty-title-here-or-something-idk-lol',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Decent-length witty title here or something idk lol",
  learningTargets: [
    { learningTargetStatement: "... I've got nothing right now"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque imperdiet bibendum nisi eget porttitor. Donec non massa sit amet tellus congue feugiat ac at risus. Morbi eget magna eros. Cras dapibus lectus ac ipsum auctor vestibulum.",
  parentSeries: "MEGAKEYBOARDSMASHKDJFKLDSJFLKDS",
  siblingCount: 13,
  product: "Reading",
  skill: "Verbs",
  progressDetails: [2,1,0,1,2],
  numQuestions: 10,
  lastOpened: new Date(2023, 5, 4),
  episodeNumber: 13
};

const ReadingEpisode_14 = {
  id: 'n',
  uniqueId: 'suspendisse-in-lectus-augue',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Suspendisse in lectus augue",
  learningTargets: [
    { learningTargetStatement: "... mnlkjdfsjkhsdfhjkdsfjklsdfjklsfdjhkmycatsayshi"}
  ],
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi aliquam feugiat odio, ut varius lectus pretium et. Morbi tortor mauris, semper in enim sit amet, facilisis pretium enim. Duis ut porta nisl, sed imperdiet risus. Mauris ac urna sagittis, placerat diam quis, sagittis ex.",
  parentSeries: "Suspendisse in lectus augue",
  siblingCount: 1,
  product: "Reading",
  skill: "Verbs",
  progressDetails: [0,1,0,2,0],
  numQuestions: 11,
  lastOpened: new Date(2023, 3, 23),
  episodeNumber: 1
};

const ReadingEpisode_15 = {
  id: 'o',
  uniqueId: 'sweet-dreams-are-made-of-this',
  image: "/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png",
  title: "Sweet Dreams Are Made of This",
  learningTargets: [
    { learningTargetStatement: "guess what just came up on my playlist"}
  ],
  description: "Sweet dreams are made of this; Who am I to disagree? I travel the world and the seven seas; Everybody's looking for something;",
  parentSeries: "Sweet Dreams Are Made of This",
  siblingCount: 1,
  product: "Reading",
  skill: "Verbs",
  numQuestions: 12,
  episodeNumber: 1
};

export const ReadingEpisodes = [
  ReadingEpisode_1,
  ReadingEpisode_2,
  ReadingEpisode_3,
  ReadingEpisode_4,
  ReadingEpisode_5
].map(e => new EpisodeCard(e));

export const ReadingAssignmentEpisodes = [
  {
    ...ReadingEpisode_1,
    parentSeries: "Axis's Big Break",
    product: "Reading",
    skill: "Prepositions",
  },
  {
    ...ReadingEpisode_2,
    parentSeries: "Axis's Big Break",
    product: "Reading",
    skill: "Prepositions",
  },
  {
    ...ReadingEpisode_3,
    parentSeries: "Axis's Big Break",
    product: "Reading",
    skill: "Prepositions",
  },
  {
    ...ReadingEpisode_4,
    parentSeries: "Axis's Big Break",
    product: "Reading",
    skill: "Prepositions",
  },
  {
    ...ReadingEpisode_5,
    parentSeries: "Axis's Big Break",
    product: "Reading",
    skill: "Prepositions",
  },
  ReadingEpisode_6,
  ReadingEpisode_7,
  ReadingEpisode_8,
  ReadingEpisode_9,
  ReadingEpisode_10,
  ReadingEpisode_11,
  ReadingEpisode_12,
  ReadingEpisode_13,
  ReadingEpisode_14,
  ReadingEpisode_15
];

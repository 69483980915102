import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'initial',
})
export class InitialPipe implements PipeTransform {
  transform(value: string | null): string {
    const r = value?.trim() || '';
    return r ? r.charAt(0) + '.' : '';
  }
}

import { GradeBands } from "../enums"

const SubjectSubheadings = {
    Reading: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Go on adventures in reading.',
        [GradeBands.BAND_4]: 'World of Wonders',
    },
    Math: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Complete missions using math.',
        [GradeBands.BAND_4]: 'SEM Missions',
    },
    Spanish: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Vive aventuras por medio de la lectura.',
        [GradeBands.BAND_4]: 'El Mundo de los Géneros',
    },
    SpanishMath: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '¡Evaluación completada!',
        [GradeBands.BAND_4]: '¡Evaluación completada!',
    },
    ReadingIsip: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Show what you know!',
        [GradeBands.BAND_4]: 'Show what you know!',
    },
    MathIsip: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Show what you know!',
        [GradeBands.BAND_4]: 'Show what you know!',
    },
    SpanishIsip: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '¡A ver cuánto sabes!',
        [GradeBands.BAND_4]: '¡A ver cuánto sabes!',
    },
    SpanishMathIsip: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '¡A ver cuánto sabes!',
        [GradeBands.BAND_4]: '¡A ver cuánto sabes!',
    },
    Orf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Read aloud in the Istation Recording Studio.',
        [GradeBands.BAND_4]: 'Read aloud in the Istation Recording Studio.',
    },
    EspanolOrf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Lee en voz alta en el estudio de grabación de Istation.',
        [GradeBands.BAND_4]: 'Lee en voz alta en el estudio de grabación de Istation.',
    },
    ISIPAROrf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Read aloud in the Istation Recording Studio.',
        [GradeBands.BAND_4]: 'Read aloud in the Istation Recording Studio.',
    },
    ESPAROrf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Lee en voz alta en el estudio de grabación de Istation.',
        [GradeBands.BAND_4]: 'Lee en voz alta en el estudio de grabación de Istation.',
    },
    RAN: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Name pictures as fast as you can.',
        [GradeBands.BAND_4]: '',
    },
    EspanolRAN: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: 'Nombra las imágenes lo más rápido que puedas.',
        [GradeBands.BAND_4]: '',
    },
};

export default SubjectSubheadings;
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { ApmModule, ApmService, ApmErrorHandler } from '@elastic/apm-rum-angular';
import { EventDispatch } from './classes';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';

// Shared functionality
import {
  ConfigurationService,
  FeatureFlagService,
  InactivityService,
  SimulacrumService,
} from './services';
import { SharedModule } from './components/shared.module';
import {
  AuthConfigModule,
  ConfigDataModule,
  DialogModule,
  GraphqlModule,
  KnowledgeBaseModule,
} from './shared/modules';
import { WasmRuntimeModule, MessagePopupModule } from './features';
import { LottiePlayerModule } from './features/lottie-player/lottie-player.module';
import { HubConnectionBuilder } from '@microsoft/signalr';

export function TranslationLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/app/', '.json');
}
export function preloadConfig (c: ConfigurationService, f: FeatureFlagService, a: ApmService): () => Promise<void> {
  return () => c.load().finally(() => {
    f.populateFlags();
    a.init({
      // Elastic Agent API is exposed through this apm instance
      // https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html
      //    enableElasticAgent => feature flag must be enabled
      //    apmThreshold => config variable that determines threshold to limit monitoring agent (0.0 - 1.0)
      serviceName: 'Istation Student Web Experience',
      serverUrl: c.config.apmServerUrl,
      environment: c.config.shortName,
      serviceVersion: environment.appVersion,
      active: f.isFlagEnabled('enableElasticAgent') === true && Math.random() < c.config.apmThreshold
    });
  })
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApmModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // environment.production ? [] :
    HttpClientInMemoryWebApiModule.forRoot(SimulacrumService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    }),
    AppRoutingModule,
    SharedModule,
    DialogModule,
    AngularSvgIconModule.forRoot(),
    AuthConfigModule,
    KnowledgeBaseModule,
		ConfigDataModule,
    GraphqlModule,
    WasmRuntimeModule,
    LottiePlayerModule.forRoot(),
    MessagePopupModule.forRoot(),
    TranslateModule.forRoot({
      // example translation file location:   /assets/i18n/app/en.json
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: preloadConfig,
      deps: [ 
        ConfigurationService,
        FeatureFlagService,
        ApmService, 
        InactivityService
      ],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    ApmService,
    EventDispatch,
    HubConnectionBuilder,
  ],
  bootstrap: [AppComponent],
  exports: [],
})

export class AppModule {}

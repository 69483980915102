import { inject } from '@angular/core';
import { DataSourceType } from '../enums/dataSource'
import { DataSourceFacade, KbFacadeType } from "./data-source-facade";

export const getKbFacadeFn = (): (() => KbFacadeType ) => {

    const dsFacade = inject(DataSourceFacade); 
    return () => dsFacade.getKbFacade();
}

export const setKbFacadeFn = () : ((source: DataSourceType) => KbFacadeType ) => {

    const dsFacade = inject(DataSourceFacade); 
    return (source: DataSourceType) => dsFacade.setKbFacade(source);
}


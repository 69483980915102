/**
 * Message states in application
 * @enum {number}
 */
 export enum MessageCodes {
    /** Authentication Required */
    HTTP_AUTH_REQUIRED  =  401,

    /** Not Authorized */
    HTTP_FORBIDDEN      =  403,

    /** Page Not Found */
    HTTP_NOT_FOUND      =  404,

    /** Unrecognized Network Error */
    HTTP_NET_GENERIC    =  499,

    /** Internal Server Error */
    HTTP_SERVER_INTERNAL=  500,

    /** Service Unavailable */
    HTTP_SERVICE_UNAVAIL=  503,

    /** Error loading APP content */
    APP_CONTENT_LOAD    =  900,

    /** App Storage Warning */
    APP_STORAGE         =  901,

    /** App Audio Device Failure */
    APP_AUDIO_DEV       =  902,

    /** Network Error */
    APP_NETWORK         =  903,

    /** Resolution Warning */
    APP_RES             =  904,

    /** Refresh Warning */
    APP_WARN_REFRESH    =  905,

    /** Multiple Instances Running Warning */
    APP_MULTI_INSTANCE  =  906,

    /** Compatibility Message */
    APP_COMPAT          =  907,

    /** Fatal Error (App Sequence Error) */
    APP_FATAL_SEQUENCE  =  908,

    /** Running Offline */
    APP_OFFLINE         =  909,

    /** Confirm Logout */
    APP_LOGOUT_CONFIRM  = 1001,

    /** Confirm Closing Tab/Window While Logged In */
    APP_CLOSE_CONFIRM   = 1002,

    /** Idle Message */
    APP_IDLE            = 1003,

    /** ORF Confirmation */
    APP_NAV_ORF         = 1004,

    /** RAN Confirmation */
    APP_NAV_RAN         = 1005,

    /** Spanish ORF Confirmation */
    APP_NAV_ORF_ES        = 1006,

    /** Spanish RAN Confirmation */
    APP_NAV_RAN_ES         = 1007,

    /** Runtime Login Error */
    RUNTIME_LOGIN_ERROR = 1105,

    /** No Class Assigned */
    NO_CLASS            = 1110,
    
    /** Browser Navigation in Activity */
    ACTIVITY_BROWSER_NAV = 1115
}
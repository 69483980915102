export const usageForUserByProduct = `query ($input: UsageInput!) {
    usageData(input: $input) {
      usageGuideline {
        usageGoalIncrementSeconds,
        usageGoals {
            maxPercentile,
            minPercentile,
            minUsageSeconds
        },
      }
      usageForProducts {
        product {
            id, 
            type, 
            value
        },
        usageForLeaUsers {
          percentile
          usageSeconds
          usageGoalSeconds
          usageGoalMet
          usageStatus
          hasInstructionSubscription
        }
      }
    }
  }`

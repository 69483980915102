import { ConfigurationService } from './../../services/configuration.service';
import { WasmService } from './../../services/wasm.service';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { 
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { environment } from 'projects/stage/src/environments/environment';
import { APISourceLocations } from '../../types';
import { AudioDescriptor, AudioType } from '../../enums';
import { TrapFocusDirective } from '../../shared/directives/trap-focus.directive';

enum SupportPageStates {
  STATE_MAIN = 0,
  STATE_LOG,
  STATE_SUBMITTED
}

@Component({
  selector: 'swe-log-popup',
  templateUrl: './log-popup.component.html',
  styleUrls: [
    '../../../styles/_dialog.scss',
    './log-popup.component.scss'
  ],
})
export class LogPopupComponent implements OnInit, AfterViewInit {
  readonly logMessage = "LogRecorded";
  readonly STATE_MAIN = SupportPageStates.STATE_MAIN;
  readonly STATE_LOG = SupportPageStates.STATE_LOG;
  readonly STATE_SUBMITTED = SupportPageStates.STATE_SUBMITTED;

  @Input() showPopup = false;
  @Output() showPopupChange = new EventEmitter<boolean>();

  @Input() showClose = true;

  @Input() source: APISourceLocations = "runtime";
  @Output() sourceChange = new EventEmitter<APISourceLocations>();

  @Input() debugUser = false;

  @ViewChildren(TrapFocusDirective) focused!: QueryList<TrapFocusDirective>;

  logIds: string[] = [];
  logInfoForm!: UntypedFormGroup;
  appVersion = environment.appVersion;
  pageState = SupportPageStates.STATE_MAIN;

  clickClose = AudioDescriptor.CLICK_BACK;
  clickType = AudioType.audio_SfxClick;

  constructor(
    public wasmService: WasmService,
    private configService: ConfigurationService,
  ) { }

  ngOnInit(): void {
    this.logInfoForm = new UntypedFormGroup({
      name: new UntypedFormControl("", {validators: Validators.required, updateOn: 'blur'}),
      phoneEmail: new UntypedFormControl("", {
        validators: [Validators.required, this.validateEmailOrPhone],
        updateOn: 'blur'
      }),
      description: new UntypedFormControl("")
    });
    document.addEventListener(this.logMessage, ((event: CustomEvent) => {
      this.logIds.push(event.detail);
    }) as EventListener);
    // update trapfocus as loginFocus changes 
    // at a slight delay so tha the DOM updates before the trap focus does
    this.logInfoForm.statusChanges
      .subscribe(() => {
        setTimeout(()=> {
          this.focused?.map((el) => el.trapFocus());
        }, 1);
      });
  }

  ngAfterViewInit(): void {
    this.focused?.map((el) => el.trapFocus());
  }

  get name() { return this.logInfoForm?.get('name') ?? new UntypedFormControl("", {validators: Validators.required, updateOn: 'blur'}); }
  get phoneEmail() { return this.logInfoForm?.get('phoneEmail') ?? new UntypedFormControl("", {
    validators: [Validators.required, this.validateEmailOrPhone],
    updateOn: 'blur'
  }); }
  get description() { return this.logInfoForm?.get('description') ?? new UntypedFormControl(""); }

  validateEmailOrPhone( control: AbstractControl ): ValidationErrors | null {
    const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const matchesEmail = control.value ? (control.value as string).match(emailRegex) : null;
    const matchesPhone = control.value ? (control.value as string).match(phoneRegex) : null;
    const res = (matchesEmail || matchesPhone);
    return res ? null : {invalid: control.value};
  }

  submitSendLogs(): void {
    this.pageState = SupportPageStates.STATE_SUBMITTED;
    this.wasmService.makeLog(
      `Name: ${this.logInfoForm.value.name} | Phone/Email: ${this.logInfoForm.value.phoneEmail} | Description: ${this.logInfoForm.value.description}`,
      this.configService.config.logsToSend,
      true
    );
  }

  openPopup(): void {
    this.showPopup = true;
  }

  @HostListener('keydown.escape', ['$event'])
  close(): void {
    if (this.showPopup) {
      this.showPopup = false;
      this.showPopupChange.emit(this.showPopup);
      this.reset();
    }
  }

  sendLogsClicked(): void {
    this.pageState = SupportPageStates.STATE_LOG;
  }

  back(): void {
    this.reset();
  }

  reset(): void {
    this.pageState = SupportPageStates.STATE_MAIN;
    this.logInfoForm.reset();
    this.logIds = [];
  }

}

import { Directive, HostListener, Input, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LessonDialogService } from '@swe/services';
import { EpisodeCard } from '@swe/pages/lesson/classes';

@Directive({
  standalone: true,
})
export class SeriesCardLinkDirective {
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private dialogService: LessonDialogService = inject(LessonDialogService);

  // "false" if no ID
  @Input() seriesId!: string | undefined;
  @Input() episode: EpisodeCard | undefined;
  @Input() assignmentId: number | undefined;

  @HostListener('click') 
  @HostListener('keyup.enter')
  @HostListener('keyup.space')
  onClick(){
    if (this.episode) {
      // open dialog
      this.dialogService.openPreview(this.episode, {
        seriesId: this.seriesId ?? '',
        assignmentId: this.assignmentId,
      });
    } else {
      // navigate
      this.router.navigate(['series', this.seriesId], {
        relativeTo: this.route,
      });
    }
  }
}

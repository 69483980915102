<div class="wave-background-overlay">
  <div class="wave wave-left">
    <div class="wave-back"
         [ngClass]="'wave-' + subjectName"></div>
    <div class="wave-front"></div>
  </div>
  <div class="wave wave-right">
    <div class="wave-back"
         [ngClass]="'wave-' + subjectName"></div>
    <div class="wave-front"></div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingDirective } from '../directives/tracking.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TrackingDirective],
  exports: [TrackingDirective],
})
export class TrackingModule {}

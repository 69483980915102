import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormSelectComponent } from '../components/form/select/select.component';

const COMPONENTS = [
  FormSelectComponent
]

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FormsModule,
    KeyValuePipe,
  ],
  exports: [
    ...COMPONENTS,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FormFieldsModule { }

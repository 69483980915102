<main class="content">
  <router-outlet></router-outlet>

  <swe-theme-switcher *sweFeatureFlag="'debugTools'"></swe-theme-switcher>

  <swe-dialog
    #debug
		*ngIf="canUseDebugPanel"
    [(showPopup)]="showDebug"
    [showClose]="true"
    [title]="'Debug Console'"
  >
    <swe-debug></swe-debug>
  </swe-dialog>
  <swe-overlay *ngIf="overlayEnabled"></swe-overlay>
</main>

<swe-loading
  *ngIf="showLoading | async"
></swe-loading>
<swe-wasm
  *ngIf="isCompatible"
  [ngClass]="(this.wasmService.showWasmBehavior | async) ? 'active' : 'inactive'"
></swe-wasm>
<swe-message-popup sweTrapFocus></swe-message-popup>

import { ModuleWithProviders, NgModule } from "@angular/core";
import { ToastService } from "@swe/shared/services";
import { ToastComponent } from "@swe/shared/components";
import { AngularSvgIconModule } from "angular-svg-icon";

@NgModule({
  imports: [
    AngularSvgIconModule,
    ToastComponent,
  ]
})
export class ToastModule {
  static forRoot(): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        ToastService,
      ],
    };
  }
}
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'swe-skeleton',
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: `<div @enterExit class="loader"></div>`,
  styles: [
`
@keyframes fadeinout {
  0% { background-color: #393D45;}
  100% { background-color: #2A2D32; }
}
:host {
  display: block;
  width: 100%;
  height: 100%;
}
.loader {
  width: 100%;
  height: 100%;
  background-color: #393D45;
  border-radius: var(--border-radius, 1rem);
  animation: 1s linear 1s infinite alternate fadeinout;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,42%);
}
`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterExit', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate(250, style({ opacity: 1 }))]),
      transition(':leave', [animate(250, style({ opacity: 0 }))]),
    ]),
  ],
})
export class SkeletonComponent { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'swe-number-counter',
	template: `<span class="num-counter" [style.visibility]="isVisible ? 'visible' : 'hidden'">{{displayedCountStr}}</span>`,
})
export class NumberCounterComponent implements OnInit {

	// The numeric value for counter start
	@Input() startValue = 0;	

	// The numeric value for counter stop
	@Input() endValue   = 0;

	// Animation duration (milliseconds)
	@Input() timeAnimDuration = 1500;

	// Enable leading zeros on the counter output
	@Input() hasLeadingZeroes = false;

	// Visibility of the counter element
	@Input() isVisible = true;	

	// The length of the string to display in the counter output
	@Input() displayedCountLength!: number;
	
	// The string value to display.
	public displayedCountStr = "";

	// When [_startAnims] is set to "true", the counter animation will start
	private _startAnims = false;
	@Input() 
	set startAnims(doAnims: boolean) {
		if (doAnims && !this._startAnims){
			this.animateCounter();
		}
		this._startAnims = doAnims;
	}
	get startAnims(): boolean {
		return this._startAnims;
	}


	ngOnInit(): void {
		if (this.hasLeadingZeroes && !this.displayedCountLength) {
			this.displayedCountLength = this.endValue.toString().length;
		}
		this.displayedCountStr = this.getAdjustedNumberString(this.startValue);
	}

	/**
	 * Triggers the count up/down animation.
	 */
	animateCounter(): void {
		const direction = (this.endValue >= this.startValue) ? 1 : -1;
		const diff = Math.abs(this.endValue - this.startValue);
		const time: number = this.timeAnimDuration >= diff ? this.timeAnimDuration / diff : 10;
		const incrementValue: number = this.timeAnimDuration >= diff ? direction : Math.ceil(diff / this.timeAnimDuration * time) * direction;
		let value  = this.startValue;
		this.isVisible = true;
		const intervalID = setInterval(() => {
			value += incrementValue;
			this.displayedCountStr = this.getAdjustedNumberString(Math.min(value, this.endValue));
			if (value >= this.endValue) {
				clearInterval(intervalID);
			}
		}, time);
	}

	/**
	 * Returns a string value of the given number, adjusted for leading zeroes.
	 * 
	 * If the number of digits of the current count is less than this
	 * value *AND* [hasLeadingZeroes] is "true", the displayed value
	 * will be adjusted to display exactly this many digits.
	 * If the input property is not used (and therefore this value is "-1") 
	 * and [hasLeadingZeroes] is "true", this value will be set to 
	 * the length of the endValue.
	 * 
	 * @param value	the given number 
	 * @returns what the description says
	 */
	getAdjustedNumberString(value: number): string {
		const fillString = value >= 0 ? "0" : "\u2003";
		return this.hasLeadingZeroes
			? value.toString().padStart(this.displayedCountLength, fillString)
				: value.toString();
	}
}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ContentManifest } from '../../enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
  selector: 'swe-episode-overview',
  template: `
<h6>{{ 'LESSONS.IN_THIS_LESSON'  | translate}}:</h6>
<ul class="overview">
  <li *ngFor="let img of images"><img [src]="'assets/icons/lessons/' + img"></li>
</ul>`,
  styles: [`
:host{
  flex: 1 1 auto;
  text-align: left;
}
h6 {
  text-transform: uppercase;
  margin-bottom: 0.5rem !important;
}
.overview {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  li {
    display: inline-block;
  }
  }`
  ],
})
export class EpisodeOverviewComponent {
  @Input() 
  set contents(values: ContentManifest[]) {
    this.images = values
      .filter(v => v in this._imageSources)
      .map(v => this._imageSources[v])
  }

  public images: string[] = [];

  private _imageSources: Record<number, string> = {
    [ContentManifest.VIDEO]: 'video.webp',
    [ContentManifest.QUESTION]: 'questions.webp',
    [ContentManifest.CONSTRUCTED_RESPONSE]: 'open-response.webp',
    [ContentManifest.RESULTS_SCREEN]: 'rewards.webp',
  };
}

import { inject, Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { WasmService } from '../services';
import { MessageCodes } from '../enums';
import { MessageService } from '../features/message-popup/message.service';


@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  readonly heartbeatFrequency = 30000;		// 30 sec
  readonly inactivityWarning  = 900000;		// 15 min (5 min before logout)
  readonly inactivityTimeout  = 1170000;	// 20 min - time for first heartbeat

  heartbeatInterval: number | undefined;
  activeStart = Date.now();
  isActive = true;
  idleWarned = false;

  private wasmService: WasmService = inject(WasmService);
  private messageService: MessageService = inject(MessageService);
  private subs = new Subscription();
    
  constructor() {
    // Initiate the heartbeat only after runtime has started
    this.wasmService.inStartupScene
      .pipe(first(inScene=>inScene))
      .subscribe(() => this.heartbeatInterval = window.setInterval(() => this.heartbeat(), this.heartbeatFrequency));

    // Record activity for the first click after inactivity
    const click = fromEvent(window, "click")
      .pipe(filter(()=> !this.isActive))
      .subscribe(() => {
        // Close the warning
        if(this.idleWarned) this.messageService.sendTrigger('OK');
        this.recordActivity();
      });

    // Force a heartbeat on tab refocus if heartbeat is active
		const visibility = fromEvent(document, "visibilitychange")
			.pipe(
				filter(() => document.visibilityState === 'visible' && !!this.heartbeatInterval)
			)
			.subscribe(() => this.heartbeat());

    // Listen for logout trigger on message service
    const message = this.messageService.triggerData
      .pipe(filter((data) => data === 'Trigger_LogOut'))
      .subscribe(async () => {
        const deltaActive = Date.now() - this.activeStart;
        if ( 
          window._isapp_module 
          && window._isapp_module.LogTimeout 
          && deltaActive >= this.inactivityTimeout 
        ) {
          window._isapp_module.LogTimeout(deltaActive/1000);
        }
        window.clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = undefined;
        await this.wasmService.logout(true);
        this.subs.unsubscribe();
      });
    this.subs.add(click);
    this.subs.add(visibility);
    this.subs.add(message);
  }

  heartbeat(): void {
    const deltaActive = Date.now() - this.activeStart;
    // console.log('beating...', {deltaActive}, 'inactivityWarning', this.inactivityWarning, 'inactivityTimeout', this.inactivityTimeout, 'isActive', this.isActive);

    if(deltaActive < this.heartbeatFrequency) return;
    this.isActive = false;
    this.notifyRuntime();

		if(deltaActive >= this.inactivityTimeout) {
			this.messageService.sendTrigger('Trigger_LogOut');
		}
    else if(deltaActive >= this.inactivityWarning && !this.idleWarned) {
      // Adjust the timer on the msg popup so it hits 0 right on a heartbeat.
      const heartbeatsUntilTimeout = Math.ceil((this.inactivityTimeout - deltaActive) / this.heartbeatFrequency
      );
      const timeoutTimer = heartbeatsUntilTimeout * (this.heartbeatFrequency / 1000);
      this.messageService.miscInfoMap.set(MessageCodes.APP_IDLE.toString(), timeoutTimer);
      this.messageService.showMessage(MessageCodes.APP_IDLE, () => {
				this.recordActivity();
      });
      this.idleWarned = true;

			// console.log("inactivity popup info:", {deltaActive}, 'timeUntilLogout', (this.inactivityTimeout - deltaActive), {heartbeatsUntilTimeout}, {timeoutTimer});
    }
  }

  notifyRuntime(): void{
    if( !window._isapp_module ) return;
    const { 
      _isapp_module: { 
        UpdateActiveTime: active, 
        UpdateInactiveTime: inactive 
      }
    } = window;
    const startUpAvailable = this.wasmService.inStartupScene.getValue();
    if(!!active && !!inactive && startUpAvailable) {
      // console.warn(`notifying... Time since last activity:${Date.now() - this.activeStart}`);
      if (this.isActive) {
        // console.warn("Active notification");
        active();
      } else {
        // console.warn("Inactive notification");
        inactive();
      }
    }
  }

  recordActivity(): void { 
    this.isActive = true;
    this.notifyRuntime();
    this.wasmService.recordActivity();
    this.activeStart = Date.now();
    this.idleWarned = false;
  }
}

/**
 * Date/time object that preserves LCM type info for JSON transfer of KnowledgeBase and ConfigData
 */
export interface LCMDateTime {
	// IVariant type
    variantType: 'vt_date' | 'vt_time';
    
	// UTC seconds since epoch
	variantValue: number;
}

/**
 * Determine whether a value is an LCMDateTime object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isLCMDateTime(value: any) {
	return value?.hasOwnProperty('variantType') && (value.variantType === 'vt_date' || value.variantType === 'vt_time') &&
		value.hasOwnProperty('variantValue') && typeof value.variantValue === 'number' ? true : false;
}

/**
 * Get a new LCMDateTime object
 * @param date Date value to use
 * @param old LCMDateTime object from which to copy IVariant type (ignored if undefined or invalid)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLCMDateTime(date: Date, old?: any): LCMDateTime {
	return {
		variantType: isLCMDateTime(old) ? (old as LCMDateTime).variantType : 'vt_time',
		variantValue: date.getTime() / 1000
	};
}

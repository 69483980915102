export enum Month {
	January,
	February,
	March,
	April,
	May,
	June,
	July,
	August,
	September,
	October,
	November,
	December,
}

// Month.July;		returns   6
// Month["July"]; 	returns   6
// Month[6]; 		returns   "July"
//		 ^ "6" is usual starting month value?
import { User } from '../types';
import subscriptions from './subscriptions';

const users: Array<
  User & {
    password: string;
    firstName: string;
    lastName: string;
    leaRole: string;
  }
> = [
  {
    id: 1234,
    username: 'teacher',
    password: 'Education321',
    firstName: 'PreviewTeacher',
    lastName: 'Teacher',
    userId: '01234',
    leaRole: 'teacher',
    isTeacher: true,
    domain: 'success.home.tx',
    avatar: 15,
    stars: 0,
    subscription: subscriptions[0],
  },
  {
    id: 56789,
    username: 'campus_mgr',
    password: 'Education321',
    firstName: 'PreviewManager',
    lastName: 'Manager',
    userId: '56789',
    leaRole: 'campusMgr',
    isTeacher: true,
    domain: 'success.home.tx',
    avatar: 3,
    stars: 0,
    subscription: subscriptions[0],
  },  
  {
    id: 19940827,
    username: 'pk',
    password: '1',
    firstName: 'Ness PK',
    lastName: 'Fire',
    fullName: 'Ness PK Fire',
    grade: -1,
    userId: '19940827',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 4,
    stars: 34,
    subscription: subscriptions[0],
  },
  {
    id: 19850913,
    username: 'k',
    password: '1',
    firstName: 'Luigi K',
    lastName: 'Mario',
    fullName: 'Luigi K Mario',
    grade: 0,
    userId: '19850913',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 4,
    stars: 34,
    subscription: subscriptions[0],
  },
  {
    id: 19960909,
    username: '1',
    password: '1',
    firstName: 'Crash 1',
    lastName: 'Bandicoot',
    fullName: 'Crash 1 Bandicoot',
    grade: 1,
    userId: '19960909',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 1,
    stars: 77,
    subscription: subscriptions[6],
  },
  {
    id: 19921131,
    username: '2',
    password: '1',
    firstName: 'Olgilvie 2',
    lastName: 'Hedgehog',
    fullName: 'Olgilvie 2 Hedgehog',
    grade: 2,
    userId: '19921131',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 7,
    stars: 77,
    subscription: subscriptions[5],
  },
  {
    id: 19970131,
    username: '3',
    password: '1',
    firstName: 'Cloud 3',
    lastName: 'Strife',
    fullName: 'Cloud 3 Strife',
    grade: 3,
    userId: '19970131',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 7,
    stars: 77,
    subscription: subscriptions[5],
  },
  {
    id: 20071113,
    username: '4',
    password: '1',
    firstName: 'Desmond 4',
    lastName: 'Miles',
    fullName: 'Desmond 4 Miles',
    grade: 4,
    userId: '20071113',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 15,
    stars: 12,
    subscription: subscriptions[2],
  },
  {
    id: 19860806,
    username: '5',
    password: '1',
    firstName: 'Samus 5',
    lastName: 'Aran',
    fullName: 'Samus 5 Aran',
    grade: 5,
    userId: '19860806',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 7,
    stars: 77,
    subscription: subscriptions[5],
  },
  {
    id: 2007116,
    username: '6',
    password: '1',
    firstName: 'Jane 6',
    lastName: 'Shapard',
    fullName: 'Jane 6 Shepard',
    grade: 6,
    userId: '2007116',
    leaRole: 'student',
    isTeacher: false,
    domain: 'istation',
    avatar: 3,
    stars: 45,
    subscription: subscriptions[3],
  },
];
export default users;

export enum LoginCode {
    loginOk         = 0x00,
    authFailure     = 0x01, // authentication failure
    dupLogin        = 0x02,	// already logged in
    netFailure      = 0x03, // unable to talk to network server
    disabled        = 0x04, // logins disabled for this domain
    noClass         = 0x05, // user not it a current classroom (for this school year)
    restricted      = 0x06, // login denied due to location or time-of-day
    invalidToken    = 0x07, // invalid SSO token
    ssoConfig       = 0x08
}

export enum LoginCodeMessage {
    'Login successful' = 0,
    'Authentication failure',
    'User already logged in',
    'Unable to talk to network server',
    'Logins have been disabled for this domain',
    'User not in a current classroom (for this school year)',
    'Login denied due to location or time-of-day',
    'Invalid SSO token',
    'SSO configuration failure'
}
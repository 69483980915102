import { LAUNCH_STATUS } from "@swe/classes/runtime";
export default class LaunchControl {
    wrapper: HTMLElement | null;
    _status: string;

    constructor(){
        this.wrapper = document.getElementById("launchWrap");
        this._status = LAUNCH_STATUS.INITIAL.name; // Loading, loaded, launched
    }

    removeClasses () {
        this.wrapper?.classList.remove("is-loading", "has-loaded", "has-launched");
    }

    get status (): string {
        return this._status;
    }

    set status (status: string) {
        if (status !== this._status) {
            this.removeClasses();
        }
        if (status === LAUNCH_STATUS.LOADING.name) {
            this.wrapper?.classList.add("is-loading");
        } else if (status === LAUNCH_STATUS.LOADED.name) {
            this.wrapper?.classList.add("has-loaded");    
        } else if (status === LAUNCH_STATUS.LAUNCHED.name) {
            this.wrapper?.classList.add("has-launched", "has-loaded");
        }
        this._status = status;
    }
}
import { ContentMedia } from "@swe/pages/lesson/types";
import { DefaultMicrolessonThumbnail } from "@swe/data/lessons";
import { ContentMediaTypes } from "@swe/enums/content-media-types";

/**
 * Takes an array of ContentMedia objects and returns the first one that both:
 *   1. has the contentMediaType of 'Thumbnail'
 *   2. has a truthy url
 * If no objects in the array satisfy both conditions, the default thumbnail is returned.
 * 
 * @param allMedia array of ContentMedia objects
 * @returns a ContentMedia object with thumbnail information
 */
export function getMicrolessonThumbnail(allMedia: ContentMedia[] | undefined): ContentMedia {
  return allMedia?.find(
    (media) => media.contentMediaType === ContentMediaTypes.Thumbnail && media.url
  ) ?? DefaultMicrolessonThumbnail;
}
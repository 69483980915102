import { InvokeResponseResult } from "../../types";

/** Utilities */
export const fromBool   = (myValue: boolean): InvokeResponseResult => ({ type: 'vt_bool', value: myValue.toString() } as InvokeResponseResult);
export const fromChar   = (myValue: string): InvokeResponseResult => ({ type: 'vt_char', value: myValue.toString() } as InvokeResponseResult);
export const fromShort  = (myValue: number): InvokeResponseResult => ({ type: 'vt_int16', value: myValue.toString() } as InvokeResponseResult);
export const fromInt    = (myValue: number): InvokeResponseResult => ({ type: 'vt_int32', value: myValue.toString() } as InvokeResponseResult);
export const fromFloat  = (myValue: number): InvokeResponseResult => ({ type: 'vt_float32', value: myValue.toString() } as InvokeResponseResult);
export const fromUnstr  = (myValue: string): InvokeResponseResult => ({ type: 'vt_unstr', value: myValue.toString() } as InvokeResponseResult);
export const fromString = (myValue: string): InvokeResponseResult => ({ type: 'vt_string', value: myValue.toString() } as InvokeResponseResult);
export const fromRef    = (myValue: string): InvokeResponseResult => ({ type: 'vt_ref', value: myValue.toString() } as InvokeResponseResult);
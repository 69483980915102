import { AudioResource, AudioResourceMapping, AudioLanguageMapping } from '../types/audio-types';
import { AudioType } from '../enums/audiotype'
import { Themes } from '../enums';
import { AudioDescriptor } from '../enums/audio-descriptor';

export const defaultAudioFolder = '/audio/';
const sfxFolderTHEMES = "sfx/ui/themes/";
const themeFolderDEFAULT = sfxFolderTHEMES + Themes.DEFAULT;
const themeFolderWAVY = sfxFolderTHEMES + Themes.WAVY;
const themeFolderSPACECAT = sfxFolderTHEMES + Themes.SPACECAT;
const themeFolderGAMERZ = sfxFolderTHEMES + Themes.GAMERZ;
const themeFolderSYNTHWAVE = sfxFolderTHEMES + Themes.SYNTHWAVE;
const sfxFolderISIP = `${defaultAudioFolder}sfx/isip/`;

export const audioStickies: string[] = [

    "idHintSubjectReadingEng",
    "idHintSubjectLecturaEsp",
    "idHintSubjectMathEng",
    "idHintSubjectMathEsp",
    "idHintRewardsEng",
    "idHintRewardsEsp",
    "idHintSubjectOrfEng",
    "idHintSubjectOrfEsp",
    "idHintSubjectRapidNamingEng",
    "idHintSubjectRapidNamingEsp",
];


export const audioMappings: AudioResourceMapping[] = [

    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_READING,
        id: "idHintSubjectReadingEng",
    },
    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_LECTURA,
        id: "idHintSubjectLecturaEsp",
    },
    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_MATH,
        id: "idHintSubjectMathEng",
    },
    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_MATH_ESP,
        id: "idHintSubjectMathEsp",
    },
    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_ORF,
        id: "idHintSubjectOrfEng",
    },
    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_ORF_ESP,
        id: "idHintSubjectOrfEsp",
    },
    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_RAN,
        id: "idHintSubjectRapidNamingEng",
    },
    {
        type: AudioType.audio_HintSubject,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_RAN_ESP,
        id: "idHintSubjectRapidNamingEsp",
    },
    {
        type: AudioType.audio_HintMisc,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_MINUTES_LEARNING,
        id: "idHintMinutesSpentEng",
    },
    {
        type: AudioType.audio_HintMisc,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_MINUTES_LEARNING_ESP,
        id: "idHintMinutesSpentEsp",
    },
    {
        type: AudioType.audio_HintMisc,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_REWARDS,
        id: "idHintRewardsEng",
    },
    {
        type: AudioType.audio_HintMisc,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_REWARDS_ESP,
        id: "idHintRewardsEsp",
    },
    {
        type: AudioType.audio_HintResults,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_HIGHEST_SCORE,
        id: "idResultsHighestScoreEng",
    },
    {
        type: AudioType.audio_HintResults,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_HIGHEST_SCORE_ESP,
        id: "idResultsHighestScoreEsp",
    },
    {
        type: AudioType.audio_HintResults,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_NEEDS_PRACTICE,
        id: "idResultsNeedsPracticeEng",
    },
    {
        type: AudioType.audio_HintResults,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_NEEDS_PRACTICE_ESP,
        id: "idResultsNeedsPracticeEsp",
    },
    {
        type: AudioType.audio_HintResults,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_RESULTS,
        id: "idResultHowDoYouFeelYouDidEng",
    },
    {
        type: AudioType.audio_HintResults,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_RESULTS_ESP,
        id: "idResultHowDoYouFeelYouDidEsp",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_OPEN_CHEST,
        id: "idIsipSimpleOpenChestEng",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_OPEN_CHEST_ESP,
        id: "idIsipSimpleOpenChestEsp",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_HI_READING,
        id: "idIsipSimpleReadingHiEng",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_HI_READING_ESP,
        id: "idIsipSimpleLecturaHiEsp",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_HI_MATH,
        id: "idIsipSimpleMathHiEng",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_HI_MATH_ESP,
        id: "idIsipSimpleMatematicasHiEsp",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_FINISHED,
        id: "idIsipSimpleFinishedEng",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_FINISHED_ESP,
        id: "idIsipSimpleFinishedEsp",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_GREAT_WORK,
        id: "idIsipSimpleGreatWorkEng",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_GREAT_WORK_ESP,
        id: "idIsipSimpleGreatWorkEsp",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_PRESS,
        id: "idIsipSimplePressEng",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_PRESS_ESP,
        id: "idIsipSimplePressEsp",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_SEE_YOU,
        id: "idIsipSimpleSeeYouEng",
    },
    {
        type: AudioType.audio_HintISIP,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.HINT_SIMPLE_SEE_YOU_ESP,
        id: "idIsipSimpleSeeYouEsp",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_SUBJECT,
        id: "idSfxClickSubjectDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.ANIM_CHEST_OPEN,
        id: "idSfxClickAnimChestOpen",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.ANIM_CLOSING,
        id: "idSfxClickAnimClosing",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.ANIM_FINISHED,
        id: "idSfxClickAnimFinished",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_SELECT_MAJOR,
        id: "idSfxClickButtonSelectMajor",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_SUBJECT,
        id: "idSfxClickSubjectWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_SUBJECT,
        id: "idSfxClickSubjectSC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_SUBJECT,
        id: "idSfxClickSubjectGM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_SUBJECT,
        id: "idSfxClickSubjectSYN",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_AVATAR_THEME,
        id: "idSfxAvatarThemeDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_AVATAR_THEME,
        id: "idSfxAvatarThemeWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_AVATAR_THEME,
        id: "idSfxAvatarThemeSC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_AVATAR_THEME,
        id: "idSfxAvatarThemeGAM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_AVATAR_THEME,
        id: "idSfxAvatarThemeSYN",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_MORE_NEXT,
        id: "idSfxMoreNextDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_MORE_NEXT,
        id: "idSfxMoreNextWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_MORE_NEXT,
        id: "idSfxMoreNextSC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_MORE_NEXT,
        id: "idSfxMoreNextGAM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_MORE_NEXT,
        id: "idSfxMoreNextSYN",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_GO_PLAY,
        id: "idSfxGoPlayDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_GO_PLAY,
        id: "idSfxGoPlayWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_GO_PLAY,
        id: "idSfxGoPlaySC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_GO_PLAY,
        id: "idSfxGoPlayGAM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_GO_PLAY,
        id: "idSfxGoPlaySYN",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_BACK,
        id: "idSfxBackDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_BACK,
        id: "idSfxBackWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_BACK,
        id: "idSfxBackSC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_BACK,
        id: "idSfxBackGAM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_BACK,
        id: "idSfxBackSYN",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_TUTORIAL,
        id: "idSfxTutorialDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_TUTORIAL,
        id: "idSfxTutorialWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_TUTORIAL,
        id: "idSfxTutorialSC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_TUTORIAL,
        id: "idSfxTutorialGAM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_TUTORIAL,
        id: "idSfxTutorialSYN",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_FINISH_SAVE,
        id: "idSfxFinishSaveDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_FINISH_SAVE,
        id: "idSfxFinishSaveWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_FINISH_SAVE,
        id: "idSfxFinishSaveSC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_FINISH_SAVE,
        id: "idSfxFinishSaveGAM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_FINISH_SAVE,
        id: "idSfxFinishSaveSYN",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.SFX_POPUP_ERROR,
        id: "idSfxPopupErrorDEF",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.SFX_POPUP_ERROR,
        id: "idSfxPopupErrorWV",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.SFX_POPUP_ERROR,
        id: "idSfxPopupErrorSC",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.SFX_POPUP_ERROR,
        id: "idSfxPopupErrorGAM",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.SFX_POPUP_ERROR,
        id: "idSfxPopupErrorSYN",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.SFX_POPUP_QUESTION,
        id: "idSfxPopupQuestionDEF",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.SFX_POPUP_QUESTION,
        id: "idSfxPopupQuestionWV",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.SFX_POPUP_QUESTION,
        id: "idSfxPopupQuestionSC",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.SFX_POPUP_QUESTION,
        id: "idSfxPopupQuestionGAM",
    },
    {
        type: AudioType.audio_SfxPopup,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.SFX_POPUP_QUESTION,
        id: "idSfxPopupQuestionSYN",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.DEFAULT,
        descriptor: AudioDescriptor.CLICK_HOME,
        id: "idSfxHomeDEF",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.WAVY,
        descriptor: AudioDescriptor.CLICK_HOME,
        id: "idSfxHomeWV",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SPACECAT,
        descriptor: AudioDescriptor.CLICK_HOME,
        id: "idSfxHomeSC",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.GAMERZ,
        descriptor: AudioDescriptor.CLICK_HOME,
        id: "idSfxHomeGAM",
    },
    {
        type: AudioType.audio_SfxClick,
        theme: Themes.SYNTHWAVE,
        descriptor: AudioDescriptor.CLICK_HOME,
        id: "idSfxHomeSYN",
    },
];


export const audioResources: AudioResource[] = [

    //////////////////////////////////
    // Subjects Hints

    // subject hint: "Reading"
    {
        
        id: "idHintSubjectReadingEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/reading.mp3"
    },
    // subject hint: "Lectura"
    {
        id: "idHintSubjectLecturaEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/lectura.mp3"
    },
    // subject hint: "Math"
    {
        id: "idHintSubjectMathEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/math.mp3"
    },
    // subject hint: "Math (Espanol)"
    {
        id: "idHintSubjectMathEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/matematicas.mp3"
    },
    // subject hint: "Oral Reading Fluency"
    {
        id: "idHintSubjectOrfEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/oral_reading_fluency.mp3"
    },
    // subject hint: "Oral Reading Fluency (Espanol)"
    {
        id: "idHintSubjectOrfEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/fluidez_en_la_lectura_oral.mp3"
    },
    // subject hint: "Rapid Naming"
    {
        id: "idHintSubjectRapidNamingEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/rapid_naming.mp3"
    },

    // subject hint: "Rapid Naming (Espanol)"
    {
        id: "idHintSubjectRapidNamingEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/subjects/nombramiento_rapido.mp3"
    },

   //////////////////////////////////
    // Misc Hints

    //  "Minutes Spent Learning"
    {
        id: "idHintMinutesSpentEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/misc/minutes_spent_learning.mp3"
    },

    //  "Minutes Spent Learning (Espanol)"
    {
        id: "idHintMinutesSpentEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/misc/minutos_dedicados_al_aprendizaje.mp3"
    },

    //  "Rewards"
    {
        id: "idHintRewardsEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/misc/rewards.mp3"
    },
    
    //  "Recompensas"
    {
        id: "idHintRewardsEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/misc/recompensas.mp3"
    },
    
   //////////////////////////////////
    // Results

    //  "Highest Score"
    {
        id: "idResultsHighestScoreEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/results/highest_score.mp3"
    },

    //  "Highest Score (Espanol)"
    {
        id: "idResultsHighestScoreEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/results/puntuacion_mas_alta.mp3"
    },

    //  "Needs Practice"
    {
        id: "idResultsNeedsPracticeEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/results/needs_practice.mp3"
    },

    //  "Needs Practice (Espanol)"
    {
        id: "idResultsNeedsPracticeEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/results/necesitas_practica.mp3"
    },

    //  "How Do You Feel You Did?"
    {
        id: "idResultHowDoYouFeelYouDidEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/results/how_do_you_feel_you_did.mp3"
    },

    //  "How Do You Feel You Did? (Espanol)"
    {
        id: "idResultHowDoYouFeelYouDidEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/results/como_sientes_que_lo_hiciste.mp3"
    },

    // ISIP PreK-K ISIP flows
    {
        id: "idIsipSimpleOpenChestEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/open_chest.mp3"
    },
    {
        id: "idIsipSimpleOpenChestEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/abre.mp3"
    },
    {
        id: "idIsipSimpleReadingHiEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/reading_hi.mp3"
    },
    {
        id: "idIsipSimpleLecturaHiEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/hola.mp3"
    },
    {
        id: "idIsipSimpleMathHiEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/math_hi.mp3"
    },
    {
        id: "idIsipSimpleMatematicasHiEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/hola.mp3"
    },
    {
        id: "idIsipSimpleFinishedEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/finished.mp3"
    },
    {
        id: "idIsipSimpleFinishedEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/terminaste.mp3"
    },
    {
        id: "idIsipSimpleSeeYouEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/see_you_next_time.mp3"
    },
    {
        id: "idIsipSimpleSeeYouEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/hasta_la_proxima.mp3"
    },
    {
        id: "idIsipSimplePressEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/press.mp3"
    },
    {
        id: "idIsipSimplePressEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/presiona.mp3"
    },
    {
        id: "idIsipSimpleGreatWorkEng",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/great_work.mp3"
    },
    {
        id: "idIsipSimpleGreatWorkEsp",
        folder: defaultAudioFolder,
        fileName: "dialogue/isip/excelente_trabajo.mp3"
    },


     //////////////////////////////////
    // Effects

    // click: subject card/ORF

    {
        id: "idSfxClickSubjectDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/subject_orf.wav"
    },
    {
        id: "idSfxClickSubjectWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/subject_orf.wav"
    },
    {
        id: "idSfxClickSubjectSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/subject_orf.wav"
    },
    {
        id: "idSfxClickSubjectGM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/subject_orf.wav"
    },
    {
        id: "idSfxClickSubjectSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/subject_orf.wav"
    },

    // click;  Profile: Avatar or theme

    {
        id: "idSfxAvatarThemeDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/avatar_theme.wav"
    },
    {
        id: "idSfxAvatarThemeWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/avatar_theme.wav"
    },
    {
        id: "idSfxAvatarThemeSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/avatar_theme.wav"
    },
    {
        id: "idSfxAvatarThemeGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/avatar_theme.wav"
    },
    {
        id: "idSfxAvatarThemeSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/avatar_theme.wav"
    },

    // click;  More/Next

    {
        id: "idSfxMoreNextDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/more_next.wav"
    },
    {
        id: "idSfxMoreNextWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/more_next.wav"
    },
    {
        id: "idSfxMoreNextSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/more_next.wav"
    },
    {
        id: "idSfxMoreNextGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/more_next.wav"
    },
    {
        id: "idSfxMoreNextSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/more_next.wav"
    },

    // click;  Go/Play

    {
        id: "idSfxGoPlayDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/go_play.wav"
    },
    {
        id: "idSfxGoPlayWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/go_play.wav"
    },
    {
        id: "idSfxGoPlaySC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/go_play.wav"
    },
    {
        id: "idSfxGoPlayGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/go_play.wav"
    },
    {
        id: "idSfxGoPlaySYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/go_play.wav"
    },

    // click;  Back

    {
        id: "idSfxBackDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/back.wav"
    },
    {
        id: "idSfxBackWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/back.wav"
    },
    {
        id: "idSfxBackSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/back.wav"
    },
    {
        id: "idSfxBackGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/back.wav"
    },
    {
        id: "idSfxBackSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/back.wav"
    },

    // click;  Tutorial

    {
        id: "idSfxTutorialDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/tutorial.wav"
    },
    {
        id: "idSfxTutorialWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/tutorial.wav"
    },
    {
        id: "idSfxTutorialSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/tutorial.wav"
    },
    {
        id: "idSfxTutorialGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/tutorial.wav"
    },
    {
        id: "idSfxTutorialSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/tutorial.wav"
    },

    // click;  Finish/Save

    {
        id: "idSfxFinishSaveDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/finish_save.wav"
    },
    {
        id: "idSfxFinishSaveWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/finish_save.wav"
    },
    {
        id: "idSfxFinishSaveSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/finish_save.wav"
    },
    {
        id: "idSfxFinishSaveGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/finish_save.wav"
    },
    {
        id: "idSfxFinishSaveSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/finish_save.wav"
    },

    // popup: error

    {
        id: "idSfxPopupErrorDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/popup_error.wav"
    },
    {
        id: "idSfxPopupErrorWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/popup_error.wav"
    },
    {
        id: "idSfxPopupErrorSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/popup_error.wav"
    },
    {
        id: "idSfxPopupErrorGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/popup_error.wav"
    },
    {
        id: "idSfxPopupErrorSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/popup_error.wav"
    },

    // popup: question

    {
        id: "idSfxPopupQuestionDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/popup_question.wav"
    },
    {
        id: "idSfxPopupQuestionWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/popup_question.wav"
    },
    {
        id: "idSfxPopupQuestionSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/popup_question.wav"
    },
    {
        id: "idSfxPopupQuestionGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/popup_question.wav"
    },
    {
        id: "idSfxPopupQuestionSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/popup_question.wav"
    },

   // click: home

    {
        id: "idSfxHomeDEF",
        folder: defaultAudioFolder,
        fileName: themeFolderDEFAULT + "/home.wav"
    },
    {
        id: "idSfxHomeWV",
        folder: defaultAudioFolder,
        fileName: themeFolderWAVY + "/home.wav"
    },
    {
        id: "idSfxHomeSC",
        folder: defaultAudioFolder,
        fileName: themeFolderSPACECAT + "/home.wav"
    },
    {
        id: "idSfxHomeGAM",
        folder: defaultAudioFolder,
        fileName: themeFolderGAMERZ + "/home.wav"
    },
    {
        id: "idSfxHomeSYN",
        folder: defaultAudioFolder,
        fileName: themeFolderSYNTHWAVE + "/home.wav"
    },
    {
        id: "idSfxClickAnimChestOpen",
        folder: sfxFolderISIP,
        fileName: "anim_chest_open.mp3"
    },
    {
        id: "idSfxClickAnimClosing",
        folder: sfxFolderISIP,
        fileName: "anim_closing.mp3"
    },
    {
        id: "idSfxClickAnimFinished",
        folder: sfxFolderISIP,
        fileName: "anim_finished.mp3"
    },
    {
        id: "idSfxClickButtonSelectMajor",
        folder: sfxFolderISIP,
        fileName: "button_select_major_hi.mp3"
    },
];

export const audioLanguageMap: AudioLanguageMapping[] = [
    {
        descriptor: AudioDescriptor.HINT_MINUTES_LEARNING,
        spanish: AudioDescriptor.HINT_MINUTES_LEARNING_ESP
    },
    {
        descriptor: AudioDescriptor.HINT_REWARDS,
        spanish: AudioDescriptor.HINT_REWARDS_ESP
    },
    {
        descriptor: AudioDescriptor.HINT_HIGHEST_SCORE,
        spanish: AudioDescriptor.HINT_HIGHEST_SCORE_ESP
    },
    {
        descriptor: AudioDescriptor.HINT_NEEDS_PRACTICE,
        spanish: AudioDescriptor.HINT_NEEDS_PRACTICE_ESP
    },
    {
        descriptor: AudioDescriptor.HINT_MINUTES_LEARNING,
        spanish: AudioDescriptor.HINT_MINUTES_LEARNING_ESP
    },
    {
        descriptor: AudioDescriptor.HINT_RESULTS,
        spanish: AudioDescriptor.HINT_RESULTS_ESP
    },

];

export default audioResources;
import { FeatureFlagService } from './../services/feature-flag.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard  {
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    //check if we have the flag, if we don't check if we have a redirect in data,
    //if we do, return the redirect path, if we don't, return false.
    return this.featureFlagService.isFlagEnabled(route.data.flags)
      ? true
        : route.data.featureFlagRedirect
          ? this.router.createUrlTree([route.data.featureFlagRedirect])
            : false;
  }
}

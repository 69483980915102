import { Component, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
	selector: 'swe-count-down',
	template: `
      <div class="timer">
        <span class="number">{{minutes}}</span><span class="unit">m </span>
        <span class="number">{{seconds < 10 ? '0' + seconds: seconds}}</span><span class="unit">s</span>
      </div>
`,
	styles: [`
		.timer {
      text-align: center;
      color: #555C6F;
      font-family: Arial, sans-serif;
      font-size: 1.8em;
      letter-spacing: -1px;
    }
    .number {
      font-weight: 600;
      font-size: 1.1em;
      margin: 0 3px 0 15px;
    }
    .unit {
      font-weight: 100;
    }
	`],
})
export class CountDownComponent implements OnInit, AfterViewInit, OnDestroy{
  //how many seconds to count down
  @Input() countdownLength = 0;

  minutes = 0;
  seconds = 0;

  countdownInterval!: number;
  
  constructor() {}

  ngOnInit(): void {
    this.updateSections();
  }

  ngAfterViewInit(): void {
    this.countdownInterval = window.setInterval(() => { this.updateCountdown()}, 1000);
  }

  ngOnDestroy(): void {
    window.clearInterval(this.countdownInterval);
  }

  updateCountdown(): void{
    --this.countdownLength;
    if(this.countdownLength > 0) {
      this.updateSections();
    } else {
      // console.log("We're done, clearing intreval now.");
      this.minutes = this.seconds = 0;
      window.clearInterval(this.countdownInterval);
    }
  }

  updateSections(): void{
    this.minutes = Math.trunc(this.countdownLength / 60);
    this.seconds = this.countdownLength % 60;
  }
}

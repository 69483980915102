export enum TypeCode {
    tc_unknown      = 0x00,
    tc_longlong	    = 0x01, 	// \x01
    tc_long	        = 0x02, 	// \x02
    tc_short	    = 0x03, 	// \x03
    tc_char	        = 0x04, 	// \x04
    tc_float	    = 0x05, 	// \x05
    tc_double	    = 0x06, 	// \x06
    tc_string	    = 0x07, 	// \x07
    tc_wstring	    = 0x08, 	// \x08	or	\b
    tc_blob	        = 0x09, 	// \x09	or	\t
    tc_vector	    = 0x0A, 	// \x0A	or	\n
    tc_map	        = 0x0B, 	// \x0B	or	\v
    tc_other	    = 0x0C, 	// \x0C	or	\f
    tc_exception	= 0x0D, 	// \x0D	or	\r
    tc_null	        = 0x0E, 	// \x0E
    tc_end	        = 0x0F, 	// \x0F
    tc_date	        = 0x10, 	// \x10
    tc_time	        = 0x11, 	// \x11
    tc_time64	    = 0x12, 	// \x12
    tc_max      	= 0xFF 	    // \xFF
}

export enum TypeCodeSize {
    tc_char = 8,
    tc_short = 16,
    tc_long = 32,
    tc_date = 32,
    tc_map = 32,
    tc_string = 32, // <-- this is a minimum
    tc_vector = 32, // <-- this is a minimum
    tc_longlong = 64,
}
import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[sweTrapFocus]'
})
export class TrapFocusDirective implements AfterViewInit, OnDestroy {

  observer!: MutationObserver;
  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.observer = new MutationObserver(() => this.trapFocus());
    this.observer.observe(this.el.nativeElement, { 
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  trapFocus(): void {
    const element: Element | null = this.el.nativeElement;
    const focusable = element?.querySelectorAll(
      'a[href]:not(:disabled), button:not(.btn-close):not(:disabled), textarea:not(:disabled), input[type="text"]:not(:disabled),' +
      'input[type="radio"]:not(:disabled), input[type="checkbox"]:not(:disabled), select:not(:disabled)'
    );
    if(element && focusable) {
      //clear old event listeners from natveElement
      if(element.removeAllListeners){
        element.removeAllListeners();
      }
      const focusableElements = Array.from(focusable).map(el => el as HTMLElement);
      const [firstFocusableElmt] = focusableElements;
      const [lastFocusableElmt] = focusableElements.slice(-1);
      //refocus if the active element isn't a focusable element
      if (!focusableElements.includes(document.activeElement as HTMLElement)){
        firstFocusableElmt?.focus();
      }
      element.addEventListener('keydown', (event) => {
        const ev = event as KeyboardEvent;
        if (ev.key !== "Tab") return;
        if (ev.shiftKey) {
          if (document.activeElement === firstFocusableElmt) {
            lastFocusableElmt.focus();
            ev.preventDefault();
          }
        } else { 
          if (document.activeElement === lastFocusableElmt) {
            firstFocusableElmt.focus();
            ev.preventDefault();
          }
        }
      });
    }
  }
}

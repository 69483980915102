import { LanguageCodes } from "../../../enums";
import { LearningTargetDto } from "../../../types";
import { AnswerStateEnum, ConstructedResponse, ContentManifest } from "../enums";
import { SeriesCard } from "../types";


export class EpisodeCard {
  id = '0';
  uniqueId = '';
  title = '';
  episodeNumber = 1;
  numQuestions = 0;
  languageCode?: string | null = 'en_US';
  progressDetails?: AnswerStateEnum[];
  score?: number;
  learningTargets: LearningTargetDto[] | null = [];
  image?: string;
  description?: string; // description of what happens in lesson
  grades?: number[];
	lastOpened?: Date;
  manifest: string[] = [];
  teiTypes: string[] = [];
  domain?: string;
  standardName?: string;

  constructor(cardData?: Partial<EpisodeCard>) {
    return Object.assign(this, cardData);
  }

  /** The ISO 639-1 language 2 letter code */
  get language(): LanguageCodes {
    const code = this.languageCode?.slice(0, 2);
    return code && [...Object.values<string>(LanguageCodes)].includes(code)
      ? code as LanguageCodes
      : LanguageCodes.English;
  }

  get completed() {
    // progress details will have the same size and number of questions
    // but if question is not answered then it will have a value of -1
    return (this?.progressDetails?.filter(d=>d > - 1)?.length ?? 0) >= this.numQuestions;
  }

  /**
   * The user's calculated progress
   * Based on number of questions and progress details
   */
  get progress(): number {
    const clamp = (value: number, valueMin:number, valueMax:number) => Math.max(valueMin, Math.min(value, valueMax));
    const actualProgress = this.progressDetails?.filter(p => p !== AnswerStateEnum.UNANSWERED);
    return this.numQuestions > 0 && actualProgress
      ? clamp(Math.round((actualProgress.length / this.numQuestions) * 100), 0, 100)
        : 0;
  }

  get completedScore(): string {
    return this.score === undefined || this.progress !== 100
      ? '——'
        : `${Math.round(this.score)}%`;
  }

  /** Localized last opened date  */
  get formattedDate(): string {
    const l10nCode = this.languageCode?.toLowerCase().replace("_","-") ?? "en-us";
    return this.lastOpened ? new Intl.DateTimeFormat(l10nCode, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(this.lastOpened) : '';
  }

  get learningTarget(): string {
    return this.learningTargets && this.learningTargets.length > 0 ? (this.learningTargets[0].learningTargetStatement ?? '') : '';
  }

  get contains(): ContentManifest[] {
    return [
      // Check the manifest first
      ...this.manifest
        .filter(v => v in ContentManifest)
        .map(v => ContentManifest[v as keyof typeof ContentManifest]),
      // check the teiTypes
      ...(this.teiTypes
        .some(v => Object.values(ConstructedResponse).includes(v as ConstructedResponse)) ? [ContentManifest.CONSTRUCTED_RESPONSE] : [])
    ].sort((a,b) => a - b);
  }

  get hasVideo(): boolean {
    return this.contains.some(m => m === ContentManifest.VIDEO);
  }

  convertToSeriesCard(): SeriesCard {
    return {
      id: '0000',
      image: this.image,
      title: this.title,
      progress: this.progress,
      domain: this.domain,
      episodes: [this],
      standardName: this.standardName
    } as SeriesCard;
  }

}

import { KnowledgeBase, KnowledgeBaseModules, KnowledgeBaseStore, KnowledgeBaseStoreResponse, KnowledgeBaseValue } from "../../types";
import { APIHandler } from "../";
import { Injectable } from "@angular/core";

@Injectable()
export class KnowledgeBaseAPI implements KnowledgeBase {
    user_id = 25651;
    private path_hydrate = `sync`;
    private path_base = `knowledge-base`;
    private kb_cache?: KnowledgeBaseStore | null;
    private school_year = new Date().getFullYear();
    private apiHandler: APIHandler;

    constructor(){
        this.apiHandler = new APIHandler({
            baseUrl: 'https://api-priv.hydra.dev.istation.net/olpdata-priv-4kcfhmbzk9',
            // defaultHeaders: {
            //     "Authorization": "Bearer " + ""
            // }
        });
    }

    async getAll(): Promise<KnowledgeBaseModules | null | undefined> {
        let returnValue = null;
        const isCached = this.kb_cache;
        if (!isCached) {
            this.kb_cache = await this.retrieve();
        }
        returnValue = this.kb_cache?.knowledgeBase.modules;
        return returnValue;
    }

    async get(section: string, key: string): Promise<KnowledgeBaseValue> {
        let returnValue = null;
        const isCached = this.kb_cache;
        if (!isCached) {
            this.kb_cache = await this.retrieve();
        }
        const modules = this.getModules();
        if(modules && modules[section] && modules[section][key]){
            returnValue = modules[section][key];
        }
        return returnValue;
    }
    
    set(section: string, key: string, value: KnowledgeBaseValue): void {
        const modules = this.getModules() || {};
        if(Object.prototype.hasOwnProperty.call(modules, section)) {
            modules[section][key] = value;
        } else {
            modules[section] = { [key]: value };
        }
        this.kb_cache = {
            knowledgeBase: { 
                modules,
                removeSet: []
            },
        };
    }

    async sync(): Promise<void> {
        const path = this.buildPath();
        const modules = this.getModules() || {};
        await this.apiHandler.post(path, {data: {'knowledgeBase': { modules, removeSet:[] }}});
    }

    async setAndSync(section: string, key: string, value: KnowledgeBaseValue): Promise<void> {
        this.set(section, key, value);
        await this.sync();
    }

    retrieve(): Promise<KnowledgeBaseStoreResponse | null> {
        const path = this.buildPath(true);
        return this.apiHandler.get<KnowledgeBaseStoreResponse>(path);
    }

    private getModules(): KnowledgeBaseModules | undefined {
        return this.kb_cache?.knowledgeBase.modules;
    }

    private buildPath(hydrate: boolean = false){
        return `${this.path_base}${( hydrate ? '/' + this.path_hydrate : '' )}/${this.school_year}/${this.user_id}`;
    }
}
import { ScreenOrientationLockType } from '@swe/enums';
import { UrlService, ConfigurationService } from '@swe/services';
import {
  EmscriptenIstationModule,
  EmscriptenModuleDecorator,
} from '@swe/types';
import LC from '@swe/classes/runtime/lib/LaunchControl';
import { saveAs } from 'file-saver';
import { STATE_LAUNCH_BUTTON, LAUNCH_STATUS, ArgumentBuilder } from '@swe/classes/runtime';

/**
 * ============================================================================
 *  Core Module Decoration
 * ============================================================================
 */

const configureModule: EmscriptenModuleDecorator<EmscriptenIstationModule> = (
  module: EmscriptenIstationModule,
  urlService: UrlService,
  configService: ConfigurationService
) => {
  const LaunchControl = new LC();

  /**
   * Create arguments for the Istation WASM Runtime by passing compile-time 
   * arguments from URL query parameters.
   */
  const runtimeArguments = (() => {
    const queryArguments = new ArgumentBuilder(urlService.getQueryParams());
    const DEFAULT_ARGUMENTS = [
      '-c',
      `AppConfig[` +
        `CDNDomain?=${configService.config.cdnURL},` +
        `SendProxyURI?=${configService.config.servicesURL},` +
        `AuthorizationUrl=${configService.config.authorizationUrl},` +
        `LogoutTimeout=-1` +
        `],` +
        `AwsApi[UseAWS=false]`,
    ];
    return queryArguments.hasArguments()
      ? queryArguments.getArgs()
      : DEFAULT_ARGUMENTS;
  })();
  /**
   * ============================================================================
   * Getters / Setters
   * ============================================================================
   */
  Object.defineProperty(module, 'canvas', {
    get() {
      return document.getElementById('canvas') as HTMLCanvasElement | null;
    },
    configurable: true,
  });
  Object.defineProperty(module, 'launchButton', {
    get() {
      return document.getElementById('Launcharoo') as
        | HTMLButtonElement
        | HTMLInputElement
        | null;
    },
    configurable: true,
  });
  Object.defineProperty(module, 'compatibilityMessage', {
    get() {
      return document.getElementById('compatibilityMessage') as HTMLElement | null;
    },
    configurable: true,
  });

  /**
   * ============================================================================
   * Core Module Configuration
   * ----------------------------------------------------------------------------
   * This configuration object is used to decorate the EmscriptenIstationModule,
   * taking the existing emscripten module and redefining it with special
   * functions intended for Istation in the Browser with is a WASM-only
   * presentation of the runtime without the visual presentation wrapper
   * of the Student Experience. The module works as a decorator which
   * extends the core EmscriptenModule with additional functionality
   * specific to Istation's application.
   */
  return Object.assign(module, <EmscriptenIstationModule>({
    /**
     * ============================================================================
     * Root properties
     * ============================================================================
     */
    totalDependencies: 0,
    arguments: runtimeArguments,

    /**
     * ============================================================================
     * Pre-Run Functions
     * ----------------------------------------------------------------------------
     * Adds the necessary bootstrapping for some internal functions which are
     * needed for the runtime.
     * ============================================================================
     */
    preRun: [
      () => {
        let resizeTimeoutID: number | NodeJS.Timeout | null;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        module['populateLogFiles'] = () => {};

        /**
         * Add fullscreen event listener to react when the user leaves fullscreen mode
         */
        module['onFullscreen'] = (isFullscreen: boolean) => {
          const launchButton = module.launchButton as
            | HTMLButtonElement
            | HTMLInputElement;
          // If the launch button is labelled as "Reload", return
          if (launchButton?.value === STATE_LAUNCH_BUTTON.RELOAD.label) return;
          // Attempt to lock the screen orientation if the browser supports orientation locking
          if (isFullscreen && window.screen.orientation) {
            (
              window.screen.orientation as ScreenOrientation & {
                lock: (mode: ScreenOrientationLockType) => Promise<void>;
                unlock: Promise<void>;
              }
            )
              .lock(ScreenOrientationLockType.LANDSCAPE)
              .then(
                () => console.log('Screen orientation locked to landscape'),
                () =>
                  console.warn(
                    'Screen orientation lock is not supported or could not be changed'
                  )
              );
          }
          // If we're not switching to fullscreen mode, enable the fullscreen button
          if (!isFullscreen) {
            module.enableFullscreenButton();
            if (Object.prototype.hasOwnProperty.call(module, 'resizeCallback'))
              module.resizeCallback();
          }
          if (
            Object.prototype.hasOwnProperty.call(
              module,
              'html5_ui_onFullscreenChange'
            )
          )
            module.html5_ui_onFullscreenChange(isFullscreen);
        };

        /**
         * Add resize callback function to cleanup the canvas sizing as the browser window is resized
         */
        module['resizeCallback'] = () => {
          const canvas = module.canvas;
          const cRatio =
            canvas && canvas.height && canvas.width
              ? canvas.height / canvas.width
              : 0.75;
          const wRatio = window.innerHeight / window.innerWidth;
          let width: number =
            wRatio < cRatio ? window.innerHeight / cRatio : window.innerWidth;
          let height: number =
            wRatio < cRatio ? window.innerHeight : window.innerWidth * cRatio;
          if (width < 800 || height < 600) {
            width = 800;
            height = 600;
          }
          if (canvas && canvas.style) {
            canvas.style.width = width + 'px';
            canvas.style.height = height + 'px';
          }
          if (module['html5_ui_onResize']) {
            module.html5_ui_onResize(width, height);
          }
          if (module.graphics_markAsDirty) {
            module.graphics_markAsDirty();
          }
        };

        // Add event listener to trigger resize callback
        window.addEventListener('resize', () => {
          if (resizeTimeoutID) {
            clearTimeout(resizeTimeoutID);
            resizeTimeoutID = null;
          }
          resizeTimeoutID = setTimeout(() => {
            module.resizeCallback();
            resizeTimeoutID = null;
          }, 500);
        });
      },
    ],

    /**
     * ============================================================================
     * Core Module Decorations
     * ============================================================================
     */
    /**
     * Manually set the canvas dimensions
     * @param width
     * @param height
     */
    setCanvasDims(width: number, height: number) {
      Object.assign(module.ISVARS, { w: width, h: height });
    },

    /**
     * Setup the initial button for launching the application
     * @param startFullscreen Whether or not to start the application in fullscreen mode
     */
    enableInitialLaunchButton: (startFullscreen: boolean) => {
      const launch = module.launchButton;
      LaunchControl.status = LAUNCH_STATUS.LOADED.name;
      module.ISVARS.UserConfigFullscreen = startFullscreen;
      if (launch) {
        launch.style.display = 'initial';
      }
      // the user previously logged in with SSO, let them know they may want to do it again
      if ( window.localStorage.showPrevSSOMessage ) {
        module.updateCompatibilityMessage(`<H2 style='text-align: center;'>Single Sign-on Detected</H2>
            <p style="color:black;text-align: center;">Your last login used a single sign-on provider. 
            To continue using single sign-on, close this browser tab and reopen Istation from your single sign-on account.
            <p style="color:black;text-align: center;">To sign in using your domain, username, and password, click “Launch Istation” below.<p>`, 'error');
      }
    },

    /**
     * Turns the Launch Istation button into a "Fullscreen" button
     */
    enableFullscreenButton: () => {
      const launchButton = module.launchButton;
      module.ISVARS.UserConfigFullscreen = true;
      if (launchButton) {
        launchButton.style.display = 'initial';
        launchButton.value = STATE_LAUNCH_BUTTON.FULLSCREEN.label;
      }
    },

    /**
     * Enable the reload button replacing the Lauunch label with "Reload"
     */
    enableReloadButton: () => {
      const launchButton = module.launchButton;
      // The SSO token is in use, we need the student to use the SSO system to re-login.
      if (window.localStorage.sso_token) {
        const launchButtonElement = module.launchButton;
        const launchStatusWrapElement =
          document.getElementById('launchStatusWrap');
        module.updateCompatibilityMessage(`<H2 style="text-align: center;">Thank you for using Istation.</H2>
          <p style="color:black;text-align: center;">To begin a new session, please close this browser tab and relaunch Istation.<p>`,
          'error'
        );
        launchButtonElement?.classList.add('compatibility-hidden');
        launchStatusWrapElement?.classList.add('compatibility-hidden');
      } else if (launchButton) {
        launchButton.style.display = 'initial';
        launchButton.value = STATE_LAUNCH_BUTTON.RELOAD.label;
      }
    },

    /**
     * Hide the WASM Runtime canvas
     */
    disableCanvas: function () {
      const canvas = module.canvas;
      if (canvas) canvas.style.display = 'none';
      if (
        document.fullscreenElement !== null &&
        typeof document.exitFullscreen === 'function'
      )
        document.exitFullscreen();
    },

    /**
     * Behavior for handling the launch button click event
     */
    onLaunchButtonClicked: (): void => {
      const launchButton = module.launchButton;
      const compatibilityMessageElement = module.compatibilityMessage;
      const timeNow = Date.now();
      const timeThen = window.localStorage.sso_time;
      if (launchButton?.value === STATE_LAUNCH_BUTTON.RELOAD.label) {
        location.reload();
        return;
      }
      // Check SSO token expiration
      if (window.localStorage.sso_token && timeThen) {
        const deltaMinutes = (timeNow - timeThen) / 1000 / 60;
        const tokenTimeout = 10; // minutes
        if (deltaMinutes > tokenTimeout) {
          const launchStatusWrap =
            document.getElementById('launchStatusWrap');
          module.updateCompatibilityMessage(`<H2 style="text-align: center;">Session Expired</H2>
            <p style="color:black;text-align: center;">Your session has expired. Please close this browser tab then relaunch Istation.<p>`,
            'error'
          );
          launchButton?.classList.add('compatibility-hidden');
          launchStatusWrap?.classList.add('compatibility-hidden');
          return;
        }
      }
      // don't keep nagging if the user clicks the launch button. They may be using login cards.
      delete window.localStorage.showPrevSSOMessage;
      // Reset any compatibility messages that were previously there (like the SSO warning)
      if (compatibilityMessageElement)
        compatibilityMessageElement.innerHTML = '';
      LaunchControl.status = LAUNCH_STATUS.LOADING.name;
      module.onLaunchIstation();
    },

    /**
     * Setup Launch for Istation WASM Runtime
     */
    onLaunchIstation: (): void => {
      const canvas = module.canvas;
      // Await a user interaction to enable full screen and audio
      if (module['UnlockUserInteraction']) {
        module.UnlockUserInteraction();
      }
      // turn on the canvas, and turn off the Launch Button
      if (canvas) {
        canvas.style.display = 'block';
      }
      LaunchControl.status = LAUNCH_STATUS.LAUNCHED.name; // setLaunchWrapperToHasLaunched();
      if (module.ISVARS.UserConfigFullscreen) {
        module.requestFullscreen(false, false);
      } else {
        // Enable fullscreen by default
        module.enableFullscreenButton();
      }
    },

    /**
     * Download the log file for the WASM Runtime
     */
    downloadLog: (passedValue = '/data/Istation.log'): void => {
      const logListBox = document.getElementById(
        'logListBox'
      ) as HTMLSelectElement | null;
      const { value } = logListBox ?? {value: passedValue}; // the full path /data/Istation.log
      const name = logListBox?.options[logListBox.selectedIndex].text ?? 'Istation.log'; // just the name: Istation.log
      const logText = module.readUtf8File(value);
      const blob = new Blob([logText], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, name);
    },

    /**
     * Set the status text and progress indicator
     * @param text The text to display in the status
     */
    setStatus: (text: string): void => {
      const statusElement = document.getElementById('status');
      const progressElement = document.getElementById('progress');
      const spinnerElement = document.getElementById('spinner');
      if (!module.setStatus.last) {
        module.setStatus.last = { time: Date.now(), text: '' };
      }
      if (text !== module.setStatus.last.text) {
        const m = text.match(/([^(]+)\((\d+(\.\d+)?)\/(\d+)\)/);
        const now = Date.now();
        if (m && now - module.setStatus.last.time < 30) return; // if this is a progress update, skip it if too soon
        module.setStatus.last.time = now;
        module.setStatus.last.text = text;
        if (m) {
          text = m[1];
          if (progressElement) {
            Object.assign(progressElement, {
              value: parseInt(m[2], 10) * 100,
              max: parseInt(m[4], 10) * 100,
              hidden: false,
            });
          }
          if (spinnerElement) spinnerElement.hidden = false;
        } else {
          if (progressElement) {
            Object.assign(progressElement, {
              value: null,
              max: null,
              hidden: true,
            });
          }
          if (!text && spinnerElement) spinnerElement.hidden = true;
        }
        if (statusElement) statusElement.innerHTML = text;
      }
    },

    /**
     * Monitor and update the status of the runtime dependencies as they are loaded
     * @param left A number to indicate how many dependencies are left to load
     */
    monitorRunDependencies: (left: number) => {
      module.totalDependencies = Math.max(module.totalDependencies, left);
      module.setStatus(
        left
          ? `Preparing... (${module.totalDependencies - left}/${
              module.totalDependencies
            })`
          : 'All downloads complete.'
      );
    },

    /**
     * Handle the Debug command to open the debug console
     */
    handleHtmlOnlyCmds(command: string) {
      return command && command === 'Debug';
    },

    updateCompatibilityMessage: (message: string, status: string) => {
      const messageElement = module.compatibilityMessage;
      if (messageElement) {
        messageElement.innerHTML = message;
        messageElement.classList.add(`compatibility-${status}`);
      }
    }
  } as unknown));
};

export { configureModule as default, configureModule };

import { Component } from '@angular/core';

@Component({
  selector: 'swe-loading',
  template: `
  <div>
    <img
      src="/assets/icon-kid-cape.png"
      width="147"
      height="164"
      aria-label="Child wearing a red cape"
    />
    <swe-progress-bar></swe-progress-bar>
    <em id="status">Loading...</em>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        z-index: var(--z-index-wasm);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: var(--sys-dialog-backdrop-bg-filter);
          background: var(--sys-dialog-backdrop-bg);
        }
      }
      div {
        z-index: 1;
        max-width: 25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      #status {
        color: white;
      }
    `,
  ],
})
export class LoadingComponent {}

import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import {
  ConfigDataService,
  ConfigurationService,
  FeatureFlagService,
  InactivityService,
  UserService,
  WasmService,
} from './';
import activityActions from '@swe/data/activity-actions';
import paths from '@swe/data/routing-paths';

@Injectable({
  providedIn: 'root',
})
export class ActivityLauncherService {
  private configService = inject(ConfigurationService);
  private configDataService = inject(ConfigDataService);
  private flags = inject(FeatureFlagService);
  private inactivityService =  inject(InactivityService);
  private router = inject(Router);
  private userService = inject(UserService);
  private wasmService = inject(WasmService);
  
  private document = inject(DOCUMENT);
  private window = inject(WINDOW);
  
  private readonly flagLookup: {[key: string]: (grade: number) => string} = {
    'Lectura': (grade: number) => this.determineLecturaWebIsipFlag(grade),
    'Matemáticas': (grade: number) => this.determineMatematicasWebIsipFlag(grade),
    'Math': (grade: number) => this.determineMathWebIsipFlag(grade),
    'Reading': (grade: number) => this.determineReadingWebIsipFlag(grade),
  }

  launchActivity(link: string[]) {
    this.inactivityService.recordActivity();

    // console.log('>> Launching activity', link);
    if (!link?.length) {
      // console.warn('No link provided')
      return;
    }

    switch (link[0]) {
      case 'wasm':
        this.launchWasmActivity(link.slice(1));
        break;
      case 'wasmr':
        this.launchWasmRange(link.slice(1));
        break;
      case 'menu':
        this.goToRoute(link.slice(1));
        break;
      case 'verbals':
        this.goToVerbal(link);
        break;
      case 'isip':
        this.goToIsip(link[1] || '');
        break;
      case 'webIsip':
        this.goToWebIsip(link.slice(1));
        break;
      case 'isip-skip-flow':
        this.goToIsip(link[1]);
        break;
      default:
        this.goToLinearPath(link[0]);
    }
  }

  launchWasmActivity(link: string[]) {
    const activity = link[0];
    const returnPath = link[1] || '';
    this.wasmService.executeActivity(activity, returnPath);
  }

  launchWasmRange(link: string []) {
    const start = link[0];
    const end   = link[1];
    const stopBefore = link[2] === 'true';
    const returnPath = link[3] || '';
    this.wasmService.executeActivityRange(start, end, stopBefore, returnPath);
  }

  private goToVerbal(link: string[]): void {
    const str = link[1]?.toLowerCase()
    if (!str) {
      return;
    }

    const returnPath = this.window.location.origin;
    this.goToIsipUrl(str, returnPath);
  }

  private goToWebIsip(link: string[]): void {
    if (link.length != 2) {
      return;
    }
    const returnPath = this.window.location.origin + '/' + link[0].toLowerCase();
    const shortcut = link[1].toLowerCase();
    this.goToIsipUrl(shortcut, returnPath);
  }

  goToIsipUrl(shortcut: string, returnPath: string): void {
    const url = new URL(this.configService.config.webIsipURL);
    const params = new URLSearchParams({
      shortcut: shortcut,
      returnUrl: returnPath,
    });
    if (this.wasmService.hasEffectiveDate()) {
      const dateOverride = this.wasmService
        .getEffectiveDate()
        .toLocaleDateString('en-US',  { year: 'numeric', month: '2-digit', day: '2-digit' });
      params.set('applicationDateOverride', dateOverride);
    }
    url.search = params.toString();
    // console.log('%c goToIsipUrl => ', 'background:yellow;color:black;', {shortcut}, {returnPath}, {url}, this.window.location.origin);
    this.goToUrl(url);
  }

  goToUrl(url: URL, doUseFullReferrer: boolean = false): void {
    if (doUseFullReferrer) {
      const a = this.document.createElement('a');
      a.style.display = 'none';
      a.setAttribute('href', url.toString());
      a.setAttribute('referrerpolicy', 'unsafe-url')
      this.document.body.appendChild(a);
      a.click();
    } else {
      this.window.location.href = url.href;
    }
  }

  goToRoute(link: string[], query?: Params): void {
    // console.log('going to', {link});
    this.router.navigate(link, {
      queryParams: query,
    });
  }

  goToLinearPath(link: string): void {
    const navTo = link.charAt(0).toUpperCase() + link.slice(1);
    const route = paths[`${navTo}Linear`]
    this.wasmService.executeActivity(route);
  }

  goToAction(subject: string, detail: string): void {
    const navTo = subject.charAt(0).toUpperCase() + subject.slice(1);
    const action = activityActions[`${navTo}${detail}`];
    this.launchActivity(action);
  }
  
  async goToIsip(subject: string): Promise<void> {
    this.goToAction(subject, await this.useWebIsip(subject) ? 'WebIsip' : 'Isip');
  }

  goToModeling(subject: string): void {
    this.goToAction(subject, 'Modeling');
  }

  /**
   * Determines if the subject is available via web isip to the user
   * @returns true if this should use web ISIP
   */
  async useWebIsip(subject: string): Promise<boolean> {
    // This is a feature that only exists in Early Reading. Any future additions to theis feature 
    // would be done diretcly in WebISIP, so we don't neeed to check for others
    if (subject.toLowerCase() === 'reading' && this.userService.grade <= 3) {
      const override = await this.configDataService.get('Screeners', 'InstructionsLang');
      if (override && override.toString().toLowerCase() !== 'en') {
        return Promise.resolve(false);
      }
    }
    
    const flag = this.determineWebIsipFlag(subject, this.userService.grade);
    return Promise.resolve(this.flags.isFlagEnabled(flag));
  }

  determineWebIsipFlag(subject: string, grade: number): string {
    if (Object.prototype.hasOwnProperty.call(this.flagLookup, subject)) {
      return this.flagLookup[subject](grade);
    }
    return '';
  }

  private determineLecturaWebIsipFlag(grade: number): string {
    // there's overlap with 2-3 and the other two flags because reasons
    return grade >=  2 && grade <= 3 ? 'enableWebIsipLt23' :
           grade >= -1 && grade <= 2 ? 'enableWebIsipLt' :
           grade >=  4 							 ? 'enableWebIsipLa' :
                                        '';
  }

  private determineMatematicasWebIsipFlag(grade: number): string {
    return grade >= -1 && grade <= 1 ? 'enableWebIsipMatesPk1' :
           grade >=  2 && grade <= 5 ? 'enableWebIsipMates25' :
                                       '';
  }

  private determineMathWebIsipFlag(grade: number): string {
    return grade >= -1 && grade <= 1 ? 'enableWebIsipMathPk1' :
           grade >=  2 && grade <= 8 ? 'enableWebIsipMath28' :
                                        '';
  }

  private determineReadingWebIsipFlag(grade: number): string {
    return grade >= -1 && grade <= 3 ? 'enableWebIsipEr' :
           grade >=  4 							 ? 'enableWebIsipAr' :
                                        '';
  }
}

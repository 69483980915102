import {Component, Input} from '@angular/core';

@Component({
  selector: 'swe-wave-background-overlay',
  templateUrl: './wave-background-overlay.component.html',
  styleUrls: ['./wave-background-overlay.component.scss']
})
export class WaveBackgroundOverlayComponent {
  @Input() subjectName!: string;

}

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from './message.service';
import { MessagePopup } from '../../types';
import { AudioType } from '../../enums';
import { Themes } from '../../enums';
import { AudioService } from '../../services/audio.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageCodes } from '../../enums';
import { Router } from '@angular/router';

@Component({
  selector: 'swe-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: [
    '../../../styles/_dialog.scss',
    './message-popup.component.scss'
  ],
})
export class MessagePopupComponent implements OnDestroy  {

  public readonly idleMessageId = MessageCodes.APP_IDLE;
	public idleTimerSeconds = 270;
  
  public messageImagePath = "assets/error-images/";
  public buttonImagePath = "assets/icons/";

  public hidden = true;
  public fullscreenHidden = true;
  public hasLink = false;
  public hasLeftButtonImage = false;
  public hasRightButtonImage = false;
  public splitMessageFirst = "";
  public splitMessageSecond = "";

  public messagePopup!: MessagePopup;
  private subscriptions = new Subscription();

  private theme: Themes = Themes.DEFAULT;
  private idAudioEffect = "";
  
  constructor(
    public messageService: MessageService,
    public translate: TranslateService,
    private audioService: AudioService,
    private userService: UserService,
    private router: Router,
  ) {
    this.subscriptions.add(this.userService.theme.subscribe( str => {
      if (str) {
        const theme = str as Themes;
        if (theme !== this.theme) {
          this.theme = theme;
        }
      }
    }));

    this.subscriptions.add(this.messageService.messageData.subscribe( data => {
      if(data){
        this.messagePopup = data;
        this.formatText();
				this.formatAdditionalInfo();
        this.setPopupVisible(true);
        this.checkButtonImages();
        this.playInitialAudioEffect();
      }
    }));

    this.subscriptions.add(this.messageService.triggerData.subscribe( data => {
      if(data === 'OK'){
        this.clickButton(data);
      } else if(data === 'Trigger_LogOut'){
        this.setPopupVisible(false);
      }
    }));

    this.subscriptions.add(this.audioService.loadedAudioId.subscribe( id => {
      if (id) {
        // Have we been waiting for a sound to be loaded, and if so, is this it?
        if ((this.idAudioEffect !== "") && (this.idAudioEffect === id)) {
          this.audioService.playSound(id, true);
          this.idAudioEffect = "";    // Now we're no longer waiting...
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clickButton(buttonType: string): void {

    if(buttonType === "OK"){
      this.messageService.onOkClicked();
    }
    else if(buttonType === "Link"){
      window.open(this.messagePopup.hyperLink, "_self");
    }
    else if(buttonType.startsWith("Trigger")){
      this.sendTrigger(buttonType);
    }
    else if(buttonType === "Refresh"){
      window.location.reload();
    }

    this.setPopupVisible(false);
  }

  setPopupVisible(show: boolean): void {
    if(show && !this.router.url.includes('logout')){
      if(this.messagePopup.isFullScreen){
        this.fullscreenHidden = false;
      } else{
        this.hidden = false;
      }
    } else{
      this.hidden = true;
      this.fullscreenHidden = true;
    }
  }

  getErrorImagePath(): string {
    return this.messageImagePath + this.messagePopup.imagePath +".png";
  }

  getButtonImagePath(which: number): string {
    return this.buttonImagePath + this.messagePopup.buttonImages[which] + ".svg";
  }

  checkButtonImages(): void{
    this.hasLeftButtonImage = this.messagePopup.buttonImages[0] !== '';
    this.hasRightButtonImage = this.messagePopup.buttonImages[1] !== '';
  }

  formatText(): void {
    if(this.messagePopup.messageText.includes("<link>")){
      this.hasLink = true;
      this.splitMessageFirst = this.messagePopup.messageText.split("<link>")[0];
      this.splitMessageSecond = this.messagePopup.messageText.split("<link>")[1];
    }
    else{
      this.hasLink = false;
    }
  }

  sendTrigger(triggerName: string): void {
    this.messageService.sendTrigger(triggerName);
    this.clearTriggers();
  }

  clearTriggers(): void {
    this.messageService.clearTriggers();
  }

  private playInitialAudioEffect(): boolean {
    const result = this.audioService.initAudio(AudioType.audio_SfxPopup, this.messagePopup.audioDesc, this.theme);
    if (result.id !== "")
    {
      if (result.loaded) {

        this.audioService.playSound(result.id, true);
      }
      else {
        this.idAudioEffect = result.id;
      }
      return true;
    }
    return false;
  }

	formatAdditionalInfo(): void {
		const codeString = this.messagePopup.code.toString();
		const infoMap = this.messageService.miscInfoMap;	// I'm too lazy to keep typing this
		if(this.messagePopup.code === MessageCodes.APP_IDLE && infoMap.has(codeString)) {
			this.idleTimerSeconds = infoMap.get(codeString);
		}
		this.messageService.miscInfoMap = new Map();	// clear out map
	}
}

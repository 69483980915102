import { ConfigurationService } from './configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getLoginMessage, WS } from '../classes';
import { Domain, User } from '../types';
import { v4 as uuid } from 'uuid';
import { WSResponse, StudentSubscription } from '../types';
import { LoginCode, TypeCode } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class WsService {
  PRODUCT_VERSION = `2.0.29865.23`;
  PACKFILE_VERSION = `2`;
  BUILD_VERSION = 589892;
  REQUEST_HEADERS: HttpHeaders = new HttpHeaders({
    Accept: '*/*',
    'Content-Type': 'application/soap+xml; charset=utf-8',
  });
  SESSION_UUID: string;
  user: User | undefined;
  defaultValue = { value: 0 };

  constructor(private httpClient: HttpClient, private configService: ConfigurationService) {
    this.SESSION_UUID = uuid();
  }

  /**
   * Access the valid login domains for users
   *
   * @param searchChunk The string to search for in the domains
   * @returns Observable<>
   */
  getDomains(searchChunk: string = ''): Observable<WSResponse<Domain[]>> {
    const messageFactory: WS = new WS();
    Object.assign(messageFactory, {
      interface: 'Security',
      command: 'getDomains',
    });
    messageFactory.addValue(searchChunk, TypeCode.tc_string);
    messageFactory.addValue(0, TypeCode.tc_map);

    return this.httpClient
      .post(
        this.configService.config.servicesURL,
        this.createRequestBody(messageFactory.messageEncoded),
        {
          headers: this.REQUEST_HEADERS,
          responseType: 'text',
        }
      )
      .pipe(
        map((r) => {
          // First element is success/fail, second is element count, response comes as a key/value order
          const { values } = this.getResponseFromSOAP(r);
          const domains: Array<{id: string, value: string, name: string}> = [];
          const { value: success } = values.shift() || this.defaultValue;
          const { value: recordCount } = values.shift() || this.defaultValue;
          for (let i = 0; i < values.length; i += 2) {
            domains.push({
              id: uuid(),
              value: values[i].value,
              name: values[i + 1].value,
            });
          }
          return { success, data: domains, total: recordCount };
        })
      );
  }

  login(
    username: string,
    password: string,
    domainName: string
  ): Observable<WSResponse<{}>> {
    const messageFactory: WS = new WS();
    Object.assign(messageFactory, {
      interface: 'Security',
      command: 'login',
    });
    messageFactory.addValue(username.toLowerCase(), TypeCode.tc_string);
    messageFactory.addValue(password, TypeCode.tc_string);
    messageFactory.addValue(this.SESSION_UUID, TypeCode.tc_string); // <-- fromHost, this is an unique UUID for session
    messageFactory.addValue(0, TypeCode.tc_short); // <-- fromPort
    messageFactory.addValue(domainName, TypeCode.tc_string); // <-- domain
    messageFactory.addValue(3, TypeCode.tc_map); // <-- Relates to the following 3 key/value pairs
    messageFactory.addValue('IS_STRPRODUCTVER', TypeCode.tc_string);
    messageFactory.addValue(this.PRODUCT_VERSION, TypeCode.tc_string);
    messageFactory.addValue(`PackFileVersion`, TypeCode.tc_string);
    messageFactory.addValue(this.PACKFILE_VERSION, TypeCode.tc_string);
    messageFactory.addValue(`VERSION`, TypeCode.tc_string);
    messageFactory.addValue(this.BUILD_VERSION, TypeCode.tc_long);

    return this.httpClient
      .post(
        this.configService.config.servicesURL,
        this.createRequestBody(messageFactory.messageEncoded),
        {
          headers: this.REQUEST_HEADERS,
          responseType: 'text',
        }
      )
      .pipe(
        map((r) => {
          const { values } = this.getResponseFromSOAP(r);
          const { value: loginCode } = values.shift() || this.defaultValue;
          const { value: oid } = values.shift() || this.defaultValue;
          const { value: className } = values.shift() || this.defaultValue;
          const { value: description } = values.shift() || this.defaultValue;
          // pull the contents out of SOAP envelope

          const studentSub: StudentSubscription = <StudentSubscription>({
            subjects: [],
            isips: [],
            verbals: [],
          });

          this.user = {
            id: parseInt(oid, 10),
            userId: oid,
            username,
            fullName: description,
            // grade?: number;
            // token?: string;
            domain: domainName,
            isTeacher: false, //default to not teacher, will be overridden on wasm data retrieval. 
            className,
            sessionId: this.SESSION_UUID,
            subscription: studentSub,
          };

          // Values will be stored as LoginCode(1B), AuthToken/OID(4B), className(5B+varible), description(5B+varible), AvatarInfo(vector/4B+variable), count?(4B), IP Address for User(4B+variable), Date(4B, in seconds), SchoolYear(4B)
          return {
            success: LoginCode.loginOk === parseInt(loginCode, 10),
            status: LoginCode[loginCode],
            statusCode: parseInt(loginCode, 10),
            message: getLoginMessage(loginCode),
            data: this.user,
          };
        })
      );
  }

  getConfig(): Observable<WSResponse<{}>> {
    const messageFactory = new WS();
    Object.assign(messageFactory, {
      interface: 'Security',
      command: 'getConfigItems',
    });
    messageFactory.addValue(
      this.user?.domain || 'istation',
      TypeCode.tc_string
    );

    return this.httpClient
      .post(
        this.configService.config.servicesURL,
        this.createRequestBody(messageFactory.messageEncoded),
        {
          headers: this.REQUEST_HEADERS,
          responseType: 'text',
        }
      )
      .pipe(
        map((r) => {
          const { values } = this.getResponseFromSOAP(r);
          const { value: success } = values.shift() || this.defaultValue;
          return { success, data: values };
        }),
        catchError(this.handleError)
      );
  }

  getGlobalManifestChecksum(): Observable<WSResponse<string>> {
    const messageFactory = new WS();
    Object.assign(messageFactory, {
      interface: 'Supervisor',
      command: 'getGlobalManifestChecksum',
    });
    messageFactory.addValue(this.PACKFILE_VERSION, TypeCode.tc_string); // <-- PackFile version?
    return this.httpClient
      .post(
        this.configService.config.servicesURL,
        this.createRequestBody(messageFactory.messageEncoded),
        {
          headers: this.REQUEST_HEADERS,
          responseType: 'text',
        }
      )
      .pipe(
        map((r) => {
          const { values } = this.getResponseFromSOAP(r);
          const { value: success } = values.shift() || this.defaultValue;
          const { value: checksum } = values.shift() || this.defaultValue;
          return { success, data: checksum };
        }),
        catchError(this.handleError)
      );
  }

  getPermissions() {
    const messageFactory = new WS();
    Object.assign(messageFactory, {
      interface: 'Security',
      command: 'permissions',
    });
    messageFactory.addValue(this.user?.id, TypeCode.tc_long); // <-- PackFile version?
    messageFactory.addValue(this.user?.className, TypeCode.tc_string);
  }

  protected createRequestBody(contents: string) {
    const requestBody = `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      xmlns:xsd="http://www.w3.org/2001/XMLSchema"
      xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body><ISRequest xmlns="http://istation.com/ws"><input>${contents}</input></ISRequest></soap12:Body>
    </soap12:Envelope>`;
    return requestBody;
  }

  protected getResponseFromSOAP(contents: string) {
    // XPATH == soap:Envelope > soap:Body > ISRequestResponse > ISRequestResult
    const parser = new DOMParser();
    const xmldoc = parser.parseFromString(contents, 'text/xml');
    const encodedMessage = xmldoc.querySelector('ISRequestResult')?.textContent;
    const message = new WS(encodedMessage);
    return message;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(e: any) {
    return throwError(e);
  }
}

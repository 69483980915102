
import { inject, InjectionToken } from '@angular/core';
import { RuntimeLoginState, RuntimeStatus } from '@swe/enums';
import { concat, fromEvent, interval, Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';
import { CustomEventLoginStateChange } from '@swe/types';
import { DOCUMENT } from '@angular/common';

export const runtimeStatusFactory = () => {
  const win = inject(WINDOW);
  const doc =  inject(DOCUMENT);
  const interval$ = interval(500)
    .pipe(
      first(() => Object.hasOwn(win, '_isapp_module')),
      map(() => RuntimeStatus.LOADED),
      startWith(RuntimeStatus.NOT_READY)
    );
  const supervisorStream$: Observable<RuntimeStatus> = fromEvent<CustomEventLoginStateChange>(doc, "LoginStateChange")
    .pipe(
      map(({detail: {State: state}}) => {
        switch (state) {
          case RuntimeLoginState.NOT_READY:
            return RuntimeStatus.LOADED;
          case RuntimeLoginState.READY:
            return RuntimeStatus.SUPERVISOR_READY;
          case RuntimeLoginState.LOGIN_FAIL:
            return RuntimeStatus.SUPERVISOR_READY;
          case RuntimeLoginState.LOGIN_SUCCESS:
            return RuntimeStatus.SUPERVISOR_ACTIVE;
          case RuntimeLoginState.LOGOFF:
            return RuntimeStatus.SUPERVISOR_READY;
          case RuntimeLoginState.EXIT:
            return RuntimeStatus.LOADED;
          default: 
           return RuntimeStatus.NOT_READY;
        }
      })
    );
  return concat(interval$, supervisorStream$);
}

export const RUNTIME_STATUS = new InjectionToken<Observable<RuntimeStatus>>('Runtime Status', {
  factory: runtimeStatusFactory
});
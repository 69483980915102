import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LogService {
    log(message: string, level: string = "DEBUG") {
        const stamp = new Date();
        const body = `[${stamp.toTimeString()}] [${level.toLowerCase}] -- ${message}`;
        if (level === "DEBUG" ) {
            console.log(body);
        }

        // add additional logging levels to files & console here
    }

    debug(message: string) {
        this.log(message, "DEBUG");
    }
}
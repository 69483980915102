import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { WasmService } from '../../../services/';
import { environment } from 'projects/stage/src/environments/environment';
import { AudioType, AudioDescriptor, MessageCodes } from '../../../enums';
import { ProfilePopupAvatarChooser } from '../../../types';
import { TrapFocusDirective } from '../../directives/trap-focus.directive';
import { DataSourceType } from '../../../enums/dataSource';
import { MessageService } from '../../../features/message-popup/message.service';

@Component({
  selector: 'swe-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss'],
})
export class ProfilePopupComponent implements AfterViewInit {
  @Input() avatar = -1;
  @Input() studentName = 'Nobody';

  @Input() showProfile = false;
  @Output() showProfileChange = new EventEmitter<boolean>();

  @Input() avatarChooser: ProfilePopupAvatarChooser = {
    show: false,
    close: false,
  };
  @Output() avatarChooserChange = new EventEmitter<ProfilePopupAvatarChooser>();

  @Input() themeChooser = false;
  @Output() themeChooserChange = new EventEmitter<boolean>();

  @Input() supportMenu = false;
  @Output() supportMenuChange = new EventEmitter<boolean>();

  @ViewChild('btnAvatar') btnAvatar!: ElementRef;
  @ViewChild('btnSupport') btnSupport!: ElementRef;
  @ViewChildren(TrapFocusDirective) focused!: QueryList<TrapFocusDirective>;

  appVersion = environment.appVersion;
  // TODO: populate the dataSource via the facadeService...
  dataSource: DataSourceType = DataSourceType.LCM;
  clickType = AudioType.audio_SfxClick;
  clickDesc = AudioDescriptor.CLICK_AVATAR_THEME;
  clickClose = AudioDescriptor.CLICK_BACK;

  public wasmService = inject(WasmService);

  private messageService = inject(MessageService);

  ngAfterViewInit(): void {
    this.focused?.map((el) => el.trapFocus());
  }

  @HostListener('keydown.escape', ['$event'])
  closeProfile(): void {
    if (this.showProfile) {
      this.showProfile = false;
      this.showProfileChange.emit(this.showProfile);
    }
  }

  logoutPopup(): void {
    this.messageService.showMessage(MessageCodes.APP_LOGOUT_CONFIRM);
  }

  handleAvatarPopup(): void {
    const toggle = !this.avatarChooser.show;
    this.avatarChooserChange.emit({ show: toggle, close: true });
  }

  handleThemePopup(): void {
    const toggle = !this.themeChooser;
    this.themeChooserChange.emit(toggle);
  }

  handleSupportPopup(): void {
    const toggle = !this.supportMenu;
    this.supportMenuChange.emit(toggle);
  }

  returnFocus(to?: string): void {
    switch (to) {
      case 'support':
        this.btnSupport.nativeElement.focus();
        break;
      case 'avatar':
      default:
        this.btnAvatar.nativeElement.focus();
        break;
    }
  }
}

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { THEMES } from '@swe/data/themes';
import { FeatureFlagService , UserService } from '@swe/services';
import { AudioDescriptor } from '@swe/enums';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  selector: 'swe-theme-chooser',
  templateUrl: './theme-chooser.component.html',
  styleUrls: ['./theme-chooser.component.scss'],
})
export class ThemeChooserComponent implements OnInit, OnDestroy {
  public themes = THEMES.filter(({isDefault, isActive}) => isDefault && isActive);
  public currentTheme!: string;
  public themeFormControl = new UntypedFormControl();
  public clickClose = AudioDescriptor.CLICK_BACK;
  
  public userService: UserService = inject(UserService);
  public featureService: FeatureFlagService = inject(FeatureFlagService);

  private subs = new Subscription();

  ngOnInit(): void {
    this.subs.add(this.userService.theme.subscribe(t => this.currentTheme = t));
    this.subs.add(this.themeFormControl.valueChanges.subscribe((theme)=> this.userService.theme.next(theme)));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}

/**
 * "Age Appropriate" names for ISIP subtests and their 
 * corresponding abbreviations used for the Knowledge Base.
 */

export enum IsipSubtestName {

	/*****************************
	 *     Reading (ER & AR)     *
	 *****************************/
	CMP = "Comprehension",	// Reading Comprehension
	SPL = "Spelling",				// Spelling / Word Analysis
	TF  = "Fluency",				// Text Fluency
	VOC = "Vocabulary",			// Vocabulary

	/*****************************
	 *  Early Reading only (ER)  *
	 *****************************/
	AD  = "Word Work",								// Alphabetic Decoding = WNF
	LC2 = "Listening Comprehension",	// Listening Comprehension CAT
	LK  = "ABC's",										// Letter Knowledge = LR + LS
	PA  = "Sounds",										// Phonemic Awareness = BS + PB + RY + ES

	/*****************************
	 *      Reading Skills       *
	 * See `joe\ISIPERSkill.joe` *
	 *****************************/
	// For students who have gated out of PA but must still take it:
	PA_0 = "Sounds",
	// CMP study, we are including CMP only if it helps or is adhoc:
	CMP_Not_Included = "Comprehension Study",
	// For students taking the AD GK study:
	AD_Study = "Word Work Study",

	// Component subtests, hidden from end user. (these shouldn't show up)
	// LR = "Letter Recog Fluency", 				// Letter Recog Fluency
	// LS = "Letter Sound Fluency", 				// Letter Sound Fluency
	// BS = "Beginning Sound Fluency", 		// Beginning Sound Fluency
	// RY = "Rhyming",											// Rhyming
	// ES = "Ending Sound Fluency",				// Ending Sound Fluency
	// PB = "Phonemic Blending Fluency",		// Phonemic Blending Fluency
	// VOC1 = "Vocab word as in picture", 	// Vocab word as in picture
	// VOC2 = "Vocab synonym", 						// Vocab synonym
}


export enum IsipSubtestNameEs {

	/*****************************
	 *     Lectura (LA & LT)     *
	 *****************************/
	COMP = "Comprensión de lectura",	// Reading Comprehension
	FLU = "Fluidez",				// Fluency
	VOC = "Vocabulario",		// Vocabulary

	/*****************************
	 *  Early Lectura only (LT)  *
	 *****************************/
	COMP_AU = "Comprensión",		// Listening Comprehension (early lectura)
	ESC = "Escritura",					// "Written Communication"/Spelling (early lectura)
	FON = "Letras y sonidos",		// Phonemic/Phonological Awareness

	/*****************************
	 *   Adv Lectura only (LA)   *
	 *****************************/
	SPL = "Palabras",		// "Word Analysis"/Spelling

	/******************************
	 *      Lectura Skills        *
	 * joe\SpanishISIPERSkill.joe *
	 *****************************/
	// FON1 = "Letras y sonidos",
	// FON2 = "Letras y sonidos",
	// FON3 = "Letras y sonidos",
	// FON4 = "Letras y sonidos",
	// ESC1 = "Escritura",
	// ESC2 = "Escritura",
	// ESC3 = "Escritura",
	// ESC4 = "Escritura",
	// // conflict with English abbreviation:
	// VOC1_ES = "Vocabulario",
	// VOC2_ES = "Vocabulario",
}


export enum IsipSubtestNameMath {

	/*****************************
	 *           Math            *
	 *****************************/
	// ones with "*" were found in "QualifyingTestEngine.joe", as opposed to "ISIPMathSkill.joe" (but some are in the Google doc)
	CA  = "Operations and Patterns",			// Computations & Algebraic Thinking
	DA  = "Data Analysis",								// * Data Analysis
	G   = "Geometry",											// Geometry
	GM  = "Geometry",											// Geometry and Measurement (>=grade 6?)
	MD  = "Measurement",									// Measurement and Data Analysis
	NSE = "Number Sense",									// Number Sense
	NF  = "Fractions",										// * Number Sense - Fractions
	NSY = "Number System",								// Number System
	PFL = "Money Sense",									// Personal Financial Literacy
	P   = "Probability",									// Probability
	SDA = "Statistics and Data Analysis",	// Statistics and Data Analysis

	// Kindergarten-only differences:
	CA_K  = "Patterns",						// CA; Computations and Algebraic Thinking
	DA_K  = "Sorting",						// DA; Data Analysis
	G_K   = "Shapes",							// G; Geometry
	NSE_K = "Numbers and Sets",		// NSE; Number Sense
	MD_K  = "Measurement",				// MD; Measurement

	// these were found in joe code but don't show up in the KB so just in case...
	M   = "Measurement",		// * Measurement
	NS  = "Number Sense",		// * Number Sense / * Number System
}


export enum IsipSubtestNameMathEs {

	/******************************
	 *        Matematicas         *
	 ******************************/
	// ones with "*" were found in "QualifyingTestEngine.joe" as opposed to "ISIPMathSkill.joe" (but some are in the Google doc)
	CA  = "Operations and Patterns",			// Computations & Algebraic Thinking
	DA  = "Data Analysis",								// * Data Analysis
	G   = "Geometry",											// Geometry
	GM  = "Geometry",											// Geometry and Measurement (>=grade 6?)
	MD  = "Measurement",									// Measurement and Data Analysis
	NSE = "Number Sense",									// Number Sense
	NF  = "Fractions",										// * Number Sense - Fractions
	NSY = "Number System",								// Number System
	PFL = "Money Sense",									// Personal Financial Literacy
	P   = "Probability",									// Probability
	SDA = "Statistics and Data Analysis",	// Statistics and Data Analysis

	// Kindergarten-only differences:
	CA_K  = "Patterns",						// CA; Computations and Algebraic Thinking
	DA_K  = "Sorting",						// DA; Data Analysis
	G_K   = "Shapes",							// G; Geometry
	NSE_K = "Numbers and Sets",		// NSE; Number Sense
	MD_K  = "Measurement",				// MD; Measurement

	// these were found in joe code but don't show up in the KB so just in case...
	M   = "Measurement",		// * Measurement
	NS  = "Number Sense",		// * Number Sense / * Number System
}


/**
 * For English names, see
 * 		joe\ISIPERSkill.joe
 * 		joe\ISIPARSkill.joe
 * 		joe\SpanishISIPERSkill.joe
 * 		joe\ISIPARESPSkill.joe
 * 		joe\ISIPMathSkill.joe
 * 		joe\QualifyingTestEngine.joe
 * 
 * For Spanish names, see
 * 		joe\ISIPERSkill.joe
 * 		joe\ISIPARSkill.joe
 * 		joe\SpanishISIPERSkill.joe
 * 		joe\ISIPARESPSkill.joe
 * 		joe\ISIPMathSkill.joe
 * 		joe\QualifyingTestEngine.joe
 * 
 * 		https://docs.google.com/spreadsheets/d/1zfjKKpemrvhlszzJe5Z_NgvqvwKszRJqdLNjNg4NFxQ/edit#gid=2059250766
 */
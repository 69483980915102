export enum Platform {
    ep_unknown = 0,
    ep_windows,
    ep_linux,
    ep_macintosh,
    ep_macosx,
    ep_ios,
    ep_chrome,
    ep_android,
    ep_arc,
    ep_unity3d,
    ep_wasm,
    ep_macos11
}
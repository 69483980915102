export enum AppConfigParams {
  SectionName = "AppConfig",
  AllowAutomation = "AllowAutomation",
  AllowListeners = "AllowListeners",
  LogLevel = "LogLevel",
  NSHost = "NSHost",
  CDNDomain = "CDNDomain",
  SendProxyURI = "SendProxyURI",
  LogoutTimeout = "LogoutTimeout",
  AwsApiDomain = "AwsApiDomain",
  AwsKnowledgeBaseLocation = "AwsKnowledgeBaseLocation",
  AwsClickstreamLocation = "AwsClickstreamLocation",
  AwsConfigDataLocation = "AwsConfigDataLocation",
  WasmSSOSetting = "WasmSSOSetting",
  AuthorizationUrl = "AuthorizationUrl"
}

export enum UserConfigParams {
  SectionName = "UserConfig",
  DefaultDomain = "DefaultDomain"
}

export enum AudioParams {
  SectionName = "Audio",
  DebugLevel = "DebugLevel"
}

export enum NetStoreParams {
  SectionName = "netStore",
  ServerEp = "serverEP"
}

export enum JumpPathParams {
  StartPath = 'StartPath',
  EndPath = 'EndPath',
  IncludeEnd = 'IncludeEnd'
}

export enum IsipShortcutParams {
  EnglishOrf = 'isip_orf',
  SpanishOrf = 'isip_esp_orf',
  EnglishRan = 'isip_eng_ran',
  SpanishRan = 'isip_esp_ran',
	EnglishReading = 'isip_reading',
	SpanishReading = 'isip_esp',
	EnglishMath = 'isip_math',
  SpanishMath = 'isip_matematicas'
}

export enum BrowserCompatParams {
  IgnoreBrowser = 'IgnoreBrowser'
}

export enum DebugParams {
  UseNewRoutes = "UseNewRoutes",
}
import { Month } from "../enums/month";
import { IsipMonthInfo } from "../types";

const isipMonthData: IsipMonthInfo[][] = [
	[// 0; total overall scores earned (4-digits)
		{
			period: Month.August,
			value: 1575,	// (< 1695); Tier 3
		},
		{
			period: Month.September,
			value: 1665,	// (< 1707); Tier 3
		},
		{
			period: Month.November,
			value: 1696,	// (< 1720); Tier 3
		},
		{
			period: Month.January,
			value: 1860,
		},
		// {
		// 	period: Month.February,
		// 	value: 1871,	// (> 1847); Tier 1
		// },
	],
	[// 1; CMP scores earned (4-digits)
		{
			period: Month.August,
			value: 1650,
		},
		{
			period: Month.September,
			value: 1814,
		},
		{
			period: Month.October,
			value: 1844,
		},
		{
			period: Month.November,
			value: 1936,
		},
		{
			period: Month.January,
			value: 2076,
		},
	],
	[// 2; SPL scores earned (4-digits)
		{
			period: Month.August,
			value: 1602,
		},
		{
			period: Month.September,
			value: 1620,
		},
		{
			period: Month.October,
			value: 1645,
		},
		{
			period: Month.November,
			value: 1755,
		},
		{
			period: Month.January,
			value: 1771,
		},
	],
	[// 3; TF scores earned (4-digits); Note: TF subtest is not used in determining the Overall Reading ability index
		{
			period: Month.August,
			value: 46,
		},
		{
			period: Month.September,
			value: 54
		},
		{
			period: Month.October,
			value: 63,
		},
		{
			period: Month.November,
			value: 68,
		},
		{
			period: Month.January,
			value: 73,
		},
	],
	[// 4; VOC scores earned (4-digits)
		{
			period: Month.August,
			value: 1472,
		},
		{
			period: Month.September,
			value: 1562,
		},
		{
			period: Month.October,
			value: 1599,
		},
		{
			period: Month.November,
			value: 1749,
		},
		{
			period: Month.January,
			value: 1766,
		},
	],




	[// 5; total overall scores earned (3-digits)
		{
			period: Month.August,
			value: 181,
		},
		{
			period: Month.September,
			value: 188,
		},
		{
			period: Month.October,
			value: 200,
		},
		{
			period: Month.November,
			value: 185,
		},
		{
			period: Month.December,
			value: 190,
		},
		{
			period: Month.January,
			value: 195,
		},
		{
			period: Month.February,
			value: 208,
		},
	],
	[// 6; COM scores earned (3-digits)
		{
			period: Month.August,
			value: 189,
		},
		{
			period: Month.September,
			value: 112,
		},
		{
			period: Month.October,
			value: 211,
		},
		{
			period: Month.November,
			value: 235,
		},
		{
			period: Month.December,
			value: 246,
		},
		{
			period: Month.January,
			value: 164,
		},
		{
			period: Month.February,
			value: 244,
		},
	],
	[// 7; SPL scores earned (3-digits)
		{
			period: Month.August,
			value: 204,
		},
		{
			period: Month.September,
			value: 161,
		},
		{
			period: Month.October,
			value: 207,
		},
		{
			period: Month.November,
			value: 165,
		},
		{
			period: Month.December,
			value: 239,
		},
		{
			period: Month.January,
			value: 170,
		},
		{
			period: Month.February,
			value: 262,
		},
	],
	[// 8; TF scores earned (3-digits); Note: TF subtest is not used in determining the Overall Reading ability index
		{
			period: Month.August,
			value: 180,
		},
		{
			period: Month.September,
			value: 190,
		},
		{
			period: Month.October,
			value: 200,
		},
		{
			period: Month.November,
			value: 190,
		},
		{
			period: Month.December,
			value: 190,
		},
		{
			period: Month.January,
			value: 200,
		},
		{
			period: Month.February,
			value: 200,
		},
	],
	[// 9; VOC scores earned (3-digits)
		{
			period: Month.August,
			value: 261,
		},
		{
			period: Month.September,
			value: 234,
		},
		{
			period: Month.October,
			value: 200,
		},
		{
			period: Month.November,
			value: 133,
		},
		{
			period: Month.December,
			value: 165,
		},
		{
			period: Month.January,
			value: 152,
		},
		{
			period: Month.February,
			value: 403,
		},
	],
];

export default isipMonthData;
<article
  class="usage-meter-container usage-meter-{{ subjectName.toLowerCase() }}"
  [style.--progress]="progress"
  [attr.aria-label]="
    'Your weekly goal is ' +
    goalValue +
    ' mins. You have completed ' +
    userValue +
    ' mins.'
  "
>
  <header class="title">
    {{ title }}
    <button class="btn-tooltip" type="button">Hint</button>
    <div class="hint-wrapper">
      {{hint}}
    </div>
  </header>
  <div class="usage-meter-wrapper" [ngClass]="{ 'goal-met': hasMetGoal }">
    <span class="tooltip" [ngClass]="{ 'anims': startAnimation }">{{ goalValue ? 0 : NO_DATA_LABEL }}</span>
    <span class="bg-bar"></span>
    <span class="bar" [ngClass]="{ 'anims': startAnimation }"></span>
    <div class="goal">
      <span class="goal-label">{{ label }}</span>
      <span class="goal-value">{{
        goalValue ? goalValue : NO_DATA_LABEL
      }}</span>
      <lottie-player 
        *ngIf="hasMetGoal"
        class="goal-icon"
        src="/assets/lottie-files/trophy.json"
        [autoplay]="true"
        [loop]="false">
      </lottie-player>
      <lottie-player *ngIf="!hasMetGoal" class="goal-icon"></lottie-player>
    </div>
    <div *ngIf="!hasMetGoal" class="goal-circle">
      <img src="/assets/icons/trophy-empty.svg" class="trophy-image">
    </div>
  </div>
</article>

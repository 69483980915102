import { Base } from "../types";

const Activities: Base[] = [
    {
        id: 856456824,
        name: "Bot Battle Training",
        description: "Learn about place value through the thousands place in both standard form and expanded form."
    },
    {
        id: 520442254,
        name: "Bot Battle Exploration",
        description: "Deepen your understanding of place value to the thousands place."
    },
    {
        id: 55787752669,
        name: "Skill Up Challenge",
        description: "Expand your place value to the thousands place power!"
    },
    {
        id: 881252366,
        name: "Bot Battle Practice",
        description: "Practice place your place value to the thousands place before your mission."
    },
    {
        id: 677895599,
        name: "Bot Battle",
        description: "Use your knowledge of place value through the thousands place to power up your germs, take on the XO-bots, and defeat Mr. X!"
    }
];

export default Activities;
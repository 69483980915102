<nav class="carousel-nav">
  <button
    *ngIf="showPrev"
    type="button"
    class="carousel-nav-prev"
    (click)="scroll($event)"
  >{{ 'BUTTONS.PREVIOUS' | translate }} </button>
  <button
    *ngIf="showNext"
    type="button"
    class="carousel-nav-next" 
    (click)="scroll($event)"
  >{{ 'BUTTONS.NEXT' | translate }}</button>
</nav>
<section class="carousel">
  <div 
    #carousel 
    class="carousel-inner"
  >
    <swe-series-card
      *ngFor="let seriesCard of series"
      [series]="seriesCard"
      [attr.data-multi]="seriesCard.episodes.length > 1"
      [episode]="seriesCard.episodes.length === 1 ? seriesCard.episodes[0] : undefined"
      [seriesId]="seriesCard.episodes.length > 1 ? seriesCard.id : undefined"
    ></swe-series-card>
  </div>
</section>

import { Component } from '@angular/core';

@Component({
  selector: 'swe-rotate',
  template: `

  <lottie-player 
    src="/assets/lottie-files/rotate-device.json"
    title="Rotate your device"
    autoplay
    loop
  ></lottie-player>
`,
  styles: [`
:host {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
:host::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: var(--sys-dialog-backdrop-bg-filter);
    background: var(--sys-dialog-backdrop-bg);
    pointer-events: none;
}

lottie-player {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14rem;
  height: 14rem;
}
  `]
})
export class RotateComponent {}

import { Month } from "../enums/month";
import { IsipMonthInfo } from "../types";

/**
 * Goals (pulled from ISIPAR 4th Grade)
 * (and then Tier 1 GK)
 */

const isipMonthGoals: IsipMonthInfo[][] = [
	[// 0
		/**
		* Tier 1 (on track) (but really need to increment these values by one)
		*/
		// {
		// 	period: Month.July,
		// 	value: 1781,
		// },
		{
			period: Month.August,
			value: 1793,
		},
		{
			period: Month.September,
			value: 1806,
		},
		{
			period: Month.October,
			value: 1820,
		},
		{
			period: Month.November,
			value: 1833,
		},
		{
			period: Month.December,
			value: 1847,
		},
		{
			period: Month.January,
			value: 1858,
		},
		{
			period: Month.February,
			value: 1868,
		},
		{
			period: Month.March,
			value: 1877,
		},
		{
			period: Month.April,
			value: 1886,
		},
		{
			period: Month.May,
			value: 1886,
		},
		{
			period: Month.June,
			value: 1886,
		},
		{
			period: Month.July,
			value: 1886,
		}
	],



	[// 1; smaller numbers...
		{
			period: Month.August,
			value: 178,
		},
		{
			period: Month.September,
			value: 181,
		},
		{
			period: Month.October,
			value: 184,
		},
		{
			period: Month.November,
			value: 188,
		},
		{
			period: Month.December,
			value: 191,
		},
		{
			period: Month.January,
			value: 194,
		},
		{
			period: Month.February,
			value: 196,
		},
		{
			period: Month.March,
			value: 198,
		},
		{
			period: Month.April,
			value: 200,
		},
		{
			period: Month.May,
			value: 202,
		},
		{
			period: Month.June,
			value: 202,
		},
		{
			period: Month.July,
			value: 202,
		}
	],
];

export default isipMonthGoals;
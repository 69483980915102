import { StudentSubjectIsip } from '../types';
import { LanguageCodes, SubjectCardState } from '../enums';
import images from './activity-images';
import isipMonthGoals from './isip-month-goals';
import isipMonthData from './isip-month-data';
import isipSubtestData from './isip-subtest-data';

const subjectsIsipInfo: StudentSubjectIsip[] = [
  // First chunk: ISIPs are not due.
  {// 0; Reading, no ISIP needed
    _name: 'ReadingIsip',
    name: 'Reading',
    sortPriority: 0,
    cardValue: 0,
    cardState: SubjectCardState.unavailable,
    image: images[3].path,
    alt_text: 'Reading ISIP thumbnail',
    subheading: 'Reading ISIP',
    description: 'ISIP is ready',
    link: ['reading/isip', 'start'],
    isNeeded: false,
	startingPeriod: 7,
	isRunningAdhoc: true,
	frequency: 1,
    subtests: isipSubtestData[0],
    totalScores: isipMonthData[0],
    tier: 1,
	goals: isipMonthGoals[0],
    isVerbal: false,
	language: LanguageCodes.English,
	modelingForGrades: [-1, 0, 1, 2, 3],
	kbIsipFolder: '',
  },
  {// 1; Lectura, no ISIP needed
    _name: 'SpanishIsip',
    name: 'Lectura',
    sortPriority: 0,
    cardValue: 1,
    cardState: SubjectCardState.unavailable,
    image: images[4].path,
    alt_text: 'Lectura ISIP thumbnail',
    subheading: 'Lectura ISIP',
    description: 'ISIP is ready',
    link: ['lectura/isip', 'start'],
	isNeeded: false,
	startingPeriod: 7,
	isRunningAdhoc: false,
	frequency: 1,
    subtests: isipSubtestData[0],
    totalScores: isipMonthData[0],
    tier: 2,
	goals: isipMonthGoals[0],
    isVerbal: false,
	language: LanguageCodes.Spanish,
	modelingForGrades: [-1, 0, 1, 2, 3],
	kbIsipFolder: '',
  },
  {// 2; Math, no ISIP needed
    _name: 'MathIsip',
    name: 'Math',
    sortPriority: 0,
    cardValue: 2,
    cardState: SubjectCardState.unavailable,
    image: images[5].path,
    alt_text: 'Math ISIP thumbnail',
    subheading: 'Math ISIP',
    description: 'ISIP is ready',
    link: ['math/isip', 'start'],
	isNeeded: false,
	startingPeriod: 7,
	isRunningAdhoc: false,
	frequency: 3,
    subtests: isipSubtestData[0],
    totalScores: isipMonthData[0],
    tier: 0,
	goals: isipMonthGoals[0],
    isVerbal: false,
	language: LanguageCodes.English,
	modelingForGrades: [-1, 0, 1],
	kbIsipFolder: '',
  },

  // First chunk: ISIPs are due. (larger #s)
  {// 3; Reading, ISIP needed, 4-digit scores, has not taken ISIP yet
    _name: 'ReadingIsip',
    name: 'Reading',
    sortPriority: 0,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: images[3].path,
    alt_text: 'Reading ISIP thumbnail',
    subheading: 'Reading ISIP',
    description: 'ISIP is ready',
    link: ['reading/isip', 'start'],
	isNeeded: true,
	startingPeriod: 7,
	isRunningAdhoc: true,
	frequency: 1,
    subtests: [],
    totalScores: [],	// they have not taken ISIP yet
    tier: 0,
	goals: isipMonthGoals[0],
    isVerbal: false,
	language: LanguageCodes.English,
	modelingForGrades: [-1, 0, 1, 2, 3],
	kbIsipFolder: '',
  },
  {// 4; Lectura, ISIP needed, 4-digit scores, taken ISIP before, no adhoc
    _name: 'SpanishIsip',
    name: 'Lectura',
    sortPriority: 0,
    cardValue: 1,
    cardState: SubjectCardState.isip,
    image: images[4].path,
    alt_text: 'Lectura ISIP thumbnail',
    subheading: 'Lectura ISIP',
    description: 'ISIP is ready',
    link: ['lectura/isip', 'start'],
	isNeeded: true,
	startingPeriod: 7,
	isRunningAdhoc: false,
	frequency: 1,
    subtests: isipSubtestData[0],
    totalScores: isipMonthData[0],
    tier: 1,
	goals: isipMonthGoals[0],
    isVerbal: false,
	language: LanguageCodes.Spanish,
	modelingForGrades: [-1, 0, 1, 2, 3],
	kbIsipFolder: '',
  },
  {// 5; Math, ISIP needed, 4-digit scores, taken ISIP before, adhoc
    _name: 'MathIsip',
    name: 'Math',
    sortPriority: 0,
    cardValue: 2,
    cardState: SubjectCardState.isip,
    image: images[5].path,
    alt_text: 'Math ISIP thumbnail',
    subheading: 'Math ISIP',
    description: 'ISIP is ready',
    link: ['math/isip', 'start'],
	isNeeded: true,
	startingPeriod: 7,
	isRunningAdhoc: true,
	frequency: 3,
    subtests: isipSubtestData[0],
    totalScores: isipMonthData[0],
    tier: 1,
	goals: isipMonthGoals[0],
    isVerbal: false,
	language: LanguageCodes.English,
	modelingForGrades: [-1, 0, 1],
	kbIsipFolder: '',
  },

  // First chunk: ISIPs are due. (smaller #s)
  {// 6; Reading, ISIP needed, 3-digit scores, taken ISIP before, adhoc
    _name: 'ReadingIsip',
    name: 'Reading',
    sortPriority: 0,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: images[3].path,
    alt_text: 'Reading ISIP thumbnail',
    subheading: 'Reading ISIP',
    description: 'ISIP is ready',
    link: ['reading/isip', 'start'],
	isNeeded: true,
	startingPeriod: 7,
	isRunningAdhoc: true,	// adhoc; retake test this month
	frequency: 1,
    subtests: isipSubtestData[1],
    totalScores: isipMonthData[5],
    tier: 0,
	goals: isipMonthGoals[1],
    isVerbal: false,
	language: LanguageCodes.English,
	modelingForGrades: [-1, 0, 1, 2, 3],
	kbIsipFolder: '',
  },
  {// 7; Lectura, ISIP needed, 3-digit scores, has not taken ISIP yet
    _name: 'SpanishIsip',
    name: 'Lectura',
    sortPriority: 0,
    cardValue: 1,
    cardState: SubjectCardState.isip,
    image: images[4].path,
    alt_text: 'Lectura ISIP thumbnail',
    subheading: 'Lectura ISIP',
    description: 'ISIP is ready',
    link: ['lectura/isip', 'start'],
	isNeeded: true,
	startingPeriod: 7,
	isRunningAdhoc: false,
	frequency: 1,
	subtests: [],	// they haven't taken ISIP yet
    totalScores: [],
    tier: 1,
	goals: isipMonthGoals[1],
    isVerbal: false,
	language: LanguageCodes.Spanish,
	modelingForGrades: [-1, 0, 1, 2, 3],
	kbIsipFolder: '',
  },
  {// 8; Math, ISIP needed, 3-digit scores, taken ISIP before, no adhoc
    _name: 'MathIsip',
    name: 'Math',
    sortPriority: 0,
    cardValue: 2,
    cardState: SubjectCardState.isip,
    image: images[5].path,
    alt_text: 'Math ISIP thumbnail',
    subheading: 'Math ISIP',
    description: 'ISIP is ready',
    link: ['math/isip', 'start'],
	isNeeded: true,
	startingPeriod: 7,
	isRunningAdhoc: false,
	frequency: 3,
    subtests: isipSubtestData[1],
    totalScores: isipMonthData[5],
    tier: 1,
	goals: isipMonthGoals[1],
    isVerbal: false,
	language: LanguageCodes.English,
	modelingForGrades: [-1, 0, 1],
	kbIsipFolder: '',
  },
];

export default subjectsIsipInfo;

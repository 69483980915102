import { Component, Input, inject } from '@angular/core';
import { ConfigurationService } from '@swe/services';
import { EpisodeCard } from '@swe/pages/lesson/classes';
import { AudioDescriptor, AudioType } from '@swe/enums';
import { AssignmentCard } from '@swe/pages/assignments/classes';
import { DeadlineStatus } from '@swe/pages/assignments/enums';

@Component({
  selector: 'swe-episode-preview',
  templateUrl: './episode-preview.component.html',
  styleUrls: ['./episode-preview.component.scss'],
})
export class EpisodePreviewComponent {
  @Input() episode!: EpisodeCard | AssignmentCard;
  @Input() parentSeries = '';
  @Input() assignmentId: number | undefined;  // AssignmentCard objects could be on series/lesson pg, but this value is only for the assignment pg
  @Input() language = 'en';
  @Input() showScreen = false;

  public types = AudioType;
  public descriptors = AudioDescriptor;
  public configService: ConfigurationService = inject(ConfigurationService);
  public TOMORROW = DeadlineStatus.DUE_TOMORROW;
  public NOW = DeadlineStatus.DUE_TODAY;
  public OVERDUE = DeadlineStatus.PAST_DUE;
  public NONE = DeadlineStatus.NONE;

  get skillName(): string {
    return (this.episode instanceof AssignmentCard) ? this.episode.skill : '';
  }

  get isAssigned(): boolean {
    return this.episode instanceof AssignmentCard ? true : false;
  }

  get dueDate(): string {
    return (this.episode instanceof AssignmentCard) ? this.episode.dateDue?.toDateString() as string : '';
  }

  get deadlineStatus(): DeadlineStatus {
    return (this.episode instanceof AssignmentCard) ? this.episode.deadlineStatus : DeadlineStatus.NONE
  }
}


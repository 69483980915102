import { 
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { AudioDescriptor, AudioType } from '../../../enums';

@Component({
  selector: 'swe-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: [
    '../../../../styles/_dialog.scss',
    './dialog.component.scss'
  ]
})
export class DialogComponent implements AfterContentInit, OnDestroy, OnChanges {

  @Input() showPopup = false;
  @Output() showPopupChange = new EventEmitter<boolean>();
  @Input() showClose = true;

  @Input() title = '';
  @Input() titleIcon = '';

  @ViewChild("dialog", { static: false }) dialog!: ElementRef<HTMLDialogElement>;
  
  changeState = new ReplaySubject<SimpleChanges>(1);
  private destroy$ = new Subject();
  clickType = AudioType.audio_SfxClick;
  clickSave = AudioDescriptor.CLICK_FINISH_SAVE;
  clickClose = AudioDescriptor.CLICK_BACK;
  isOpen = false;

  constructor() { }

  ngAfterContentInit(): void {
    this.changeState
      .pipe(
        takeUntil(this.destroy$),
        skipWhile(() => !this.dialog)
      )
      .subscribe(({showPopup}: SimpleChanges) => {
        if(showPopup?.currentValue && !this.isOpen) {
          this.open();
        } else if (!showPopup?.currentValue && this.isOpen) {
          this.close();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changeState.next(changes);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  open(): void {
    this.isOpen = true;
    this.dialog.nativeElement.showModal();
    this.showPopupChange.emit(true);
  }

  save(): void {
    this.close();
  }

  @HostListener('keydown.escape', ['$event'])
  @HostListener('keydown.esc', ['$event']) // For IE11 coverage
  close($event?: Event): void {
    if($event && !this.showClose) return;
		if (this.dialog && this.dialog.nativeElement ) {
      this.isOpen = false;
      this.dialog.nativeElement.close();
      this.showPopupChange.emit(false);
    }
  }

}

import { GradeBands, Grades } from "../enums";

export const GRADE_BANDS: Array<{
    id: number,
    label: string,
    availableGrades: Array<number>
}> = [
    {
        id: GradeBands.NONE,
        label: "None",
        availableGrades: [
            Grades.None
        ]
    },
    {
        id: GradeBands.BAND_1,
        label: "PreK-K",
        availableGrades: [
            Grades.Prekindergarten,
            Grades.Kindergarten
        ]
    },
    {
        id:  GradeBands.BAND_2,
        label: "1-2",
        availableGrades: [
            Grades.First,
            Grades.Second
        ]
    },
    {
        id:  GradeBands.BAND_3,
        label: "3-5",
        availableGrades:  [
            Grades.Third,
            Grades.Fourth,
            Grades.Fifth
        ]
    },
    {
        id:  GradeBands.BAND_4,
        label: "6-12",
        availableGrades: [
            Grades.Sixth,
            Grades.Seventh,
            Grades.Eighth,
            Grades.Freshman,
            Grades.Sophomore,
            Grades.Junior,
            Grades.Senior
        ]
    }
];
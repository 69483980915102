import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioDescriptor } from '../../enums';
import { AudioType } from '../../enums/audiotype';

@Component({
  selector: 'swe-user-rating',
  templateUrl: './user-rating-window.component.html',
  styleUrls: ['./user-rating-window.component.scss'],
})
export class UserRatingWindowComponent {
  @Input() animOffTime = 350;        // ms for the window to animate off
  @Input() animOffDelayTime = 1200;  // ms to delay animating the window off
  // if disableAfterClick is [true], after clicking a star the user-rating window 
    // needs to be disabled and then animate off-screen, else it stays on-screen 
    // and you can continue to adjust the rating
  @Input() disableAfterClick = true;
  @Input() questionLabel = "UNDEFINED";
  @Input() choiceLabels = ["", "", ""];
  
  @Input() showRater = false;  // the user-rating window needs to be shown
  @Output() showRaterChange = new EventEmitter<boolean>(false);
  @Output() ratingValue = new EventEmitter<number>();  // rating selected
  
  // ratingWindow is the HTML element of the whole user rating window
  ratingWindow: HTMLElement | undefined = undefined;
  // index of the rating button clicked (1-based)
  clickedIndex = -1;
  // set to "true" to manipulate value of "aria-disabled" on the buttons
  isDisabled = false;
  // max rating you can select
  readonly MAX_RATING = 3;

  // CSS class names
  readonly CLASS_SELECTED = "selected";
  readonly CLASS_GLOW = "glow";
  readonly CLASS_SHINE_1 = "shine";
  readonly CLASS_SHINE_2 = "ultra-shine";

  hintType = AudioType.audio_HintResults;
  hintDesc = AudioDescriptor.HINT_RESULTS;

  /**
   * Handle rating-clicked event.
   * @param event the click event from the rating button chosen
   */
  selectRating(event: Event, index: number): void {
    const element = event.currentTarget as HTMLElement;  // btn clicked
    this.clickedIndex = index + 1;
    this.handleRatingClick(element);
    this.ratingValue.emit(this.clickedIndex);
    if (this.isDisabled) {
      // We want to hide the window if they can only select once.
      this.hideRaterWindow();
    }
  }

  /**
   * Adjusts the look of the faces within the user-rating window.
   * Adds/removes classes for CSS manipulation, also disables 
   * [ratingWindow] if [disableAfterClick] is set to "true" (i.e. 
   * allow the user to select only one rating value and don't 
   * allow them to change it).
   * @param element the rating button element clicked
   */
  handleRatingClick(element: HTMLElement): void {
    // disable further clicks on the window if so desired
    if (this.disableAfterClick) {
      // top HTML element of the component since we'll be messing with it a lot after this
      this.ratingWindow = element.closest('.rating-window') as HTMLElement;

      // add class that will disable input on the window
      this.ratingWindow.classList.add("disabled");

      this.isDisabled = true;  // change value of "aria-disabled" on the buttons
    }

    // Check to see if there's a star that has been clicked. If "last" is 
      // undefined, then the component has not been interacted with yet.
    const container = element.closest('.rating-contents') as HTMLElement;
    const lastSelected = container.querySelector('.' + this.CLASS_SELECTED) as HTMLElement;
    if (lastSelected) {
      // Another btn has been clicked, so this will no longer be the "selected" rating.
      lastSelected.classList.remove(this.CLASS_SELECTED);

      // just in case, remove animation effects
      const lastBtn = lastSelected.querySelector(".rating-btn") as HTMLElement;
      if (lastBtn) {
        lastBtn.classList.remove(this.CLASS_GLOW);
        lastBtn.classList.remove(this.CLASS_SHINE_1);
        lastBtn.classList.remove(this.CLASS_SHINE_2);
      }
    }

    // Mark the clicked button as "selected" 
      // (this will make it the last class in the element's class list).
    const parentElement = element.parentElement as HTMLElement
    parentElement.classList.add(this.CLASS_SELECTED);

    // Add the animation effects
    if (this.clickedIndex === this.MAX_RATING) {
      element.classList.add(this.CLASS_GLOW);
      element.classList.add(this.CLASS_SHINE_2);
    } else if (this.clickedIndex !== 1) {
      element.classList.add(this.CLASS_SHINE_1);
    }
  }

  /**
   * Hide the user-rating window.
   */
  hideRaterWindow(): void {
    this.showRater = false;
    if (this.ratingWindow) {
      setTimeout(() => {
        // delay starting the animation by [animOffDelayTime] ms
        (this.ratingWindow as HTMLElement).classList.add("rating-window-anim-off");
        setTimeout(() => {
          // delay event emitter for having the window hidden 
            // until the animation completes in [animOffTime] ms
          this.showRaterChange.emit(this.showRater);
        }, this.animOffTime);
      }, this.animOffDelayTime);
    } else {
      this.showRaterChange.emit(this.showRater);
    }
  }

  /**
   * Returns the time it takes to animate the window offscreen in 
   * string format to use by the CSS animation.
   * @returns (string) the time in seconds
   */
  getFormattedAnimOffTime(): string {
    return "" + (this.animOffTime/1000) + "s";
  }
}

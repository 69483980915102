<article 
  class="card card-series card-shadow"
  tabindex="0"
>
  <figure class="card-image">
    <img 
      crossorigin
      [src]="thumbnail"
      [src-fallback]="fallbackThumbnail"
    />
  </figure>
  <svg-icon *ngIf="hasAssignment" class="card-assign-icon" src="/assets/icons/mini-folder.svg"></svg-icon>
  <swe-progress
    *ngIf="series.progress"
    [progress]="series.progress"></swe-progress>
  <header
    *ngIf="!!series.standardName"
    class="card-skill"
  ><span>{{ series.standardName }}</span></header>
  <div class="card-title">
    <h4>{{ series.title }}</h4>
  </div>
</article>
<ng-container *ngIf="isMulti">
  <span class="card-bg-top"></span>
  <span class="card-bg-middle"></span>
  <span class="card-bg-bottom"></span>
</ng-container>
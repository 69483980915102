import { gql } from "apollo-angular";

export const ACTIVITY_LIST_QUERY = gql`
query ($input: MicrolessonHierarchyInput!) {
  microlessonByHierarchy (input: $input) {
    sections{
      contentSectionType,
      name,
      sections {
        languageCode,
        oid,
        name,
        contentSectionType,
        sections{
          contentSectionType,
          description,
          oid,
          languageCode,
          name,
          content {
            title,
            description,
            domain,
            studentFriendlyStandardName,
            learningTargets {
              learningTargetStatement
            },
            manifest,
            teitypes,
            contentStatus,
            episodeNumber,
            grades,
            oid,
            questionCount,
            uniqueId,
            contentMedia {
              contentMediaType,
              description,
              name,
              altText,
              url
            }
          },
          sections {
            name,
            oid,
            contentSectionType,
            contentMedia {
              contentMediaType,
              description,
              name,
              altText,
              url
            },
            content {
              title,
              description,
              domain,
              studentFriendlyStandardName,
              learningTargets {
                learningTargetStatement
              },
              manifest,
              teitypes,
              contentStatus,
              episodeNumber,
              grades,
              oid,
              parentSeries,
              questionCount,
              uniqueId,
              contentMedia {
                contentMediaType,
                description,
                name,
                altText,
                url
              }
            }
            
          }
        }
      }
    }
  }
}
`;

export const ACTIVITY_GRADES_QUERY = gql`
query ($input: MicrolessonHierarchyInput!) {
  microlessonByHierarchy (input: $input) {
    sections{
      name
    }
  }
}
`;

export const HAS_INCOMPLETE_ASSIGNMENTS_QUERY = gql`
query (
  $assignedInput: ContentAssignmentStudentInput!,
  $inprogressInput: ContentAssignmentStudentInput!,
) {
  assigned: contentAssignmentStudentData (input: $assignedInput) {
    assignments {
      assignedById,
      classroomId,
      dueDate
    }
  },
  inprogress: contentAssignmentStudentData (input: $inprogressInput) {
    assignments {
      assignedById,
      classroomId,
      dueDate
    }
  }
}
`


export const ASSIGNMENTS_DATA_QUERY = gql`
query ($input: ContentAssignmentStudentInput!) {
  contentAssignmentStudentData (input: $input) {
    assignments {
      assignedById,
      classroomId,
      availableDate,
      completedDate,
      dueDate,
      id,
      status,
      content {
        product,
        title,
        uniqueId,
        oid,
        description,
        manifest,
        languageCode,
        questionCount,
        skill,
        teiTypes,
        contentMedia {
          altText,
          contentMediaType,
          url
        },
        learningTargets {
          learningTargetStatement
        }
      }
    }
  }
}
`;

export const MICROLESSON_QUERY = gql`
query ($input: MicrolessonInput!) {
  microlessonData (input: $input) {
    content {
      product,
      skill,
      domain,
      title,
      description,
      languageCode,
      contentMedia {
        url,
        contentMediaType
      }
    }
  }
}`
import { AppConfigParams, AudioParams, NetStoreParams, UserConfigParams} from "@swe/enums";
import { UrlParams } from "@swe/services";

export class ArgumentBuilder {
  args: string[] = [];
  params?: UrlParams;

  constructor(params: UrlParams){
    this.params = params;
    this.params && this.parseParams(); // Auto parse the params if they're passed in
  }

  /**
   * Determine if we have any arguments after parsing
   * @returns true if there are arguments, false if there isn't.
   */
  hasArguments(): boolean {
    return this.args.length > 0;
  }

  /**
   * Parse the params for anything within the wasm argument specific enums,
   * add to the args string array if found.
   * One string per config group.
   */
  parseParams(): void {
    this.addArgIfExists(this.parseSection(AudioParams));
    this.addArgIfExists(this.parseSection(NetStoreParams));
    this.addArgIfExists(this.parseSection(AppConfigParams));
    this.addArgIfExists(this.parseSection(UserConfigParams));
  }

  /**
   * Search the params passed in by the url for matches to a specific enum
   * which represents a group of config options for the runtime arguments
   * @param configEnum The enum for a config group to search the params with.
   * @returns string representing the runtime argument expectation.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseSection(configEnum: any): string{
    let config = '';
    if(this.params) {
      const paramKeys = Object.keys(this.params);
      for(const key of paramKeys) {
        if(Object.values<string>(configEnum).includes(key)){
          if(config.length > 0){
            config = config.concat(',');
          }
          config = config.concat(key, '=', this.params[key])
        }
      }  
    }
    if(config.length > 0 ){
      this.addCIfNeeded();
      config = configEnum.SectionName + '[' + config + ']';
    }
    return config;
  }

  /**
   * If we're making changes, we need to include -c once in the beginning of the arguments.
   */
  addCIfNeeded(): void{
    if(!this.args.includes('-c')){
      this.args.push('-c');
    }
  }

  /**
   * Adds config string to args array if it's not empty
   * @param config a config string to add if it's got args
   */
  addArgIfExists(config: string): void{
    if(config.length > 0){
      this.args.push(config);
    }
  }

  /**
   * Gets the args for the runtime
   * @returns the string array of arguments for the wasm runtime
   */
  getArgs(): string[]{
    const [control, ...sequence] = this.args;
    return [control, sequence.join(',')];
  }
}

 /* eslint-disable  @typescript-eslint/no-explicit-any */
// Create a new service file called `SignalRService` and move the hubConnection code there
import { inject, Injectable } from '@angular/core';
import {
  HubConnectionBuilder,
  HttpTransportType,
  HubConnectionState,
  HubConnection,
} from '@microsoft/signalr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  readonly serviceName: string = 'SignalRService';

  private hubConnectionBuilder: HubConnectionBuilder = inject(HubConnectionBuilder);
  private hubConnection!: HubConnection;
  private _debug = false;

  //Connect to the SignalR server
  async connectToServer(url: string, debug?: boolean): Promise<boolean | void> {
    this._debug = debug || false;
    this.hubConnectionBuilder
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
    this.hubConnection = this.hubConnectionBuilder.build();
    return this.hubConnection
      .start()
      .then(() => {
        if (debug) {
          console.log(`${this.serviceName}: Connected to ${url}`);
        }
        return true;
      })
      .catch((err) => {
        console.warn(
          `${this.serviceName}: Error while starting connection: ${err}`
        );
      });
  }

  //Subscribe to a message from the SignalR server
  subscribeMessage(methodName: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.hubConnection.on(methodName, (...args: any) => {
        if (this._debug) {
          console.log(`${this.serviceName}: message received.`, args);
        }
        observer.next(args);
      });
    });
  }

  //Invoke a method on the SignalR server
  async invokeMethod(methodName: string, ...args: any): Promise<any> {
    if (this.hubConnection.state === HubConnectionState.Connected){
      try {
        return await this.hubConnection.invoke<any>(methodName, ...args);
      } catch (error) {
        console.error(`${this.serviceName}: Error invoking method: ${error}`);
      }  
    }
  }

  //Disconnect from the SignalR server
  async unsubscribeServer(): Promise<void> {
    return this.hubConnection.stop();
  }
}

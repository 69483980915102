<section>
  <fieldset id="isip-data-skip-flag">
    <legend>ISIP Skip Flag</legend>
    <form [formGroup]="isipSkipToggleForm">
      <div class="custom-control custom-switch isip-override">
        <input
          id="isip-skip-switch"
          class="custom-control-input switch"
          formControlName="isipSkipSwitch"
          type="checkbox"
          [checked]="assessmentApiService.canSkipAssessment"
          (change)="assessmentApiService.canSkipAssessment = !assessmentApiService.canSkipAssessment"
        />
        <label for="isip-skip-switch" class="custom-control-label">Skip ISIP?</label>
      </div>
    </form>
  </fieldset>
</section>
<section>
  <fieldset id="isip-data-override">
    <legend>ISIP Score/Goal Data</legend>
    <form [formGroup]="isipDataToggleForm">
      <div class="custom-control custom-switch isip-override">
        <input
          id="isip-override-switch"
          class="custom-control-input switch"
          formControlName="isipDataOverrideSwitch"
          type="checkbox"
          [checked]="assessmentApiService.useDataOverride"
          (change)="assessmentApiService.useDataOverride = !assessmentApiService.useDataOverride"
        />
        <label for="isip-override-switch" class="custom-control-label">Enable ISIP Data Override?</label>
      </div>
    </form>
    <form *ngIf="assessmentApiService.useDataOverride" [formGroup]="isipDataForm">
      <hr>
      <div class="form-group" *ngFor="let tier of ['Best','Worst']; let i = index">
        <label [for]="'isipSubtest'+tier">{{tier}} Subtest:</label>
        <select [id]="'isipSubtest'+tier" [formControlName]="'isipSubtest'+tier">
          <optgroup *ngFor="let subject of availableSubtests" [label]="subject.name">
            <option *ngFor="let s of subject.subtests" [value]="s[0]">
              {{s[0]}} ({{s[1]}})
            </option>
          </optgroup>
        </select>
      </div>
      <p>** Need at <b>least one score</b> for the following data (will autofill goals afterwards if there are none filled out, as well as correct any that are invalid). Stars will be calculated based on score/goal values.</p>
      <table formArrayName="isipPeriodFormArray">
        <tr>
          <th>Period</th>
          <th *ngFor="let _ of [].constructor(NUM_PERIODS); let i=index" class="align-center">{{i}}</th>
        </tr>
        <tr *ngFor="let dataType of ['Score','Goal']">
          <th>{{dataType}}s</th>
          <td *ngFor="let _ of isipPeriodFormArray.controls; let i=index" [formGroupName]="i">
            <input [id]="'isipPeriod'+dataType" [formControlName]="'isipPeriod'+dataType" type="number" [min]="SCORE_MIN" [max]="SCORE_MAX" />
          </td>
        </tr>
      </table>
      <hr>
      <button id="isipFormSubmitButton" type="submit" class="btn btn-system-primary" (click)="setAssessmentApiDataFromIsipForm()">Save</button>
      <button id="isipFormClearButton" type="button" class="btn btn-system-primary" (click)="resetIsipForm()">Reset</button>
    </form>
  </fieldset>
</section>
interface activityImages {
	name: string,
	path: string,
}

const images: activityImages[] = [
	{
		name: "Learning_Reading",
		path: 'assets/thumbnails/Reading.png',
	},
	{
		name: "Learning_Lectura",
		path: 'assets/thumbnails/Lectura.png',
	},
	{
		name: "Learning_Math",
		path: 'assets/thumbnails/Math.png',
	},

	{
		name: "ISIP_Reading",
		path: 'assets/thumbnails/ReadingISIP_0.png',
	},
	{
		name: "ISIP_Lectura",
		path: 'assets/thumbnails/LecturaISIP_0.png',
	},
	{
		name: "ISIP_Math",
		path: 'assets/thumbnails/MathISIP_0.png',
	},
	{
		name: "ESPAROrf",
		path: 'assets/thumbnails/EsparOrf.png',
	},
	{
		name: "EspanolOrf",
		path: 'assets/thumbnails/EspanolOrf.png',
	}	,
	{
		name: "EspanolRAN",
		path: 'assets/thumbnails/EspanolRAN.png',
	},
	{
		name: "ISIPAROrf",
		path: 'assets/thumbnails/ISIPAROrf.png',
	},
	{
		name: "Orf",
		path: 'assets/thumbnails/Orf.png',
	},
	{
		name: "RAN",
		path: 'assets/thumbnails/RAN.png',
	}
];

export default images;
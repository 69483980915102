<div
  #runtimeContainer 
  [ngClass]="(this.wasmService.isLoading) ? 'off-screen' : 'runtime'"
>
  <canvas
    #runtimeCanvas
    id="canvas"
    oncontextmenu="event.preventDefault()"
    tabindex="-1"
  ></canvas>
  <button
    type="button"
    class="btn btn-fullscreen"
    *ngIf="showFullscreenButton" 
    (click)="toggleFullScreen()" 
  >
    <span class="icon-fullscreen"></span>Fullscreen
  </button>
  <swe-rotate *ngIf="showOrientationOverlay"></swe-rotate>
</div>
<swe-loading [ngClass]="(this.wasmService.isLoading) ? 'loading' : 'hidden'"></swe-loading>
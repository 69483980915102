import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WasmComponent } from './wasm.component';
import { RotateComponent } from './rotate.component';
import { SharedModule } from '../../components/shared.module';
import { LottiePlayerModule } from '../lottie-player/lottie-player.module';

@NgModule({
  declarations: [
    WasmComponent,
    RotateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LottiePlayerModule
  ],
  exports: [
    WasmComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WasmRuntimeModule { }

import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AudioService } from '../../services/audio.service';
import { UserService } from '../../services/user.service';
import { AudioType, GradeBands } from '../../enums';

@Directive({
  selector: '[sweAudioHint]'
})
export class AudioHintDirective implements OnInit, OnDestroy {

  type!: AudioType;
  desc!: string;
  gradeBand: GradeBands = GradeBands.NONE;

  @Input() set hintType(val: AudioType) {
    if (val) {
      this.type = val;
    }
  }
  @Input() set hintDesc(val: string) {
    if (val) {
      this.desc = val;
    }
  }
  private mouseOverTimer!: NodeJS.Timeout;
  private idSound!: string;
 
  constructor( private el: ElementRef, 
    private audioService: AudioService,
    private userService: UserService,
    private translate: TranslateService) {

  }

  ngOnInit(): void {
    this.userService.gradeBand.subscribe((gb) => {
      const gradeBand = gb as GradeBands;
      if (gradeBand !== this.gradeBand) {
        this.gradeBand = gradeBand;
      }
    });
    if (this.type != AudioType.audio_Undefined) {

      // Obtain the current language and check if an equivalent for that language exists...
      const lang = this.translate.currentLang;
      if (lang !== "en") {

        this.desc = this.audioService.getLanguageEquivalent(this.desc, lang);
      }

      this.idSound = this.audioService.initAudio(this.type, this.desc).id;
    }
  }

  ngOnDestroy(): void {
    this.audioService.release(this.idSound);
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.isHoverEnabled()) {
      if (this.audioService.isAudioPlaying() == false) {
        if (
          this.idSound != undefined
          && this.audioService.isAudioLoaded(this.idSound)
        ) {
          this.mouseOverTimer = setTimeout(() => this.audioService.playSound(this.idSound), 300);        
        }
      }
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.isHoverEnabled()) {
      if (this.idSound != undefined) {
        this.audioService.fadeAudio(this.idSound, 1.0, 0.0, 2000);
      }
      clearTimeout(this.mouseOverTimer);
    }
  }

  @HostListener('click') 
  onClick(): void {
    if (this.audioService.isAudioPlaying() == false) {
      if (
        this.idSound != undefined 
        && this.audioService.isAudioLoaded(this.idSound)
      ) {
        this.audioService.playSound(this.idSound);
      }
    }  
  }

  private isHoverEnabled() : boolean {
    return this.gradeBand === 1;
  }
}

import { Injectable } from '@angular/core';
import { ConfigData, ConfigDataValue } from '../../types';
import { SupervisorDataTypes } from './../../enums';
import { supervisorGetData } from '@istation-hydra/runtime-wasm/JSWasmApi.js';
import { LCMDateTime, isLCMDateTime } from '../lcm-data-common';

@Injectable()
export class ConfigDataWASM implements ConfigData {
	userOid!: number;
	readonly storageName = 'ConfigData';

	supervisorGetDataRef = supervisorGetData;

	/**
	 * (Call this after setting userOid.)
	 * loads and saves the ConfigData.
	 * Called when user data is created, 
	 * or from [get] method when data is not found.
	 */
	async loadData(): Promise<string | null> {
		if (!this.userOid) {
			return null;
		}
		const rawString: string | undefined = this.supervisorGetDataRef(this.userOid, SupervisorDataTypes.CONFIG);
		if (rawString) {
			sessionStorage.setItem(this.storageName, rawString);
			return rawString;
		}
		return null;
	}

	/**
	 * Gets the specified key from the saved ConfigData.
	 * @param section ConfigData section of the key
	 * @param key     ConfigData key to get
	 */
	async get(section: string, key: string = ''): Promise<ConfigDataValue> {
		const data = sessionStorage.getItem(this.storageName) || await this.loadData()
			.catch(() => console.error("ConfigDataWasm.get: Cannot load user ConfigData"));
		const parsed: Record<string, Record<string, ConfigDataValue>> = data ? JSON.parse(data) : {};
		const isValidSection = parsed
														&& Object.keys(parsed).length
														&& Object.keys(parsed).includes(section);
		// console.log({parsed}, 'length:', Object.keys(parsed).length, 'hasSection:', Object.keys(parsed).includes(section), {isValidSection});

		if (isValidSection && !key) {
			// empty key parameter; return the section data as string
			return JSON.stringify(parsed[section]);
		} else if (isValidSection && Object.keys(parsed[section]).includes(key)) {
			const value = parsed[section][key];
      return isLCMDateTime(value) ? new Date((value as LCMDateTime).variantValue * 1000) : value;
		}
		
		// data invalid, or section was not found, or key in section was not found
		console.error(`ConfigDataWASM.get: either ${this.storageName} was not valid, ${section} section was not found, or ${key} was not found under ${section}.`);
		return null;
	}

	/**
	 * Clears stored data.
	 * Called when the user logs out.
	 */
	clearData(): void {
		sessionStorage.removeItem(this.storageName);
	}
}
<div class="rating-window"
	[style.--animRatingTimeOff]="getFormattedAnimOffTime()"
>
	<div class="rating-contents">
		<span class="rating-header" sweAudioHint [hintType]="hintType" [hintDesc]="hintDesc">
			{{questionLabel}}
		</span>
		<ul>
			<li
				*ngFor="let _ of [].constructor(MAX_RATING); let i=index"
				id="rating-{{i}}"
				class="rating-container"
			>
				<button
					class="rating-btn focus-outline hover-special icon-{{i}}"
					[attr.aria-label]="choiceLabels[i]"
					[attr.aria-disabled]="isDisabled"
					(click)="selectRating($event, i)"
				></button>
				<div class="rating-label">{{choiceLabels[i]}}</div>
			</li>
		</ul>
	</div>
</div>
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioHintDirective } from '../directives/audio-hint.directive';
import { AudioClickDirective } from '../directives/audio-click.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        AudioHintDirective,
        AudioClickDirective,
      ],
      exports: [
        AudioHintDirective,
        AudioClickDirective
      ],
      schemas: [CUSTOM_ELEMENTS_SCHEMA],
    })
    export class AudioHelperSharedModule {}
        
import { IsipSubtest } from '../types';
import { IsipSubtestName } from '../enums';
import isipMonthData from './isip-month-data';

const isipSubtestData: IsipSubtest[][] = [
	[
		{
			name: IsipSubtestName.CMP,
			scores: isipMonthData[1],
		},
		{
			name: IsipSubtestName.SPL,
			scores: isipMonthData[2],
		},
		{
			name: IsipSubtestName.TF,
			scores: isipMonthData[3],
		},
		{
			name: IsipSubtestName.VOC,
			scores: isipMonthData[4],
		},
	],
	[
		{
			name: IsipSubtestName.CMP,
			scores: isipMonthData[6],
		},
		{
			name: IsipSubtestName.SPL,
			scores: isipMonthData[7],
		},
		{
			name: IsipSubtestName.TF,
			scores: isipMonthData[8],
		},
		{
			name: IsipSubtestName.VOC,
			scores: isipMonthData[9],
		},
	],
]

export default isipSubtestData;
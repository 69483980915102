<section>
  <menu
    *ngIf="featureService.isFlagEnabled('allowThemeChooser') else themePromo" 
    class="theme">
    <li 
      *ngFor="let theme of themes"
      class="theme-option">
      <input
        type="radio"
        name="theme"
        [formControl]="themeFormControl"
        [id]="'theme-option-'+ theme.short_name"
        [value]="theme.short_name"
        [checked]="currentTheme === theme.short_name" />
      <label [for]="'theme-option-'+ theme.short_name">{{theme.name}}</label>
    </li>
  </menu>
  <ng-template #themePromo>
      <figure class="promo-image">
        <img src="/assets/theme-painting.svg" class="painting"/>
        <img src="/assets/thumbnails/theme_1.jpg" class="theme-thumb" />
        <img src="/assets/thumbnails/theme_2.jpg" class="theme-thumb" />
        <img src="/assets/thumbnails/theme_3.jpg" class="theme-thumb" />
        <img src="/assets/thumbnails/theme_4.jpg" class="theme-thumb" />
        <img src="/assets/thumbnails/theme_5.jpg" class="theme-thumb" />
        <caption>Coming Soon!</caption>
      </figure>
  </ng-template>
</section>
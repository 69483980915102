<section>
  <fieldset id="debug-edit-ini">
    <legend>Edit INI</legend>
    <header class="btn-group" >
      <button type="button" class="btn btn-system-primary" id="debugPanelLoadButton" #loadButton tabindex=-1 (click)="loadIni()">Load IS.ini</button>
      <button type="button" class="btn btn-system-primary" id="debugPanelSaveButton" #saveButton tabindex=-1 (click)="saveIni()">Save IS.ini</button>
    </header>
    <textarea id="debugPanelOutput" #output class="form-control" rows="9" tabindex=-1></textarea>
  </fieldset>

  <fieldset id="debug-log-file">
    <legend class="form-label" for="logListBox">Download Log File</legend>
    <div class="input-group">
      <select class="form-select" id="logListBox" #logList [disabled]="!(this.wasmService.logFiles | async)?.length">
      <ng-container *ngIf="(this.wasmService.logFiles | async) as logs; else loadingOption">
        <option>Select a log file</option>
        <option *ngFor="let log of logs" [value]="log.path">{{log.name}}</option>
      </ng-container>
      <ng-template #loadingOption>
        <option>Loading...</option>
      </ng-template>
      </select>
      <button class="btn btn-system-primary" id="debugPanelDownloadButton" type="button" tabindex=-1 (click)="downloadLog()" [disabled]="!logList.value">Download</button>
    </div>
  </fieldset>

  <fieldset id="debug-view-error-code">
    <legend>View Error Code Message</legend>
    <form [formGroup]="errorForm" (ngSubmit)="showMessageSubmit()">
      <div class="input-group">
        <select id="code" class="form-select" formControlName="errorCode">
            <option value="">Select an error code</option>
            <option *ngFor="let error of availableErrors" [value]="error.code">{{error.errorName}} ({{error.code}})</option>
        </select>
        <button type="submit" class="btn btn-system-primary">Show Message</button>
      </div>
    </form>
  </fieldset>

  <form 
    [formGroup]="executePathForm" 
    (ngSubmit)="executeFormSubmit()"
  >
    <fieldset id="debug-debug-launch">
      <legend>Debug Launch</legend>
      <div>
        <button class="btn btn-system-primary" type="button" id="openJumpSceneButton" (click)="openJumpSceneButton()" [disabled]="!this.wasmService.isOpenJumpSceneAllowed()">Open Jump Scene</button>
        <hr />
      </div>
      <div>
        <label for="start" class="form-label"> Start Path </label>
        <input id="start" class="form-control" type="text" formControlName="startPath" />
      </div>
      <div>
        <label for="end" class="form-label"> End Path </label>
        <input id="end" class="form-control" type="text" formControlName="endPath" />
      </div>
      <div class="form-check">
        <input id="incl" class="form-check-input" type="checkbox" formControlName="includeEnd" />
        <label for="incl" class="form-check-label"> Include Last Path? </label>
      </div>
      <div class="form-check">
        <input id="save" class="form-check-input" type="checkbox" formControlName="rememberLast" />
        <label for="save" class="form-check-label"> Save info for later? </label>
      </div>
      <button class="btn btn-system-primary" type="submit">Launch</button>
    </fieldset>
  </form>

  <fieldset id="debug-set-date">
    <legend>Set Effective Date</legend>
    <div class="input-group">
      <input class="form-control date" type="date" id="effectiveDate" [(ngModel)]="effectiveDate"/>  
      <button type="button" class="btn btn-system-primary"  id="debugPanelDateButton" [disabled]="!checkEffectiveDate()" (click)="effectiveDateSubmit()">Time Travel</button>
    </div>
    <div *ngIf="displayDate">
      Current Effective Date: {{displayDate}}
    </div>
  </fieldset>

  <fieldset id="debug-usage">
    <legend>Usage</legend>
    <form [formGroup]="usageForm">
      <div class="custom-control custom-switch usage-override">
        <input 
          type="checkbox" 
          id="usage-override-switch"
          class="custom-control-input switch"
          formControlName="usageOverrideSwitch"
          />
        <label for="usage-override-switch" class="custom-control-label">Enable Usage Override?</label>
      </div>
      <ng-container *ngIf="this.usageService.usageOverride">
        <div class="form-group">
          <label for="usage-current" class="form-label">Current Usage</label>
          <div class="input-group">
            <input type="range" class="form-range" min="0" max="10000" step="60" id="usage-current" formControlName="usageCurrent">
            <input type="text" class="form-control" size="3" readonly [value]="this.usageService.secondsToMinutes(this.usageService.manualUsage)" />
          </div>
        </div>
        <div class="form-group">
          <label for="usage-goal" class="form-label">Usage Goal</label>
          <div class="input-group">
            <input type="range" class="form-range" min="1" max="2460" step="60" id="usage-goal" formControlName="usageGoal">
            <input type="text" class="form-control" size="3" readonly [value]="this.usageService.secondsToMinutes(this.usageService.manualUsageGoal)" />
          </div>  
        </div>
      </ng-container>
    </form>
  </fieldset>

  <fieldset id="debug-data-source">
    <legend>Data Source</legend>
    <label class="data-src-label"> {{ dataSource }} </label>
  </fieldset>

  <fieldset id="debug-user-data">
    <legend>User Data</legend>
    <button class="btn btn-system-primary" type="button" id="dumpUserObject" (click)="dumpUserObject()">Dump user object</button>
  </fieldset>

  <fieldset id="debug-assignments">
    <legend>Assignments</legend>
    <p>Data</p>
    <button class="btn btn-system-primary" type="button" id="allAssignments" (click)="dumpAssignments()">All</button>
    <button class="btn btn-system-primary" type="button" id="readingAssignments" (click)="dumpAssignments('reading')">Reading</button>
    <button class="btn btn-system-primary" type="button" id="mathAssignments" (click)="dumpAssignments('math')">Math</button>
    <button class="btn btn-system-primary" type="button" id="spanishAssignments" (click)="dumpAssignments('lectura')">Lectura</button>
    <hr>
    <p>Has incomplete</p>
    <button class="btn btn-system-primary" type="button" id="hasAllAssignments" (click)="dumpHasAssignments()">Any</button>
    <button class="btn btn-system-primary" type="button" id="hasReadingAssignments" (click)="dumpHasAssignments('reading')">Reading</button>
    <button class="btn btn-system-primary" type="button" id="hasMathAssignments" (click)="dumpHasAssignments('math')">Math</button>
    <button class="btn btn-system-primary" type="button" id="hasSpanishAssignments" (click)="dumpHasAssignments('lectura')">Lectura</button>
  </fieldset>

  <fieldset id="debug-legacy-assignments">
    <legend>Legacy Assignments</legend>
    <button class="btn btn-system-primary" type="button" id="allLegacyAssignments" (click)="dumpLegacyAssignmentStatus()">All</button>
    <button class="btn btn-system-primary" type="button" id="readingLegacyAssignments" (click)="dumpLegacyAssignmentStatus('reading')">Reading</button>
    <button class="btn btn-system-primary" type="button" id="mathLegacyAssignments" (click)="dumpLegacyAssignmentStatus('math')">Math</button>
    <button class="btn btn-system-primary" type="button" id="spanishLegacyAssignments" (click)="dumpLegacyAssignmentStatus('lectura')">Lectura</button>
  </fieldset>

  <fieldset id="debug-assessment-api">
    <legend>Assessment API</legend>
    <form name="AssessmentApiForm" (ngSubmit)="assessmentApiFormSubmit($event.submitter.name)">
      <label for="apiDataYear" class="form-label"> API School Year </label>
      <input id="apiDataYear" class="form-control" type="text" placeholder="Current year" pattern="[0-9]{4}" [(ngModel)]="apiYear" name="apiYear" #apiYearInput />
      <br>
      <button id="apiAssessmentsButton" class="btn btn-system-primary" name="raw" [disabled]="!apiYearInput.validity.valid">Raw</button>
      <button id="apiLegacyDataButton" class="btn btn-system-primary" name="transformed" [disabled]="!apiYearInput.validity.valid">Transformed</button>
    </form>
    <hr>
    <button class="btn btn-system-primary" type="button" id="apiSchoolYearButton" (click)="assessmentApiYearToConsole()">School Year</button>
  </fieldset>
</section>
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'swe-form-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class FormSelectComponent {

  @Input() label = "Select a value";
  @Input() selectedValue!: string
  @Input() options: {[index: string | number]: string } = {};

  @Output() selectedValueChange: EventEmitter<string> = new EventEmitter<string>();

  changedValue(event: string): void {
    this.selectedValue = event
    this.selectedValueChange.emit(event);
  }

}

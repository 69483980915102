import { NgModule } from '@angular/core';
import {
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
  NamedOptions,
} from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { ConfigurationService, UsageService } from '../../services';

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    UsageService,
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(c: ConfigurationService, httpLink: HttpLink): NamedOptions {
        return {
          usage: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.usageURL + 'graphql/',
            }),
          },
          assessmentResults: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.assessmentApiUrl + 'graphql/',
            }),
          },
          contentMetadata: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.contentMetadataUrl + 'graphql/',
            }),
          },
        };
      },
      deps: [ConfigurationService, HttpLink],
    },
  ],
})
export class GraphqlModule {}

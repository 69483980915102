import { Component, OnInit, OnDestroy, Input, inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { AudioType, AudioDescriptor, MessageCodes } from '../../../enums';
import { APISourceLocations, ProfilePopupAvatarChooser } from '../../../types';
import { MessageService } from '../../../features/message-popup/message.service';

@Component({
  selector: 'swe-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  isLoaded = false;
  stars = -1;
  avatar = -1;
  gradeBand = -1;
  userMenuVisible = false;
  hintType = AudioType.audio_HintMisc;
  hintDesc = 'Rewards';
  clickType = AudioType.audio_SfxClick;
  clickBack = AudioDescriptor.CLICK_BACK;
  clickMenu = AudioDescriptor.CLICK_AVATAR_THEME;

  triggers: string[] = [];

  private subscriptions = new Subscription();

  @Input()
  set showAvatarChooser(value: boolean) {
    this.avatarChooser.show = value;
  }
  get showAvatarChooser(): boolean {
    return this.avatarChooser.show;
  }
  @Input()
  set showAvatarChooserClose(value: boolean) {
    this.avatarChooser.close = value;
  }
  get showAvatarChooserClose(): boolean {
    return this.avatarChooser.close;
  }
  @Input() showBackButton = false;
  @Input() backButtonLink?: string;
  @Input() backButtonText?: string;

  showThemeChooser = false;
  showSupportMenu = false;
  avatarChooser: ProfilePopupAvatarChooser = {
    show: false,
    close: true,
  };

  // TODO: Change this to an @Input pushed in from a higher level component
  facade: APISourceLocations = 'runtime';
  private router: Router = inject(Router);

  constructor(
    public userService: UserService,
    private location: Location,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    const sub_isLoaded = this.userService.isLoaded.subscribe(
      (isLoaded) => (this.isLoaded = isLoaded)
    );
    const sub_avatar = this.userService.avatar.subscribe(
      (newAvatar) => (this.avatar = newAvatar)
    );
    const sub_stars = this.userService.stars.subscribe(
      (newStars) => (this.stars = newStars)
    );
    const sub_gradeband = this.userService.gradeBand.subscribe(
      (gradeBand) => (this.gradeBand = gradeBand)
    );

    this.subscriptions.add(sub_isLoaded);
    this.subscriptions.add(sub_avatar);
    this.subscriptions.add(sub_stars);
    this.subscriptions.add(sub_gradeband);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleUserMenu(): void {
    this.userMenuVisible = !this.userMenuVisible;
    if (!this.userMenuVisible) {
      document.getElementById('open-profile-menu')?.focus();
    }
  }

  public goBack(): void {
    if (this.backButtonLink) {
      this.router.navigate([this.backButtonLink]);
    } else {
      this.location.back();
    }
  }

  logoutPopup(): void {
    this.messageService.showMessage(MessageCodes.APP_LOGOUT_CONFIRM);
  }
}

import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'swe-toast',
  standalone: true,
  imports: [ CommonModule ],
  template: `<ng-content name="[icon]"></ng-content><span class="toast-message"><ng-content></ng-content></span><ng-content select="[action]"></ng-content>`,
  styles: [`
:host {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  background-color: antiquewhite;
  border-radius: 1.25rem;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.65);
  color: rgb(48, 27, 0);
}
`],
  animations: [
    trigger('toastAnimation', [
      state('void', style({
        opacity: 0,
        bottom: '-2rem'
      })),
      state('visible', style({
        opacity: 1,
        bottom: '2rem'
      })),
      transition('void => visible', [
        animate('300ms ease-out')
      ]),
      transition('visible => void', [
        animate('300ms ease-in')
      ])
    ])
  ],
})
export class ToastComponent {
  @HostBinding('@toastAnimation') toastAnimation = 'visible';
}

import { Injectable } from '@angular/core';
import { MESSAGES } from '../../data/messages';
import { fromEvent, Subject } from 'rxjs';
import { CustomEventMessage, MessagePopup } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public messageData = new Subject<MessagePopup | undefined>();
  public triggerData = new Subject<string>();
  // for any miscellaneous info that may be needed for the popup
  public miscInfoMap = new Map();
  
  private onOkClickedFunction: (() => void) | undefined;
  
  constructor() { 
    fromEvent<CustomEventMessage>(document, 'RuntimeMessageAlertBox')
      .subscribe(({
        detail: {
          OnOkClicked, 
          ErrorCode
        }
      }) => {
        if(OnOkClicked) this.onOkClickedFunction = OnOkClicked;
        this.handleError(ErrorCode);
      });
  }

  handleError(errorCode: number): void {
    this.messageData.next(MESSAGES.find(e => e.code == errorCode));
  }

  showMessage(id: number, okFn?: () => void): void {
    this.onOkClickedFunction = okFn;
    this.messageData.next(MESSAGES.find(e => e.code == id));
  }

  onOkClicked(): void {
    this.onOkClickedFunction?.call(this);
  }

  setOkClicked(okFn: () => void): void {
    this.onOkClickedFunction = okFn;
  }

  sendTrigger(trigger: string): void {
    this.triggerData.next(trigger);
  }

  clearTriggers(): void {
    this.triggerData.next();
  }

}

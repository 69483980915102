import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { FeatureFlagPreloadingStrategy } from './classes/feature-flags/preload-strategy';
import { PageNotFoundComponent } from './components/page-not-found.component';
import {
  ActivityGuard,
  DebugGuard,
  DirectURLGuard,
  FeatureFlagGuard,
  ReturnFromExternalGuard,
  SubjectNameGuard,
  WasmNavigationGuard,
} from './guards';
import { FeatureFlagsEnum } from './enums';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  // Home is the default route
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
//    canActivate: [CompatibilityGuard],
      canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'verbals',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/activity/activity.module').then(
        (m) => m.ActivityPageModule
      ),
    canActivate: [AutoLoginPartialRoutesGuard, ActivityGuard],
    canDeactivate: [WasmNavigationGuard]
  },
  {
    path: 'activity',
    loadChildren: () =>
      import('./pages/activity/activity.module').then(
        (m) => m.ActivityPageModule
      ),
    canActivate: [AutoLoginPartialRoutesGuard, ActivityGuard],
    canDeactivate: [WasmNavigationGuard]
  },
  {
    path: ':subject/navigator',
    loadChildren: () => import('./pages/navigator/navigator.module').then(m => m.NavigatorModule),
    canActivate: [SubjectNameGuard, DirectURLGuard],
  },
  {
    path: ':subject/explore',
    loadChildren: () => import('./pages/explore/explore.module').then(m => m.ExploreModule),
    canActivate: [SubjectNameGuard, DirectURLGuard],
  },
  {
    path: ':subject/tools',
    loadChildren: () => import('./pages/math-tools/math-tools.module').then(m => m.MathToolsModule),
    canActivate: [SubjectNameGuard, DirectURLGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    data: { flags: FeatureFlagsEnum.DEBUG_TOOLS, featureFlagRedirect: 'access-denied' },
    canActivate: [FeatureFlagGuard],
  },
  {
    path: 'reading',
    loadChildren: () =>
      import('./pages/subject/reading.module').then((m) => m.ReadingModule),
    data: {
      subject: 'Reading',
    },
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'lectura',
    loadChildren: () =>
      import('./pages/subject/lectura.module').then((m) => m.LecturaModule),
    data: {
      subject: 'Lectura',
    },
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'math',
    loadChildren: () =>
      import('./pages/subject/math.module').then((m) => m.MathModule),
    data: {
      subject: 'Math',
    },
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: ':subject/isip',
    loadChildren: () => import('./pages/isip-flow/isip-flow.module').then((m) => m.IsipFlowModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'app-launch',
    loadChildren: () => import('./pages/app-launch/app-launch.module').then((m) => m.AppLaunchPageModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: ':subject/assignments',
    loadChildren: () => import('./pages/assignments/assignments.module').then((m) => m.AssignmentsModule),
    canActivate: [ReturnFromExternalGuard, AutoLoginPartialRoutesGuard, SubjectNameGuard],
    data: {returnFrom:['microlessonsURL']},
  },
  {
    path: ':subject/lesson',
    loadChildren: () => import('./pages/lesson/lesson.module').then((m) => m.LessonModule),
    canActivate: [ReturnFromExternalGuard, AutoLoginPartialRoutesGuard, SubjectNameGuard],
    data: {returnFrom:['microlessonsURL']},
  },
	{
    path: ':subject/lesson/series/:seriesId',
    loadChildren: () => import('./pages/lesson-series/series.module').then((m) => m.SeriesModule),
    canActivate: [ReturnFromExternalGuard, AutoLoginPartialRoutesGuard, SubjectNameGuard],
    data: {returnFrom:['microlessonsURL']},
  },
  {
    path: ':subject/assessment',
    loadChildren: () => import('./pages/isip-flow/features/simplified/simplified.page').then(({SimplifiedPageModule}) => SimplifiedPageModule),
    canActivate: [AutoLoginPartialRoutesGuard, DebugGuard],
  },
  {
    path: 'components-test',
    loadChildren: () =>
      import('./pages/components-test/components-test.module').then((m) => m.ComponentsTestModule),
    canActivate: [AutoLoginPartialRoutesGuard, DebugGuard ],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },

  { path: '**', component: PageNotFoundComponent }, // Capture missing routes
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: FeatureFlagPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
// providers: [CompatibilityGuard],
})
export class AppRoutingModule {}

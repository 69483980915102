/*
 * The type of TEIs (technology enhanced items)
 * ex. Multiple choice
 * 
 * Captured 2/26/2024 from @istation-hydra/olp-html-practice/src/app/shared/enums/template-id.ts
 */
export enum TemplateID {
  NONE = -1,
  MULTIPLE_CHOICE,
  INLINE_CHOICE,
  TRUE_FALSE_CHOICE,
  TEXT_ENTRY,
  MULTIPLE_CHOICE_IMAGE,
  MULTI_SELECT,
  MULTI_SELECT_IMAGE,
  STATIC_HORIZONTAL_SEQUENCING,
  DYNAMIC_HORIZONTAL_SEQUENCING,
  STATIC_VERTICAL_SEQUENCING,
  // T_CHART,
  // VENN_DIAGRAM,
  // MATCHING,
  // BASIC_SORTING,
  // BASIC_SORTING_ALT,
  // DROP_BUCKET,
  FILL_IN_THE_BLANK_TILE_TEXT,
  CONSTRUCTED_RESPONSE,
  CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  EXTENDED_CONSTRUCTED_RESPONSE,
  EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  SORTING_BUCKETS,
  SORTING_BUCKETS_IMAGES,
}

export enum ConstructedResponse {
  CONSTRUCTED_RESPONSE = "Short Constructed Response",
  EXTENDED_CONSTRUCTED_RESPONSE = "Extended Constructed Response",
}

export const ConstructedResponseTypes = [
  TemplateID.CONSTRUCTED_RESPONSE,
  TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  TemplateID.EXTENDED_CONSTRUCTED_RESPONSE,
  TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
];

export const ComplexFeedbackTypes = [
  TemplateID.INLINE_CHOICE,
  TemplateID.TEXT_ENTRY,
  TemplateID.STATIC_HORIZONTAL_SEQUENCING,
  TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING,
  TemplateID.STATIC_VERTICAL_SEQUENCING,
  ...ConstructedResponseTypes,
];

export const SaveIntervalTypes = [...ConstructedResponseTypes];

export const NonMutableTryTypes = [
  TemplateID.TRUE_FALSE_CHOICE,
  ...ConstructedResponseTypes,
];
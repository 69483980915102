export class STATE_LAUNCH_BUTTON {
    static LAUNCH = new STATE_LAUNCH_BUTTON('Launch Istation');
    static FULLSCREEN = new STATE_LAUNCH_BUTTON('Fullscreen');
    static RELOAD =  new STATE_LAUNCH_BUTTON('Reload');
    label: string;
    constructor(label: string) {
        this.label = label;
    }
    toString() {
        return `${this.label}`;
    } 
}

export const STATE_LAUNCH_BUTTON_B = {
    LAUNCH:     "Launch Istation",
    FULLSCREEN: "Fullscreen",
    RELOAD:     "Reload",
};
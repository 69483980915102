import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
  import { TrackingService } from '../services/tracking.service';
  
  @Directive({
    selector: '[sweTracking]',
  })
  export class TrackingDirective implements AfterViewInit {
    @Input() sweTracking!: { event: string; user_id?: string } | string;
    @Input() href?: string;
    monthName?: string;
    subjectName?: string;
  
    constructor(
      private el: ElementRef,
      private trackService: TrackingService,
      private route: ActivatedRoute,
    ) {}
  
    ngAfterViewInit(): void {
      const {month, subject} = this.el.nativeElement.dataset;
      if (month) this.monthName = month;
      if (subject) this.subjectName = subject;
    }

    @HostListener('click')
    trackClick(): void {
      if (
        typeof this.sweTracking === 'object' &&
        // eslint-disable-next-line no-prototype-builtins
        this.sweTracking.hasOwnProperty('event')
      ) {
        this.trackService.push(this.sweTracking);
      } else if (this.sweTracking === 'cta') {
        this.trackService.push({
          location: this.route.url,
          click_url: this.href,
          click_text: this.el.nativeElement.innerText,
        });
      } else if (this.sweTracking === 'isip_progress_visualization_month_click' && this.monthName && this.monthName !== "undefined") {
        this.trackService.push({event: this.sweTracking, month: this.monthName});
      } else if (this.sweTracking === 'subject_dashboard_open') {
        const t: {event: string, location: string, subject?: string} = {event: this.sweTracking, location: "More"};
        if (this.subjectName) t.subject = this.subjectName;
        this.trackService.push(t);
      } else {
        this.trackService.push({
          event: this.sweTracking
        })
      }
    }
  }

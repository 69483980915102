/**
* Ensures that an Element is in view by scrolling some ancestor of the Element such that the
* bounding rectangle of child element is inside the bounding rectangle of the ancestor Element.
* 
* Element.scrollIntoView() can have undesired behavior when wanting to scroll in only one 
* direction.
* 
* @param child The element to ensure is in view
* @param view The Element that will be scrolled
* @param options - Options for how the view Element should scroll
* @param options.scrollY - If true, the view Element will scroll vertically if needed to bring 
*  the child Element into view.
* @param options.scrollX - If true, the view Element will scroll horizontally if needed to bring 
*  the child Element into view.
* @param options.scrollX - specifies a number of pixels to add to a scroll amount as padding.
* @returns None (void)
*/
export function ensureElementVisible(
  child: HTMLElement | null,
  view: HTMLElement | null,
  {scrollX = false, scrollY = true, padding = 0}: {scrollX?: boolean, scrollY?: boolean, padding?: number} = {},
): void {
  if (!child || !view) {
    return;
  }

  const childRect = child.getBoundingClientRect();
  const viewRect = view.getBoundingClientRect();

  const scroll = {left: 0, top: 0}
  if (scrollX) {
    if (childRect.left < viewRect.left || childRect.width > viewRect.width) {
      scroll.left = childRect.left  - viewRect.left  - padding;
    } else if (childRect.right > viewRect.right) {
      scroll.left = childRect.right - viewRect.right + padding;
    }
  }

  if (scrollY) {
    if (childRect.top < viewRect.top || childRect.height > viewRect.height) {
      scroll.top = childRect.top - viewRect.top - padding;
    } else if (childRect.bottom > viewRect.bottom) {
      scroll.top = childRect.bottom - viewRect.bottom + padding;
    }
  }

  if (scroll.left || scroll.top) {
    view.scrollBy({
      ...scroll,
      behavior: 'smooth',
    });
  }
}
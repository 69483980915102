import { Injectable } from '@angular/core';
import { ConfigData, ConfigDataValue } from '../../types';
import { APIHandler } from '../api-handler';

@Injectable()
export class ConfigDataAPI implements ConfigData {

	userOid!: number;
	readonly configData = 'ConfigData';
	readonly path_base ='config-data';
	readonly school_year = new Date().getFullYear();
	private apiHandler: APIHandler;
	
	constructor(){
		this.apiHandler = new APIHandler({
            baseUrl: 'https://api-priv.hydra.dev.istation.net/olpdata-priv-4kcfhmbzk9',
        });
	}

	/**
	 * Sets ConfigData to sessionStorage
	 */
	async loadData(): Promise<string | null> {
		if (!this.userOid) {
			return Promise.reject(null);
		}
		
		const rawData = await this.retrieveConfigData();
		if (rawData) {
			const rawDataSting = JSON.stringify(rawData);
			sessionStorage.setItem(this.configData, rawDataSting);
			return Promise.resolve(rawDataSting);
		}
		return Promise.reject(null);
	}

	/**
	 * Gets the specified key from ConfigData.
	 * @param section ConfigData section of the key
	 * @param key     ConfigData key to get, optional
	 */
	async get(section: string, key?: string): Promise<ConfigDataValue> {
		const data = 
			sessionStorage.getItem(this.configData) || 
			await this.loadData()
				.catch(() => console.error("ConfigDataAPI.get: Cannot load user ConfigData"));
		
		let parsed: Record<string, Record<string, string>>;
		parsed = data ? JSON.parse(data)['modules'] : {};
		// if config data does not contain modules
		if(!parsed) parsed = data ? JSON.parse(data) : {};
		// if data not parsed correctly
		if(typeof parsed === 'string') parsed = JSON.parse(String(parsed));
		
		const isValidSection = 
					parsed &&
					Object.keys(parsed).length !== 0 &&
					Object.keys(parsed).includes(section);

		if (isValidSection && !key) {
			// empty key parameter; return the section data as string
			return JSON.stringify(parsed[section]);
		} else if (isValidSection && key && Object.keys(parsed[section]).includes(key)) {
			// return key value as string
			return parsed[section][key];
		}
		// data invalid
		console.error(`ConfigDataAPI.get: failed to retrieve requested data. Please make sure section/key is valid`);
		return null;
	}

	clearData(): void {
		sessionStorage.removeItem(this.configData);
	}

  retrieveConfigData(): Promise<{}> {
    const path = this.buildPath();
    return this.apiHandler.get(path);
  }

  private buildPath(){
    return `${this.path_base}/${this.school_year}/${this.userOid}`;
  }

}
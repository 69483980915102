//browsers 
const chrome =  { browser: 'chrome', minVersion: 88 };
const edge =    { browser: 'edge', minVersion: 96 };
const firefox = { browser: 'firefox', minVersion: 1 };
const safari =  { browser: 'safari', minVersion: 16.2 };
const mobileChrome =  { browser: 'mobile chrome', minVersion: 88 };
const mobileFirefox =  { browser: 'mobile firefox', minVersion: 1 };
const mobileSafari = { browser: 'mobile safari', minVersion: 16.2 };

const CompatibilityData = [
    {
        os: 'Windows',
        supported: [  chrome, edge, ],
        experimental: [ firefox, ],
    },
    {
        os: 'Chrome OS',
        supported: [ chrome, edge, ],
        experimental: [ firefox, ],
    },
    {
        os: 'Chromium OS',
        supported: [ chrome,  ],
        experimental: [],
    },
    {
        os: 'Android',
        supported: [ chrome, edge, ],
        experimental: [ firefox, mobileFirefox, mobileChrome, ],
    },
    {
        os: 'macOS',
        supported: [ chrome, edge, safari, ],
        experimental: [ firefox, ],
    },
    {
        os: 'Mac OS',
        supported: [ chrome, edge, safari, ],
        experimental: [ firefox, ],
    },
    {
        os: 'ipad',
        supported: [ chrome, safari, mobileSafari, ],
        experimental: [ mobileChrome, ],
    },
];

export default CompatibilityData;
<div class="sys dialog" *ngIf="!hidden">
  <section class="popup dialog-content">
      <img [src]="getErrorImagePath()" class="error-image" [alt]="messagePopup.imagePath">

      <header class="dialog-header">
        <h2 class="head-text">
          {{messagePopup.headText}}
        </h2>
      </header>

      <article class="dialog-body">
        <p class="message-text" *ngIf="!hasLink">
          {{messagePopup.messageText}}
        </p>

        <p class="message-text" *ngIf="hasLink">
          {{splitMessageFirst}} <a [href]="messagePopup.hyperLink">{{messagePopup.hyperLinkText}}</a>{{splitMessageSecond}}
        </p>

        <div class="dialog-countdown" *ngIf="messagePopup.code === idleMessageId">
          <p>You will be logged out in </p>
          <swe-count-down [countdownLength]="idleTimerSeconds"></swe-count-down>
        </div>
      </article>

      <footer class="dialog-footer">
        <button class="btn btn-system-secondary" *ngIf="this.messagePopup.buttons.length > 1" (click)="clickButton(this.messagePopup.buttons[1][0])">
          {{this.messagePopup.buttons[1][1]}} &nbsp; <svg-icon *ngIf="hasRightButtonImage" [src]="getButtonImagePath(1)"></svg-icon>
        </button>

        <button class="btn btn-system-primary" *ngIf="this.messagePopup.buttons[0][1].length > 0" (click)="clickButton(this.messagePopup.buttons[0][0])">
          {{this.messagePopup.buttons[0][1]}} &nbsp; <svg-icon *ngIf="hasLeftButtonImage" class="invert" [src]="getButtonImagePath(0)"></svg-icon>
        </button>
      </footer>
  </section>
  <div class="dialog-backdrop"></div>
</div>

<div class="sys fullscreen" *ngIf="!fullscreenHidden">
  <section>

    <img [src]="getErrorImagePath()" class="fullscreen-error-image" [alt]="messagePopup.imagePath">

    <h2 class="fullscreen-head-text">
      {{messagePopup.headText}}
    </h2>

    <p class="fullscreen-message-text" *ngIf="!hasLink">
      {{messagePopup.messageText}}
    </p>

    <p class="fullscreen-message-text" *ngIf="hasLink">
      {{splitMessageFirst}} <a [href]="messagePopup.hyperLink">{{messagePopup.hyperLinkText}}</a>{{splitMessageSecond}}
    </p>

    <button *ngIf="this.messagePopup.buttons[0][1].length > 0" (click)="clickButton(this.messagePopup.buttons[0][0])" class="btn btn-system-primary">
      <svg-icon *ngIf="hasLeftButtonImage" [src]="getButtonImagePath(0)"></svg-icon>  &nbsp; {{this.messagePopup.buttons[0][1]}}
    </button>
  </section>
</div>



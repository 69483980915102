const paths: {[index: string] : string} = {
  MainMenuFeatured: 'ControlRoom.WasmPowerPath',
  ControlScene: 'ControlRoom.WasmPowerPath.PPW_MainMenu',
  WasmStartup: 'ControlRoom.WasmStart',
  IntroToComputers: 'Screeners.ScreenerCAT_IntroToPC',
  IntroToComputersEs: 'ISIP_Espanol.ISIP_Espanol_IntroToPC',
  IntroToTabletsEs: 'ISIP_Espanol.ISIP_Espanol_IntroToTS',
  IntroToComputersMath: 'ISIPMath.ISIPMath_Early.ISIPMath_IntroToPC',
  KnowledgeKnights: 'Testing.MCO.DungeonDudes_start',
  ReadingIsip: 'ControlRoom.StudentExperience.Isip.Reading_start',
  ReadingLinear: 'ControlRoom.StudentExperience.Linear.Reading_start',
  ReadingFeatured: 'ControlRoom.StudentExperience.FeaturedActivities.Reading_start',
  ReadingAssignments: 'ControlRoom.StudentExperience.Assignments.Reading_start',
  ReadingIpractice: 'Ipractice.PowerPath_IPractice.PowerPath_IPractice_Reading',
  ReadingBooks: 'Ipractice.Ipractice_Library.Ipractice_Ipractice',
  ReadingWorldOfWonders: 'Ipractice.Ipractice_TimelessTales2.WorldofWonders',
  MathIsip: 'ControlRoom.StudentExperience.Isip.Math_start',
  MathLinear: 'ControlRoom.StudentExperience.Linear.Math_start',
  MathFeatured: 'ControlRoom.StudentExperience.FeaturedActivities.Math_start',
  MathAssignments: 'ControlRoom.StudentExperience.Assignments.Math_start',
  MathIpractice: 'Ipractice.PowerPath_IPractice.PowerPath_IPractice_Math',
  MathGames: 'Math.Math_IpracticeArcade_graph.Math_Arcade',
  MathTools: '',
  LecturaIsip: 'ControlRoom.StudentExperience.Isip.Spanish_start',
  MatematicasIsip: 'ControlRoom.StudentExperience.Isip.SpanishMath_start',
  LecturaLinear: 'ControlRoom.StudentExperience.Linear.Spanish_start',
  LecturaNovedades: 'ControlRoom.StudentExperience.FeaturedActivities.Spanish_start',
  LecturaAssignments: 'ControlRoom.StudentExperience.Assignments.Spanish_start',
  LecturaLibros: 'Ipractice.Ipractice_Libros.Ipractice_Ipractice',
  LecturaIpractice: 'Ipractice.PowerPath_IPractice.PowerPath_IPractice_Spanish',
  ClassicIpractice: 'Ipractice.Ipractice_Ipractice.Ipractice_Ipractice',
  TeacherStation: 'Ipractice.Ipractice_TeacherStation.Ipractice_Ipractice',
  Tt2WorldOfWonders: 'Ipractice.Ipractice_TimelessTales2.WorldofWonders',
  Tt2Arcade: 'Ipractice.Ipractice_TimelessTales2.Arcade',
  WritingRules: 'ControlRoom.StudentExperience.WritingRules',

};
export default paths;

import { Component, OnInit } from '@angular/core';
import { MessageService } from '../features/message-popup/message.service';
import { MessageCodes } from '../enums';

@Component({
  selector: 'swe-page-not-found',
  template: ``,
})
export class PageNotFoundComponent implements OnInit {

  constructor(public messageService: MessageService) { }

  ngOnInit(): void {
    this.messageService.showMessage(MessageCodes.HTTP_NOT_FOUND);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from '../components/dialog/dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ThemeChooserComponent } from '../components/theme-chooser/theme-chooser.component';
import { SharedModule } from '../../components/shared.module';
import { AudioHelperSharedModule } from './audio-helper-shared.module';
import { AvatarChooserComponent } from '../components/avatar-chooser/avatar-chooser.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
    DebugComponent,
    FlagOverrideControlComponent,
    GeneralDebugControlComponent,
    IsipDebugControlComponent,
} from '@swe/features/debug-menu';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        AudioHelperSharedModule,
        FormsModule,
        AngularSvgIconModule,
        ThemeChooserComponent,
        IsipDebugControlComponent,
        GeneralDebugControlComponent,
    ],
    /**
     * Components which will need to be nested in a dialog need to be added!
     */
    declarations: [
        DialogComponent,
        AvatarChooserComponent,
        DebugComponent,
        FlagOverrideControlComponent,
    ],
    exports: [
        DialogComponent,
        ThemeChooserComponent,
        AvatarChooserComponent,
        DebugComponent,
        FlagOverrideControlComponent,
    ]
})
export class DialogModule { }
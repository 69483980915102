<div
	class="sys dialog"
	[attr.aria-hidden]="!showPopup">
	<section sweTrapFocus class="dialog-content">
		<header class="dialog-header">
			<!-- not back-button -->
			<svg-icon
				*ngIf="pageState === STATE_MAIN; else backButtonTemplate"
				src="assets/icons/support-icon.svg"
				alt="Support"
			></svg-icon>
			<!-- back-button -->
			<ng-template #backButtonTemplate>
				<button type="button"
					class="btn btn-system-tertiary btn-back"
					sweAudioClick
					(click)="back()"
					[audioType]="clickType"
					[descriptor]="clickClose"
					aria-label="Back">
					<svg-icon src="assets/icons/back.svg"></svg-icon>
				</button>
			</ng-template>

			<h2>Support</h2>

			<!-- close-button -->
			<button type="button"
				class="btn btn-system-tertiary btn-close"
				*ngIf="showClose"
				sweAudioClick
				(click)="close()"
				[audioType]="clickType"
				[descriptor]="clickClose"
				aria-label="Close">
				<svg-icon src="assets/icons/close.svg"></svg-icon>
			</button>
		</header>

		<!-- MAIN PAGE; default state, Show this if not [STATE_LOG] or [STATE_SUBMITTED]. -->
		<div
			*ngIf="pageState === STATE_MAIN; else pageSendLogTemplate"
			class="dialog-body"
			>
			<h3>Contact Support</h3>
			<div class="contact-block">
				<p>Mon - Fri</p>
				<p>7:00 AM to 6:30 PM CT</p>
			</div>
			<div class="contact-block contact-block-icon">
				<svg-icon src="assets/icons/phone-icon.svg" alt="Phone"></svg-icon>
				<span>
					<p>Main: 214-237-9300</p>
					<p>Toll Free: 1-866-883-7323, press 2</p>
				</span>
			</div>
			<div class="contact-block contact-block-icon">
				<svg-icon src="assets/icons/email-icon.svg" alt="Email"></svg-icon>
				<span>
					<a href="mailto:support@istation.com" title="Click to send email">Support@istation.com</a>
				</span>
			</div>
			<div class="contact-block-bottom">
				<span class="versions">
					Runtime Version: {{wasmService.runtimeVersion | async}}<br/>
					Experience Version: {{appVersion}}
					<swe-source-switch
						*ngIf="debugUser"
						[source]="source"
						(sourceChange)="sourceChange.emit($event)"
					></swe-source-switch>
				</span>
				<button class="btn-send-log" (click)="sendLogsClicked()">Send Logs</button>
			</div>
		</div>

		<!-- SEND LOG PAGE; Form to fill out to send log. -->
		<ng-template #pageSendLogTemplate>
				<ng-container *ngIf="pageState === STATE_LOG; else pageSubmittedTemplate">
					<div class="dialog-body form">
						<h3>Send Logs</h3>
						<div class="form-container">
							<p class="info-text">Sending log files is for advanced troubleshooting only.
								<br>Please
								<button class="btn-back-link" (click)="back()">
									contact Support
								</button>
								prior to sending this file.</p>
							<form [formGroup]="logInfoForm">
								<div>
									<div class="form-group">
										<label for="name">Name</label>
										<input type="text"
												id="name"
												name="name"
												class="form-control"
												formControlName="name"
												required>
										<div *ngIf="name.invalid && (name.dirty || name.touched)"
												class="alert">
											<div *ngIf="name.errors?.['required']" class="alert">
												Please enter your name.
											</div>
										</div>
									</div>

									<div class="form-group">
										<label for="phoneEmail">Phone or Email</label>
										<input type="text"
											id="phoneEmail"
											name="phoneEmail"
											class="form-control"
											formControlName="phoneEmail"
											required>
										<div *ngIf="phoneEmail.invalid && (phoneEmail.dirty || phoneEmail.touched)" class="alert">
											Please enter a phone number or email address.
										</div>
									</div>

									<span class="form-label-optional">
										Optional
									</span>
									<div class="form-group">
										<label for="power">Enter a short description of the observed problem.</label>
										<textarea id="description"
												name="description"
												class="form-control"
												formControlName="description">
										</textarea>
									</div>

								</div>
							</form>
						</div>
					</div>
					<footer class="dialog-footer">
						<button class="btn btn-system-secondary" type="reset"  (click)="back()">
							Cancel
						</button>
						<button class="btn btn-system-primary" type="submit" [disabled]="logInfoForm.invalid" (click)="submitSendLogs()">
							Send
						</button>
					</footer>
				</ng-container>

				<!-- LOG SUBMITTED PAGE; Confimation and view log(s) submitted. -->
				<ng-template #pageSubmittedTemplate>
					<div class="dialog-body">
						<h3>Send Logs</h3>
						<details>
							<summary>Log IDs</summary>
							<div class="log-container">
								<div *ngFor="let log of logIds" class="log-content">
									<svg-icon src="assets/icons/log-icon.svg" alt="Log"></svg-icon>
									<span>Log ID: {{log}}</span>
									<span>Sent</span>
								</div>
							</div>
						</details>
					</div>
					<footer class="dialog-footer">
						<button class="btn btn-system-primary" (click)="close()">
							Close
						</button>
					</footer>
				</ng-template>

		</ng-template>
	</section>
</div>
<div class="dialog-backdrop"></div>

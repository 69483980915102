import { Injectable } from '@angular/core';
import { AudioInitResult, AudioResource, HowlerResource } from '../types/audio-types';
import { AudioType } from '../enums/audiotype';
import { Themes } from '../enums';
import { Howl, Howler } from 'howler';
import { audioResources, audioMappings, audioStickies, audioLanguageMap } from '../data/audio-resources';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { filter } from 'rxjs/operators';

export type AudioSequenceItem = {
  /** Delay (in milliseconds) before this sequence item is played */
  delay: number,
  resource: HowlerResource
};
export type AudioSequenceData = {
  cancelled: boolean;       // provide ability to cancel sequence playback in progress...
  sounds: Array<AudioSequenceItem>;
};
export type AudioSequence = Map<string, AudioSequenceData>;

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  public loadedAudioId = new Subject<string>();
  public playbackStartId = new Subject<string>();
  public playbackEndId = new Subject<string>();
  private soundsArray: HowlerResource[] = [];
  private soundsSequence: AudioSequence = new Map();
  private currentSound = "";
  private queuedSound = "";
  private readonly maxSounds: number = 20;       // Max that should be held in the collection at a given time. May need to be tweaked later...
  private audioPlaying = false;
  
  constructor() { 
    Howler.autoSuspend = false;
  }

  getResourceId(type: AudioType, descriptor: string, theme: Themes = Themes.DEFAULT) : string {
    return type !== AudioType.audio_Undefined 
      ? audioMappings
          .find(({
            type: audioType, 
            descriptor: audioDesc,
            theme: audioTheme
          }) => audioType === type && audioDesc === descriptor && audioTheme === theme )?.id || "" 
      : "";
  }

  initAudio(type: AudioType, descriptor: string, theme: Themes = Themes.DEFAULT) : AudioInitResult {
    let result = { id: "", loaded: false};
    const id = this.getResourceId(type, descriptor, theme);
    if (type !== AudioType.audio_Undefined && id) {
      result = this.requestStockResource(id);
    }
    return result;
  }

  getLanguageEquivalent(descriptor: string, language: string) : string {
    const spanishKeys = ["sp", "es"]
    if (spanishKeys.includes(language)) {
      const alternative = audioLanguageMap.find(({descriptor: itemDesc}) => itemDesc === descriptor);
      descriptor = alternative && alternative.spanish ? alternative.spanish : descriptor;
    } // "sp"
    return descriptor;
  }
  
  async isAudioLocked () : Promise<boolean> {
    const checkHTML5Audio = async () => {
      const audio = new Audio();
      try {
        await audio.play();
        return false;
      } catch (err) {
        return true ;
      }
    };
    try {
      return Howler.ctx.state.valueOf() === 'suspended';
    } catch (e) {
      return checkHTML5Audio();
    }
  }

  isAudioLoaded(id : string) : boolean {
    return id ? this.isAudioResourceLoaded(id) : false;
  }

  isAudioPlaying() {
    return this.audioPlaying;
  }

  resumeContext() {
    return Howler.ctx.state === 'suspended' ? Howler.ctx.resume() : Promise.resolve();
  }
  
  /**
   * Initiate a playback event for an audio resource
   * @param id The resourceId of the audio to play
   * @param preemptCurrent Stop the current playback and preempt with new audio
   * @param simultaneous Play audio simultaneously to the current playback
   * @param silent Mute the audio play
   * @returns Promise<boolean>
   */
  async playSound(
    id: string,
    preemptCurrent: boolean = false,
    simultaneous: boolean = false,
    silent:boolean = false
  ) : Promise<boolean> {
    if (preemptCurrent && this.isAudioPlaying()) {
      const current = this.getHowlerResource(this.currentSound);
      if (current && current.howler.playing()) {
        this.queuedSound = id;
        this.stopAudio(current);
        return false;
      }
    }
    if (
      id 
      && (
        (
          !this.isAudioPlaying() 
          && !simultaneous
        ) 
      || simultaneous 
      ) 
    ) {
      const sound = this.getHowlerResource(id);
      return sound?.audioLoaded 
        ? this.resumeContext().then(() => this.play(sound, silent)).then( result => !!result )
        : false;
        // In case we want to turn and handle rejections
        // Promise.reject(`audio.service - Error locating sound for: ${id}`)
      }
    // In case we want to turn and handle rejections
    /* else {
      throw `audio.service - Playback already in progress. Rejected: ${id}`;
    }
    */
    return false;
  }

  /**
   * Play a sequence of audio resources
   * @param sequenceId The identifier for the sequence to play.
   * @returns Observable caller can use to track progress.
   */
  playSequence(sequenceId: string): Subject<string> | boolean {
    const sequence = this.soundsSequence.get(sequenceId);
    if (sequence && 
      sequence.sounds.length >= 1 && 
      !sequence.cancelled) {
      const loadedSequence = Array.from(sequence.sounds);
      const firstResource = loadedSequence[0];
      // setup sequence for playback
      const sequencer$ = new Subject<string>();
      const playbackSubscription = this.playbackEndId
        .pipe(
          filter(e => loadedSequence[0].resource.id === e ), // Only listen for id's which are part of this sequence
          // tap(e => console.warn(`playback notification on sequence '${sequenceId}' for resource '${e}'; previous resource was '${loadedSequence[0].resource.id}'`)),
        )
        .subscribe(() => {
          loadedSequence.shift();
          const { resource: nextResource, delay} = loadedSequence.slice(0,1)[0] || {};
          if (nextResource && !sequence.cancelled) {
            setTimeout(() => { 
              this.playSound(nextResource.id, false, true); 
              sequencer$.next(nextResource.id);
            }, delay);
          } else {
            // un-cancel the sequence...
            sequence.cancelled = false;
            playbackSubscription.unsubscribe();
            sequencer$.complete();
          }
        });
      setTimeout(() => { 
        this.playSound(firstResource.resource.id, true, true);
        sequencer$.next(firstResource.resource.id);
      }, firstResource.delay);
      return sequencer$;
    }
    return false;
  }

  fadeAudio(id: string, start: number, end: number, duration: number) : boolean { 
    const sound = this.getHowlerResource(id);
    if (this.audioPlaying && sound ) {
      sound.howler.fade(start, end, duration);
      return true;
    }
    return false;
  }

  release(id: string) : number {
    const sound = this.getHowlerResource(id);
    return sound ? sound.dec() : -1;
  }

  addToSequence(resourceId: string, sequenceId?: string, delay: number = 0): string | boolean {
    sequenceId = sequenceId || uuid();
    const sequence = this.soundsSequence.get(sequenceId) || { cancelled: false, sounds:[] };
    const resource = this.getHowlerResource(resourceId);
    if(resource) {
      sequence.sounds.push({delay, resource});
      this.soundsSequence.set(sequenceId, sequence);
      return sequenceId;  
    } else return false;
  }

  removeSequence(sequenceId: string) {
    const sequence = this.soundsSequence.get(sequenceId);
    if (sequence) {
      this.soundsSequence.delete(sequenceId);
      return true;
    } else return false;
  }

  /**
   * Removes all current audio resources
   */
  teardown(){
    this.retireSound(this.soundsArray.length);
  }

  private requestStockResource(id: string) : AudioInitResult {
    const resource = audioResources.find(({ id: audioId }) => audioId === id);
    const sound = this.getHowlerResource(id);
    const loaded = sound ? sound.audioLoaded : false;
    // Now make sure it hasn't been requested ...
    if (resource) {
      sound ? sound.inc() : this.addAudioResource(resource);
    } else {
      id = ''
    }
    return { id, loaded };    
  }

  private addAudioResource(ar: AudioResource) : boolean {
    if (ar) {
      const sound = new HowlerResource(ar);      
      const soundfile = sound.folder + sound.fileName;
      sound.howler = new Howl({
        src: [soundfile],
        autoplay: false,
        mute: true,
        onload: () => {
          sound.audioLoaded = true;
          this.loadedAudioId.next(sound.id);
        },
        onplay: () => {
          this.audioPlaying = true;
          this.currentSound = sound.id;
          this.playbackStartId.next(sound.id);
        },
        onend: () => {
          sound.howler.mute( true );
          sound.howler.volume( 0.0) ;
          this.audioPlaying = false;
          this.currentSound = "";
          this.playbackEndId.next(sound.id);
        },
        onstop: () => {
          this.audioPlaying = false;
          this.currentSound = "";
          this.playbackEndId.next(sound.id);
          if (this.queuedSound !== "") {
            this.playSound(this.queuedSound);
            this.queuedSound = "";
          }
        }
      });
      sound.inc();
      sound.sticky = audioStickies.includes(ar.id);
      this.soundsArray.push( sound );
      if (this.soundsArray.length > this.maxSounds) {
        this.retireSound(this.maxSounds - this.soundsArray.length);
      }
      return true;
    }
    return false;
  }

    /**
   * Halts playback of the current audio
   * @param sequenceIds Array of sequence ids that could be in progress
   * @returns true if currentSound property is non-empty, false otherwise.
   */
cancelCurrentPlayback(sequenceIds: Array<string> | undefined) : boolean {

    if (this.currentSound.length) {
      // check if this is part of a sequence, and if so, cancel the sequence...

      if (sequenceIds) {
        sequenceIds.forEach(element => {
          const sequence = this.soundsSequence.get(element);
          if (sequence && sequence.sounds.filter(item => item.resource.id === this.currentSound).length) {
              sequence.cancelled = true;
          }
        });
      }
      const sound = this.getHowlerResource(this.currentSound);
      if (sound) this.stopAudio(sound);
      return true;
    }
    return false;
  }

  private stopAudio(sound: HowlerResource ): void { 
    sound?.howler?.playing() && sound.howler.stop();
  }

  private getHowlerResource(id: string): HowlerResource | undefined{
    return this.soundsArray.find(({id: itemId}) => itemId === id);
  }

  private retireSound(target : number) : number {
    let retired = 0;
    const sounds = [...this.soundsArray];
    sounds.map((sound, idx) => {
      if (
        retired < target
        && sound
        && !sound.howler.playing()  
        && !sound.getRefCount() 
        && !sound.sticky
      ) {
        // unload the Howl object...
        if (sound.howler.state() !== 'unloaded') {
          sound.howler.unload();
        }
        // then remove the array item.... 
        this.soundsArray.splice(idx, 1);
        ++retired;
      }
    });
    return retired;
  } 

  private isAudioResourceLoaded (id: string) : boolean {
    const sound = this.getHowlerResource(id);
    return sound ? sound.audioLoaded : false
  }

  private async play(sound: HowlerResource, silent: boolean = false) : Promise<boolean> {
    return this.isAudioLocked()
      .then( (result) => {
        if (!result) {
          sound.howler.play();
          if (!silent) {
            sound.howler.mute(false);
            sound.howler.volume(1.0);
          }
          return true;
        }
        return false;
      });
  }

}

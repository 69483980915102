export enum Position {
	top,
	topRight,
	right,
	bottomRight,
	bottom,
	bottomLeft,
	left,
	topLeft,
	middle,
}
import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AudioService } from '../../services/audio.service';
import { UserService } from '../../services/user.service';
import { AudioType, Themes } from '../../enums';

@Directive({
    selector: '[sweAudioClick]'
  })
  export class AudioClickDirective implements OnInit, OnDestroy {

    type!: AudioType;
    desc!: string;
    theme!: Themes;
    private idSound = "";

    @Input()
    set audioType(val: AudioType) {
      if (val) {
        this.type = val;
      }
    }
    @Input()
    set descriptor(val: string) {
      if (val) {
        this.desc = val;
      }
    }

    constructor(
      private audioService: AudioService,
      private userService: UserService
    ) {}

    ngOnInit(): void {
      this.userService.theme.subscribe((str) => {
        const theme = str as Themes;
        if (theme !== this.theme) {
          if (this.idSound !== "") {
            this.audioService.release(this.idSound);
            this.idSound = "";
          }
          this.theme = this.type === AudioType.audio_HintISIP ? Themes.DEFAULT : theme;
          this.idSound = this.audioService.initAudio(this.type, this.desc, this.theme).id;
        }
      });
    }

    ngOnDestroy(): void {
      this.audioService.release(this.idSound);
    }

    @HostListener('click')
    onClick(): void {
      if (
          !this.audioService.isAudioPlaying()
          && this.idSound 
          && this.audioService.isAudioLoaded(this.idSound)
        ) {
          this.audioService.playSound(this.idSound);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../features/message-popup/message.service';
import { MessageCodes } from '../../enums';

@Component({
  selector: 'swe-unauthorized',
  template: ``,
})
export class UnauthorizedComponent implements OnInit {

  constructor(public messageService: MessageService) { }

  ngOnInit(): void {
    this.messageService.showMessage(MessageCodes.HTTP_FORBIDDEN);
  }

}

import { inject, Injectable } from '@angular/core';
import { Apollo, ApolloBase } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  ACTIVITY_GRADES_QUERY,
  ACTIVITY_LIST_QUERY,
  MICROLESSON_QUERY,
  ASSIGNMENTS_DATA_QUERY,
  HAS_INCOMPLETE_ASSIGNMENTS_QUERY,
} from '../data/queries'
import { ContentHeader } from '@swe/types'
import {
  ContentAssignmentStudentDataResponse,
  ContentAssignmentStudentDto,
  ContentAssignmentStudentInput,
  ContentDataByHierarchyResponse,
  ContentDataRequestInput,
  ContentSection,
  HasIncompleteAssignmentsResponse,
  MicrolessonDataResponse,
} from '@swe/types/content-metadata';
import { IstationSubjects } from '@swe/enums';
import { UserService } from '@swe/services';
import { AssignmentRoute, AssignmentStatus } from '@swe/pages/assignments/enums';

@Injectable({
  providedIn: 'root',
})
export class ContentMetadataService {
  public apollo: ApolloBase;
  private userService = inject(UserService);

  constructor(
    private apolloProvidor: Apollo
  ) {
    this.apollo = apolloProvidor.use('contentMetadata');
  }

  public getContentByHierarchy(input: ContentDataRequestInput = {}): Promise<ContentSection[]> {
    const v = {
      input: input
    };

    return this.apollo.query<ContentDataByHierarchyResponse>({
      query: ACTIVITY_LIST_QUERY,
      variables: v,
      errorPolicy: 'all'
    }).pipe(
      map(({data}) => {
        return data.microlessonByHierarchy.sections;
      })
    ).toPromise();
  }

  public async doesSubjectHaveContentForGrades(subject: IstationSubjects, grades: number[]): Promise<boolean> {
    const v = {
      input: {
        subjectFilter: [subject],
        grades: grades
      }
    }
    return this.apollo.query<ContentDataByHierarchyResponse>({
      query: ACTIVITY_GRADES_QUERY,
      variables: v,
      errorPolicy: 'all'
    }).pipe(
      map(({data}) => {
        return data.microlessonByHierarchy.sections.length > 0
      })
    ).toPromise();
  }

  public async getAssignments(filters: ContentAssignmentStudentInput) : Promise<ContentAssignmentStudentDto[]> {
    const v = {
      input: {
        // The passed filters object is last so it can override the studentIdFilter if it's specified
        ...{studentIdFilter: [this.userService.id]},
        ...filters
      }
    }
    return this.apollo.query<ContentAssignmentStudentDataResponse>({
      query: ASSIGNMENTS_DATA_QUERY,
      variables: v,
      errorPolicy: 'all'
    }).pipe(
      // Filter out assignments where assignedById and classroomId are null/falsy (meaning they are self-assigned lessons)
      map(({data}) => data.contentAssignmentStudentData.assignments.filter(x => x.assignedById && x.classroomId))
    ).toPromise();
  }

  public async hasIncompleteAssignments(subject: IstationSubjects) : Promise<boolean> {
    const v: {assignedInput: ContentAssignmentStudentInput, inprogressInput: ContentAssignmentStudentInput} = {
      assignedInput: {
        subjectFilter: subject,
        studentIdFilter: [this.userService.id],
        statusFilter: AssignmentStatus.ASSIGNED,
        route: AssignmentRoute.STUDENT,
      },
      inprogressInput: {
        subjectFilter: subject,
        studentIdFilter: [this.userService.id],
        statusFilter: AssignmentStatus.IN_PROGRESS,
        route: AssignmentRoute.STUDENT,
      }
    };
    return await this.apollo.query<HasIncompleteAssignmentsResponse>({
      query: HAS_INCOMPLETE_ASSIGNMENTS_QUERY,
      variables: v
    }).pipe(
      // Filter out assignments where assignedById and classroomId are null/falsy (meaning they are self-assigned lessons)
      switchMap(({data}) => of([...data.assigned.assignments, ...data.inprogress.assignments].some(x => x.assignedById && x.classroomId)))
    ).toPromise();
  }

  public async fetchMicrolessonData(oid: number | undefined = undefined): Promise<ContentHeader[]> {
    const v = {
      input: {
        ...((oid || oid === 0) && {oid: oid})
      }
    }
    return await this.apollo.query<MicrolessonDataResponse>({
      query: MICROLESSON_QUERY,
      variables: v,
    }).pipe(
      switchMap(({data}) => {
        return of(data.microlessonData.content);
      })
    ).toPromise();
  }
}
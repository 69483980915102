import { Component, Input } from '@angular/core';

@Component({
	selector: 'swe-nav-stars',
	template: `
		<swe-number-counter #navStarContainer class="nav-stars"
			[ngClass]="{'starsAnims': doStarCountAnimation && useStarAnimation}"
			[startValue]="(numStars + starCountOffset)"
			[endValue]="numStars"
			[timeAnimDuration]="starCountDuration"
			[hasLeadingZeroes]="true"
			[isVisible]="true"
			[displayedCountLength]="3"
			[startAnims]="doStarCountAnimation"
		></swe-number-counter>
	`,
	styles: [
		`
			.nav-stars {
				height: 50px;
				font-size: 1.125rem;
				line-height: 2.75;
				background: var(--nav-stars-bg);
				border-radius: var(--nav-stars-border-radius, 3.125rem);
				display: inline-block;
				color: var(--nav-stars-text-color, white);
				padding: 0 60px 0 1rem;
			}

			.starsAnims::after {
				content: '';
				position: absolute;
				height: 50px;
				padding: 0 60px 0 0;
				background: var(--nav-stars-single-white);
				animation: 0.3s ease-out 2 alternate star_pop;
				opacity: 0;
			}
			@keyframes star_pop {
				0% {
					transform: scale(0.75);
					opacity: 0;
				}
				100% {
					transform: scale(1.2);
					opacity: 1;
				}
			}
		`,
	],
})
export class NavStarsComponent {
	
	@Input() numStars!: number;	// star count value to display

	/******************************************************************
	 * The input variables below are for counter animation is needed. *
	 *****************************************************************/

	// This is the value to offset the star counter by. 
		// negative values for [numOffset] less than the actual star-count,
		// positive values for [numOffset] more than the actual star-count
											// (for whatever reason)
	@Input() starCountOffset = 0;
	
	// if [numOffset] is not zero and [doAnimation] gets set to "true"
		// the star counter animation will be triggered
	@Input() doStarCountAnimation = false;
	
	// if [true] then star animation will trigger when countdown starts
	@Input() useStarAnimation = false;

	// speed (milliseconds) of the star counter animation
	@Input() starCountDuration = 0;


	constructor() {}
}

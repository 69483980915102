import {
  ApplicationRef,
  ComponentRef,
  Injectable,
  createComponent,
  inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EpisodePreviewComponent } from '@swe/pages/lesson/components';
import { DialogComponent } from '@swe/shared/components';
import { EpisodeCard } from '@swe/pages/lesson/classes';
import { AssignmentCard } from '@swe/pages/assignments/classes';
import { DeadlineStatus } from '@swe/pages/assignments/enums';
import { AnswerStateEnum } from '@swe/pages/lesson/enums';

interface LessonDialogOptions {
  seriesId?: string;
  assignmentId?: number;
}

@Injectable()
export class LessonDialogService {
  private dialogContainer!: ComponentRef<DialogComponent>;
  private appRef: ApplicationRef = inject(ApplicationRef);
  private router: Router = inject(Router);
  private dialogID = 'dialog-preview';

  openPreview(
    episode: EpisodeCard,
    { seriesId = undefined, assignmentId = undefined }: LessonDialogOptions = {}
  ): void {
    if (!this.dialogContainer || !document.getElementById(this.dialogID)) {
      const lessons = document.querySelector('main') as HTMLElement;
      const hostElement = document.createElement('div');
      const environmentInjector = this.appRef.injector;
      const episodeContent = this.getPreviewContent(episode, {
        seriesId,
        assignmentId,
      });
      const titleContent = episode.title;
      const episodeNumber = document.createElement('SPAN');
      const footerContent = episodeContent?.querySelector('footer');
      // We can't name slots for projectable nodes, however each node is injected into a slot by node order.
      const projectableNodes = [
        [episodeContent], // Body slot
      ];
      episodeNumber.innerText = `${episode.episodeNumber}`;
      hostElement.id = this.dialogID;
      lessons.appendChild(hostElement);
      if (footerContent) {
        projectableNodes.push(
          [...footerContent.children].map((n) => n as HTMLElement)
        ); // Footer slot
        footerContent.remove();
      } else {
        projectableNodes.push([]);
      }

      // Add tracking data to the dialog element
      hostElement.dataset.progress = episode.progress.toString();
      hostElement.dataset.episodeNumber = episode.episodeNumber.toString();
      hostElement.dataset.incorrectAnswers = (
        episode.progressDetails?.reduce((acc, q) => {
          acc += Number(q === AnswerStateEnum.INCORRECT);
          return acc;
        }, 0) || 0
      ).toString();
      hostElement.dataset.grades = episode.grades?.join(',');

      if (
        episode.episodeNumber !== 0 &&
        seriesId !== '' &&
        !this.router.url.includes('assignments')
      ) {
        projectableNodes.push([episodeNumber]);
      }
      if (episode instanceof AssignmentCard) {
        hostElement.dataset.assignment = 'true';
        hostElement.dataset.assignmentOverdue = (
          episode.deadlineStatus === DeadlineStatus.PAST_DUE
        ).toString();
      }
      this.dialogContainer = createComponent(DialogComponent, {
        hostElement,
        environmentInjector,
        projectableNodes,
      });
      this.appRef.attachView(this.dialogContainer.hostView);
      this.dialogContainer.setInput('title', titleContent);
      this.dialogContainer.instance.showPopupChange.subscribe(
        (popupChange) => !popupChange && this.dialogContainer.destroy()
      );
      this.router.events.subscribe((event) => {
        // don't close if navigation is cancelled
        if (!(this.dialogContainer && event instanceof NavigationEnd)) return;
        this.dialogContainer.destroy();
      });
    }
    this.dialogContainer.changeDetectorRef.detectChanges();
    this.dialogContainer.instance.open();
  }

  private getPreviewContent(
    episode: EpisodeCard,
    options?: LessonDialogOptions
  ): HTMLElement {
    const environmentInjector = this.appRef.injector;
    const episodePreviewRef = createComponent(EpisodePreviewComponent, {
      environmentInjector,
    });
    episodePreviewRef.setInput('episode', episode);
    episodePreviewRef.setInput('parentSeries', options?.seriesId ?? '');
    if (options?.assignmentId) {
      episodePreviewRef.setInput(
        'assignmentId',
        (episode as AssignmentCard).assignmentId
      );
    }
    episodePreviewRef.changeDetectorRef.detectChanges();
    return episodePreviewRef.location.nativeElement as HTMLElement;
  }
}

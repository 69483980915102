import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ConfigurationService } from '@swe/services';
import { Configuration } from '@swe/types';

@Injectable({
  providedIn: 'root'
})
export class ReturnFromExternalGuard  {
  configurationService = inject(ConfigurationService);
  router = inject(Router);
  document = inject(DOCUMENT)

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    // internal navigation
    if (this.router.getCurrentNavigation()?.previousNavigation) {
      return true;
    }
    // external navigation
    const returnFrom = route.data?.returnFrom;
    if (returnFrom && typeof returnFrom[Symbol.iterator] === 'function') {
      for (const r of returnFrom) {
        const match = this.configurationService.config[r as keyof Configuration]
        if (match && match === this.document.referrer) {
          return true;
        }
      }
    }
    return this.router.createUrlTree(['/home']);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  user_id?: string;

  constructor() {}

  push(data: {}): void {
    if(this.user_id) {
      Object.assign(data, {user_id: this.user_id});
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(data);
  }
}

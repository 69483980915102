<dialog
    class="sys dialog"
    #dialog
    sweTrapFocus
>
    <section class="dialog-content">
        <header class="dialog-header">
            <svg-icon *ngIf="titleIcon" [src]="titleIcon"></svg-icon>
            <h2 *ngIf="title">
                <ng-content select="[title]"></ng-content>
                {{title}}
            </h2>
            <button
                type="button"
                class="btn btn-system-tertiary btn-close"
                *ngIf="showClose"
                sweAudioClick
                (click)="close()"
                [audioType]="clickType"
                [descriptor]="clickClose"
                aria-label="Close">
                <svg-icon src="assets/icons/close.svg"></svg-icon>
            </button>
        </header>
        <article class="dialog-body">
            <ng-content>
            </ng-content>
        </article>
        <footer class="dialog-footer">
            <ng-content select="[footer]"></ng-content>
        </footer>
    </section>
</dialog>
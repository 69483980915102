export const readingEntryPoints = [
  'HumanEx',
  'ReadingPreK',
  'ReadingStage2',
  'TimelessTales',
  'Writing',
  'PowerPath_Reading',
];

export const mathEntryPoints = [
  'Math',
  'PowerPath_Math',
];

export const spanishEntryPoints = [
  'SpanishReading',
  'PowerPath_Spanish',
];
import { Howl } from 'howler';
import { AudioType } from '../enums/audiotype';
import { Themes } from '../enums';
import { AudioDescriptor } from '../enums/audio-descriptor';

export interface AudioInitResult {
    id: string;
    loaded: boolean;
}

export interface AudioResourceMapping {
    type: AudioType;
    theme: Themes;
    descriptor: AudioDescriptor;
    id: string;
}
  
export interface AudioLanguageMapping {
    descriptor: AudioDescriptor;
    spanish: AudioDescriptor;
}

//** Base audio resource  */
export interface AudioResource {
    id : string;
    folder : string;
    fileName : string;
}

export class HowlerResource implements AudioResource {
    descriptor!: string;
    id!: string;
    folder!: string;
    fileName!: string;
    
    audioLoaded = false;
    sticky = false;
    howler !: Howl;

    private refCount = 0;

    constructor(ar: AudioResource) { 
        
        if (ar != undefined) {

            this.id = ar.id;
            this.folder = ar.folder;
            this.fileName = ar.fileName;
        }
        else {
            this.id = "";
        }
    }

    getRefCount() : number {

        return this.refCount;
    }

    inc() : number {

        return ++this.refCount;
    }

    dec() : number {

        if (this.refCount > 0) {

            return --this.refCount;
        }
        return 0;
    }
}


export enum RuntimeLoginState {
  // WASM is running, however supervisor is not ready for login
  NOT_READY = 'NotReady',

  // WASM is running, and Supervisor is ready for login
  READY = 'Ready',

  // WASM running, Supervisor does not have an instance anymore to get data for the user
  LOGIN_FAIL = 'LoginFail',

  // The point at which Supervisor can get data for the user
  LOGIN_SUCCESS = 'LoginSuccess',

  // Supervisor does not have an instance anymore to get data for the user
  LOGOFF = 'Logoff',

  // not sure what state the runtime goes into here
  EXIT = 'OnAppExit',
}
import { FeatureFlagService } from './feature-flag.service';
import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';
import { UrlService } from './url.service';
import { WasmSSOSetting } from '../enums';
import CompatibilityData from "../data/compatibility-data";

export type ScreenLegacy = Screen & {
  mozOrientation?: OrientationType;
  msOrientation?: OrientationType;
};

export const MIN_SCREEN_DIMENSIONS = [800, 600];
export const MIN_WINDOW_DIMENSIONS = [1024, 600];

@Injectable({
  providedIn: 'root',
})
export class CompatibilityService {
  private browserName: string;
  private browserVersionNumber: number;
  private osName: string;
  isIpad: boolean;
  ignoringBrowser = false;
  landscapeMediaQueryList: MediaQueryList;

  constructor(
    private urlService: UrlService,
    private featureFlagService: FeatureFlagService
  ) {
    const parser = new UAParser();
    const {model, type} = parser.getDevice();
    this.browserName = (parser.getBrowser().name || '').toLowerCase();
    this.browserVersionNumber = ((v: string) => parseFloat(v))(
      parser.getBrowser().version || ''
    );
    this.osName = parser.getOS().name || '';
    this.isIpad = model?.toLowerCase() === 'ipad';
    this.landscapeMediaQueryList = window.matchMedia('(orientation: landscape)');
  }

  isLandscape(): boolean {
    const s: ScreenLegacy = this.screen;
    const orientation =
      (s.orientation || {}).type || s.mozOrientation || s.msOrientation;
    return orientation?.includes('landscape') ?? this.landscapeMediaQueryList.matches;
  }

  /**
   * Checks the compatibility of the current system
   * @returns boolean
   */
  checkCompatibility(): boolean {
    return this.checkBrowser() && this.checkScreenDimensions();
  }

  /**
   * Check the browser is compatible
   * @returns boolean
   */
  checkBrowser(): boolean {
    // Check if URL param IgnoreBrowser is set to true
    const { IgnoreBrowser } = this.urlService.getParamsFromStorage();
    if (IgnoreBrowser || this.ignoringBrowser) {
      this.ignoringBrowser = true;
      return true;
    }
    // set iPad as it's own os as all Macs seem to have the name macOS
    if(this.isIpad){
      this.osName = 'ipad';
    }
    
    const platform = CompatibilityData.find(platform => platform.os === this.osName);
    const isExperimental = this.featureFlagService.isFlagEnabled('experimentalBrowser');

    //console.warn("You are on the following platform", platform );
    if(platform){
      const browserCheck = platform.supported;
      if(isExperimental){
        browserCheck.push(...platform.experimental);
      }

      return !!browserCheck.find(m => 
          m.browser === this.browserName 
          && m.minVersion <= this.browserVersionNumber 
          );
    }
    return false;
  }

  /**
   * Check the dimensions of the computer screen
   * @returns boolean
   */
  checkScreenDimensions(): boolean {
    const { width: screenWidth, height: screenHeight } = this.screen;
    const [minX, minY] = !this.isLandscape()
      ? [...MIN_SCREEN_DIMENSIONS].reverse()
      : MIN_SCREEN_DIMENSIONS;
    return screenWidth > minX && screenHeight > minY;
  }

  /**
   * Check the dimensions of the browser viewable area
   * @returns boolean
   */
  checkWindowDimensions(): boolean {
    const { innerWidth: width, innerHeight: height } = this.window;
    const [minX, minY] = MIN_WINDOW_DIMENSIONS;
    return width > minX && height > minY;
  }

  /**
   * Determines if the user should load the legacy runtime app on an iPad 
   * (app-launch) or is allowed to run the Student Experience in the 
   * Safari browser)
   * 
   * @returns boolean
   */
  checkLegacyLauncher(): boolean {
    const { WasmSSOSetting: setting } = this.urlService.getParamsFromStorage();
    const shouldUseNativeApp =
      setting === WasmSSOSetting.FORCE_NATIVE_ON_IPAD ||
      setting === WasmSSOSetting.NONE;
    return this.isIpad && shouldUseNativeApp;
  }

  get screen(): ScreenLegacy {
    return window.screen as ScreenLegacy;
  }

  get shouldRotate(): boolean {
    const { innerWidth: width } = this.window;
    const [ minimumWidth ] = MIN_SCREEN_DIMENSIONS;
    // Is portrait orientation and will cut off sides of runtime
    return !this.isLandscape() && width <= minimumWidth;
  }

  private get window(): Pick<
    Window,
    'innerHeight' | 'innerWidth' | 'ontouchstart'
  > {
    const { ontouchstart, innerHeight, innerWidth } = window;
    return ontouchstart
      ? { innerHeight, innerWidth, ontouchstart }
      : { innerHeight, innerWidth };
  }
 
}
